import { useEffect, useRef } from "react";

export default function useInfiniteScroll(fetchMoreData: () => void, config: any) {
  const observerTarget = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          fetchMoreData()
        }
      },
      config
    );

    if (observerTarget?.current) {
      observer.observe(observerTarget?.current);
    }

    return () => {
      observer.disconnect()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMoreData, observerTarget]);

  return observerTarget
}
