import { createAsyncThunk } from "@reduxjs/toolkit";

import { removeImpersonateTokens } from "utils/apiCallHelpers";

import instance from "../../middlewares/api";
import fetchMe from "../user/fetchMe";
import { EAgency } from "../../constants";

const logoutImpersonate = createAsyncThunk(
  EAgency.logoutImpersonate,
  async (arg, thunkAPI) => {
    const { data } = await instance.get('auth/logout');

    removeImpersonateTokens()

    await thunkAPI.dispatch(fetchMe());

    return data;
  }
)

export default logoutImpersonate;
