import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EPreOrder } from '../../constants';
import { IRole } from '../../types/common';
import { IBasicPreOrder } from '../../types/preOrder';
import { ERole } from '../../../models/consts';

interface ICancelOpportunityProps {
  id: number;
  role: IRole;
}

const cancelOpportunity = createAsyncThunk<IBasicPreOrder, ICancelOpportunityProps>(
  EPreOrder.cancelOpportunity,
  async ({ id, role }) => {
    let url = '/lead-managers';
    if ([ERole.CUSTOMER_ADMIN, ERole.CUSTOMER].includes(role.name)) {
      url = '/enterprise';
    }
    const { data } = await instance.post(`${url}/pre-orders/${id}/cancel`);

    return data;
  },
);

export default cancelOpportunity;
