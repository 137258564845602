import React, { FC, useState } from 'react';
import { RefCallBack } from 'react-hook-form';
import { clone, cloneDeep } from 'lodash';

import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import BootstrapInput from '../BootstrapInput';
import { StyledDrawerWrapperBox, CloseButton, DrawerHeaderContainer } from './styled';

interface ISelect {
  id?: string;
  disabled?: boolean;
  label: string;
  labelMenuItem?: string;
  onChange: (event: { target: { name: any; value: any } }) => void;
  options: any[];
  inputRef?: RefCallBack;
  value?: any;
  name?: string;
  sx?: any;
  arrowIconSx?: any;
  isClearable?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

let oldSelectedItem: { [x: string]: any; id: any };

const Select: FC<ISelect> = ({
  label,
  labelMenuItem = 'name',
  options,
  onChange,
  disabled,
  value,
  name,
  sx,
  arrowIconSx,
  isClearable,
  onOpen,
  onClose,
  ...params
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isOpen, setOpen] = useState(false);
  const selectedItem = options.find(item =>
    typeof item.id !== 'undefined' ? item.id === value : item === value,
  );

  const revertSelectedItem = () => {
    onChange({
      target: {
        name: oldSelectedItem ? oldSelectedItem[labelMenuItem] : undefined,
        value: oldSelectedItem?.id || oldSelectedItem || '',
      },
    });
  };

  const handleOpen = () => {
    setOpen(true);

    oldSelectedItem = cloneDeep(selectedItem);

    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = (saveOnClose: boolean) => {
    if (!saveOnClose && selectedItem) {
      revertSelectedItem();
    }

    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  if (mobile) {
    return (
      <Box>
        <BootstrapInput
          dataTest={`mui-component-select-${name}`}
          placeholder={label}
          value={(selectedItem && selectedItem[labelMenuItem]) || selectedItem || ''}
          onClick={() => (disabled ? undefined : handleOpen())}
          disabled={disabled}
          withArrow
          arrowIconSx={arrowIconSx}
          readOnly
          sx={sx}
        />
        <Drawer anchor="bottom" open={isOpen} onClose={() => handleClose(false)}>
          <StyledDrawerWrapperBox id={`mui-component-select-${name}-listbox`}>
            <DrawerHeaderContainer
              container
              alignItems="flex-start"
              justifyContent="flex-start"
              flexWrap="nowrap"
            >
              <CloseButton variant="text" onClick={() => handleClose(false)} color="secondary">
                <CloseIcon />
              </CloseButton>
              <Typography variant="h4">{label}</Typography>
            </DrawerHeaderContainer>
            {isClearable && (
              <MenuItem data-option-index key="empty-option" value={''}>
                Default
              </MenuItem>
            )}
            {options.map((item: any, index: number) => (
              <MenuItem
                data-option-index={index}
                key={item[labelMenuItem] || item}
                value={item.id || item}
                onClick={() =>
                  onChange({
                    target: {
                      value: item.id || typeof item.id === 'number' ? item.id : item,
                      name: item[labelMenuItem] || item,
                    },
                  })
                }
                selected={item.id ? item.id === value : item === value}
                sx={{ fontSize: 16 }}
              >
                {item[labelMenuItem] || item}
              </MenuItem>
            ))}
            <Grid container alignItems="center" justifyContent="center" paddingTop={3.75}>
              <Button
                data-test="mobile-select-button"
                variant="contained"
                color="secondary"
                onClick={() => handleClose(true)}
                disabled={disabled || !selectedItem}
                sx={{ width: { xs: 200, sm: 'auto' } }}
              >
                Select
              </Button>
            </Grid>
          </StyledDrawerWrapperBox>
        </Drawer>
      </Box>
    );
  }

  return (
    <MuiSelect
      {...params}
      label={label}
      onChange={onChange}
      input={<BootstrapInput />}
      inputProps={{ sx: { mr: 3 } }}
      disabled={disabled}
      value={value}
      name={name}
      displayEmpty
      id={`mui-component-select-${name}`}
      MenuProps={{
        id: `mui-component-select-${name}-listbox`,
      }}
    >
      {isClearable && (
        <MenuItem data-option-index key="empty-option" value={''}>
          Default
        </MenuItem>
      )}
      {options.map((item: any, index: number) => (
        <MenuItem
          data-option-index={index}
          key={item[labelMenuItem] || item}
          value={typeof item.id !== 'undefined' ? item.id : item}
        >
          {item[labelMenuItem] || item}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

export default Select;
