import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EUser } from '../../constants';
import { ILogin } from 'models';
import { ELocalStoreKeys, mobileCookieMaxAge } from 'models/consts';
import { Cookies } from 'react-cookie';
import { Capacitor } from '@capacitor/core';

import registerUserNotification from '../mobile/registerUserNotifications';

const cookies = new Cookies(null, { path: '/', sameSite: 'lax' });
const isMobileNative = Capacitor.isNativePlatform();

const login = createAsyncThunk(EUser.login, async (payload: ILogin, thunkAPI) => {
  const response = await instance.post('auth/login', payload);
  const {
    data: { accessToken, refreshToken, id, ...newUser },
  } = response;

  cookies.set(ELocalStoreKeys.ACCESS_TOKEN, accessToken, {
    maxAge: isMobileNative ? mobileCookieMaxAge : undefined,
  });
  cookies.set(ELocalStoreKeys.REFRESH_TOKEN, refreshToken, {
    maxAge: isMobileNative ? mobileCookieMaxAge : undefined,
  });

  // Register for user notifications on successful login if the app is running on a mobile device
  if (isMobileNative) {
    try {
      //@ts-ignore
      thunkAPI.dispatch(registerUserNotification(id));
    } catch (err) {
      //Suppress this error
      console.warn(err);
    }
  }

  return newUser;
});

export default login;
