import React, { FunctionComponent, useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useAppDispatch, useAppSelector } from "store/hooks";

import ROUTES from "../../../router/constants";
import {EOrderFilter, ERole, ESorting} from "models/consts";
import {IRole} from "store/types/common";

import fetchPreOrderList from "store/query/preorder/fetchPreOrderList";
import sendCustomerInvitation from "store/query/preorder/sendCustomerInvitation";
import {
  hasMorePreOrderSelectorSelector,
  isPreOrdersLoadingSelector,
  preOrderListSelector
} from "store/selectors/getPreOrderSelector";

import { StyledTitleContainer } from './styled'

import Loader from "../../Loader/Loader";
import EmptyList from "../OrderList/EmptyList";
import PreOrderItem from "../OrderItem/PreOrderItem";
import paidOpportunity from "../../../store/query/preorder/paidOpportunity";
import MuiLink from "@mui/material/Link";
import OrderItem from "../OrderItem/OrderItem";

interface IPurchaseItem {
  filter: EOrderFilter;
  role: IRole;
  subType: any;
  route?: string;
}

const GroupedListPreOrder: FunctionComponent<IPurchaseItem> = ({ subType, filter, role, route }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const three = useMediaQuery(theme.breakpoints.between('lg', 'xxl'));

  const key = [filter, subType.key, 'ASC'].join(':') as string

  const orderList = useAppSelector(preOrderListSelector(key));
  const orderListLoading = useAppSelector(isPreOrdersLoadingSelector(key));
  const hasMore = useAppSelector(hasMorePreOrderSelectorSelector(key));

  useEffect(() => {
    if(!orderList.length && !orderListLoading && hasMore) {
      dispatch(fetchPreOrderList({
        params: {
          limit: three && 3 || 4,
          sortStartDate: ESorting.ASC,
          statuses: subType.filter
        },
        key,
        filter,
        role,
      }))
    }
  }, [dispatch, subType, filter, hasMore, key, orderList.length, orderListLoading, role, three])

  const handleAction = (id: number) => {
    if(subType.key === 'qualifyLead' || role.name === ERole.RESOURCE_MANAGER) {
      navigate(`${ROUTES.PRE_ORDER}/${id}`)
    } else if(subType.key === 'inviteCustomer') {
      dispatch(sendCustomerInvitation({id}))
    } else if(subType.key === 'finalizePaperwork' && role.name === ERole.LEAD_MANAGER) {
      dispatch(paidOpportunity({id}))
    } else if(subType.key === 'needActions') {
      navigate(`${ROUTES.PRE_ORDER}/${id}`)
    }
  }

  return (
    <Box mb={3}>
      <StyledTitleContainer>
        <Typography component="h3" variant="h1">{subType.label}</Typography>
        <MuiLink color="secondary" component={Link} to={`${route}/${subType.key}--${filter}`} >
          View All
        </MuiLink>
      </StyledTitleContainer>
      {!orderListLoading ? !!orderList.length && !orderListLoading && (
        <Grid container spacing={3}>
          {![EOrderFilter.CURRENT, EOrderFilter.FINISH_ORDERS].includes(filter) && orderList?.map((order: any) => (
            <Grid xxl={3} lg={4} sm={6} xs={12} key={`order-${order.id}`}>
              <PreOrderItem preOrder={order} onAction={handleAction} role={role} />
            </Grid>
          ))}
          {[EOrderFilter.CURRENT, EOrderFilter.FINISH_ORDERS].includes(filter) && orderList?.map((order: any) => (
            <Grid xxl={3} lg={4} sm={6} xs={12} key={`order-${order.id}`}>
              <OrderItem order={order} filter={filter} />
            </Grid>
          ))}
        </Grid>
      ) || (
        <EmptyList />
      ) : (
        <Loader />
      )}
    </Box>
  )
}

export default GroupedListPreOrder
