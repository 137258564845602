import { Capacitor } from '@capacitor/core';
import { CapacitorCookies } from '@capacitor/core';
import { ELocalStoreKeys, mobileCookieMaxAge } from 'models/consts';
import { Cookies } from 'react-cookie';

const cookies = new Cookies(null, { path: '/', sameSite: 'lax' });
const isMobileNative = Capacitor.isNativePlatform();

const deleteCapacitorCookies = async (cookieNames: string[]) => {
  const cookies = await CapacitorCookies.getCookies();

  return Promise.all(
    Object.keys(cookies).map(key => {
      if (cookieNames.includes(key)) {
        return CapacitorCookies.deleteCookie({ key });
      } else {
        return Promise.resolve();
      }
    }),
  );
};

const removeCookies = async (cookieNames: string[]) => {
  if (isMobileNative) {
    await deleteCapacitorCookies(cookieNames);
  } else {
    cookieNames.forEach(cookieName => {
      cookies.remove(cookieName);
    });
  }
};

export const removeAuthTokens = async () => {
  await removeCookies([ELocalStoreKeys.ACCESS_TOKEN, ELocalStoreKeys.REFRESH_TOKEN]);
};

export const removeImpersonateTokens = async () => {
  await removeCookies([
    ELocalStoreKeys.IMPERSONATE_TOKEN,
    ELocalStoreKeys.IMPERSONATE_REFRESH_TOKEN,
    ELocalStoreKeys.GLOBAL_IMPERSONATION,
  ]);
};

export const saveTokens = (accessToken: string, refreshToken: string) => {
  const impersonateToken = cookies.get(ELocalStoreKeys.IMPERSONATE_TOKEN);

  if (impersonateToken) {
    cookies.set(ELocalStoreKeys.IMPERSONATE_TOKEN, accessToken);
    cookies.set(ELocalStoreKeys.IMPERSONATE_REFRESH_TOKEN, refreshToken);
  } else {
    cookies.set(ELocalStoreKeys.ACCESS_TOKEN, accessToken, {
      maxAge: isMobileNative ? mobileCookieMaxAge : undefined,
    });
    cookies.set(ELocalStoreKeys.REFRESH_TOKEN, refreshToken, {
      maxAge: isMobileNative ? mobileCookieMaxAge : undefined,
    });
  }
};
