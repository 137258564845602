import {createAsyncThunk} from "@reduxjs/toolkit";

import instance from "../../middlewares/api";

import { EConsultant } from "../../constants";
import { IConsultantCapacity } from "../../types/consultant";

interface IUpdateCapacityProps {
  endDate: string;
  keySelector: string;
  newAvailability: number;
  startDate: string;
}
const updateCapacity = createAsyncThunk<IConsultantCapacity[], IUpdateCapacityProps>(
  EConsultant.updateCapacity,
  async ({ endDate, newAvailability, startDate}) => {
    const { data } = await instance.patch('consultant/calendar/capacities', { endDate, newAvailability, startDate})
    return await data
  }
)

export default updateCapacity
