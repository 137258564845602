import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { StyledBox } from './styled';
import EditIcon from 'styles/icons/EditIcon';
import CertificateListItem from './CertificateListItem';

import { EConsultantEditingModals } from 'models/consts';
import { ICertificateItem } from "store/types/certificates";

interface ICertificates {
  certificates?: ICertificateItem[],
  isCurrentUser: boolean,
  onOpen?: (open: { type: number; open: boolean }) => void,
}

const Certificates: React.FunctionComponent<ICertificates> = ({certificates, isCurrentUser, onOpen }) => {
  
  return(
    <StyledBox>
      <Box display="flex" justifyContent="space-between" alignItems="center" my={3}>
        <Typography variant="h4">Certifications</Typography>
        {isCurrentUser && onOpen && (
          <IconButton id="edit-certificates-btn" color="tertiary" size="small" onClick={() => onOpen({open: true, type: EConsultantEditingModals.CERTIFICATIONS})}>
            <EditIcon fontSize="inherit" sx={{ fill: 'none' }} />
          </IconButton>
        )}
      </Box>
      {!!certificates?.length && (
        <Box>
          {certificates.map((item) => <CertificateListItem key={item.id} item={item} />)}
        </Box>
      )}
    </StyledBox>
  );
}
 

export default Certificates;
