export enum EUser {
  newConsultant = 'user/newConsultant',
  addUserExpertise = 'user/addExpertise',
  updateUserExpertise = 'user/updateExpertise',
  createCustomerCompany = 'user/createCompany',
  updateCustomerCompany = 'user/updateCompany',
  changeCustomerCompany = 'user/changeCustomerCompany',
  logo = 'user/logo',
  updateCustomerWithCompanyCreation = 'user/updateCustomerWithCompanyCreation',

  //refactored
  addUserLanguages = 'user/addLanguages',
  changePassword = 'user/changePassword',
  forgotPassword = 'user/forgotPassword',
  login = 'user/login',
  logout = 'user/logout',
  me = 'user/me',
  setMe = 'user/setMe',
  removeUserLanguages = 'user/removeLanguages',
  resetPassword = 'user/resetPassword',
  sendVerifyCode = 'user/sendVerifyCode',
  signup = 'user/signup',
  verifyCode = 'user/verifyCode',
  updateUserLanguages = 'user/updateUserLanguages',
  updateUserInfo = 'user/updateInfo',
  updateConsultant = 'user/updateConsultant',
  updateRole= 'user/updateRole',
  impersonateUser= 'user/impersonate',
  getConsultatntList = 'user/getConsultantList',
  getCustomerList = 'user/getCustomerList',
  getAgencyList = 'user/getAgencyList',
  getAgencyTeamRoasterList = 'user/getAgencyTeamRoasterList',
}
