import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';

import BootstrapInput from '../BootstrapInput';
import CountrySelect from './CountrySelect';
import { GENERIC_PHONE_NUMBER, PHONE_NUMBER } from 'models/consts';

interface IDefaultValue {
  country: string;
  countryCallingCode?: any;
  nationalNumber?: any;
}

interface IPhoneNumber {
  name: string;
  control: any;
  defaultValue?: IDefaultValue;
  placeholder?: string;
  rules?: any;
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
}

export const PhoneNumber: FC<IPhoneNumber> = ({
  control,
  defaultValue,
  placeholder,
  rules,
  error,
  errorText,
  disabled,
}) => (
  <Box sx={{ position: 'relative' }}>
    <Box display="flex">
      <Box sx={{ mr: 2 }}>
        <Controller
          name="mobilePhone.country"
          defaultValue={defaultValue?.country || ''}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <CountrySelect {...field} inputRef={ref} disabled={disabled} />
          )}
          rules={rules}
        />
      </Box>
      <Controller
        name="mobilePhone.nationalNumber"
        control={control}
        defaultValue={defaultValue?.nationalNumber || ''}
        render={({ field: { ref, ...field } }) => (
          <BootstrapInput
            {...field}
            inputRef={ref}
            placeholder={placeholder ? placeholder : 'Type your mobile phone'}
            autoComplete="off"
            disabled={disabled}
            dataTest="mobilePhoneNumber"
          />
        )}
        rules={{
          ...rules,
          pattern: {
            value: defaultValue?.country === 'UA' ? PHONE_NUMBER : GENERIC_PHONE_NUMBER,
            message:
              defaultValue?.country === 'UA'
                ? 'Phone number should be 9 numbers'
                : 'Phone number should be 10 numbers',
          },
        }}
      />
    </Box>
    {error && <FormHelperText error>{errorText}</FormHelperText>}
  </Box>
);
