import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EPreOrder } from '../../constants';
import { IBasicPreOrder } from "../../types/preOrder";

const sendToAgency = createAsyncThunk<IBasicPreOrder, {id: number}>(
  EPreOrder.sendToAgency,
  async ({id}) => {
    const { data } = await instance.post(`/lead-managers/pre-orders/${id}/send-to-agency`);

    return data;
  }
)

export default sendToAgency;
