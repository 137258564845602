import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ESize } from 'models/consts';
import { IWorker } from 'store/types/consultant';

import StarRating from 'components/StarRating';
import { BootstrapInput } from 'components/CustomFields';

interface IDetailProps {
  consultant?: IWorker;
  onDataSave: (value: any) => void;
}

const DetailFeedback: FunctionComponent<IDetailProps> = ({ consultant, onDataSave }) => {
  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useFormContext();

  return (
    <form id="detail-feedback" onSubmit={handleSubmit(onDataSave)}>
      <DialogContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2.5}>
          <Typography fontSize="1rem">Expertise</Typography>
          <Controller
            control={control}
            name="expertiseRate"
            render={({ field }) => (
              <StarRating
                {...field}
                size={ESize.MEDIUM}
                onRatingChange={stars => field.onChange(stars)}
              />
            )}
            rules={{ required: true }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2.5}>
          <Typography fontSize="1rem">Communication</Typography>
          <Controller
            control={control}
            name="communicationRate"
            render={({ field }) => (
              <StarRating
                {...field}
                size={ESize.MEDIUM}
                onRatingChange={stars => field.onChange(stars)}
              />
            )}
            rules={{ required: true }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2.5}>
          <Typography fontSize="1rem">Availability</Typography>
          <Controller
            control={control}
            name="availabilityRate"
            render={({ field }) => (
              <StarRating
                {...field}
                size={ESize.MEDIUM}
                onRatingChange={stars => field.onChange(stars)}
              />
            )}
            rules={{ required: true }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={6.5}>
          <Typography fontSize="1rem">Timely delivery</Typography>
          <Controller
            control={control}
            name="deliveryRate"
            render={({ field }) => (
              <StarRating
                {...field}
                size={ESize.MEDIUM}
                onRatingChange={stars => field.onChange(stars)}
              />
            )}
            rules={{ required: true }}
          />
        </Stack>
        <Stack>
          <FormLabel>Leave your feedback for {consultant?.firstName}</FormLabel>
          <Controller
            control={control}
            name="feedbackText"
            render={({ field: { ref, ...field } }) => (
              <BootstrapInput
                {...field}
                inputRef={ref}
                error={!!errors.feedbackText}
                helperText={errors.feedbackText?.message || 'Incorrect data'}
                multiline
                rows={2}
              />
            )}
            rules={{
              required: true,
              maxLength: {
                message: 'The max count of characters is 1000',
                value: 1000,
              },
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid} fullWidth variant="contained" type="submit">
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

export default DetailFeedback;
