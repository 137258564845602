import { createAsyncThunk} from '@reduxjs/toolkit';

import instance from '../../middlewares/api';

import { EOrder } from '../../constants';

import { IOrderSharedLink } from "../../types/order";

const fetchSharedLinks = createAsyncThunk<IOrderSharedLink[], string>(
  EOrder.fetchSharedLinks,
  async (orderId) => {
    const { data } = await instance.get(`/orders/${orderId}/shared-links`);

    return data
  }
)

export default fetchSharedLinks;
