import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ExtendCellBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'table-cell',
    p: 0.5,
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.primary.contrastText,
  }),
);

const ExtendHeaderCellBox = styled(ExtendCellBox)({
  pb: 1.5,
  pt: 1.5,
  borderBottom: '1px solid #EBEBEB',
  fontWeight: 'bold',
});

const CalculatedValue = styled('span')({
  padding: '3px 10px',
  borderRadius: 0.5,
  display: 'block',
});

export { ExtendCellBox, ExtendHeaderCellBox, CalculatedValue };
