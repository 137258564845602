import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IActionButton } from 'models/inner-models';

import InfoIcon from 'styles/icons/InfoIcon';

const StyledSnackbarWrap = styled(Box)(({ theme }) => ({
  borderRadius: 13,
  padding: 18,
  fontSize: '.875rem',
  marginBottom: theme.spacing(3),
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  [theme.breakpoints.down('md')]: {
    borderTopLeftRadius: 13,
    borderBottomLeftRadius: 13,
  },
}));

interface ISnackBarProps {
  title: string;
  description?: string;
  button?: IActionButton;
  children?: React.ReactNode;
}

const SnackBar: FunctionComponent<ISnackBarProps> = ({ title, description, button, children }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledSnackbarWrap>
      <Grid container spacing={2}>
        {!isTablet && (
          <Grid xs="auto" sx={{ color: 'primary.main' }}>
            <InfoIcon />
          </Grid>
        )}
        <Grid xs>
          <Typography variant="h5">
            <b>{title}</b>
          </Typography>
          {children}
          {description && <Typography>{description}</Typography>}
          {button && (
            <Button onClick={button.callback} sx={{ mt: 2, width: '100%' }} variant="contained">
              {!!button.icon && button.icon}
              {button.name}
            </Button>
          )}
        </Grid>
      </Grid>
    </StyledSnackbarWrap>
  );
};

export default SnackBar;
