import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import { Drawer } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import Masonry from '@mui/lab/Masonry';

const StyledPopperBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    backgroundColor: 'rgba(31, 31, 31, 0.4)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  }),
);

const StyledDrawer = styled(Drawer)(({ theme }) =>
  theme.unstable_sx({
    '.MuiBackdrop-root': {
      transition: 'none !important',
    },
  }),
);

const StyledDrawerWrapperBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    py: 4,
    px: 2,
    backgroundColor: 'white',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
);

const CloseButton = styled(Button)({
  padding: 0,
  height: '24px',
  width: '24px',
  minWidth: '24px',
  marginRight: '14px',
});

const DrawerHeaderContainer = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    paddingBottom: '24px',
    borderBottom: '1px solid #EBEBEB',
  }),
);

const StyledMasonry = styled(Masonry)(({ theme }) =>
  theme.unstable_sx({
    maxHeight: '100% !important',
    overflow: 'unset !important',
    flexWrap: {
      xs: 'nowrap',
      md: 'wrap',
    },
    '& .MuiListSubheader-root': {
      color: theme.palette.text.primary,
    },
    [`& .${autocompleteClasses.paper}`]: {
      backgroundColor: 'red',
      maxHeight: '40vh',
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: 1.5,
      pt: 2,
      pb: 1.25,
    },
    [`& .${autocompleteClasses.groupUl}`]: {
      pb: 1,
    },
    [`& .${autocompleteClasses.option}`]: {
      borderLeft: '1px solid #D4D4D4',
      ml: 2.125,
      pl: 2.5,
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
    },
  }),
);

const StyledPaperBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    backgroundColor: 'white',
    overflowY: 'scroll',
  }),
);

export {
  StyledDrawer,
  StyledDrawerWrapperBox,
  StyledMasonry,
  StyledPaperBox,
  StyledPopperBox,
  CloseButton,
  DrawerHeaderContainer,
};
