import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledHeader = styled('header')(({theme}) => theme.unstable_sx({
  flex: '0 1 100%',
  display: 'flex',
  zIndex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: 64
}))

const StyledStickyBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  // zIndex: 1,
  backgroundColor: theme.palette.common.white,
}))


export { StyledHeader, StyledStickyBox }
