import React, { FunctionComponent } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';

import { byRole, EMAIL_REGEX } from 'models/consts';

import { useAppDispatch } from 'store/hooks';
import inviteUser from 'store/query/enterprise/inviteUser';

import BootstrapInput from '../../CustomFields/BootstrapInput';
import CustomAutocomplete from '../../CustomFields/CustomAutocomplete';

interface IAddMember {
  onClose: () => void;
}

const AddMember: FunctionComponent<IAddMember> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({ mode: 'onChange' });

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    await dispatch(
      inviteUser({
        email: data.email,
        roleId: data.role.id,
      }),
    )
      .unwrap()
      .then(() => onClose());
  };

  return (
    <>
      <DialogContent>
        <form id="add-team-member" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <InputLabel>Email</InputLabel>
            <Controller
              control={control}
              name="email"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  inputRef={ref}
                  error={!!errors.email}
                  placeholder="Enter your email"
                  helperText={errors.email?.message || 'Incorrect data'}
                  type="email"
                />
              )}
              rules={{
                required: 'This field is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email',
                },
              }}
            />
          </FormGroup>
          <FormGroup>
            <InputLabel>Choose role</InputLabel>
            <Controller
              control={control}
              name="role"
              render={({ field }) => (
                <CustomAutocomplete
                  autocompleteProps={{
                    multiple: undefined,
                  }}
                  field={field}
                  options={byRole}
                  placeholder="Select a Role"
                />
              )}
              rules={{ required: 'This field is required' }}
            />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isValid || isSubmitting}
          color="secondary"
          form="add-team-member"
          fullWidth
          type="submit"
          variant="contained"
          data-test="add-team-member"
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default AddMember;
