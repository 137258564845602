import React from 'react';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

import dayjs from 'utils/dayjs';
import { dollarFormat } from 'utils/textFormatter';

import TableCell from 'components/TableCustomized/TableCell';
import TableRow from 'components/TableCustomized/TableRow';

import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from 'styles/icons/LogoutIcon';
import { IInvoice } from 'store/types/enterprise';

interface IManageOrdersTableRow {
  row: IInvoice;
}

const ManageInvoicesTableRow: React.FunctionComponent<IManageOrdersTableRow> = ({ row }) => {
  return (
    <TableRow>
      <TableCell>
        <Typography>{row.description}</Typography>
      </TableCell>
      <TableCell>
        <Chip
          className={`${row.status}-invoice`}
          label={row.status}
          sx={{ textTransform: 'capitalize' }}
          variant="filled"
        />
      </TableCell>
      <TableCell>
        <Typography>
          <Tooltip title="USD">
            <span>$</span>
          </Tooltip>
          {dollarFormat(row.amountCents / 100)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{dayjs(row.createdAt).format('MM/DD/YYYY')}</Typography>
      </TableCell>
      <TableCell align="center">
        {row.paymentLink && (
          <Button
            variant="text"
            color="secondary"
            sx={{ padding: 0, minWidth: 'unset', height: 'auto' }}
            href={row.paymentLink}
            target="_blank"
          >
            <LogoutIcon />
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ManageInvoicesTableRow;
