import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EConsultant } from "../../constants";

interface ISaveAvatar {
  image: Blob;
  userId: number;
  key: string | number;
}

const saveAvatar = createAsyncThunk<{ avatarUrl: string }, ISaveAvatar>(
  EConsultant.saveAvatar,
  async ({ image }) => {
    const formData = new FormData();
    formData.append('avatar_image', image, 'avatar_image.jpeg');
    const { data } = await instance.post('consultant/image', formData, { headers: { 'Content-Type': 'multipart/form-data' }})

    return data;
  }
)

export default saveAvatar
