import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Accordion = styled(MuiAccordion)({
  border: 'none',
  '&.Mui-expanded': {
    margin: 0
  },
  '&.Mui-expanded::before': {
    opacity: 1
  },
  '&:before': {
    backgroundColor: '#ebebeb',
    left: 64
  },
})

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => theme.unstable_sx({
  padding: 0,
  border: 'none',
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    my: 2,
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap'
    },
    '&.Mui-expanded': {
      my: 2,
    }
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    alignSelf: {
      xs: 'start',
      lg: 'center'
    },
    mt: {
      xs: 1.5,
      lg: 0
    }
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  marginLeft: theme.spacing(8),
  paddingLeft: 0,
  paddingTop: 0,
  '& .MuiTableRow-root .MuiTableCell-head': {
    paddingTop: 0
  },
  '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
    paddingLeft: 0
  }
}))

const StyledDuration = styled(Typography)(({ theme }) => theme.unstable_sx({
  width: '33%',
  flexGrow: 1,
  flexShrink: 0,
  mx: 1,
  ml: {
    xs: 10.5,
    lg: 1
  },
  display: {
    xs: 'none',
    lg: 'block',
  }
}))

const StyledDurationMobile = styled(Typography)(({ theme }) => theme.unstable_sx({
  flexGrow: 1,
  flexShrink: 0,
  display: {
    xs: 'block',
    lg: 'none',
  }
}))

const StyledBoxCompany = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  width: {
    xs: '100%',
    lg: 'auto'
  },
  ml: {
    xs: 7,
    lg: 0
  }
}))

const StyledBoxSeniority = styled(Box)(({ theme }) => theme.unstable_sx({
  mr: {
    xs: -3,
    lg: 1
  },
  display: {
    xs: 'flex',
    lg: 'none'
  },
}))

export { Accordion, AccordionDetails, AccordionSummary, StyledBoxCompany, StyledDuration, StyledDurationMobile, StyledBoxSeniority }
