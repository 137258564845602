import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import DialogContent from '@mui/material/DialogContent';

import { useAppSelector } from 'store/hooks';

import { ECustomerRegisterModal, ERole } from 'models/consts';
import { IBasicCustomer } from 'store/types/customer';

import { getExtendMeByRoleSelector } from 'store/selectors/getUserSelector';

import Signin from 'views/Auth/Signin';
import Signup from 'views/Auth/Signup';
import CustomerProfileRegistration from 'views/Auth/CustomerProfileRegistration';
import VerificationCode from 'views/Auth/VerificationCode/VerificationCode';

import ModalWrapper from '../Modal';

interface IModalCustomerProps {
  visible: ECustomerRegisterModal | null;
  close: () => void;
}

const ModalCustomerWrapper: FunctionComponent<IModalCustomerProps> = ({ visible, close }) => {
  const customer = useAppSelector(getExtendMeByRoleSelector) as IBasicCustomer;
  const [step, setStep] = useState<ECustomerRegisterModal | null>(visible);

  useEffect(() => {
    if (visible && step === null) {
      if (!customer) {
        setStep(ECustomerRegisterModal.SIGNUP);
      } else if (!customer.isConfirm) {
        setStep(ECustomerRegisterModal.VERIFICATION_CODE);
      } else if (!customer.companies?.length || !customer.companies[0]?.countryIso) {
        setStep(ECustomerRegisterModal.PROFILE);
      } else {
        close();
      }
    }
  }, [close, customer, step, visible]);

  const changeStep = useCallback((customerStep: ECustomerRegisterModal | null) => {
    setStep(customerStep);
  }, []);

  const renderCustomerAuth = useCallback(() => {
    switch (step) {
      case ECustomerRegisterModal.SIGNIN:
        return <Signin isModal={true} onChangeCustomerStep={changeStep} />;
      case ECustomerRegisterModal.VERIFICATION_CODE:
        return <VerificationCode isModal={true} onChangeCustomerStep={changeStep} />;
      case ECustomerRegisterModal.PROFILE:
        return <CustomerProfileRegistration isModal={true} onChangeCustomerStep={close} />;
      case ECustomerRegisterModal.SIGNUP:
        return (
          <Signup
            isModal={true}
            userRole={ERole.SELF_CUSTOMER}
            onChangeCustomerStep={changeStep}
            allowToggleRole={false}
          />
        );
    }
  }, [close, step, changeStep]);

  const modalTitle = useMemo(() => {
    switch (step) {
      case ECustomerRegisterModal.SIGNIN:
        return {
          title: 'Sign in',
        };
      case ECustomerRegisterModal.VERIFICATION_CODE:
        return {
          title: 'Verify account',
          subTitle: 'Please enter the verification code we sent to your email address',
        };
      case ECustomerRegisterModal.PROFILE:
        return {
          title: 'Tell us about your business',
        };
      case ECustomerRegisterModal.VERIFICATION_SUCCESS:
        return {};
      default:
        return {
          title: 'Sign Up as a Customer',
        };
    }
  }, [step]);

  return (
    <ModalWrapper
      dialogProps={{
        onClose: () => close && close(),
        open: !!visible,
      }}
      title={modalTitle}
    >
      <DialogContent>{renderCustomerAuth()}</DialogContent>
    </ModalWrapper>
  );
};

export default ModalCustomerWrapper;
