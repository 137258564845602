import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { ECommon } from "../../constants";
import { IGetSeniorityCounts, ISearchSeniority } from "../../types/common";

const getSeniorityCounts = createAsyncThunk<IGetSeniorityCounts[], ISearchSeniority>(
  ECommon.getSeniorityCount,
  async (payload) => {
    const { data } = await instance.post('seniorityV6-separate-count', payload)
    return data
  }
)

export default getSeniorityCounts;

