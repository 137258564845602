import React, { lazy, Suspense, useCallback, useEffect } from 'react';
import CustomScroll from 'react-custom-scroll';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';

import { StyledStepContainer } from '../styled';

import { useAppSelector } from 'store/hooks';
import ROUTES from 'router/constants';

import { getMeAgencySelector } from 'store/selectors/getAgencySelector';
import { isAgencyAdminSelector } from 'store/selectors/getUserSelector';

import Loader from 'components/Loader/Loader';
const Profile = lazy(() => import('./Profile'))

const AgencyCreationFlow = () => {
  const {step = 0} = useParams();
  const navigate = useNavigate();

  const isAgencyAdmin = useAppSelector(isAgencyAdminSelector);
  const agency = useAppSelector(getMeAgencySelector);


  useEffect(() => {
    !isAgencyAdmin && navigate(ROUTES.ORDER_LIST);
  }, [ navigate, isAgencyAdmin ]);

  const renderStep = useCallback(() => {
    switch (+step) {
      default:
        return (
          <Profile agency={agency} />
        );
    }
  }, [step, agency]);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <CustomScroll heightRelativeToParent="100%" allowOuterScroll>
        <Container maxWidth="xxl" sx={{height: '100%', overflowY: 'scroll'}}>
          <StyledStepContainer>
            <Grid container sx={{ justifyContent: 'center'}}>
              <Grid xs>
                <Suspense fallback={<Loader/>}>
                  { renderStep() }
                </Suspense>
              </Grid>
            </Grid>
          </StyledStepContainer>
        </Container>
      </CustomScroll>
    </Box>
  );
}

export default AgencyCreationFlow;
