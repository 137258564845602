import { useAppSelector } from 'store/hooks';
import { categoryProductsWithoutDeprecatedSelector } from 'store/selectors/getCommonSelector';
import formatDurationYearsOnly from 'helpers/formatDurationYearsOnly';
import { IBasicConsultant } from 'store/types/consultant';

const useConsultantProductExperience = (consultant: IBasicConsultant, isSearchView?: boolean) => {
  const categoriesWithProductsWithoutDeprecatedList = useAppSelector(
    categoryProductsWithoutDeprecatedSelector,
  );

  let headLine = '';
  let description = '';
  let category = '';

  if (
    isSearchView &&
    (!consultant?.description || !consultant?.shortDescriptions) &&
    consultant?.productExperienceAge?.length
  ) {
    consultant.productExperienceAge.forEach(item => {
      const duration = formatDurationYearsOnly(item.month);
      const product = categoriesWithProductsWithoutDeprecatedList.find(
        productItem => productItem.id === item.productId,
      );
      if (!consultant?.shortDescriptions) {
        if (headLine !== '') headLine += ', ';
        headLine += `${duration} in ${product?.name}`;
      }
      if (!consultant?.description) {
        if (description === '') {
          description += `With ${duration} of expertise in ${product?.name}`;
        } else {
          description += `, ${duration} in ${product?.name}`;
        }
        if (product?.categoryName && !category.includes(product.categoryName)) {
          if (category === '') {
            category += `, expertise spans across ${product.categoryName}`;
          } else {
            category += `, ${product.categoryName}`;
          }
        }
      }
    });
  }

  return {
    headLine: consultant?.shortDescriptions || headLine,
    description: consultant?.description || description + category,
  };
};

export default useConsultantProductExperience;
