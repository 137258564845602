import React, { BaseSyntheticEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';

import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import initNotifications from 'store/query/notification/initNotifications';
import { notificationsSelector } from 'store/selectors/getNotificationSelector';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { socketInstance } from 'store/middlewares/socket';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import NotificationContainer from './Container';

const NotificationPopover: FunctionComponent<any> = () => {
  const [open, setOpen] = useState(null);

  const handleOpen = (event: BaseSyntheticEvent) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const dispatch = useAppDispatch();

  const me = useAppSelector(getMeSelector);
  const notifications = useAppSelector(notificationsSelector);

  const unreadedCount = useMemo(
    () => notifications?.filter(({ isShow }) => !isShow).length || 0,
    [notifications],
  );

  useEffect(() => {
    if (me?.id) {
      socketInstance?.disconnect();
      dispatch(initNotifications());
    }
  }, [dispatch, me?.id]);

  return (
    <>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            height: 'calc(40vh - 16px)',
            mt: 1.5,
            pl: 2,
            pr: '2px',
            py: 1,
            width: 400,
          },
        }}
      >
        <NotificationContainer handleClose={handleClose} />
      </Popover>
      <IconButton
        data-test="notification-button"
        onClick={handleOpen}
        disableRipple
        sx={{ mx: 1, pr: { xs: '34px' } }}
      >
        <Badge
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={unreadedCount}
          color="secondary"
        >
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
    </>
  );
};

export default NotificationPopover;
