import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { FavoriteIcon } from 'styles/icons/FavoriteIcon';
const StyledCommon = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    lineHeight: 1.2,
    wordBreak: 'break-word',
    maxWidth: '100%',
    maxHeight: {
      lg: 72,
    },
    overflow: {
      lg: 'hidden',
    },
    textOverflow: {
      lg: 'ellipsis',
    },
  }),
);
const StyledName = styled(StyledCommon)(({ theme }) =>
  theme.unstable_sx({
    fontSize: {
      xs: 22,
      sm: 26,
      lg: 30,
    },
  }),
);
const StyledShortDescriptions = styled(StyledCommon)(({ theme }) =>
  theme.unstable_sx({
    mb: 1,
  }),
);
const StyledIconBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: {
      sm: 'column',
      xl: 'row',
    },
    flexWrap: {
      xs: 'wrap',
      xl: 'nowrap',
    },
    justifyContent: {
      xs: 'end',
      sm: 'start',
    },
  }),
);
const StyledRavenRated = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#FDB829',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    width: '250px',
    height: '22px',
    top: '20px',
    left: '-45px',
    paddingLeft: '45px',
    transform: 'rotate(-45deg)',
  }),
);
const StyledRavenRatedText = styled(Typography)({
  color: '#fff',
  fontSize: '0.6rem',
  fontWeight: 500,
});
const StyledRecommended = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#61ADE2',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    width: '370px',
    height: '22px',
    top: '8px',
    left: '-63px',
    paddingLeft: '52px',
    transform: 'rotate(-45deg)',
  }),
);
const StyledFavoriteIcon = styled(FavoriteIcon)(({ theme }) =>
  theme.unstable_sx({
    color: '#fff',
  }),
);

const StyledSkillsBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  marginBottom: theme.spacing(3),
  borderBottom: '1px solid #ebebeb',
}));
const StyledAgencyRecommendedContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    height: 151,
    borderBottom: '1px solid #ebebeb',
    overflowX: 'auto',
    position: 'relative',
  }),
);
const StyledAgencyRecommendedWrap = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    top: 0,
    left: '50%',
    height: 150,
    transform: 'translateX(-50%)',
  }),
);
const StyledCarouselControl = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    bottom: 12,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 144,
    display: 'flex',
    justifyContent: 'space-between',
  }),
);

export {
  StyledAgencyRecommendedContainer,
  StyledAgencyRecommendedWrap,
  StyledCarouselControl,
  StyledCommon,
  StyledIconBox,
  StyledName,
  StyledShortDescriptions,
  StyledFavoriteIcon,
  StyledRavenRated,
  StyledRavenRatedText,
  StyledRecommended,
  StyledSkillsBox,
};
