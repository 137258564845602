import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { EConsultantEditingModals } from 'models/consts';
import { ITagItem } from 'store/reducers/tags';

import EditIcon from 'styles/icons/EditIcon';

import { AdditionalSkills } from 'components/ConsultantComponents';

interface ConsultantExperienceProps {
  isCurrentUser?: boolean;
  onOpen: (open: { type: number; open: boolean }) => void;
  tags: ITagItem[];
}

const StyledBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  marginBottom: theme.spacing(3),
  borderBottom: '1px solid #ebebeb',
}));

const Skills: FunctionComponent<ConsultantExperienceProps> = ({ isCurrentUser, onOpen, tags }) => (
  <StyledBox>
    <Box my={3} display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h4">Additional Skills</Typography>
      {isCurrentUser && (
        <IconButton
          data-test="edit-skills-btn"
          color="tertiary"
          size="small"
          onClick={() => onOpen({ open: true, type: EConsultantEditingModals.ADDITIONAL_SKILLS })}
        >
          <EditIcon fontSize="inherit" sx={{ fill: 'none' }} />
        </IconButton>
      )}
    </Box>
    <AdditionalSkills tags={tags} />
  </StyledBox>
);

export default Skills;
