import {createAsyncThunk} from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EConsultant } from 'store/constants';
import { IAgreement } from "../../types/consultant";

interface IAgreementForm {
  company: string;
  fullName: string;
  ipAddress: string;
}

interface CreateAgreementProps {
  agreement: IAgreementForm
  userId: number
}

const createAgreement = createAsyncThunk<IAgreement, CreateAgreementProps>(
  EConsultant.createAgreement,
  async ({ agreement }) => {
    const { data } = await instance.post('consultant/agreements', agreement)
    return await data
  }
)

export default createAgreement;
