import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

import BackgroundSVG from './background.svg';
import HalfZero from './HalfZero';

const StyledHiveBG = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    background: {
      lg: `url(${BackgroundSVG}) no-repeat calc(100% + 50px) 0px / 54%`,
      xs: `url(${BackgroundSVG}) no-repeat 90% -50px / 100%`,
      xxl: `url(${BackgroundSVG}) no-repeat calc(100% + 50px) 0px`,
    },
    height: '100%',
    width: '100%',
    zIndex: 1,
  }),
);

const StyledHiveMobileBG = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    transform: 'translateX(30%)',
    mt: '-20%',
    '& img': {
      width: '90%',
    },
  }),
);

const StyledAxisInversion = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: {
      xs: 'none',
      xxl: 'block',
    },
    position: 'absolute',
    height: '100%',
    transform: 'scale(-1, 1) translate(0, 0)',
    width: '100%',
    animation: 'zIndex 8s infinite ease-in-out',
    zIndex: 0,
    '@keyframes zIndex': {
      '40%': {
        zIndex: 2,
      },
    },
  }),
);

const StyledBeeWrap = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'scale(50%)',
    offsetPath: 'path("M 650 500 C -400 200 850 100 4800 600")',
    offsetDistance: '0%',
    offsetRotate: '0deg',
    animation: 'move 8000ms infinite ease-in-out, size 8000ms infinite ease-in-out 500ms',
    zIndex: 100,
    '@keyframes move': {
      '0%': {
        offsetDistance: '0%',
      },
      '100%': {
        offsetDistance: '100%',
        offsetRotate: '0deg',
      },
    },
    '@keyframes size': {
      '15%': {
        transform: 'scale(100%)',
      },
      '90%': {
        transform: 'scale(50%)',
      },
    },
  }),
);

const StyledTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: {
      lg: '2.75rem',
    },
    order: {
      xs: 1,
      lg: 0,
    },
    mb: {
      xs: 2.5,
      md: 10,
    },
    mt: {
      xs: 2.5,
      md: 7,
    },
  }),
);

const Styled404Wrap = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    position: 'relative',
    alignSelf: {
      xs: 'center',
      lg: 'start',
    },
    display: 'flex',
    order: {
      lg: 0,
    },
    '& img': {
      position: 'absolute',
      width: {
        xs: '40%',
        sm: 'auto',
      },
      top: '50%',
      left: '50%',
      transform: {
        xs: 'translate(-25px, -40px)',
        sm: 'translate(-45px, -65px)',
      },
    },
  }),
);

const StyledNumber = styled(Typography)<TypographyProps>(({ theme }) =>
  theme.unstable_sx({
    fontSize: {
      xs: '5rem',
      sm: '9rem',
    },
    lineHeight: 1,
    fontWeight: 700,
    color: '#ffb300',
  }),
);

const StyledHalfZero = styled(HalfZero)(({ theme }) =>
  theme.unstable_sx({
    fill: 'transparent',
    transform: {
      sm: 'translateX(8px)',
    },
    width: {
      xs: 35,
      sm: 70,
    },
    height: {
      xs: 73.5,
      sm: 147,
    },
  }),
);

export {
  StyledAxisInversion,
  StyledBeeWrap,
  StyledHalfZero,
  StyledHiveMobileBG,
  StyledHiveBG,
  StyledNumber,
  StyledTitle,
  Styled404Wrap,
};
