export const toCamelCase = (str: string) => {
    const convertedStr = str
        .toLowerCase()
        .replace(/(^\w|\s\w)/g, match => match.trim().toUpperCase())
        .replace(/\s+/g, '');
    return convertedStr
}

export const convertToCamelCase = (languages: any) => {
    const updatedLanguages = languages.map((language: any) => ({
        ...language,
        name: toCamelCase(language.name)
    }));
    return updatedLanguages;
}

export const onTypeaheadItemChange = (event: any, fieldName: string, setValue: any) => {
    if(fieldName === 'languages' && event.length === 1) {
      setValue('languages', event[0].id)
    }
};

