import React, {FunctionComponent} from "react";

import Paper from "@mui/material/Paper";
import MuiTableContainer from "@mui/material/TableContainer";
import MuiTable from "@mui/material/Table";

interface ITableContainer {
  children: string | JSX.Element | JSX.Element[];
  elevation?: number;
  sx?: any;
}

const TableContainer: FunctionComponent<ITableContainer> = ({ children, ...props }) => {
  return (
    <MuiTableContainer component={Paper} elevation={0} sx={{mt: 1}} {...props}>
      <MuiTable stickyHeader aria-label="table">
        {children}
      </MuiTable>
    </MuiTableContainer>
  )
}

export default TableContainer
