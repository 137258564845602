import React, { FunctionComponent, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import SortDescIcon from 'styles/icons/SortDescIcon';

import { outputQuery } from 'helpers/decorators';

import ROUTES from 'router/constants';
import { EOrderFilter, ESorting, LIMITS } from 'models/consts';
import { IBasicUser } from 'store/types/user';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import getCustomerOrders from 'store/query/agency/getCustomerOrders';
import usersTeam from 'store/query/agency/usersTeam';
import {
  getBasicCustomerListByAgencySelector,
  loadedAgencyCustomerSelector,
  loadingAgencyCustomerSelector,
} from 'store/selectors/getAgencySelector';
import { getMeRoleSelector } from 'store/selectors/getUserSelector';

import { BootstrapInput } from 'components/CustomFields';
import EmptyList from 'components/Order/OrderList/EmptyList';
import Loader from 'components/Loader/Loader';
import GroupedListOrders from 'components/Order/GroupedList/GroupedListOrder';
import { ToggleOrders } from 'components/Order/OrderList';
import { subTabsForAgency } from 'components/Order/OrderList/tabOrder';
import GroupedListPreOrder from 'components/Order/GroupedList/GroupedListPreOrder';

const CustomerList: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const [filter, setFilter] = React.useState<EOrderFilter>(EOrderFilter.UNASSIGNED);
  const [sorting, setSorting] = useState<ESorting>(ESorting.ASC);

  const key = [filter, sorting].join(':') as string;

  const role = useAppSelector(getMeRoleSelector);
  const customers = useAppSelector(getBasicCustomerListByAgencySelector(key, 'user'));
  const customersLoading = useAppSelector(loadingAgencyCustomerSelector(key));
  const customersLoaded = useAppSelector(loadedAgencyCustomerSelector(key));

  useEffect(() => {
    if (
      !customers.length &&
      !customersLoading &&
      !customersLoaded &&
      filter !== EOrderFilter.NEED_ACTION
    ) {
      dispatch(
        getCustomerOrders({
          params: {
            limit: LIMITS.order,
            offset: customers?.length,
          },
          key,
          filter,
          role,
        }),
      );
    }
  }, [customers.length, customersLoading, customersLoaded, dispatch, filter, key, role]);

  useEffect(() => {
    dispatch(
      usersTeam({
        filter: outputQuery({ filterByRole: 2, filterByStatus: true }),
        key: 'agency-specialist-list',
      }),
    );
  }, [dispatch]);

  const onSearch = (searchTerm: any) => {
    dispatch(getCustomerOrders(searchTerm));
  };

  const debouncedSearch = debounce(onSearch, 500);

  const handleSearch = (value: any, field: string) => {
    const filter = {
      offset: 0,
      [field]: value,
    };

    debouncedSearch(outputQuery(filter));
  };

  const handleChange = (event: any, newFilter: any) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const changeSorting = () => {
    setSorting(sorting => (sorting === ESorting.ASC ? ESorting.DESC : ESorting.ASC));
  };

  return (
    <>
      <Box mt={4}>
        <BootstrapInput
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ ml: 2, mr: 0 }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(event: any) => {
            handleSearch(event.target.value, 'filterByName');
          }}
          placeholder="Search"
          sx={{ width: { md: 300 } }}
        />
      </Box>
      <Typography mt={{ xs: 2.5, md: 7 }} variant="h1" data-test="agency-order-list-header">
        Manage Orders
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <ToggleOrders value={filter} handleChange={handleChange} userRole={role} />
        <IconButton
          onClick={changeSorting}
          size="small"
          sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
        >
          {sorting === ESorting.DESC ? (
            <SortDescIcon />
          ) : (
            <SortDescIcon sx={{ transform: 'rotateX(180deg)' }} />
          )}
        </IconButton>
      </Box>
      {filter === EOrderFilter.NEED_ACTION &&
        subTabsForAgency.map((tab: any) => (
          <GroupedListPreOrder
            key={tab.key}
            subType={tab}
            filter={filter}
            role={role}
            route={ROUTES.AGENCY_PREORDER}
          />
        ))}
      {!customersLoading ? (
        (!!customers.length &&
          !customersLoading &&
          customers.map((customer: IBasicUser) => (
            <GroupedListOrders
              key={customer.id}
              groupedList={customer}
              filter={filter}
              id={customer.id}
              role={role}
              route={ROUTES.AGENCY_CUSTOMER_ORDER}
              title={`${customer.firstName} ${customer.lastName}`}
            />
          ))) ||
        (filter !== EOrderFilter.NEED_ACTION && <EmptyList />)
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CustomerList;
