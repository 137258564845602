import { Components } from '@mui/material/styles';
import { toggleButtonClasses } from '@mui/material/ToggleButton';
import {tableCellClasses} from "@mui/material/TableCell";

const MuiToggleButton: Components['MuiToggleButton'] = {
  styleOverrides: {
    root: ({ theme }: any) => theme.unstable_sx({
      borderColor: 'secondary.main',
      color: 'secondary.main',
      px: 2,
      textTransform: 'none',
      [`&:first-of-type, &.${tableCellClasses.head}:nth-of-type(5n + 1)`]: {
        pl: 3,
      },
      [`&:last-of-type`]: {
        pr: 3,
      },
      [`&.${toggleButtonClasses.sizeSmall}`]: {
        borderRadius: 35,
        height: 35,
        fontSize: '.75rem'
      },
      [`&.${toggleButtonClasses.selected}, &.${toggleButtonClasses.selected}:hover, &:hover`]: {
        backgroundColor: 'secondary.main',
        color: 'common.white'
      },
    }),
  }
}

export default MuiToggleButton
