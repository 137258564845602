import React, { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import { StyledContainer } from './styled'

const FullwidthLayout: FunctionComponent = () => (
  <StyledContainer maxWidth={false} fixed={false} sx={{px: '0 !important'}}>
    <Outlet />
  </StyledContainer>
);

export default FullwidthLayout;


