import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrderCreation } from 'store/constants';
import { ISearchDataDto } from "../../types/inner-models";

const searchWithMatching = createAsyncThunk<any, ISearchDataDto>(
  EOrderCreation.search,
  async (payload) => {
    const { data } = await instance.post('matchingV6', payload);

    return data;
  }
)

export default searchWithMatching;
