export enum EAgency {
  activateAgenciesWithLeadSource = 'agency/activateAgenciesWithLeadSource',
  changeUserStatus = 'agency/changeUserStatus',
  customerOrders = 'agency/customerOrders',
  createAgency = 'agency/createAgency',
  createVirtualSpecialist = 'agency/createVirtualSpecialist',
  fetchAllAgencies = 'agency/fetchAllAgencies',
  getMatchedAgencyById = 'agency/getMatchedAgencyById',
  impersonateToken = 'agency/impersonateToken',
  invitationUser = 'agency/invitationUser',
  logoutImpersonate = 'agency/logoutImpersonate',
  resendInvitationUser = 'agency/resendInvitationUser',
  updateAgency = 'agency/updateAgency',
  updateAgencyOrder = 'agency/updateAgencyOrder',
  usersTeam = 'agency/usersTeam',
  getAgencyStripeDashboardLink = 'agency/getAgencyStripeDashboardLink',
  addAgencyStripePayment = 'agency/addAgencyStripePayment',
  updateUsersTeamAvailability= 'agency/updateUsersTeamAvailability',
  deleteAgencyUsers= 'agency/deleteAgencyUsers',
}

export enum EAuth {
  requestAgency= 'auth/request-agency',
}
