import { createAsyncThunk} from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';

import { EAgency } from "../../constants";
import { IAgencyUser } from "../../types/agency";

const resendInviteUser = createAsyncThunk<IAgencyUser[], { email: string, roleId: number }>(
  EAgency.resendInvitationUser,
  async ({ email, roleId })=> {
    const { data } = await instance.post('agency/users-team/resend-invite-user', { email, roleId });

    return data
  }
);

export default resendInviteUser;
