import { Components } from '@mui/material/styles';

const MuiBadge: Components['MuiBadge'] = {
  styleOverrides: {
    badge: {
      fontSize: 11,
      color: '#ffca28',
      bottom: 8,
    },
  },
};

export default MuiBadge;
