import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EConsultant } from 'store/constants';
import { ICertificateDto, ICertificateItem } from "../../types/certificates";

interface ICertificateCreation {
  key: string;
  certificate: ICertificateDto
}

const createConsultantCertificate = createAsyncThunk<ICertificateItem, ICertificateCreation>(
  EConsultant.addCertificate,
  async ({ certificate }) => {
    const { data } = await instance.post('consultant/certificates', certificate);
    return data;
  }
);

export default createConsultantCertificate;
