import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EConsultant } from 'store/constants';
import { ICertificateItem } from "../../types/certificates";

interface IConsultantCertificatesProps {
  key: string | number;
  userId: number
}

const fetchConsultantCertificates = createAsyncThunk<ICertificateItem[], IConsultantCertificatesProps>(
  EConsultant.getCertificates,
  async ({ userId }) => {
    const { data } = await instance.get(`consultant/${userId}/certificates?offset=0&limit=25`);
    return data;
  }
);

export default fetchConsultantCertificates;
