import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRootBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    marginTop: { xs: '20%', md: '15%' },
    marginX: { md: '10%', lg: '15%', xl: '20%' },
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  }),
);

const StyledHeading1 = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 42,
    fontWeight: 600,
    marginTop: 3,
    lineHeight: 1.1,
  }),
);

const StyledHeading2 = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 17,
    marginTop: 1.5,
  }),
);

const StyledSearchBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    marginTop: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
);

const StyledSearchButton = styled(Button)(({ theme }) =>
  theme.unstable_sx({
    marginTop: 3,
    width: { xs: '100%', md: 0 },
    paddingX: 12,
  }),
);

export { StyledRootBox, StyledHeading1, StyledHeading2, StyledSearchBox, StyledSearchButton };
