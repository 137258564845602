import { createAsyncThunk} from '@reduxjs/toolkit';
import { AxiosResponse } from "axios";

import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

interface IApproveOrderComplete {
  order: {
    ids: number[]
  };
  notification: {
    ids: number[]
  };
}

const approveOrderComplete = createAsyncThunk<AxiosResponse, IApproveOrderComplete>(
  EOrder.approveOrderComplete,
  async (payload) => {
    const { data } = await instance.patch('orders/approve-complete', payload.order);

    return data;
  }
)

export default approveOrderComplete;
