import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const AddButton = styled(Button)(({ theme }) => theme.unstable_sx({
  p: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'primary.main'
  }
}))

export { AddButton }
