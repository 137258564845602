import { Components } from '@mui/material/styles';

const MuiSvgIcon: Components['MuiSvgIcon'] = {
  styleOverrides: {
    fontSizeLarge: {
      fontSize: '2.5rem',
    },
  },
};

export default MuiSvgIcon;
