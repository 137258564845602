import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EConsultantTags } from 'store/constants';

const getConsultantTagsById = createAsyncThunk(
  EConsultantTags.getConsultantTagsById,
  async (userId: number) => {
    const response = await instance.get(`consultant/${userId}/tags`);
    return await response.data;
  }
);

export default getConsultantTagsById;
