import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {ECompany} from "../../constants";

const createConsultantCompany = createAsyncThunk(
  ECompany.createConsultantCompany,
  async (payload: any) => {
    const { data } = await instance.post('companies', [payload.company])
    return await data
  }
)

export default createConsultantCompany
