import React, { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Controller, useForm } from "react-hook-form";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";

import useDeviceInfo from "hooks/useDeviceInfo";

import { EMAIL_REGEX } from "models/consts";
import { IBasicUser } from "store/types/user";

import { IModalProps } from "store/types/common";

import { getMeSelector } from "store/selectors/getUserSelector";
import submitFeedbacks from "store/query/feedbacks/submitFeedbacks";

import ModalWrapper from "../Modal";
import { BootstrapInput } from "../../CustomFields";

const FloatingFeedbackDialog: FunctionComponent<IModalProps> = ({visible, close}) => {
  const dispatch = useAppDispatch();
  const {deviceType, os, browser, screenDimensions, userAgent}= useDeviceInfo();
  const deviceInfo = {
    deviceType: deviceType,
    os: os,
    browser: browser,
    screenDimensions: screenDimensions,
    userAgent: userAgent,
    timeZone: new Date(),
  }

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      message: '',
    }
  });

  const onSubmit = (values: any) => {
    dispatch(submitFeedbacks({
      email: user.email,
      lastName: user.lastName,
      name: user.firstName,
      message: '',
      browserMetaData: JSON.stringify(deviceInfo),
      status: 'active',
      senderId: user.id,
      ...values
    }));
    close && close();
  }

  return (
    <ModalWrapper
      dialogProps={{
        onClose: () => close && close(),
        open: visible,
        PaperProps: {
          sx: {
            bgcolor: 'primary.main',
          }
        },
      }}
      title={{title: 'Do you have some feedback for us?'}}
    >
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Controller
              control={control}
              name="message"
              render={({field: { ref, ...field}}) => (
                <BootstrapInput
                  {...field}
                  inputRef={ref}
                  placeholder="Write a message..."
                  multiline
                  rows={4}
                  error={!!errors.message}
                  helperText={errors.message?.message || 'Incorrect data'}
                />
              )}
              rules={{
                required: 'This field is required',
                maxLength: {
                  message: "The max count of characters is 1000",
                  value: 1000,
                },
              }}
            />
          </FormGroup>
          <FormGroup>
            <InputLabel>If you want us to reply, please leave an email</InputLabel>
            <Controller
              control={control}
              name="email"
              render={({field: { ref, ...field}}) => (
                <BootstrapInput
                  {...field}
                  inputRef={ref}
                  error={!!errors.email}
                  placeholder="email@example.com"
                  helperText={errors.email?.message || 'Incorrect data'}
                  type="email"
                />
              )}
              rules={{
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email'
                }
              }}
            />
          </FormGroup>
          <Box sx={{display: 'flex', justifyContent: 'center'}} mt={3}>
            <Button color="secondary" type="submit" size="large" variant="bgOutlined">
              Submit
            </Button>
          </Box>
        </form>
      </DialogContent>
    </ModalWrapper>
  );
};

export default FloatingFeedbackDialog;
