import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet-async";

import { IBasicConsultant } from "store/types/consultant";
import { IBasicUser } from "store/types/user";

interface IHelmetAsync extends IBasicUser {
  consultant?: IBasicConsultant
}
const HelmetAsync: FunctionComponent<IHelmetAsync> = ({ consultant, ...user }) => (
  <Helmet>
    <title>{[user?.firstName, user?.lastName].join(' ')}</title>
    <meta name="og:title" content={[user?.firstName, user?.lastName].join(' ')}/>
    <meta name="description" content={consultant?.description}/>
    <meta name="og:description" content={consultant?.description}/>
    <meta name="og:image" content={consultant?.avatarUrl || undefined}/>
  </Helmet>
)

export default HelmetAsync
