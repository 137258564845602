import { createAsyncThunk}  from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EAgencyTags } from 'store/constants';

const addAgencyTags = createAsyncThunk(
  EAgencyTags.addAgencyTags,
  async ({tags}: {tags: any, agencyId: number}) => {
    const response = await instance.post(`agency/company-tags`, tags)
    return await response.data;
  }
);

export default addAgencyTags;
