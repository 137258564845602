import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EPreOrder } from '../../constants';
import { IBasicPreOrder } from "../../types/preOrder";

const declineOpportunity = createAsyncThunk<IBasicPreOrder, {id: number}>(
  EPreOrder.declineOpportunity,
  async ({id}) => {
    const { data } = await instance.post(`/agency/pre-orders/${id}/decline`);

    return data;
  }
)

export default declineOpportunity;
