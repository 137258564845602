import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

import find from 'lodash/fp/find';
import matches from 'lodash/fp/matches';

import { OrderCreationState } from '../reducers/order-creation';

import { IMatchedCard } from '../types/consultant';

const getElementByIdAndCardType = (
  selector: { id: number | null; cardType: string },
  data: IMatchedCard[],
) => find(matches(selector), data);

const GetOrderCreationState = (state: DefaultRootState) => state.orderCreation;

export const matchedResultsSelector = createSelector(
  GetOrderCreationState,
  ({ matchedResults }: OrderCreationState) => matchedResults.items,
);

export const getMatchedCardSelector = (selector: { cardType: string; id: number | null }) =>
  createSelector(GetOrderCreationState, ({ matchedResults }) =>
    getElementByIdAndCardType(selector, matchedResults.items),
  );

export const matchedLoadingSelector = createSelector(
  GetOrderCreationState,
  ({ matchedResults }: OrderCreationState) => matchedResults.loading,
);

export const matchedTotalSelector = createSelector(
  GetOrderCreationState,
  ({ matchedResults }: OrderCreationState) => matchedResults.countAll,
);

export const matchedResultsHasMoreSelector = createSelector(
  GetOrderCreationState,
  ({ matchedResults }: OrderCreationState) => matchedResults.hasMore,
);

export const newMatchingScoreSelector = createSelector(
  (state: DefaultRootState) => state.orderCreation,
  ({ matchingScore }: OrderCreationState) => matchingScore,
);
export const timeLineOrderSelector = createSelector(
  (state: DefaultRootState) => state.orderCreation,
  ({ timeLine }: OrderCreationState) => timeLine,
);
