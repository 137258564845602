import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const Logo: FunctionComponent<ISvgProps> = ({className, height = 28, hideName, width = 165}) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 165 28" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g clipPath="url(#clip0_7_5256)">
        {!hideName &&
          <>
            <path d="M37.1216 10.7212H40.2377L42.3617 18.162L42.6997 20.3591H42.7512L43.1186 18.162L45.3969 10.7212H48.2558L50.4606 18.162L50.8501 20.3591H50.9089L51.247 18.162L53.393 10.7212H56.4871L52.6066 23.5888H49.3803L46.8154 14.2732L44.2725 23.5888H41.0535L37.1216 10.7212Z" fill="black"/>
            <path d="M64.6229 23.8451C63.3679 23.862 62.1291 23.5622 61.0217 22.9735C59.9969 22.4144 59.1454 21.5862 58.5597 20.5787C57.9904 19.5267 57.6924 18.3502 57.6924 17.1549C57.6924 15.9597 57.9904 14.7832 58.5597 13.7311C59.1437 12.7243 59.9958 11.898 61.0217 11.3436C62.1314 10.7464 63.3766 10.4437 64.6376 10.4648C65.8894 10.4424 67.1254 10.7453 68.2241 11.3436C69.2444 11.9069 70.083 12.7476 70.642 13.7677C71.2297 14.8138 71.5383 15.9927 71.5383 17.1915C71.5383 18.3904 71.2297 19.5692 70.642 20.6153C70.0717 21.6245 69.2289 22.4541 68.2094 23.0102C67.1054 23.5901 65.8704 23.8775 64.6229 23.8451ZM64.6229 21.2452C65.1429 21.2639 65.6613 21.1783 66.1474 20.9933C66.6335 20.8084 67.0773 20.5279 67.4524 20.1686C67.8274 19.7652 68.1176 19.291 68.3057 18.774C68.4937 18.2571 68.576 17.7078 68.5474 17.1586C68.5789 16.6124 68.498 16.0657 68.3097 15.5518C68.1214 15.038 67.8296 14.5679 67.4524 14.1705C67.0845 13.7975 66.6433 13.5042 66.1565 13.3088C65.6696 13.1135 65.1475 13.0204 64.6229 13.0354C64.0928 13.0202 63.5651 13.113 63.0722 13.3082C62.5794 13.5034 62.1317 13.7968 61.7567 14.1705C61.377 14.5666 61.0832 15.0362 60.8936 15.5503C60.7039 16.0643 60.6224 16.6118 60.6542 17.1586C60.6255 17.7084 60.7082 18.2583 60.8977 18.7755C61.0871 19.2927 61.3792 19.7665 61.7567 20.1686C62.1388 20.5286 62.589 20.8093 63.0811 20.9941C63.5732 21.179 64.0973 21.2643 64.6229 21.2452Z" fill="black"/>
            <path d="M74.2505 10.721H77.1902V12.3029C77.644 11.7357 78.2179 11.2755 78.8709 10.9552C79.524 10.6349 80.24 10.4624 80.9678 10.45C81.3531 10.4359 81.7369 10.5059 82.0922 10.6551L81.7101 13.0353L81.6072 13.1158C81.3222 13.0556 81.0312 13.0286 80.74 13.0353C80.0209 13.0343 79.3185 13.2514 78.7262 13.6578C78.0731 14.1107 77.5448 14.7202 77.1902 15.4301V23.5886H74.2505V10.721Z" fill="black"/>
            <path d="M84.5396 4.80371H87.4793V15.152H87.5822L91.7492 10.7578H95.4239L90.0001 16.4703L95.8428 23.6254H92.1682L87.5601 18.0448H87.4793V23.6254H84.5396V4.80371Z" fill="black"/>
            <path d="M102.81 23.8451C101.614 23.8705 100.432 23.588 99.3779 23.0249C98.3961 22.471 97.595 21.6473 97.0702 20.652C96.5102 19.5736 96.2297 18.3727 96.2544 17.1587C96.2338 15.9398 96.5435 14.738 97.1511 13.6799C97.7328 12.6946 98.564 11.8789 99.5616 11.3144C100.55 10.7498 101.671 10.4567 102.81 10.4649C103.961 10.4426 105.098 10.7176 106.11 11.2631C107.081 11.7709 107.875 12.5599 108.388 13.5261C108.952 14.6162 109.23 15.8304 109.197 17.0561V17.9569H99.1648C99.193 18.8983 99.5885 19.7916 100.267 20.447C100.987 21.0617 101.914 21.3834 102.861 21.3478C103.639 21.3799 104.408 21.1755 105.066 20.7619C105.646 20.3474 106.078 19.7601 106.301 19.0848L108.917 19.2386L108.998 19.3704C108.663 20.6941 107.886 21.8644 106.793 22.688C105.628 23.4972 104.229 23.9037 102.81 23.8451ZM106.367 15.7892C106.346 15.3698 106.235 14.9598 106.041 14.587C105.847 14.2143 105.575 13.8875 105.243 13.6287C104.554 13.0805 103.691 12.7972 102.81 12.8304C101.954 12.8102 101.118 13.0931 100.451 13.6287C99.7871 14.1827 99.3547 14.9635 99.2383 15.8184L106.367 15.7892Z" fill="black"/>
            <path d="M111.732 10.721H114.723V12.3029C115.175 11.7359 115.748 11.2757 116.4 10.9554C117.052 10.635 117.767 10.4624 118.493 10.45C118.879 10.4359 119.262 10.5059 119.618 10.6551L119.221 13.0353L119.118 13.1158C118.833 13.056 118.542 13.029 118.251 13.0353C117.529 13.0342 116.825 13.2512 116.23 13.6578C115.582 14.1109 115.061 14.7208 114.716 15.4301V23.5886H111.732V10.721Z" fill="black"/>
            <path d="M129.187 23.8452C128.411 23.8693 127.638 23.7335 126.917 23.4461C126.196 23.1588 125.543 22.7262 124.997 22.1754V23.5888H122.021V4.80371H125.005V12.0102C125.542 11.5219 126.165 11.137 126.842 10.875C127.594 10.596 128.391 10.457 129.194 10.4649C130.262 10.4506 131.314 10.7266 132.237 11.2632C133.182 11.7936 133.95 12.5912 134.441 13.5555C135.014 14.6686 135.292 15.9087 135.25 17.1587C135.279 18.4143 134.98 19.656 134.383 20.7619C133.865 21.7304 133.083 22.5329 132.126 23.0762C131.229 23.5798 130.217 23.8446 129.187 23.8452ZM128.51 21.3258C129.009 21.3444 129.505 21.2604 129.969 21.0789C130.434 20.8974 130.855 20.6225 131.208 20.2712C131.956 19.4124 132.334 18.2937 132.259 17.1587C132.298 16.5998 132.226 16.0387 132.045 15.508C131.865 14.9774 131.58 14.4879 131.208 14.0681C130.629 13.5049 129.886 13.1391 129.086 13.0233C128.286 12.9075 127.469 13.0477 126.754 13.4236C126.227 13.6853 125.783 14.0854 125.468 14.5808C125.158 15.0747 124.995 15.6461 124.997 16.2286V18.1108C124.995 18.6933 125.158 19.2646 125.468 19.7586C125.808 20.2429 126.259 20.6396 126.783 20.9157C127.318 21.1869 127.91 21.3275 128.51 21.3258Z" fill="black"/>
            <path d="M143.819 23.8452C142.626 23.8682 141.447 23.5858 140.394 23.0249C139.413 22.471 138.612 21.6473 138.087 20.6521C137.532 19.5718 137.255 18.3718 137.278 17.1587C137.262 15.9385 137.577 14.7366 138.19 13.68C138.774 12.6965 139.604 11.8814 140.6 11.3144C141.589 10.7508 142.71 10.4577 143.849 10.4649C144.999 10.4403 146.137 10.7155 147.148 11.2632C148.121 11.7687 148.916 12.5582 149.427 13.5262C149.991 14.6162 150.271 15.83 150.242 17.0562V17.957H140.181C140.204 18.8981 140.597 19.7927 141.276 20.447C141.997 21.0605 142.923 21.3819 143.871 21.3478C144.648 21.3771 145.416 21.173 146.075 20.7619C146.651 20.3448 147.08 19.7582 147.303 19.0848L149.926 19.2386L150 19.3704C149.665 20.6941 148.888 21.8644 147.795 22.688C146.632 23.4967 145.236 23.9032 143.819 23.8452ZM147.384 15.7892C147.36 15.376 147.249 14.9724 147.058 14.6051C146.867 14.2377 146.599 13.915 146.274 13.658C145.583 13.1081 144.717 12.8247 143.834 12.8597C142.978 12.8439 142.144 13.1263 141.475 13.658C140.809 14.2 140.371 14.9708 140.247 15.8185L147.384 15.7892Z" fill="black"/>
            <path d="M158.606 23.8452C157.411 23.8695 156.229 23.587 155.174 23.0249C154.2 22.4677 153.407 21.6443 152.888 20.6521C152.334 19.5718 152.056 18.3718 152.08 17.1587C152.057 15.9405 152.364 14.7388 152.969 13.68C153.553 12.6965 154.384 11.8814 155.38 11.3144C156.369 10.7518 157.489 10.4588 158.628 10.4649C159.779 10.4403 160.917 10.7155 161.928 11.2632C162.901 11.7687 163.696 12.5582 164.206 13.5262C164.771 14.6162 165.051 15.83 165.022 17.0562V17.957H154.968C154.991 18.8981 155.384 19.7927 156.063 20.447C156.784 21.0605 157.71 21.3819 158.658 21.3478C159.435 21.3771 160.203 21.173 160.862 20.7619C161.438 20.3448 161.867 19.7582 162.09 19.0848L164.714 19.2386L164.787 19.3704C164.452 20.6941 163.675 21.8644 162.582 22.688C161.42 23.4967 160.023 23.9032 158.606 23.8452ZM162.171 15.7892C162.15 15.3698 162.038 14.9598 161.844 14.5871C161.65 14.2143 161.378 13.8875 161.046 13.6287C160.356 13.0788 159.489 12.7954 158.606 12.8304C157.751 12.8146 156.916 13.097 156.247 13.6287C155.584 14.1726 155.146 14.9424 155.02 15.7892H162.171Z" fill="black"/>
          </>
        }

        <path d="M2.00637 28.3564C3.11446 28.3564 4.01274 27.4612 4.01274 26.357C4.01274 25.2528 3.11446 24.3577 2.00637 24.3577C0.898282 24.3577 0 25.2528 0 26.357C0 27.4612 0.898282 28.3564 2.00637 28.3564Z" fill="#FFB300"/>
        <path d="M3.99072 15.4376L13.1406 24.5629C13.3506 24.7705 13.5173 25.0175 13.631 25.2897C13.7448 25.5618 13.8033 25.8537 13.8033 26.1484C13.8033 26.4432 13.7448 26.7351 13.631 27.0072C13.5173 27.2794 13.3506 27.5264 13.1406 27.734C12.9323 27.9433 12.6844 28.1093 12.4113 28.2227C12.1382 28.336 11.8453 28.3944 11.5495 28.3944C11.2537 28.3944 10.9608 28.336 10.6877 28.2227C10.4146 28.1093 10.1667 27.9433 9.95838 27.734L0.801106 18.6088C0.380587 18.1875 0.144531 17.6174 0.144531 17.0232C0.144531 16.429 0.380587 15.8589 0.801106 15.4376C1.22513 15.0181 1.7984 14.7827 2.39591 14.7827C2.99342 14.7827 3.56669 15.0181 3.99072 15.4376Z" fill="#FFB300"/>
        <path d="M4.23312 5.53609L23.2973 24.5336C23.5073 24.7412 23.674 24.9882 23.7877 25.2604C23.9014 25.5325 23.96 25.8244 23.96 26.1192C23.96 26.414 23.9014 26.7058 23.7877 26.978C23.674 27.2501 23.5073 27.4971 23.2973 27.7047C23.0889 27.914 22.8411 28.0801 22.568 28.1934C22.2949 28.3067 22.002 28.3651 21.7062 28.3651C21.4104 28.3651 21.1175 28.3067 20.8444 28.1934C20.5713 28.0801 20.3234 27.914 20.115 27.7047L1.05086 8.70722C0.840873 8.49959 0.674208 8.25258 0.560473 7.98045C0.446738 7.70832 0.388184 7.41645 0.388184 7.12166C0.388184 6.82687 0.446738 6.535 0.560473 6.26287C0.674208 5.99074 0.840873 5.74373 1.05086 5.53609C1.25923 5.32684 1.5071 5.16076 1.78018 5.04742C2.05327 4.93408 2.34617 4.87573 2.64199 4.87573C2.93781 4.87573 3.23071 4.93408 3.5038 5.04742C3.77688 5.16076 4.02475 5.32684 4.23312 5.53609Z" fill="#FFB300"/>
        <path d="M10.1274 1.26632L27.2661 18.3377C27.6888 18.7593 27.9263 19.3309 27.9263 19.9269C27.9263 20.5229 27.6888 21.0946 27.2661 21.5162C26.8433 21.9352 26.2712 22.1704 25.6749 22.1704C25.0786 22.1704 24.5066 21.9352 24.0838 21.5162L6.94515 4.43745C6.52463 4.01615 6.28857 3.4461 6.28857 2.85188C6.28857 2.25767 6.52463 1.68761 6.94515 1.26632C7.15352 1.05706 7.40139 0.890982 7.67447 0.777645C7.94756 0.664308 8.24046 0.605957 8.53628 0.605957C8.8321 0.605957 9.125 0.664308 9.39809 0.777645C9.67117 0.890982 9.91904 1.05706 10.1274 1.26632Z" fill="#FFB300"/>
        <path d="M20.4283 1.46857C19.5501 0.593397 18.1262 0.593398 17.2479 1.46858C16.3697 2.34375 16.3697 3.76269 17.2479 4.63787L24.1232 11.4891C25.0015 12.3643 26.4254 12.3643 27.3037 11.4891C28.1819 10.614 28.1819 9.19503 27.3037 8.31985L20.4283 1.46857Z" fill="#FFB300"/>
      </g>
      <defs>
        <clipPath id="clip0_7_5256">
          <rect width="165" height="27.7859" fill="white" transform="translate(0 0.607178)"/>
        </clipPath>
      </defs>
    </svg>
  );
}
