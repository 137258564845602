import React, { FunctionComponent } from 'react';

import { ISvgProps } from '../../models/inner-models';

export const LogoWhite: FunctionComponent<ISvgProps> = (props) => (
  <svg width="100%" height="100%" {...props} viewBox="0 0 165 28" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g clipPath="url(#clip0_3_9883)">
      <path className="word" d="M37.1215 10.2209H40.2376L42.3616 17.6618L42.6997 19.8588H42.7511L43.1186 17.6618L45.3969 10.2209H48.2558L50.4606 17.6618L50.8501 19.8588H50.9089L51.2469 17.6618L53.393 10.2209H56.487L52.6066 23.0886H49.3802L46.8153 13.7729L44.2724 23.0886H41.0534L37.1215 10.2209Z" fill="white"/>
      <path className="word" d="M64.6227 23.3448C63.3678 23.3617 62.1289 23.0619 61.0216 22.4733C59.9967 21.9142 59.1452 21.0859 58.5595 20.0785C57.9903 19.0264 57.6922 17.8499 57.6922 16.6547C57.6922 15.4594 57.9903 14.2829 58.5595 13.2309C59.1436 12.224 59.9956 11.3977 61.0216 10.8434C62.1312 10.2461 63.3764 9.94346 64.6374 9.96453C65.8892 9.94219 67.1253 10.2451 68.2239 10.8434C69.2442 11.4066 70.0828 12.2473 70.6418 13.2675C71.2296 14.3136 71.5382 15.4924 71.5382 16.6913C71.5382 17.8902 71.2296 19.069 70.6418 20.1151C70.0715 21.1242 69.2288 21.9539 68.2092 22.5099C67.1052 23.0898 65.8703 23.3773 64.6227 23.3448ZM64.6227 20.7449C65.1427 20.7637 65.6612 20.678 66.1472 20.4931C66.6333 20.3081 67.0771 20.0277 67.4522 19.6683C67.8273 19.265 68.1174 18.7908 68.3055 18.2738C68.4936 17.7568 68.5758 17.2075 68.5473 16.6583C68.5787 16.1122 68.4978 15.5654 68.3095 15.0516C68.1212 14.5377 67.8295 14.0677 67.4522 13.6703C67.0843 13.2973 66.6432 13.0039 66.1563 12.8086C65.6694 12.6133 65.1474 12.5202 64.6227 12.5351C64.0926 12.5199 63.5649 12.6128 63.0721 12.808C62.5792 13.0032 62.1315 13.2966 61.7565 13.6703C61.3768 14.0663 61.0831 14.536 60.8934 15.05C60.7037 15.5641 60.6223 16.1116 60.6541 16.6583C60.6253 17.2081 60.7081 17.7581 60.8975 18.2753C61.0869 18.7925 61.3791 19.2663 61.7565 19.6683C62.1386 20.0284 62.5889 20.309 63.081 20.4939C63.5731 20.6787 64.0972 20.764 64.6227 20.7449Z" fill="white"/>
      <path className="word" d="M74.2504 10.2208H77.1901V11.8027C77.6438 11.2355 78.2178 10.7753 78.8708 10.455C79.5239 10.1347 80.2399 9.96214 80.9677 9.94978C81.353 9.93566 81.7368 10.0056 82.0921 10.1548L81.7099 12.535L81.6071 12.6156C81.3221 12.5554 81.0311 12.5283 80.7398 12.535C80.0207 12.534 79.3184 12.7512 78.7261 13.1575C78.0729 13.6105 77.5447 14.22 77.1901 14.9298V23.0884H74.2504V10.2208Z" fill="white"/>
      <path className="word" d="M84.5394 4.30347H87.4792V14.6518H87.5821L91.7491 10.2576H95.4238L90 15.97L95.8427 23.1252H92.1681L87.56 17.5446H87.4792V23.1252H84.5394V4.30347Z" fill="white"/>
      <path className="word" d="M102.81 23.3449C101.614 23.3702 100.432 23.0877 99.3778 22.5247C98.396 21.9707 97.5949 21.147 97.0701 20.1518C96.51 19.0734 96.2296 17.8725 96.2543 16.6584C96.2336 15.4396 96.5434 14.2378 97.1509 13.1797C97.7326 12.1944 98.5639 11.3786 99.5615 10.8142C100.55 10.2496 101.671 9.95643 102.81 9.96463C103.961 9.94239 105.097 10.2174 106.11 10.7629C107.081 11.2707 107.875 12.0596 108.388 13.0259C108.952 14.1159 109.23 15.3302 109.196 16.5559V17.4567H99.1646C99.1929 18.3981 99.5883 19.2913 100.267 19.9467C100.987 20.5615 101.914 20.8831 102.861 20.8475C103.639 20.8796 104.408 20.6753 105.066 20.2617C105.646 19.8471 106.078 19.2598 106.301 18.5845L108.917 18.7383L108.998 18.8702C108.663 20.1938 107.886 21.3641 106.793 22.1878C105.628 22.997 104.229 23.4034 102.81 23.3449ZM106.367 15.2889C106.346 14.8695 106.235 14.4596 106.041 14.0868C105.847 13.714 105.574 13.3872 105.243 13.1284C104.554 12.5803 103.691 12.2969 102.81 12.3302C101.954 12.31 101.118 12.5928 100.451 13.1284C99.787 13.6824 99.3546 14.4633 99.2381 15.3182L106.367 15.2889Z" fill="white"/>
      <path className="word" d="M111.732 10.2208H114.723V11.8027C115.175 11.2357 115.748 10.7755 116.4 10.4551C117.052 10.1348 117.767 9.9622 118.493 9.94978C118.879 9.93566 119.262 10.0056 119.618 10.1548L119.221 12.535L119.118 12.6156C118.833 12.5558 118.542 12.5287 118.251 12.535C117.529 12.5339 116.825 12.751 116.23 13.1575C115.582 13.6106 115.061 14.2206 114.716 14.9298V23.0884H111.732V10.2208Z" fill="white"/>
      <path className="word" d="M129.187 23.3449C128.411 23.3691 127.638 23.2332 126.917 22.9459C126.196 22.6586 125.543 22.2259 124.998 21.6751V23.0886H122.021V4.30347H125.005V11.5099C125.542 11.0216 126.165 10.6367 126.842 10.3748C127.594 10.0958 128.392 9.95674 129.194 9.96464C130.262 9.95035 131.314 10.2263 132.237 10.7629C133.183 11.2934 133.95 12.091 134.441 13.0552C135.014 14.1683 135.292 15.4084 135.25 16.6584C135.279 17.9141 134.98 19.1557 134.383 20.2617C133.865 21.2302 133.083 22.0327 132.126 22.5759C131.229 23.0796 130.217 23.3444 129.187 23.3449ZM128.511 20.8256C129.009 20.8442 129.505 20.7601 129.97 20.5786C130.434 20.3972 130.855 20.1222 131.208 19.771C131.956 18.9121 132.334 17.7935 132.259 16.6584C132.298 16.0996 132.226 15.5384 132.045 15.0078C131.865 14.4771 131.58 13.9876 131.208 13.5679C130.629 13.0047 129.887 12.6389 129.086 12.5231C128.286 12.4073 127.469 12.5474 126.754 12.9234C126.227 13.185 125.783 13.5852 125.468 14.0805C125.158 14.5745 124.995 15.1458 124.998 15.7283V17.6105C124.995 18.193 125.158 18.7644 125.468 19.2583C125.808 19.7427 126.259 20.1394 126.783 20.4155C127.318 20.6867 127.91 20.8273 128.511 20.8256Z" fill="white"/>
      <path className="word" d="M143.819 23.3449C142.626 23.368 141.447 23.0856 140.394 22.5247C139.413 21.9707 138.612 21.147 138.087 20.1518C137.532 19.0716 137.255 17.8715 137.278 16.6585C137.262 15.4382 137.577 14.2363 138.19 13.1797C138.774 12.1963 139.604 11.3811 140.6 10.8142C141.589 10.2506 142.71 9.9575 143.849 9.96465C145 9.94006 146.137 10.2152 147.148 10.7629C148.121 11.2684 148.916 12.058 149.427 13.0259C149.991 14.116 150.272 15.3297 150.243 16.5559V17.4567H140.181C140.204 18.3979 140.597 19.2924 141.276 19.9468C141.997 20.5602 142.923 20.8817 143.871 20.8476C144.648 20.8769 145.416 20.6728 146.075 20.2617C146.651 19.8446 147.08 19.2579 147.303 18.5846L149.927 18.7384L150 18.8702C149.665 20.1938 148.888 21.3641 147.795 22.1878C146.633 22.9964 145.236 23.4029 143.819 23.3449ZM147.384 15.2889C147.36 14.8757 147.249 14.4722 147.058 14.1048C146.867 13.7375 146.599 13.4147 146.274 13.1578C145.583 12.6079 144.717 12.3245 143.834 12.3595C142.978 12.3437 142.144 12.626 141.475 13.1578C140.809 13.6998 140.371 14.4705 140.247 15.3182L147.384 15.2889Z" fill="white"/>
      <path className="word" d="M158.606 23.3449C157.41 23.3693 156.228 23.0868 155.174 22.5247C154.2 21.9674 153.407 21.144 152.888 20.1518C152.334 19.0716 152.056 17.8715 152.08 16.6585C152.057 15.4403 152.364 14.2385 152.969 13.1797C153.553 12.1963 154.384 11.3811 155.38 10.8142C156.369 10.2516 157.489 9.95854 158.628 9.96465C159.779 9.94006 160.916 10.2152 161.928 10.7629C162.901 11.2684 163.696 12.058 164.206 13.0259C164.771 14.116 165.051 15.3297 165.022 16.5559V17.4567H154.968C154.991 18.3979 155.384 19.2924 156.063 19.9468C156.784 20.5602 157.71 20.8817 158.658 20.8476C159.435 20.8769 160.203 20.6728 160.862 20.2617C161.438 19.8446 161.867 19.2579 162.09 18.5846L164.713 18.7384L164.787 18.8702C164.452 20.1938 163.674 21.3641 162.582 22.1878C161.419 22.9964 160.023 23.4029 158.606 23.3449ZM162.17 15.2889C162.149 14.8695 162.038 14.4596 161.844 14.0868C161.65 13.7141 161.378 13.3872 161.046 13.1285C160.356 12.5786 159.489 12.2952 158.606 12.3302C157.75 12.3144 156.916 12.5967 156.247 13.1285C155.583 13.6723 155.146 14.4422 155.02 15.2889H162.17Z" fill="white"/>
      <path d="M2.00637 27.8561C3.11446 27.8561 4.01274 26.961 4.01274 25.8568C4.01274 24.7526 3.11446 23.8574 2.00637 23.8574C0.898282 23.8574 0 24.7526 0 25.8568C0 26.961 0.898282 27.8561 2.00637 27.8561Z" fill="#FFB300"/>
      <path d="M3.99069 14.9374L13.1406 24.0626C13.3506 24.2703 13.5173 24.5173 13.631 24.7894C13.7447 25.0615 13.8033 25.3534 13.8033 25.6482C13.8033 25.943 13.7447 26.2349 13.631 26.507C13.5173 26.7791 13.3506 27.0261 13.1406 27.2338C12.9322 27.443 12.6844 27.6091 12.4113 27.7224C12.1382 27.8358 11.8453 27.8941 11.5495 27.8941C11.2537 27.8941 10.9608 27.8358 10.6877 27.7224C10.4146 27.6091 10.1667 27.443 9.95835 27.2338L0.801079 18.1085C0.38056 17.6872 0.144505 17.1172 0.144505 16.523C0.144505 15.9287 0.38056 15.3587 0.801079 14.9374C1.22511 14.5179 1.79838 14.2825 2.39589 14.2825C2.99339 14.2825 3.56667 14.5179 3.99069 14.9374Z" fill="#FFB300"/>
      <path d="M4.23322 5.03585L23.2974 24.0334C23.5074 24.241 23.6741 24.488 23.7878 24.7601C23.9015 25.0323 23.9601 25.3241 23.9601 25.6189C23.9601 25.9137 23.9015 26.2056 23.7878 26.4777C23.6741 26.7498 23.5074 26.9968 23.2974 27.2045C23.089 27.4137 22.8412 27.5798 22.5681 27.6932C22.295 27.8065 22.0021 27.8648 21.7063 27.8648C21.4104 27.8648 21.1176 27.8065 20.8445 27.6932C20.5714 27.5798 20.3235 27.4137 20.1151 27.2045L1.05095 8.20698C0.840968 7.99934 0.674303 7.75234 0.560568 7.48021C0.446833 7.20808 0.388279 6.9162 0.388279 6.62141C0.388279 6.32662 0.446833 6.03475 0.560568 5.76262C0.674303 5.49049 0.840968 5.24349 1.05095 5.03585C1.25932 4.8266 1.50719 4.66051 1.78028 4.54718C2.05337 4.43384 2.34626 4.37549 2.64209 4.37549C2.93791 4.37549 3.23081 4.43384 3.50389 4.54718C3.77698 4.66051 4.02485 4.8266 4.23322 5.03585Z" fill="#FFB300"/>
      <path d="M10.1274 0.766072L27.266 17.8375C27.6888 18.2591 27.9263 18.8307 27.9263 19.4267C27.9263 20.0227 27.6888 20.5943 27.266 21.0159C26.8433 21.435 26.2712 21.6702 25.6749 21.6702C25.0786 21.6702 24.5066 21.435 24.0838 21.0159L6.94513 3.93721C6.52461 3.51591 6.28855 2.94585 6.28855 2.35164C6.28855 1.75742 6.52461 1.18737 6.94513 0.766072C7.15349 0.55682 7.40137 0.390738 7.67445 0.277401C7.94754 0.164064 8.24043 0.105713 8.53626 0.105713C8.83208 0.105713 9.12498 0.164064 9.39806 0.277401C9.67115 0.390738 9.91902 0.55682 10.1274 0.766072Z" fill="#FFB300"/>
      <path d="M20.4283 0.96833C19.5501 0.0931527 18.1262 0.0931537 17.2479 0.968331C16.3697 1.84351 16.3697 3.26245 17.2479 4.13763L24.1232 10.9889C25.0015 11.8641 26.4254 11.8641 27.3037 10.9889C28.1819 10.1137 28.1819 8.69478 27.3037 7.81961L20.4283 0.96833Z" fill="#FFB300"/>
    </g>
    <defs>
      <clipPath id="clip0_3_9883">
        <rect width="165" height="27.7859" fill="white" transform="translate(0 0.106934)"/>
      </clipPath>
    </defs>
  </svg>
);
