import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

const RatingContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: {
      xs: 'column',
      lg: 'row',
    },
    justifyContent: 'space-between',
    my: 3,
  }),
);

const RatingItem = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 26,
  }),
);

const DetailsContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: {
      lg: '60%',
    },
  }),
);

const ProgressContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    width: '100%',
  }),
);

const StyledLinearProgress = styled(LinearProgress)(({ theme }) =>
  theme.unstable_sx({
    maxWidth: 240,
    height: 4,
    mr: 1,
    flexBasis: '100%',
  }),
);

const TotalRateContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: {
      lg: 'column',
    },
    justifyContent: 'space-between',
  }),
);

const TotalContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
  }),
);

const FeedbackContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    marginBottom: 4,
  }),
);

const Feedback = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }),
);

const FeedbackHeaderContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    marginLeft: '10px',
  }),
);

const FeedbackHeader = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: '1px solid #ebebeb',
    width: '100%',
    paddingTop: 2,
    paddingBottom: 1,
  }),
);

const FeedbackRatingContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    my: 1,
  }),
);

export {
  DetailsContainer,
  FeedbackContainer,
  FeedbackHeaderContainer,
  FeedbackHeader,
  Feedback,
  FeedbackRatingContainer,
  ProgressContainer,
  RatingContainer,
  RatingItem,
  StyledLinearProgress,
  TotalContainer,
  TotalRateContainer,
};
