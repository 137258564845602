import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EConsultant } from "../../constants";

const getCalendar = createAsyncThunk(
  EConsultant.fetchCalendar,
  async (params: any) => {
    const { data } = await instance.get('consultant/calendar', { params })
    return await data
  }
)

export default getCalendar
