import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import Grid from '@mui/material/Unstable_Grid2';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { TIMELINE_HOURS_NEEDED } from 'models/consts';

import dayjs from 'utils/dayjs';
import { calcWeeks, getEndDate, isFriday } from 'utils/dateFormatter';

import { BootstrapInput } from '../../CustomFields';
import DateTextInput from '../../CustomFields/DateTextInput';

import CalendarIcon from '../../../styles/icons/CalendarIcon';

const NumberOfHoursForm: FunctionComponent<any> = ({
  setNewAvailability,
  setNewHours,
  setNewWeeks,
  timeLine,
}) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    control,
    formState: { errors },
    reset,
    setError,
    setValue,
    watch,
  } = useFormContext();

  const { endDate, hours } = watch();

  const handlerChange = (hours: number, availabilityWeek: number, startDate: string) => {
    if (!!availabilityWeek && !!startDate && !!hours) {
      const dayOfWeek = dayjs(startDate).day(); // 0 (Sunday) to 6 (Saturday)
      const daysUntilNextMonday = (8 - dayOfWeek) % 7; // 8 - dayOfWeek - guarantees next Monday
      const endDate = getEndDate(
        dayjs(startDate).add(daysUntilNextMonday, 'day').format('YYYY-MM-DD'),
        hours,
        availabilityWeek,
      );
      setDate(endDate || '', hours);
    }
    setNewHours(hours);
  };

  const setDate = (value: Date | string, hours: number) => {
    setValue('endDate', value ? dayjs(value).format('YYYY-MM-DD') : undefined);

    const weeks = calcWeeks(value, timeLine.endDate);
    setNewWeeks(weeks || 0);
    setNewAvailability(!!(hours && weeks) ? Math.ceil(hours / weeks) : undefined);
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={5} sm={6}>
        <FormGroup>
          <InputLabel>Change number of hours</InputLabel>
          <Controller
            control={control}
            name="hours"
            rules={{
              validate: {
                requiredAtLeastOne: value => !!value || 'Required filled at least one field',
                maxLength: value => value <= 5000 || 'Value cannot be more than 5000',
              },
            }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                filterOptions={options => options}
                freeSolo
                getOptionLabel={option => String(option)}
                onChange={(_, value) => {
                  handlerChange(Number(value), 40, timeLine.endDate);
                  field.onChange(value);
                }}
                onInputChange={(_, value) => {
                  handlerChange(Number(value), 40, timeLine.endDate);
                  field.onChange(value);
                }}
                options={TIMELINE_HOURS_NEEDED}
                renderInput={({
                  InputLabelProps,
                  inputProps: { onChange, ...inputProps },
                  ...params
                }) => (
                  <BootstrapInput
                    {...params}
                    inputProps={inputProps}
                    label="Select"
                    onChange={(event: any) => {
                      let { value } = event.target;

                      if (value.startsWith('0') && value.length > 1) {
                        value = value.substring(1);
                      }

                      const isValidInput = /^[1-9]\d*$/;
                      if (isValidInput.test(value) || value === '') {
                        field.onChange(value);
                      }
                    }}
                    data-test="allHoursField"
                    error={!!errors.hours && errors.hours.type !== 'requiredAtLeastOne'}
                    helperText={errors.hours?.message}
                  />
                )}
              />
            )}
          />
        </FormGroup>
      </Grid>
      <Grid xs={7} sm={6}>
        <FormGroup>
          <InputLabel>Change done date</InputLabel>
          <Controller
            name="endDate"
            control={control}
            rules={{
              validate: {
                requiredAtLeastOne: value => !!value || 'Required filled at least one field',
              },
            }}
            render={({ field }) => (
              <DatePicker
                selected={!!endDate ? dayjs(endDate).toDate() : null}
                onChange={(date: Date) => {
                  field.onChange(date);
                  setDate(date, hours);
                }}
                calendarStartDay={1}
                customInput={
                  <DateTextInput
                    className="text-custom-input"
                    onReset={() => {
                      reset({ endDate: undefined });
                    }}
                    icon={<CalendarIcon />}
                    data-test="endDateField"
                  />
                }
                showPopperArrow={false}
                minDate={(timeLine.endDate && dayjs(timeLine.endDate).toDate()) || null}
                filterDate={isFriday}
                isClearable
                placeholderText="MM/DD/YYYY"
                withPortal={xs}
                dayClassName={date => (isFriday(date) && 'available-end-date') || null}
                popperPlacement="bottom-start"
              />
            )}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default NumberOfHoursForm;
