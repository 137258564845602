import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { StateMachineProvider } from 'little-state-machine';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DialogContent } from '@mui/material';

import ROUTES from 'router/constants';

import useScrollToTop from 'hooks/useScrollToTop';

import { useAppSelector } from 'store/hooks';
import {
  getCustomerSelector,
  getMeSelector,
  isSalesSelector,
  isTalentSelector,
} from 'store/selectors/getUserSelector';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';
import { getAgencyByIdSelector } from 'store/selectors/getAgencySelector';

import { IBasicCustomer } from '../../store/types/customer';
import { IBasicUser } from 'store/types/user';
import { IBasicConsultant } from 'store/types/consultant';

import { EAgencyRegStep, EConsultantRegProgress, EConsultantRegStep, ERole } from 'models/consts';

import { StyledFluid, StyledRoot, StyledWrap } from './styled';

import UserAgreement from 'views/Auth/ConsultantRegister/UserAgreement/UserAgreement';
import NavbarMenu from 'components/NavbarMenu/NavbarMenu';
import Header from 'components/Header/Header';
import FloatingFeedbackDialog from 'components/Modal/FloatingFeedbackDialog/FloatingFeedbackDialog';
import ModalWrapper from 'components/Modal/Modal';
import OfflineBanner from 'components/OfflineBanner/OfflineBanner';

const AuthorizedLayout: FunctionComponent = () => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false);
  const user = useAppSelector(getMeSelector) as IBasicUser;
  const customer = useAppSelector(getCustomerSelector) as IBasicCustomer;
  const consultant = useAppSelector(getBasicConsultantSelector(user?.id)) as IBasicConsultant;
  const [modal, setModal] = useState(!consultant?.agreements);

  const isSales = useAppSelector(isSalesSelector);
  const isTalent = useAppSelector(isTalentSelector);

  const closeModal = () => {
    setModal(false);
  };
  const methods = useForm<any>({
    mode: 'onChange',
  });
  useScrollToTop(ref);
  const agency = useAppSelector(getAgencyByIdSelector(user?.agencyId));

  const consultantRegistrationProgress = useMemo(() => {
    if ([ERole.CONSULTANT, ERole.INFLUENCER].includes(user?.role.name)) {
      if (consultant.isApproved && !consultant?.agencyId) {
        if (consultant.agreements || user.role.name === ERole.INFLUENCER) {
          return EConsultantRegProgress.COMPLETE;
        }
        return EConsultantRegProgress.MISSING_ACCEPT_TERMS;
      } else {
        if (consultant.callScheduled) {
          return EConsultantRegProgress.CONSULTANT_UNVETTED;
        } else if (consultant.agencyId) {
          return consultant.registrationStep >= EConsultantRegStep.PROFICIENCY
            ? EConsultantRegProgress.COMPLETE
            : EConsultantRegProgress.REGISTRATION_INCOMPLETE;
        }
        return EConsultantRegProgress.CALL_UNSCHEDULED;
      }
    }
    return EConsultantRegProgress.NOT_CONSULTANT;
  }, [
    consultant?.agencyId,
    consultant?.agreements,
    consultant?.callScheduled,
    consultant?.isApproved,
    consultant?.registrationStep,
    user?.role.name,
  ]);

  useEffect(() => {
    if ((consultant && !consultant.isConfirm) || (customer && !customer.isConfirm)) {
      navigate(ROUTES.SIGNUP_CODE);
      return;
    }
    if (user?.role.name === ERole.INFLUENCER) {
      //   if (!influencer.isApproved) {
      //     `${ROUTES.CONSULTANT_REGISTRATION}/${influencer.registrationStep + 1}`
      /**
       * I commented it because when using the filter and creating a virtual user, the application goes into endless GET requests /auth/me and /consultant-products/:id/full
       */
    } else if (consultantRegistrationProgress > EConsultantRegProgress.NOT_CONSULTANT) {
      if (
        consultantRegistrationProgress <= EConsultantRegProgress.CALL_UNSCHEDULED &&
        !user?.isVirtual
      ) {
        navigate(
          `${ROUTES.CONSULTANT_REGISTRATION}/${consultant.registrationStep + 1}`,
          consultant.agencyId ? { state: { isAgencyAddingUser: true } } : {},
        );
      }
    } else if (user?.role.name === ERole.AGENCY_ADMIN && !agency?.location) {
      navigate(`${ROUTES.AGENCY_CREATION}/${EAgencyRegStep.PROFILE}`);
    }
  }, [
    agency?.location,
    consultant,
    consultantRegistrationProgress,
    navigate,
    user?.role.name,
    user?.isVirtual,
    customer,
  ]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowX: 'hidden' }}>
      <StateMachineProvider>
        <Header />
      </StateMachineProvider>
      <OfflineBanner />
      <StyledRoot>
        <StyledFluid>
          <StyledWrap ref={ref}>
            <Outlet />
            {consultantRegistrationProgress === EConsultantRegProgress.MISSING_ACCEPT_TERMS && (
              <ModalWrapper
                dialogProps={{
                  open: modal,
                }}
                title={{
                  title: 'User Agreement',
                }}
              >
                <>
                  <DialogContent>
                    <FormProvider {...methods}>
                      <UserAgreement close={closeModal} />
                    </FormProvider>
                  </DialogContent>
                </>
              </ModalWrapper>
            )}
          </StyledWrap>
        </StyledFluid>
      </StyledRoot>

      <FloatingFeedbackDialog
        visible={showFeedbackDialog}
        close={() => setShowFeedbackDialog(false)}
      />
      <Box
        sx={{
          display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
          position: 'fixed',
          bottom: 80,
          right: 16,
        }}
      >
        <Tooltip title="Leave Feedback" arrow>
          <IconButton
            onClick={() => setShowFeedbackDialog(true)}
            size="large"
            sx={{ p: 0, display: { xs: 'none', lg: 'inherit' } }}
          >
            <img src="/img/feedbackIcon.png" height={'100%'} />
          </IconButton>
        </Tooltip>
      </Box>
      {!isSales && !isTalent && <NavbarMenu />}
    </Box>
  );
};

export default AuthorizedLayout;
