import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {EAgency} from "../../constants/agency";

interface IUpdateOrderProps {
  key: string | number;
  orderId: number;
  userId: string | number;
}
const updateOrder = createAsyncThunk<any, IUpdateOrderProps>(
  EAgency.updateAgencyOrder,
  async ({ orderId, userId }) => {
    const { data } = await instance.post('agency/orders', { data: [{ orderId, userId }]})
    return data
  }
)

export default updateOrder
