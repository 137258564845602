import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ROUTES from 'router/constants';

import { IModalProps } from 'store/types/common';

import { ArrowDashedIcon1 } from 'styles/icons/ArrowDashedIcon1';
import { ArrowDashedIcon2 } from 'styles/icons/ArrowDashedIcon2';
import {
  StyledArrowWrap,
  StyledDialogContent,
  StyledCheckmark,
  StyledDialog,
  StyledHeaderTitle,
  StyledStepImg,
  StyledStepNumber,
} from './styled';
import useSafeAreaView from 'components/SafeAreaView';

const CongratulationsModal: FunctionComponent<IModalProps> = ({ visible, close }) => {
  const navigate = useNavigate();
  const { safeAreaPadding } = useSafeAreaView();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    navigate(ROUTES.CONSULTANT, {
      state: {
        enableCongratsModal: false,
      },
    });
    close && close();
  };

  return (
    <StyledDialog open={visible} onClose={handleClose} maxWidth={'lg'} fullScreen={mobile}>
      <StyledDialogContent>
        <Box display="flex" flexDirection="column">
          <StyledHeaderTitle
            data-test="congrats-header"
            mb={1.5}
            variant="h1"
            mt={safeAreaPadding.top}
          >
            Congratulations!
          </StyledHeaderTitle>
          <Typography variant="h4" sx={{ fontSize: { xs: '.875rem', md: '1.125rem' } }}>
            You are one step closer to getting great gigs.
          </Typography>
          <Typography sx={{ fontSize: { xs: '.875rem', md: '1.125rem' }, mb: { xs: 1.5, xl: 4 } }}>
            Now connect with our Talent team to kickstart our 3-step vetting process:
          </Typography>
          <Grid container mb={{ xs: 1, md: 4 }} ml={{ xs: 2.5, sm: 0 }}>
            <Grid xs={12} sm={4} mb={{ xs: 4, sm: 0 }}>
              <Box display="flex" alignItems="center" mb={2.5}>
                <StyledStepImg>
                  <img src="/img/congratulations/Vector.png" />
                  <StyledCheckmark
                    src={!mobile ? '/img/checkmark.png' : '/img/checkmark_mobile.png'}
                  />
                </StyledStepImg>
                <StyledArrowWrap>
                  <ArrowDashedIcon1 height={28} width={73} />
                </StyledArrowWrap>
              </Box>
              <Typography variant="h6" mr={2}>
                Create your Profile
              </Typography>
              <Typography variant="body1" mr={2}>
                Thank you for setting your expertise and availability.
              </Typography>
            </Grid>
            <Grid xs={12} sm={4} mb={{ xs: 4, sm: 0 }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2.5}>
                <StyledStepImg>
                  <img src="/img/congratulations/Vector.png" />
                  <StyledStepNumber variant="h1">2</StyledStepNumber>
                </StyledStepImg>
                <StyledArrowWrap>
                  <ArrowDashedIcon2 height={28} width={73} />
                </StyledArrowWrap>
              </Box>
              <Typography variant="h6" mr={2}>
                Initial Screening Call
              </Typography>
              <Typography variant="body1" mr={2}>
                Review your experience and skillset with our talent team.
              </Typography>
            </Grid>
            <Grid xs={12} sm={4} mb={{ xs: 2, md: 0 }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2.5}>
                <StyledStepImg>
                  <img src="/img/congratulations/Vector.png" />
                  <StyledStepNumber variant="h1">3</StyledStepNumber>
                </StyledStepImg>
              </Box>
              <Typography variant="h6">Clear a Background Check</Typography>
              <Typography variant="body1">
                A standard background check for our customer’s peace of mind.
              </Typography>
            </Grid>
          </Grid>
          <Typography>
            Once you successfully complete these steps,
            <br /> you’ll be considered for every work order that comes our way.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            mt={{ xs: 3, md: 4 }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Button
              href="https://calendly.com/marketas/onboarding"
              target="_blank"
              color="secondary"
              variant="contained"
            >
              Book Your Initial Screening Call
            </Button>
            <Typography
              color="secondary"
              component={Link}
              onClick={handleClose}
              sx={{
                mx: 4,
                mb: { xs: 4, sm: 0 },
                mt: { xs: 4, sm: 0 },
                textDecoration: 'underline',
                '&:hover': {
                  textDecoration: 'none',
                  cursor: 'pointer',
                },
              }}
            >
              <strong>Skip to My Profile</strong>
            </Typography>
          </Box>
        </Box>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default CongratulationsModal;
