import React, { FunctionComponent, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { outputQuery } from 'helpers/decorators';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getInvoicesByPageSelector,
  loadingInvoicesSelector,
  totalInvoicesSelector,
} from 'store/selectors/getEnterpriseSelector';

import TableContainer from '../../TableCustomized/TableContainer';
import EnhancedTableHeadSort from '../../TableCustomized/EnhancedTableHeadSort';
import ManageInvoicesTableRow from './ManageInvoicesTableRow';
import { invoiceHeadCells } from '../../TableCustomized/constants';
import { LIMITS } from '../../../models/consts';
import ManageTableWrapper from 'components/TableCustomized/ManageTableWrapper';
import getInvoices from 'store/query/enterprise/getInvoices';
import Loader from 'components/Loader/Loader';
import { Box, Grid, TableCell, TableRow, Typography } from '@mui/material';

const ManageInvoicesTable: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);

  const invoices = useAppSelector(getInvoicesByPageSelector(page + 1));
  const totalInvoicesOrders = useAppSelector(totalInvoicesSelector);
  const loadingInvoices = useAppSelector(loadingInvoicesSelector);

  const { getValues, setValue, watch } = useFormContext();

  const handleRequestSort = (event: React.MouseEvent<unknown>, orderByField: string) => {
    const isAsc = watch('orderByField') === orderByField && watch('orderBySequence') === 'asc';
    setValue('orderByField', orderByField);
    setValue('orderBySequence', isAsc ? 'desc' : 'asc');

    const filter = {
      orderByField,
      orderBySequence: isAsc ? 'desc' : 'asc',
    };

    dispatch(getInvoices({ filter: outputQuery(filter), limit: LIMITS.team, offset: 0 }))
      .unwrap()
      .then(() => setPage(0));
  };

  const handleChangePage = (newPage: number, limit: number, offset: number) => {
    const { orderByField, orderBySequence } = getValues();

    const filter = {
      orderByField,
      orderBySequence,
    };
    setPage(newPage);
    dispatch(getInvoices({ filter: outputQuery(filter), limit, offset }));
  };

  return (
    <>
      {loadingInvoices ? (
        <Loader />
      ) : (
        <TableContainer sx={{ mb: 3 }}>
          <EnhancedTableHeadSort
            order={watch('orderBySequence')}
            orderBy={watch('orderByField')}
            headCells={invoiceHeadCells}
            onRequestSort={handleRequestSort}
          />
          <ManageTableWrapper
            page={page}
            total={totalInvoicesOrders}
            defaultLimit={LIMITS.team}
            count={invoices.length}
            onPageChange={handleChangePage}
            noEmptyRows={true}
            TableContent={
              !!invoices?.length ? (
                <>
                  {invoices.map(row => (
                    <ManageInvoicesTableRow key={row.id} row={row} />
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography>No invoices</Typography>
                  </TableCell>
                </TableRow>
              )
            }
          />
        </TableContainer>
      )}
    </>
  );
};

export default ManageInvoicesTable;
