import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledRoot = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  width: '100%',
  height: '100%',
})

const StyledFluid = styled(Box)({
  position: 'relative',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
})

const StyledWrap = styled(Box)({
  display: 'flex',
  position: 'absolute',
  inset: 0,
  flexDirection: 'column',
  // overflowY: 'scroll',
})

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  },
}))

const StyledRightSide = styled(Box)(({ theme }) => ({
  flex: '1 1 70%',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100% - 64px)'
  },
}))

export { StyledContainer, StyledFluid, StyledRightSide, StyledRoot, StyledWrap }
