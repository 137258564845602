import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const FeedbackStarIcon: FunctionComponent<ISvgProps> = ({ id, fill }) => (
  <svg width="44" height="41" viewBox="0 0 44 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id={`MyGradient-${id}`}>
        <stop offset="0%" stopColor="#FFD029" />
        <stop offset={`${fill}%`} stopColor="#FFD029" />
        <stop offset={`${fill}%`} stopColor="#F5F5F5" />
        <stop offset="100%" stopColor="#F5F5F5" />
      </linearGradient>
    </defs>
    <path
      d="M20.2098 1.30087C20.9435 -0.185471 23.063 -0.185473 23.7967 1.30086L28.7484 11.3324C29.0394 11.9221 29.6018 12.3311 30.2525 12.4262L41.329 14.0452C42.9689 14.2849 43.6224 16.3005 42.4352 17.4569L34.4235 25.2603C33.9518 25.7198 33.7364 26.382 33.8478 27.0311L35.7381 38.0529C36.0184 39.6868 34.3033 40.9327 32.836 40.1611L22.9342 34.9538C22.3514 34.6474 21.6551 34.6474 21.0724 34.9538L11.1705 40.1611C9.70326 40.9327 7.98816 39.6868 8.2684 38.0529L10.1588 27.0311C10.2701 26.382 10.0548 25.7198 9.58301 25.2603L1.57128 17.4569C0.384095 16.3005 1.03766 14.2849 2.67749 14.0452L13.754 12.4262C14.4047 12.3311 14.9671 11.9221 15.2581 11.3325L20.2098 1.30087Z"
      fill={`url(#MyGradient-${id})`}
    />
  </svg>
);
