import instance from "../../middlewares/api";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {EConsultant} from "../../constants";

const getConsultantPipeStatus = createAsyncThunk(
  EConsultant.getPipeStatus,
  async () => {
    const { data } = await instance.get('/pipedrive/user/me')
    return data
  }
)

export default getConsultantPipeStatus
