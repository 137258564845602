import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { IBasicUser } from 'store/types/user';

import { redirectBasedOnUserTypeStatus } from 'utils/redirectUtil';

const HomeRedirect = () => {
  const user = useAppSelector(getMeSelector) as IBasicUser;
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      redirectBasedOnUserTypeStatus(user, navigate);
    }
  }, [user, navigate]);

  return null;
};

export default HomeRedirect;
