import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EPreOrder } from 'store/constants';

const fetchInviteCustomersLinkByPreOrderId = createAsyncThunk<any, number>(
  EPreOrder.getInviteCustomersLinkByPreOrderId,
  async (preOrderId) => {
    const { data } = await instance.get(`/lead-managers/pre-orders/${preOrderId}/invite-customers-link`);

    return data
  }
)

export default fetchInviteCustomersLinkByPreOrderId;
