import {styled} from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import {TableRow} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => theme.unstable_sx({
  border: `1px solid ${theme.palette.grey[300]}`,
  width: {
    xs: '50%',
    md: '39%'
  },
  height: 0,
  '&th': {
    backgroundColor: 'black'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => theme.unstable_sx({
  '& th': {
    backgroundColor: 'tertiary.main',
    py: '5px'
  }
}))

const StyledBoxWrap = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between'
}))

export { StyledBoxWrap, StyledTableCell, StyledTableRow }
