import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from '@mui/material/DialogContent';
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const StyledDialog = styled(Dialog)(({ theme }) => theme.unstable_sx({
  '& .MuiPaper-root': {
    background: {
      xs: '85% / cover no-repeat #FFCA28 url(/img/workerbee-video.png)',
      md: 'center / cover no-repeat #FFCA28 url(/img/workerbee-video.png)'
    },
    // width: 970,
    overflow: 'hidden'
  }
}))

const StyledDialogContent = styled(DialogContent)(({ theme }) => theme.unstable_sx({
  px: {
    sm: 5
  }
}))

const StyledHeaderTitle = styled(Typography)(({ theme }) => theme.unstable_sx({
  fontSize: {
    xs: '1.875rem',
    xl: '2.5rem'
  },
}))

const StyledStepImg = styled(Box)(({ theme }) => theme.unstable_sx({
  position: 'relative',
  ml: -2.5
}))

const StyledArrowWrap = styled(Box)(({ theme }) => theme.unstable_sx({
  flexGrow: 1,
  textAlign: 'center',
  ml: -2.5,
  display: {
    xs: 'none',
    sm: 'block'
  }
}))

const StyledStepNumber = styled(Typography)(({ theme }) => theme.unstable_sx({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: {
    xs: '2.375rem',
    md: '3rem'
  },
  lineHeight: 'normal'
}))

const StyledCheckmark = styled('img')({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-15%, -75%)'
})

export { StyledArrowWrap, StyledDialogContent, StyledCheckmark, StyledDialog, StyledHeaderTitle, StyledStepImg, StyledStepNumber }
