import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Typography from '@mui/material/Typography';

const PageButton = styled(MuiButton)(({ theme }) => theme.unstable_sx({
  minWidth: 32,
  width: 32,
  height: 32,
  fontSize: '0.75rem',
  px: 1.25,
  borderColor: 'tertiary.main',
  marginLeft: '5px',
  '&.Mui-disabled ': {
    borderColor: 'secondary.main',
    backgroundColor: 'secondary.main',
    color: 'common.white'
  }
}));

const StatusChip = styled(Chip)(({theme}) => theme.unstable_sx({
  fontSize: { xs: '0.625rem', lg: '0.75rem' },
  '& .MuiChip-label': {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
}));

const TableLabel = styled(Typography)(({theme}) => theme.unstable_sx({
  wordWrap: 'break-word',
  fontSize: { xs: '0.625rem', lg: '0.875rem' },
}));

export {
  PageButton,
  StatusChip,
  TableLabel,
}
