import React, { FunctionComponent } from 'react';

import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const StyledLoaderWrap = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const Loader: FunctionComponent<CircularProgressProps> = props => (
  <StyledLoaderWrap data-test="loader-block">
    <CircularProgress color="inherit" {...props} />
  </StyledLoaderWrap>
);

export default Loader;
