import {createAsyncThunk} from "@reduxjs/toolkit";

import { EUser } from "../../constants";
import { IBasicUser } from "../../types/user";

const setUserFromSSR = createAsyncThunk<any, IBasicUser>(
  EUser.setMe,
  async (payload) => {
    return await new Promise<IBasicUser>((resolve) => {
      resolve(payload);
    });
  }
)

export default setUserFromSSR;
