import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { EFeedbackModal } from 'models/consts';

import { IFeedbackForm } from 'store/types/form';
import { IModalProps } from 'store/types/common';
import { IWorker } from 'store/types/consultant';

import createFeedback from 'store/query/order/createFeedback';

import { orderFeedbackLoadedSelector } from 'store/selectors/getOrderSelector';

import DetailFeedback from './DetailFeedback';
import GeneralFeedback from './GeneralFeedback';
import Loader from 'components/Loader/Loader';
import ModalWrapper from '../Modal';

export interface IFeedbackModalProps extends IModalProps {
  consultant: IWorker;
}

const FeedbackModal: FunctionComponent<IFeedbackModalProps> = ({ visible, close, consultant }) => {
  const dispatch = useAppDispatch();

  const { id = '' } = useParams();

  const methods = useForm<IFeedbackForm>({ mode: 'onChange' });

  const loaded = useAppSelector(orderFeedbackLoadedSelector(id));

  const [step, setStep] = useState<EFeedbackModal>(EFeedbackModal.GENERAL);

  const handleClose = useCallback(() => {
    close && close();
  }, [close]);

  const onSubmit: SubmitHandler<IFeedbackForm> = useCallback(
    value => {
      if (consultant.id) {
        dispatch(
          createFeedback({
            orderId: id,
            feedback: {
              ...value,
              workerId: consultant.id,
            },
          }),
        )
          .unwrap()
          .then(handleClose);
      }
    },
    [consultant?.id, dispatch, handleClose, id],
  );

  const renderFeedbackStage = useMemo(() => {
    switch (step) {
      case EFeedbackModal.GENERAL:
        return <GeneralFeedback onDataSave={() => setStep(EFeedbackModal.DETAIL)} />;
      default:
        return <DetailFeedback onDataSave={onSubmit} consultant={consultant} />;
    }
  }, [consultant, onSubmit, step]);

  const modalTitle = useMemo(() => {
    switch (step) {
      case EFeedbackModal.GENERAL:
        return {
          title: 'Give feedback',
        };
      default:
        return {
          title: 'Give feedback',
          subTitle: `Tell us more about ${consultant?.firstName}'s performance`,
        };
    }
  }, [consultant?.firstName, step]);

  return (
    <ModalWrapper
      dialogProps={{
        open: visible,
        onClose: handleClose,
      }}
      title={modalTitle}
    >
      {loaded ? <FormProvider {...methods}>{renderFeedbackStage}</FormProvider> : <Loader />}
    </ModalWrapper>
  );
};

export default FeedbackModal;
