import Dialog, { dialogClasses } from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

const StyledDialog = styled(Dialog)(({ theme }) => theme.unstable_sx({
  [`& .${dialogClasses.paper}`]: {
    [theme.breakpoints.up('md')]: {
      width: 645,
      maxWidth: 645,
    }
  }
}))

export { StyledDialog }
