import { Components } from '@mui/material/styles';

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    tertiary: true;
  }
}

const MuiFab: Components['MuiFab'] = {
  styleOverrides: {
    root: ({ theme }: any) =>
      theme.unstable_sx({
        width: theme.spacing(10),
        height: theme.spacing(10),
      }),
  },
};

export default MuiFab;
