import React, { FunctionComponent, Suspense, useEffect, useMemo } from 'react';
import CustomScroll from 'react-custom-scroll';

import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import shownNotification from 'store/query/notification/shownNotification';
import {
  isNotificationLoaded,
  notificationsSelector,
} from 'store/selectors/getNotificationSelector';

import { StyledNotificationWrap, StyledNoNotification } from './styled';

import NotificationItem from './NotificationItem';
import Loader from 'components/Loader/Loader';

const Container: FunctionComponent<any> = ({ handleClose }) => {
  const dispatch = useAppDispatch();
  const loadedNotification = useAppSelector(isNotificationLoaded);
  const notifications = useAppSelector(notificationsSelector);

  const unreadedCount = useMemo(
    () => notifications?.filter(({ isShow }) => !isShow).length || 0,
    [notifications],
  );

  useEffect(() => {
    if (unreadedCount && notifications) {
      setTimeout(() => {
        dispatch(
          shownNotification({
            ids: notifications.filter(({ isShow }) => !isShow).map(({ id }) => id),
          }),
        );
      }, 5000);
    }
  }, [dispatch, unreadedCount, notifications]);

  return (
    <>
      {loadedNotification ? (
        <Suspense fallback={<Loader />}>
          <StyledNotificationWrap>
            <Typography gutterBottom variant="h6">
              Notifications
            </Typography>
            {notifications?.length ? (
              <CustomScroll heightRelativeToParent="calc(100% - 36px)" allowOuterScroll>
                <Box sx={{ height: '100%', overflowY: 'scroll' }}>
                  <List sx={{ pr: '14px', bgcolor: 'background.paper' }}>
                    {notifications.map(notification => (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        close={handleClose}
                      />
                    ))}
                  </List>
                </Box>
              </CustomScroll>
            ) : (
              <StyledNoNotification>No notification yet</StyledNoNotification>
            )}
          </StyledNotificationWrap>
        </Suspense>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Container;
