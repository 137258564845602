import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { ListItemProps } from "@mui/material/ListItem";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ListItemButton, { listItemButtonClasses } from "@mui/material/ListItemButton";
import { listItemTextClasses } from "@mui/material/ListItemText";
import { typographyClasses } from "@mui/material/Typography";

const drawerWidth = 325

// @ts-ignore
interface ExtentdsTypographyProps extends TypographyProps {
  component?: string;
}

const StyledDrawer = styled(Drawer)(({ theme }) => theme.unstable_sx({
  width: { xs: '100%', md: drawerWidth },
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    border: 0,
    borderRight: {
      md: '1px solid #ebebeb'
    },
    position: 'relative',
    width: { md: drawerWidth },
    backgroundColor: 'transparent'
  },
}))

const StyledListItem = styled(ListItemButton)(({ theme }) => theme.unstable_sx({
  borderRadius: 1,
  [`&.${listItemButtonClasses.selected}, &.${listItemButtonClasses.selected}:hover`]: {
    backgroundColor: theme.palette.primary.main,
    [`& .${listItemTextClasses.secondary} .${typographyClasses.root}`]: {
      color: 'text.primary'
    },
  }
}))

const StyledLastMessage = styled(Typography)<ExtentdsTypographyProps>(({ theme }) => theme.unstable_sx({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'block'
}))

const StyledBoxDate = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignSelf: 'stretch',
  alignItems: 'flex-end'
}))

const StyledTypographyCount = styled(Typography)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.secondary.main,
  height: 20,
  px: 1,
  borderRadius: '20px'
}))

const StyledTypographyName = styled(Typography)(({ theme }) => theme.unstable_sx({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

export { StyledBoxDate, StyledDrawer, StyledLastMessage, StyledListItem, StyledTypographyCount, StyledTypographyName }
