import {createAsyncThunk} from "@reduxjs/toolkit";

import instance from "../../middlewares/api";
import { EConsultant } from "../../constants";
import { IOutDates } from "../../types/consultant";

interface IAddOutDatesProps {
  keySelector?: string;
  outDates: IOutDates[],
  userId: number | string;
}
const updateOutDates = createAsyncThunk<IOutDates[], IAddOutDatesProps>(
  EConsultant.updateOutDates,
  async ({ outDates }) => {
    const { data } = await instance.patch('out-dates', outDates)
    return await data
  }
)

export default updateOutDates
