import React from 'react';

import { StyledBarItem } from './styled'

import { SeniorityLevelType } from "./constants";

interface IBarItem {
  isActive?: boolean
  level: SeniorityLevelType
  onToggle?: (newLevel: SeniorityLevelType) => void
  disabled?: boolean
}

const BarItem: React.FunctionComponent<IBarItem> = ({ isActive, level, onToggle, disabled }) => (
  <StyledBarItem id='bar-item' isActive={isActive} disabled={disabled} onClick={() => onToggle && onToggle(level)}/>
);

export default BarItem;
