import { Components } from '@mui/material/styles';

const MuiLinearProgress: Components['MuiLinearProgress'] = {
  styleOverrides: {
    root: {
      height: 9,
      borderRadius: 8,
    },
    colorPrimary: {
      backgroundColor: '#F5F5F5',
      boxShadow: 'inset -1px 1px #EBEBEB, inset 1px -1px #EBEBEB',
    },
    bar: {
      borderRadius: 8,
    },
  },
};

export default MuiLinearProgress;
