import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EAgencyProducts } from "store/constants/productAndExperience";

const removeProductAndExperienceRate = createAsyncThunk(
  EAgencyProducts.RemoveProductAndExperienceRate,
  async (payload: number) => {
    const { data } = await instance.delete('/agency/expertises', { data: { ids: [payload] } })
    return await data
  }
)

export default removeProductAndExperienceRate
