import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { ECommon } from "../../constants";

const fetchExpertiseLevels = createAsyncThunk(
  ECommon.expertiseLevels,
  async () => {
    const { data } = await instance.get('levels')
    return await data
  }
)

export default fetchExpertiseLevels
