import { TableCell } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";

// @ts-ignore
interface IExtendTypographyProps extends TypographyProps {
  component: string
}

const StyledDetailKey = styled(Typography)<IExtendTypographyProps>(({ theme }) => theme.unstable_sx({
  flexBasis: 'auto',
  color: '#5D5D5E',
  paddingRight: 3,
  width: '220px',
  maxWidth: '100%',
}))

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 4,
    paddingRight: 4,
  },
}))

export { StyledDetailKey, StyledHeaderCell };
