import React, { FunctionComponent } from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Typography from '@mui/material/Typography';

import { Atention } from 'styles/icons/Atention';
import { StyledBoxWrap, StyledTableCell, StyledTableRow } from './styled';

import CustomTooltip from 'components/CustomTooltip';

interface isConsultantProfile {
  isConsultantProfile?: boolean;
}

const NoBusinessSetup: FunctionComponent<isConsultantProfile> = ({ isConsultantProfile }) => {
  return (
    <Grid spacing={2} direction="column" alignItems="center">
      <Grid container xs={12} mb={2.5}>
        <Grid
          data-test="not_entered-warning"
          xs={12}
          sx={{
            border: '2px solid #FFCA28',
            padding: '16px 35px 16px 35px',
          }}
          display="flex"
          flexWrap="nowrap"
          alignItems="center"
        >
          <Atention style={{ flexShrink: 0 }} />
          {!isConsultantProfile ? (
            <Typography
              width="auto"
              sx={{
                pl: { xs: 1.5, md: 4 },
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              `As a Workerbee specialist, you&apos;re an independent contractor connecting with
              companies through our talent marketplace.`
            </Typography>
          ) : (
            <Typography
              width="auto"
              sx={{
                pl: { xs: 1.5, md: 4 },
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              *We apologize for the inconvenience, but at this time,
              <span style={{ fontWeight: 'bold' }}>
                Workerbee operates exclusively within the United States and Canada.
              </span>{' '}
              We cannot accept profiles from individuals without work authorization in these
              countries. We look forward to expanding our services to more regions in the future.
              Thank you for your understanding.
            </Typography>
          )}
        </Grid>
      </Grid>

      {!isConsultantProfile && (
        <>
          <Table sx={{ display: { xs: 'none', md: 'table' } }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: 'none', pl: 0, width: 120 }}></TableCell>
                <TableCell align="center">
                  <Typography variant="h3">Sole Proprietor</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h3">Limited Liability Company (LLC)</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ border: 'none', pl: 0 }}>
                  <Typography>
                    <b>Availability</b>
                  </Typography>
                </TableCell>
                <StyledTableCell>
                  <Typography>Up to 20 hours/week</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography>Up to 40 hours/week</Typography>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ border: 'none', pl: 0 }}>
                  <Typography>
                    <b>Tax Identifier</b>
                    <CustomTooltip
                      placement="left"
                      title="To establish your independent contractor status with both Workerbee and your clients, you'll need an EIN (Employer Identification Number). An EIN is a unique nine-digit number assigned by the Internal Revenue Service (IRS) in the United States to identify your business entity for tax purposes."
                    />
                  </Typography>
                </TableCell>
                <StyledTableCell>
                  <Typography>EIN</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography>LLC</Typography>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ border: 'none', pl: 0, verticalAlign: 'top' }}
                >
                  <Typography>
                    <b>Benefits</b>
                  </Typography>
                </TableCell>
                <StyledTableCell>
                  <StyledBoxWrap>
                    <Typography>
                      Being a sole proprietor with an EIN helps prevent identity theft and
                      establishes a clear independent contractor relationship between you,
                      Workerbee, and your clients.
                    </Typography>
                    <Button
                      type="button"
                      variant="contained"
                      sx={{ alignSelf: 'center', mt: 2 }}
                      href="https://sa.www4.irs.gov/modiein/individual/index.jsp"
                      target="_blank"
                    >
                      Get an EIN
                    </Button>
                  </StyledBoxWrap>
                </StyledTableCell>
                <StyledTableCell>
                  <StyledBoxWrap>
                    <Typography>
                      An LLC provides personal asset protection in case of legal issues or
                      bankruptcy.
                    </Typography>
                    <Button
                      type="button"
                      variant="contained"
                      sx={{ alignSelf: 'center', mt: 2 }}
                      href="https://www.sba.gov/business-guide/launch-your-business/register-your-business"
                      target="_blank"
                    >
                      Set up an LLC
                    </Button>
                  </StyledBoxWrap>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table sx={{ display: { md: 'none' } }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="h6">Sole Proprietor</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6">Limited Liability Company (LLC)</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell component="th" align="center" colSpan={2}>
                  <Typography>
                    <b>Availability</b>
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
              <TableRow>
                <StyledTableCell>
                  <Typography>Up to 20 hours/week</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography>Up to 40 hours/week</Typography>
                </StyledTableCell>
              </TableRow>

              <StyledTableRow>
                <StyledTableCell component="th" align="center" colSpan={2}>
                  <Typography>
                    <b>Tax Identifier</b>
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
              <TableRow>
                <StyledTableCell>
                  <Typography>EIN</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography>LLC</Typography>
                </StyledTableCell>
              </TableRow>

              <StyledTableRow>
                <StyledTableCell component="th" align="center" colSpan={2}>
                  <Typography>
                    <b>Benefits</b>
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
              <TableRow>
                <StyledTableCell>
                  <StyledBoxWrap>
                    <Typography>
                      Being a sole proprietor with an EIN helps prevent identity theft and
                      establishes a clear independent contractor relationship between you,
                      Workerbee, and your clients.
                    </Typography>
                    <Button
                      type="button"
                      variant="contained"
                      sx={{ alignSelf: 'center', mt: 2, width: 140 }}
                      href="https://sa.www4.irs.gov/modiein/individual/index.jsp"
                      target="_blank"
                    >
                      Get an EIN
                    </Button>
                  </StyledBoxWrap>
                </StyledTableCell>
                <StyledTableCell>
                  <StyledBoxWrap>
                    <Typography>
                      An LLC provides personal asset protection in case of legal issues or
                      bankruptcy.
                    </Typography>
                    <Button
                      type="button"
                      variant="contained"
                      sx={{ alignSelf: 'center', mt: 2, width: 140 }}
                      href="https://www.sba.gov/business-guide/launch-your-business/register-your-business"
                      target="_blank"
                    >
                      Set up an LLC
                    </Button>
                  </StyledBoxWrap>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Grid xs={12} my={2}>
            <Typography mb={2}>
              Please remember to consult with a legal or tax professional to ensure compliance with
              any specific state or local requirements and to receive personalized advice for your
              business.
            </Typography>
            <Typography mb={2}>
              To understand the differences between LLC and sole proprietorship, check out this
              link:{' '}
              <Link
                target="_blank"
                href="https://www.sba.gov/business-guide/launch-your-business/choose-business-structure"
                color="inherit"
              >
                https://www.sba.gov/business-guide/launch-your-business/choose-business-structure
              </Link>
              .
            </Typography>
            <Typography mb={2}>
              If you want to learn about the Employer Identification Number (EIN), this link has got
              you covered:{' '}
              <Link
                target="_blank"
                href="https://www.irs.gov/businesses/small-businesses-self-employed/how-to-apply-for-an-ein"
                color="inherit"
              >
                https://www.irs.gov/businesses/small-businesses-self-employed/how-to-apply-for-an-ein
              </Link>
              .
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default NoBusinessSetup;
