import React, { FunctionComponent } from 'react';

import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import mapValues from 'lodash/fp/mapValues';
import omit from 'lodash/fp/omit';
import orderBy from 'lodash/fp/orderBy';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { IConsultantExperience } from 'store/types/experiences';

import ExperiencesGroupedWrap from './ExperiencesGroupedWrap';

const transformExperiencesData = flow(
  orderBy('activities', 'desc'),
  groupBy('activities'),
  mapValues(groupedData =>
    flow(
      groupBy('company.id'),
      mapValues(companyData => ({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        company: companyData[0].company,
        experiences: map(omit(['company']))(companyData),
      })),
    )(groupedData),
  ),
);

interface IExperiencesView {
  experiences: IConsultantExperience[];
}

const ExperiencesView: FunctionComponent<IExperiencesView> = ({ experiences }) => {
  const grouped = groupBy('activities')(experiences);
  return (
    <>
      {/*TODO: remove after successful testing*/}
      {/*{Object.entries(transformExperiencesData(experiences)).map(([activities, sortedByCompanies]) => (*/}
      {/*    <Box key={activities} my={2.5}>*/}
      {/*      <Typography variant="body1" textTransform="capitalize" mb={2}>*/}
      {/*        <b>{activities}s</b>*/}
      {/*      </Typography>*/}
      {/*      {Object.entries(sortedByCompanies).map(([name, company], index) => (*/}
      {/*        <ExperiencesGroupedWrap key={`${activities}-${index}-${company}`} item={company} />*/}
      {/*      ))}*/}
      {/*    </Box>*/}
      {/*  ))}*/}
      {Object.entries(grouped).map(([activities, sortedExperiences]) => (
        <Box key={activities} my={2.5}>
          <Typography variant="body1" textTransform="capitalize" mb={2}>
            <b>{activities}s</b>
          </Typography>
          {sortedExperiences?.map(({ company, ...experience }: any, index) => (
            <ExperiencesGroupedWrap
              key={`${activities}-${index}-${company.id}`}
              item={{
                company,
                experiences: [experience],
              }}
            />
          ))}
        </Box>
      ))}
    </>
  );
};

export default ExperiencesView;
