import { createSlice } from '@reduxjs/toolkit';
import { IError } from 'models/inner-models';
import updateOrderDraft from 'store/query/order/updateOrderDraft';
import approveDraftOrder from 'store/query/order/approveDraftOrder';
import declineDraftOrder from 'store/query/order/declineDraftOrder';

export interface OrderDraftState {
  actionError: IError | null;
  loaded: boolean;
  loading: boolean;
}

const initialState: OrderDraftState = {
  actionError: null,
  loaded: false,
  loading: false,
}

const { reducer} = createSlice({
  name: 'orderDraft',
  initialState,
  reducers: {
    // resetOrderDraftCreation: () => initialState,
  },
  extraReducers:  (builder) => { builder
    .addCase(updateOrderDraft.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateOrderDraft.fulfilled, (state) => {
      state.loaded = true;
      state.loading = false;
    })
    .addCase(updateOrderDraft.rejected, (state, { error, type }) => {
      state.actionError = {
        message: error?.message || '',
        action: type,
      }
      state.loaded = false;
      state.loading = false;
    })

    .addCase(approveDraftOrder.pending, (state) => {
      state.loading = true;
    })
    .addCase(approveDraftOrder.rejected, (state) => {
      state.loading = false;
    })
    .addCase(approveDraftOrder.fulfilled, (state) => {
      state.loading = false;
    })

    .addCase(declineDraftOrder.pending, (state) => {
      state.loading = true;
    })
    .addCase(declineDraftOrder.rejected, (state) => {
      state.loading = false;
    })
    .addCase(declineDraftOrder.fulfilled, (state) => {
      state.loading = false;
    })
  },
});

export default reducer;
