import React, { useMemo } from 'react';

import { ERole } from 'models/consts';

import { useAppSelector } from 'store/hooks';
import { getMeRoleSelector } from 'store/selectors/getUserSelector';

import CustomerList from 'views/Agency/CustomerList';
import OrderList from 'views/Orders/OrderList';
import PreOrderList from 'views/PreOrders';
import PurchaseList from 'views/Purchase/PurchaseList';
import Impersonation from 'views/Settings/Impersonation';
import InfluencerLanding from '../views/Influencer/InfluencerLanding';
import TalentSearch from 'views/TalentSearch';

const OrderListRoute = () => {
  const role = useAppSelector(getMeRoleSelector);

  return useMemo(() => {
    switch (role?.name) {
      case ERole.AGENCY_ADMIN:
      case ERole.RESOURCE_MANAGER:
        return <CustomerList />;
      case ERole.CUSTOMER_ADMIN:
      case ERole.CUSTOMER:
        return <PurchaseList />;
      case ERole.LEAD_MANAGER:
        return <PreOrderList />;
      case ERole.ADMIN:
        return <Impersonation />;
      case ERole.INFLUENCER:
        return <InfluencerLanding />;
      case ERole.SALES:
      case ERole.TALENT:
        return <TalentSearch />;
      default:
        return <OrderList />;
    }
  }, [role?.name]);
};

export default OrderListRoute;
