import React, { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

import { StyledItem, StyledHexagon, StyledHexagonNumber, StyledStepName, StyledWrap } from "./styled";

import { IStepperItem } from "store/types/common";

const Step: FC<IStepperItem<any>> = ({ number, text }) => {
  const {step} = useParams();

  const currentStep = useMemo(() => {
    return number === +(step || 1)
  }, [number, step])

  const prevStep = useMemo(() => number > +(step || 1), [number, step])

  return (
    <StyledItem currentStep={currentStep} key={`item-${number}`}>
      <StyledWrap sx={{mr: {xs: 1.5, md: 2 }}}>
        <StyledHexagon
          state={
            prevStep ? 'next' :
              currentStep ? 'active' : 'prev'
          }
        />
        <StyledHexagonNumber inactive={prevStep}>
          {step && +(step) <= number && number}
        </StyledHexagonNumber>
      </StyledWrap>
      <StyledStepName currentStep={currentStep} inactive={prevStep}>{text}</StyledStepName>
    </StyledItem>
  )
}
export default Step
