import React from 'react';
import { Link, Outlet } from 'react-router-dom';

import Box from '@mui/material/Box'
import LogoBlack from 'styles/icons/LogoBlack';

import { StyledContainer } from "../AuthorizedLayout/styled";
import { StyledHeader, StyledStickyBox } from "./styled";

const DocumentLayout = () => (
  <StyledContainer maxWidth="xxl">
    <StyledStickyBox>
      <StyledHeader>
        <Link to="/"><LogoBlack sx={{width: 165, height: 28}} /></Link>
      </StyledHeader>
    </StyledStickyBox>
    <Box py={4}>
      <Outlet />
    </Box>
  </StyledContainer>
);

export default DocumentLayout;
