import {Navigate, useRouteError} from 'react-router-dom'
import ROUTES from "../../router/constants";
import React from "react";

const ErrorBoundary = () => {
  const error = useRouteError() as any;

  if(error && error?.message === 'Refresh token is expired or invalid') {
    return <Navigate replace to={ROUTES.AUTH} />
  }
  return null
}

export default ErrorBoundary
