import sortBy from "lodash/fp/sortBy";
import dayjs from 'utils/dayjs'

import { getCountryCallingCode } from "react-phone-number-input/input";

import formatDurationInYearsMonths from "../helpers/formatDurationInYearsMonths";

export const groupArrayByDate = (array: any[], key: string) => (
  array.reduce((r: any, a: any) => {
    r[dayjs(a.state[key]).format('ddd, MMM D, YYYY')] = r[dayjs(a.state[key]).format('ddd, MMM D, YYYY')] || [];
    r[dayjs(a.state[key]).format('ddd, MMM D, YYYY')].push(a);
    return r;
  }, Object.create(null))
)

export const groupArrayByDateDesc = (array: any[], key: string) => {
  const groupedObject = array.reduce((r: any, a: any) => {
    const formattedDate = dayjs(a.state[key]).format('ddd, MMM D, YYYY');
    r[formattedDate] = r[formattedDate] || [];
    r[formattedDate].push(a);
    return r;
  }, Object.create(null));

  const sortedKeys = Object.keys(groupedObject).sort((a, b) => {
    return new Date(b).getTime() - new Date(a).getTime();
  });

  return sortedKeys.reduce((result: any, key: string) => {
    result[key] = groupedObject[key];
    return result;
  }, Object.create(null));
};

export const stringToLowerCaseWithReplaceSpace = (value: string) => value.toLowerCase().replace(/(\s)+/g, '_')

export const calculateExperiencesDuration = (periods: any[]) => {
  const uniqueMonths = new Set();
  const commonMonths = new Set();

  periods.map((period, index) => {
    const start = dayjs(period.startDate).startOf('month');
    const end = dayjs(period.endDate || new Date()).endOf('month');
    const months = Math.round(dayjs.duration(end.diff(start)).asMonths());

    for (let i = 0; i < months; i++) {
      const month = start.clone().add(i, "months").format("YYYY-MM");

      if (index === 0 || !uniqueMonths.has(month)) {
        uniqueMonths.add(month);
      } else {
        commonMonths.add(month);
      }
    }
  })

  return {
    duration: uniqueMonths.size,
    durationFormatted: formatDurationInYearsMonths(dayjs.duration({years: Math.floor(uniqueMonths.size / 12),months: uniqueMonths.size % 12}))
  }
}

export function calculateValueProgressBar(value: number) {
  if (value < 12) {
    return value / 12 * 25;
  } else if (value < 36) {
    return (value - 12) / 24 * 25 + 25;
  } else if (value < 60) {
    return (value - 36) / 24 * 25 + 50;
  } else if (value < 84) {
    return (value - 60) / 24 * 25 + 75;
  } else {
    return 100;
  }
}

export const sortingBy = (list: any[], field: string) => sortBy(field)(list)

export const pluralizeWord = (word: string, count: number) => {
  if (count !== 1) {
    return word + 's';
  }
  return word;
}

export const getPhoneNumber = (phoneNumber: any, countryIso: any) => {
  const countryCallingCodeLength = getCountryCallingCode(countryIso).length
  const cleanedPhoneNumber = phoneNumber.slice(countryCallingCodeLength + 1)
  return cleanedPhoneNumber;
}
