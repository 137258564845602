import { IProductExperienceAge } from '../models';
import dayjs from "./dayjs";
import {IProductExperienceFull} from "../store/types/consultant";

export interface IAccumulator {
  consultantId: number;
  month: number;
  productId: number;
};

export const getReducedExperiences = (productExperienceAge: IProductExperienceFull[]) => {
  const reducedExperiences = productExperienceAge
    .reduce((accumulator: IAccumulator[], currentItem: IProductExperienceFull) => {
      let month = 1;
      currentItem.experiences.map((experience) => {
        return month = month + dayjs(experience.endDate || dayjs()).diff(experience.startDate, 'month')
      })

      const foundItem = accumulator.find((item) => item.productId === currentItem.product.id);

      if (foundItem) {
        foundItem.month = foundItem.month + month;
      } else {
        accumulator.push({
          consultantId: currentItem.expertise.userId,
          month: month,
          productId: currentItem.product.id,
        });
      }
      return accumulator;
    }, []);

  return reducedExperiences;
};

export const getProductWithMaxPeriodOfWork = (experiences: any[]) => {
  const maxExpItem = experiences
    .reduce((accumulator: any, currentItem: any) => {
      if (!accumulator || currentItem.month > accumulator.month) {
        return currentItem;
      }
      return accumulator;
    }, undefined);

  return maxExpItem;
};

