import { Components } from '@mui/material/styles';

const MuiContainer: Components['MuiContainer'] = {
  styleOverrides: {
    root: ({ theme }: any) =>
      theme.unstable_sx({
        pl: {
          xs: 2,
          sm: 3,
          lg: 5,
        },
        pr: {
          xs: 0,
          sm: 1,
          lg: 3,
        },
      }),
  },
};

export default MuiContainer;
