import React, { FunctionComponent } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ROUTES from 'router/constants';

import { ELocalStoreKeys } from 'models/consts';

import updateAction from 'store/actions/updateAction';
import { useAppDispatch } from 'store/hooks';
import { IBasicUser } from 'store/types/user';

import logoutImpersonate from 'store/query/agency/logoutImpersonate';

import { StyledBoxImpersonate } from '../styled';

const ConsultantImpersonation: FunctionComponent<{ user: IBasicUser }> = ({ user }) => {
  const [cookies] = useCookies([
    ELocalStoreKeys.IMPERSONATE_TOKEN,
    ELocalStoreKeys.GLOBAL_IMPERSONATION,
  ]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isGlobalImpersonated = cookies[ELocalStoreKeys.GLOBAL_IMPERSONATION];

  const { actions } = useStateMachine({ updateAction });

  const removeImpersonateToken = () => {
    dispatch(logoutImpersonate())
      .unwrap()
      .finally(() => {
        navigate(ROUTES.TEAM);
        actions.updateAction({
          experiences: [],
          expertise: {},
          levelId: 0,
          timeLine: {},
          worker: {},
          searchParams: {},
        });
      });
  };

  return (
    <StyledBoxImpersonate data-test="impersonation-header">
      <Typography variant="body2">
        Impersonation is active now. You are logged in
        {(user.firstName || user.lastName) && (
          <>
            {' '}
            as{' '}
            <strong data-test="impersonate-username">
              {user.firstName} {user.lastName}
            </strong>
          </>
        )}
        . {!isGlobalImpersonated ? 'Back to' : ''}&nbsp;
        <Link
          onClick={removeImpersonateToken}
          sx={{ color: 'text.primary', cursor: 'pointer', textDecoration: 'underline' }}
          data-test="impersonation-link"
        >
          {isGlobalImpersonated ? 'Exit Impersonation' : 'Team Roster'}
        </Link>
        .
      </Typography>
    </StyledBoxImpersonate>
  );
};

export default ConsultantImpersonation;
