import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Conversation, ConversationUpdateReason, Message } from '@twilio/conversations';

import dayjs from 'utils/dayjs';
import { ellipsize } from 'utils/textFormatter';

import { Hidden } from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { useAppSelector } from 'store/hooks';

import { IChatItem } from 'store/types/chat';
import { getMeSelector } from 'store/selectors/getUserSelector';

import Avatar from 'components/Avatar';

import {
  StyledBoxDate,
  StyledLastMessage,
  StyledListItem,
  StyledTypographyCount,
  StyledTypographyName,
} from './styled';
import { currentChatSelector } from '../../../store/selectors/getChatListSelector';
import { ERoleId } from '../../../models/consts';

interface IConversationsListItem {
  item: IChatItem;
  conversationItem: Conversation | undefined;
}

const ConversationsListItem: React.FunctionComponent<IConversationsListItem> = ({
  item,
  conversationItem,
}) => {
  const navigate = useNavigate();
  const { room } = useParams();

  const me = useAppSelector(getMeSelector);
  const currentChat = useAppSelector(currentChatSelector(room));

  const companion = useMemo(() => {
    if ([ERoleId.CUSTOMER_ADMIN, ERoleId.CUSTOMER].includes(me.role.id)) {
      const workerUser = item?.order?.agency || item.order?.worker || item.preOrder?.agency;
      return {
        ...workerUser,
        avatarUrl:
          item?.order?.agency?.logo ||
          item?.preOrder?.agency?.logo ||
          item?.order?.worker?.consultant.avatarUrl,
      } as any;
    } else {
      const customerUser = item.order?.customer || item.preOrder?.customer;
      return {
        ...customerUser,
        avatarUrl:
          item.order?.customer?.customer?.companies[0]?.logo ||
          item.preOrder?.customer?.customer?.companies[0]?.logo,
      } as any;
    }
  }, [me.role.id, item.order, item.preOrder]);

  const [countUnread, setCountUnread] = useState<number>(0);
  const [lastMessage, setLastMessage] = useState<any>();

  const nameText = `${companion.firstName || companion.name} ${companion.lastName ?? ''}`;
  const nameTextEllipsis = ellipsize(nameText, 27);

  const updatedConversationListener = useCallback(
    ({
      conversation,
      updateReasons,
    }: {
      conversation: Conversation;
      updateReasons: ConversationUpdateReason[];
    }) => {
      const updatedIndex = updateReasons.includes('lastReadMessageIndex');
      const updatedlastMessage = updateReasons.includes('lastMessage');

      if (updatedIndex) {
        conversation.getUnreadMessagesCount().then((count: any) => {
          setCountUnread(count || 0);
        });
      }

      if (updatedlastMessage) {
        conversation.getUnreadMessagesCount().then((count: any) => {
          setCountUnread(count || 0);
        });
        conversation.getMessages(1).then((messagePaginator: any) => {
          setLastMessage({
            body: messagePaginator?.items[0]?.body || '',
            dateCreated: messagePaginator?.items[0]?.dateCreated,
          });
        });
      }
    },
    [],
  );

  const addedMessageListener = useCallback(
    (message: Message) => {
      if (currentChat?.chatSId === conversationItem?.sid) {
        conversationItem
          ?.updateLastReadMessageIndex((conversationItem?.lastMessage?.index || 0) + 1)
          .then()
          .catch((error: any) => {
            console.error(error);
          });
      }
    },
    [conversationItem, currentChat?.chatSId],
  );

  useEffect(() => {
    if (conversationItem) {
      conversationItem.on('updated', updatedConversationListener);
      conversationItem.on('messageAdded', addedMessageListener);

      conversationItem.getUnreadMessagesCount().then(count => {
        if (
          conversationItem &&
          conversationItem?.lastReadMessageIndex === null &&
          conversationItem?.lastMessage?.index !== undefined
        ) {
          setCountUnread(count || conversationItem?.lastMessage?.index + 1);
        } else {
          setCountUnread(count || 0);
        }
      });

      conversationItem.getMessages(1).then((messagePaginator: any) => {
        setLastMessage({
          body: messagePaginator?.items[0]?.body || '',
          dateCreated: messagePaginator?.items[0]?.dateCreated,
        });
      });
    }
    return () => {
      if (conversationItem) {
        conversationItem.off('updated', updatedConversationListener);
        conversationItem.off('messageAdded', addedMessageListener);
      }
    };
  }, [addedMessageListener, conversationItem, updatedConversationListener]);

  return (
    <StyledListItem
      selected={(room && +room === item.id) || false}
      onClick={() => navigate(item.id.toString())}
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <Avatar
          image={companion.avatarUrl}
          firstName={companion.firstName || companion.name || ''}
          lastName={companion.lastName || ''}
          size="small"
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <StyledTypographyName variant="body1">
            <Hidden smDown>
              <b>{nameTextEllipsis}</b>
            </Hidden>
            <Hidden mdUp>
              <b>{nameText}</b>
            </Hidden>
          </StyledTypographyName>
        }
        secondary={
          <>
            <Typography component="span" color="text.secondary" sx={{ display: 'block' }}>
              {(item.order?.hashCode && `Order #${item.order?.hashCode}`) ||
                (item.preOrder?.id && `Pre Order #${item.preOrder?.id}`)}
            </Typography>
            <StyledLastMessage component="span" gutterBottom color="text.primary">
              {lastMessage?.body}
            </StyledLastMessage>
          </>
        }
      />
      <StyledBoxDate my={3 / 4}>
        <Typography
          variant="body1"
          color={(room && +room === item.id && 'common.white') || 'text.secondary'}
        >
          {lastMessage && dayjs(lastMessage.dateCreated).format('HH:mm')}
        </Typography>
        {countUnread > 0 && (
          <StyledTypographyCount variant="body1">
            <strong>{countUnread}</strong>
          </StyledTypographyCount>
        )}
      </StyledBoxDate>
    </StyledListItem>
  );
};

export default ConversationsListItem;
