import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import debounce from 'lodash/debounce';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { TAXES } from 'store/constants';
import ROUTES from 'router/constants';
import { EMAIL_REGEX, ESeniority } from 'models/consts';

import { IBasicOrders, IFullOrders } from 'store/types/order';
import { IBasicUser } from "store/types/user";

import {
  categoryProductsSelector,
  productPricesSelector,
  expertiseLevelsSelector,
} from 'store/selectors/getCommonSelector';
import { isOrderDraftCreationLoadingSelector } from 'store/selectors/getOrderDraftCreationSelector';

import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';
import fetchAvailability from 'store/query/common/fetchAvailability';
import fetchProductPrices from 'store/query/common/fetchProductPrices';
import fetchCustomers from 'store/query/common/fetchCustomersByName';
import createOrderDraft from 'store/query/order/createOrderDraft';
import updateOrderDraft from 'store/query/order/updateOrderDraft';
import fetchExpertiseLevels from "store/query/common/fetchExpertiseLevels";

import dayjs from 'utils/dayjs';
import { calcWeeks, getEndDate } from 'utils/dateFormatter';

import useChartData from 'hooks/useChartData';
import useTotalWeeks from 'hooks/useTotalWeeks';

import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import WorkTimeChart from 'components/WorkTimeChart';
import { BootstrapInput } from 'components/CustomFields';
import { customersSearchSelector } from "store/selectors/getOrderSelector";
import ProductsModulesWork from "components/CustomFields/ProductsModulesWork";
import TotalDeliveredHours from "components/CustomFields/TotalDeliveredHours";
import RangeDateTimeline from "components/CustomFields/RangeDateTimeline";
import LevelIdSelect from "../../components/CustomFields/LevelIdSelect";

export interface IOrderForm {
  consultant: IBasicUser;
  order?: IFullOrders | IBasicOrders;
  isModal?: boolean;
  onSubmitForm?: () => void;
}

const OrderForm: React.FunctionComponent<IOrderForm> = ({
  consultant,
  isModal,
  order,
  onSubmitForm,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [ search, setSearch ] = useState('')

  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);
  const seniorities = useAppSelector(expertiseLevelsSelector);

  const customersOptions = useAppSelector(customersSearchSelector(search));
  const isFetching = useAppSelector(isOrderDraftCreationLoadingSelector);

  const defaultFlexibleHours = order ? order.timeLine.flexibleHours : false;
  const defaultCustomer = order && order.customer ? {
    value: order.customer.id,
    name: !order.customer.firstName && !order.customer.lastName
      ? order.customer.email
      : `${order.customer.firstName} ${order.customer.lastName}`
  } : '';
  const defaultCustomerEmail = defaultCustomer
    ? ''
    : order ? order.customerEmail : '';
  const defaultAllHours = order ? order.timeLine.allHours : 20;
  const defaultAvailabilityWeek = defaultFlexibleHours
    ? 0
    : order ? (order.timeLine.availabilityWeek || 0) : 20;
  const defaultStartDate = order ? order.timeLine.startDate : dayjs().day(1 + 14).format('YYYY-MM-DD');
  const defaultEndDate = order ? order.timeLine.endDate : getEndDate(defaultStartDate, defaultAllHours, defaultAvailabilityWeek)

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      product: order ? order.expertise.product : undefined,
      modules: order ? order.expertise.modules : [],
      works: order ? order.expertise.works : [],
      primaryModule: order
        ? order.expertise.modules.find((item) => item.PreOrdersExpertiseModules.isPrimary)?.id
        : undefined,
      primaryWorkType: order
        ? order.expertise.works.find((item) => item.PreOrdersExpertiseWorks.isPrimary)?.id
        : undefined,
      allHours: defaultAllHours,
      availabilityWeek: defaultAvailabilityWeek,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      flexibleHours: defaultFlexibleHours,
      levelId: order
        ? order.customPrice
          ? ESeniority.CUSTOM
          : order.levelId
        : undefined,
      customer: defaultCustomer,
      customerEmail: defaultCustomerEmail,
      customPriseCents: order
        ? order.customPrice
          ? order.customPrice.priceCents / 100
          : ''
        : '',
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
    clearErrors,
  } = methods;

  const currentProduct = watch('product');
  const selectedAvailability = watch('availabilityWeek');
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const totalTime = watch('allHours');
  const flexibleHours = watch('flexibleHours');
  const customer = watch('customer');
  const customerEmail = watch('customerEmail');

  const productPrices = useAppSelector(productPricesSelector(currentProduct?.id || 0));

  const flatCustomersList = useMemo(() => {
    return customersOptions?.map((item: IBasicUser) => ({
      value: item.id,
      name: !item.firstName && !item.lastName ? item.email : `${item.firstName} ${item.lastName}`
    }));
  }, [customersOptions]);

  const totalWeeks = useTotalWeeks({startDate, endDate});
  const chartData = useChartData({selectedAvailability: selectedAvailability || 0, startDate, totalTime, flexibleHours, totalWeeks});

  useEffect(() => {
    dispatch(fetchAvailability());
    dispatch(fetchExpertiseLevels());
  }, [ dispatch ]);

  useEffect(() => {
    if (currentProduct?.id) {
      dispatch(fetchProductPrices(currentProduct.id))
    }
  }, [ dispatch, currentProduct?.id ]);

  useEffect(() => {
    if(!categoriesWithProductsList?.length) {
      dispatch(fetchProductsWithModulesWorks())
    }
  }, [categoriesWithProductsList?.length, dispatch]);

  const onSearch = (value: string) => {
    if (value) {
      dispatch(fetchCustomers({search: value, limit: 100}))
        .unwrap()
        .then(() => setSearch(value));
    }
  }
  const debouncedSearch = debounce(onSearch, 500);
  const handleCustomerSearch = (event: SyntheticEvent, value: string) => {
    debouncedSearch(value);
  };

  const onSubmit = async (data: any) => {
    const weeks = calcWeeks(data.endDate, data.startDate);
    const availabilityWeek = data.flexibleHours
      ? Math.ceil(totalTime / weeks)
      : data.availabilityWeek;
    const allHours = data.flexibleHours
      ? weeks * availabilityWeek
      : data.allHours;
    const payload: any = {
      customerId: data.customer?.value || undefined,
      customerEmail: data.customerEmail || undefined,
      taxes: TAXES,
      description: 'draft order',
      name: `${data.product.name} - ${
        data.modules.map((module: any) => module.name).join(', ') || ''
      } / ${consultant.firstName}`,
      timeLine: {
        startDate: data.startDate,
        endDate: data.endDate,
        availabilityWeek: availabilityWeek,
        allHours: allHours,
        flexibleHours: data.flexibleHours,
      },
      expertise: {
        productId: data.product.id,
        modules: data.modules.map((item: any) => ({
          moduleId: item.id,
          isPrimary: data.primaryModule === item.id,
        })),
        works: data.works.map((item: any) => ({
          workId: item.id,
          isPrimary: data.primaryWorkType === item.id,
        })),
      },
    };
    if (data.customerEmail) {
      payload.customerEmail = data.customerEmail;
    } else if (data.customer?.value) {
      payload.customerId = data.customer.value;
    }
    if (!!data?.customPriseCents) {
      payload.customPrise = {
        priceCents: data.customPriseCents * 100,
      };
    } else if (parseInt(data.levelId) !== ESeniority.CUSTOM) {
      payload.levelId = parseInt(data.levelId);
    }
    if (order) {
      dispatch(updateOrderDraft({
        id: order.id,
        ...payload,
      }))
        .unwrap()
        .then((response) => {
          if (!!response.length) {
            onSubmitForm && onSubmitForm();
            !isModal && navigate(ROUTES.ORDER_LIST);
          }
        });
    } else {
      dispatch(createOrderDraft(payload))
        .unwrap()
        .then((response) => {
          if (response) {
            onSubmitForm && onSubmitForm();
            !isModal && navigate(ROUTES.ORDER_LIST);
          }
        });
    }
  };

  return (
    <FormProvider {...methods}>
      <form id="order-form" onSubmit={handleSubmit(onSubmit)}>
        <Typography component="div" variant="h4">Expertise</Typography>
        <Typography component="div" variant="body1" mb={2}>Define the scope of the order</Typography>

        <ProductsModulesWork isModal={isModal} />

        <Typography component="div" variant="h3">Timeline</Typography>
        <Typography component="div" variant="body1" mb={2}>Define the timeline of this order</Typography>
        <Grid container>
          <Grid container xs={12} md={isModal ? 12 : 6}>
            <TotalDeliveredHours />
            <RangeDateTimeline />
          </Grid>
          <Grid container xs={12} md={isModal ? 12 : 5} mdOffset={isModal ? 0 : 1}>
            {chartData && (
              <WorkTimeChart
                withLabels
                estimatedTime={totalTime}
                flexibleHours={flexibleHours}
                chartData={chartData}
                totalWeeks={totalWeeks || 0}
                isMarginLeft={false}
              />
            )}
          </Grid>
        </Grid>

        {seniorities && !!productPrices?.length && (
          <LevelIdSelect productPrices={productPrices} />
        )}

        <Typography component="div" variant="h3" mb={2}>
          Select an existing customer or send an invitation to a new customer
        </Typography>
        <Box sx={{position: 'relative',}}>
          <Grid container spacing={2} xs={12}>
            <Grid xs={12} md={12} lg={6}>
              <FormGroup sx={{pb: 3, mb: 0}}>
                <InputLabel>Select Customer</InputLabel>
                <FormControl>
                  <Controller
                    control={control}
                    name="customer"
                    render={({field: {ref, ...field}}) => (
                      <CustomAutocomplete
                        autocompleteProps={{
                          multiple: undefined,
                          onInputChange: handleCustomerSearch,
                        }}
                        field={field}
                        options={flatCustomersList}
                        placeholder="Enter Name"
                        customFilter={(options) => options}
                        handleChange={(event) => {
                          field.onChange(event);
                          setValue('customerEmail', '');
                          clearErrors('customerEmail');
                        }}
                      />
                    )}
                    rules={{
                      validate: {
                        optionalRequired: (value) => {
                          if (!value && !customerEmail) {
                            return 'Specify a customer!';
                          }
                          return undefined;
                        }
                      }
                    }}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid xs={12} md={12} lg={6}>
              <FormGroup sx={{pb: 3, mb: 0}}>
                <InputLabel>Invite New Customer</InputLabel>
                <FormControl>
                  <Controller
                    control={control}
                    name="customerEmail"
                    render={({field: {ref, ...field}}) => (
                      <BootstrapInput
                        {...field}
                        type="email"
                        inputRef={ref}
                        placeholder="Enter Customer Email"
                        disabled={!!customer}
                        onChange={(event: any) => {
                          field.onChange(event);
                          setValue('customer', '');
                          clearErrors('customer');
                        }}
                      />
                    )}
                    rules={{
                      validate: {
                        optionalRequired: (value) => !!value || !!customer || 'Specify a customer!'
                      },
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Invalid email'
                      }
                    }}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>
          {(!!errors?.customerEmail || !!errors.customer) && (
            <FormHelperText
              error
              sx={{
                bottom: {xs: '10px', md: '10px'},
              }}
            >
              Specify a customer!
            </FormHelperText>
          )}
        </Box>

        <Grid container xs={12} spacing={2} justifyContent="center">
          <Grid xs={12} md={4}>
            <FormGroup>
              <LoadingButton
                color="secondary"
                loading={isFetching}
                form="order-form"
                type="submit"
                fullWidth
                disabled={!isValid || isFetching}
                variant="contained"
              >
                Send Draft
              </LoadingButton>
            </FormGroup>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default OrderForm;
