import { TypographyVariantsOptions } from '@mui/material/styles';

const typography: TypographyVariantsOptions = {
  fontSize: 14,
  fontFamily: 'Visuelt Pro, Arial',
  h1: {
    fontSize: '1.875rem',
    fontWeight: 700,
  },
  h2: {
    fontSize: '1.625rem',
    fontWeight: 700,
  },
  h3: {
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  h4: {
    fontSize: '1.375rem',
    fontWeight: 700,
  },
  h5: {
    fontSize: '1.25rem',
    fontWeight: 700,
  },
  h6: {
    fontSize: '1.125rem',
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  subtitle2: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  body1: {
    fontSize: '0.875rem',
  },
  body2: {
    fontSize: '0.75rem',
  },
  button: {
    fontSize: '0.875rem',
  },
};
export default typography;
