export enum EEnterprise {
  acceptUsersOrders = 'enterprise/acceptUsersOrders',
  addEnterpriseCompany = 'enterprise/addEnterpriseCompany',
  getPurchaseOrder = 'enterprise/getPurchaseOrder',
  getPurchaseOrders = 'enterprise/getPurchaseOrders',
  getPurchaseOrdersBalance = 'enterprise/getPurchaseOrdersBalance',
  changeUserRole = 'enterprise/changeUserRole',
  changeUserStatus = 'enterprise/changeUserStatus',
  checkInviteUser = 'enterprise/checkInviteUser',
  fetchUsersList = 'enterprise/fetchUsersList',
  searchUsersList = 'enterprise/searchUsersList',
  registrationUser = 'enterprise/registrationUser',
  invitationUser = 'enterprise/invitationUser',
  resendInvitationUser = 'enterprise/resendInvitationUser',
  getInvoices = 'enterprise/getInvoices',
}
