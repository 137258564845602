import React, { FunctionComponent, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import getConsultantProductsFull from 'store/query/consultant/getConsultantProductsFull';
import fetchConsultantCertificates from 'store/query/consultant/fetchConsultantCertificates';
import { productsSummarySelector } from 'store/selectors/getConsultantSelector';
import { consultantCertificatesSelector } from 'store/selectors/getConsultantCerеificates';

import ExperiencesPanel from 'components/ConsultantComponents/ConsultantSummary/ExperiencesPanel';

const StyledBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  borderBottom: '1px solid #ebebeb',
}));

interface IAgencySummary {
  activeConsultant?: number;
}
const AgencySummary: FunctionComponent<IAgencySummary> = ({ activeConsultant }) => {
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const expertiseSummary = useAppSelector(productsSummarySelector(activeConsultant || 0));
  const certificates = useAppSelector(consultantCertificatesSelector(activeConsultant || 0));

  useEffect(() => {
    if (activeConsultant && !certificates.length) {
      dispatch(fetchConsultantCertificates({ key: activeConsultant, userId: activeConsultant }));
    }
  }, [dispatch, activeConsultant, certificates.length]);

  useEffect(() => {
    if (activeConsultant && !expertiseSummary.length) {
      dispatch(getConsultantProductsFull(activeConsultant));
    }
  }, [dispatch, activeConsultant, expertiseSummary.length]);

  return (
    <StyledBox>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={{ xs: 0, md: 3 }}
        mb={3}
      >
        <Typography variant="h4">Expertise summary</Typography>
      </Box>
      <Box>
        {expertiseSummary.map(({ experiences, expertise, product }: any) => (
          <ExperiencesPanel
            key={product.name}
            expanded={expanded === product?.name}
            experiences={experiences}
            expertise={expertise}
            handleChange={handleChange(product.name)}
            productName={product.name}
            product={product}
            isCertified={
              certificates ? !!certificates?.find(item => item.productId === product.id) : false
            }
          />
        ))}
      </Box>
    </StyledBox>
  );
};
export default AgencySummary;
