import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';

import OrderForm from './OrderForm';

const ConsultantOrderCreation = () => {
  const me = useAppSelector(getMeSelector);

  return (
    <Box mt={{xs: 2.5, md: 7}}>
      <Typography component="div" variant="h1" mb={2}>Create Order</Typography>
      <OrderForm consultant={me} />
    </Box>
  );
};

export default ConsultantOrderCreation;
