import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';

import { EUser } from '../../constants';
import { ERole } from '../../../models/consts';

import getConsultantProductsFull from '../consultant/getConsultantProductsFull';

const fetchMe = createAsyncThunk(EUser.me, async (_, thunkAPI) => {
  const { data } = await instance.get(`auth/me`);
  if ([ERole.CONSULTANT, ERole.INFLUENCER].includes(data.role.name)) {
    await thunkAPI.dispatch(getConsultantProductsFull(data.id));
  }
  return data;
});

export default fetchMe;
