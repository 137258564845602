import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';

const InfluencerLanding: FunctionComponent = () => {
  return (
    <Box mt={4}>
      <iframe
        src="https://myworkerbee.com/influencers/"
        width="100%"
        height="100%"
        style={{ minHeight: 'calc(100vh - 120px)', border: 'none', padding: 0 }}
      ></iframe>
    </Box>
  );
};

export default InfluencerLanding;
