import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { EOrder } from '../../constants';

interface IRequestParams {
  orderId: number;
  isPurchase: boolean;
  matching: number;
  timeline: any;
}

const updateOrderTimeline = createAsyncThunk<any, IRequestParams>(
  EOrder.updateOrderTimeline,
  async payload => {
    let url = `orders`;
    if (payload.isPurchase) {
      url = 'enterprise/orders';
    }
    const { data } = await instance.patch('time-lines', [payload.timeline]);
    await instance.patch(url, [
      {
        id: payload.orderId,
        workerMatching: payload.matching,
        isNotification: false,
      },
    ]);

    return data;
  },
);

export default updateOrderTimeline;
