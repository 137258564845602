/**
 *  Refactor 12.23
 */
import { createSlice } from "@reduxjs/toolkit";

import sortBy from 'lodash/sortBy'

import { DEFAULT_ENTITY } from "models/consts";

import { CommonEntities, CommonEntitiesAsObject, IDynamicKey, ILevel } from "../types/common";
import { ILanguage } from "../types/language";
import {
  IProductStripe,
  IModuleOfProduct,
  IWorkTypesOfProduct,
  IExtendCategoryWithProducts
} from "../types/categoryProducts";
import { ICompanyRoles } from "../types/company";
import { ITimePreferences } from "../types/consultant";

import fetchAvailability from "../query/common/fetchAvailability";
import fetchCategoriesWithProducts from "../query/common/fetchCategoriesWithProducts";
import fetchModulesWithWorks from "../query/common/fetchModulesWithWorks";
import fetchProductPrices from "../query/common/fetchProductPrices";
import fetchProductPricesWithoutFee from "../query/common/fetchProductPricesWithoutFee";
import fetchProductsWithModulesWorks from "../query/common/fetchProductsWithModulesWorks";
import fetchRoles from "../query/common/fetchRoles";
import fetchTimePreferences from "../query/common/fetchTimePreferences";
import getLanguages from "../query/common/getLanguages";
import fetchExpertiseLevels from "../query/common/fetchExpertiseLevels";
import fetchWorks from "../query/common/fetchWorks";
import {flatProductsList} from "../../helpers/decorators";

export interface CommonState {
  availabilities: CommonEntities<number[]>;
  categoryProducts: CommonEntities<IExtendCategoryWithProducts[]>;
  expertiseLevels: CommonEntities<Array<ILevel> | null>;
  languages: CommonEntities<Array<ILanguage>>;
  modules: CommonEntities<IModuleOfProduct[]>;
  modulesByProduct: CommonEntitiesAsObject<IModuleOfProduct[] | null>;
  productPrices: IDynamicKey<IProductStripe[]>,
  roles: CommonEntities<ICompanyRoles[] | null>;
  timePreferences: CommonEntities<ITimePreferences[]>;
  workTypes: CommonEntities<IWorkTypesOfProduct[]>;
  workTypesByProduct: CommonEntitiesAsObject<IWorkTypesOfProduct[] | null>;
}

const initialState: CommonState = {
  availabilities: DEFAULT_ENTITY,
  categoryProducts: DEFAULT_ENTITY,
  expertiseLevels: DEFAULT_ENTITY,
  languages: DEFAULT_ENTITY,
  modules: DEFAULT_ENTITY,
  modulesByProduct: {},
  productPrices: {},
  roles: DEFAULT_ENTITY,
  timePreferences: DEFAULT_ENTITY,
  workTypes: DEFAULT_ENTITY,
  workTypesByProduct: {},
}

const { reducer } = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    /**
     *  Get Languages
     */
    .addCase(getLanguages.pending, (state ) => {
      state.languages = {
        ...state.languages,
        loaded: false,
        loading: true,
      }
    })
    .addCase(getLanguages.fulfilled, (state, action ) => {
      const { payload } = action;

      state.languages = {
        items: payload,
        loaded: true,
        loading: false,
      }
    })

    /**
     *  Get Availability
     */
    .addCase(fetchAvailability.pending, (state ) => {
      state.availabilities = {
        ...state.availabilities,
        loading: true
      };
    })
    .addCase(fetchAvailability.fulfilled, (state, { payload } ) => {
      state.availabilities = {
        ...state.availabilities,
        items: payload,
        loading: false
      };
    })

    /**
     *  Get Categories and Products
     */
    .addCase(fetchCategoriesWithProducts.pending, (state ) => {
      state.categoryProducts = {
        ...state.categoryProducts,
        loaded: false,
        loading: true
      }
    })
    .addCase(fetchCategoriesWithProducts.fulfilled, (state, action) => {
      const { payload } = action
      const items = flatProductsList(payload)

      state.categoryProducts = {
        ...state.categoryProducts,
        items,
        loaded: true,
        loading: false
      }
    })

    /**
     *  Get Categories and Products
     */
    .addCase(fetchProductsWithModulesWorks.pending, (state ) => {
      state.modules = {
        ...state.modules,
        loaded: true,
        loading: false
      }
      state.workTypes = {
        ...state.workTypes,
        loaded: true,
        loading: false
      }
      state.categoryProducts = {
        ...state.categoryProducts,
        loaded: true,
        loading: false
      }
    })
    .addCase(fetchProductsWithModulesWorks.fulfilled, (state, { payload } ) => {
      const { modules, products, works } = payload
      const items = flatProductsList(products)

      state.modules = {
        ...state.modules,
        items: modules,
        loaded: true,
        loading: false
      }
      state.workTypes = {
        ...state.workTypes,
        items: works,
        loaded: true,
        loading: false
      }
      state.categoryProducts = {
        ...state.categoryProducts,
        items,
        loaded: true,
        loading: false
      }
    })

    /**
     *  Get Product Prices
     */
    .addCase(fetchProductPrices.pending, (state, action ) => {
      const { meta } = action
      state.productPrices[meta.arg] = []
    })
    .addCase(fetchProductPrices.fulfilled, (state, action ) => {
      const { meta, payload } = action
      state.productPrices[meta.arg] = sortBy(payload, 'levelId')
    })

    /**
     *  Get Expertise Levels
     */
    .addCase(fetchExpertiseLevels.pending, (state) => {
      state.expertiseLevels = {
        ...state.expertiseLevels,
        loaded: false,
        loading: true,
      }
    })
    .addCase(fetchExpertiseLevels.fulfilled, (state, action) => {
      const { payload } = action;

      state.expertiseLevels = {
        items: payload,
        loaded: true,
        loading: false,
      }
    })

    /**
     *  Get Product Modules and Works
     */
    .addCase(fetchModulesWithWorks.pending, (state, action ) => {
      state.modulesByProduct[action.meta.arg] = {
        items: null,
        loaded: false,
        loading: true
      }
      state.workTypesByProduct[action.meta.arg] = {
        items: null,
        loaded: false,
        loading: true
      }
    })
    .addCase(fetchModulesWithWorks.fulfilled, (state, action) => {
      const { meta, payload } = action;
      state.modulesByProduct[meta.arg] = {
        items: sortBy(payload.modules, 'name'),
        loaded: false,
        loading: true
      }
      state.workTypesByProduct[meta.arg] = {
        items: payload.works,
        loaded: false,
        loading: true
      }
    })

    /**
     *  Get Product Prices Without Fee
     */
    .addCase(fetchProductPricesWithoutFee.pending, (state, action ) => {
      const { meta } = action
      state.productPrices[meta.arg] = []
    })
    .addCase(fetchProductPricesWithoutFee.fulfilled, (state, action) => {
      const { meta, payload } = action
      state.productPrices[meta.arg] = payload
    })

    /**
     *  Get Customer Company Roles
     */
    .addCase(fetchRoles.fulfilled, (state, action) => {
      const { payload } = action
      state.roles = {
        ...state.roles,
        items: payload,
        loading: true
      };
    })

    /**
     *  Get Product Prices Without Fee
     */
    .addCase(fetchTimePreferences.pending, (state) => {
      state.timePreferences = {
        ...state.timePreferences,
        loading: true
      };
    })
    .addCase(fetchTimePreferences.fulfilled, (state, action) => {
      const { payload } = action;
      state.timePreferences = {
        ...state.timePreferences,
        items: payload,
        loading: true
      };
    })

    /**
     *  Get ProductWorks
     */
    .addCase(fetchWorks.pending, (state, action) => {
      state.workTypesByProduct[action.meta.arg] = {
        items: null,
        loaded: false,
        loading: true
      }
    })
    .addCase(fetchWorks.fulfilled, (state, action) => {
      const { meta, payload } = action;
      state.workTypesByProduct[meta.arg] = {
        items: payload,
        loaded: false,
        loading: true
      }
    })
});

export default reducer
