import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { IconButton } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { IBasicUser } from 'store/types/user';
import { IBasicConsultant, IProductExperienceFull } from 'store/types/consultant';

import fetchConsultantCertificates from 'store/query/consultant/fetchConsultantCertificates';
import { consultantCertificatesSelector } from 'store/selectors/getConsultantCerеificates';

import ModalEditConsultantWrapper from 'components/Modal/ModalEditConsultant/ModalEditConsultantWrapper';
import ConsultantSummary from 'components/ConsultantComponents/ConsultantSummary';
import CongratulationsModal from 'components/Modal/CongratulationsModal/CongratulationsModal';
import Skills from 'components/ConsultantComponents/Skills';

import ConsultantFeedback from 'views/Feedback/OrderFeedback/ConsultantFeedback';
import AvatarBlock from 'components/ConsultantComponents/HeaderConsultant/AvatarBlock';
import ConsultantNameBlock from 'components/ConsultantComponents/HeaderConsultant/ConsultantNameBlock';
import DescriptionBlock from 'components/ConsultantComponents/HeaderConsultant/DescriptionBlock';
import AgencyBlock from 'components/ConsultantComponents/HeaderConsultant/AgencyBlock';
import LeftSide from 'components/ConsultantComponents/LeftSide';
import Certificates from 'components/ConsultantComponents/Certificates';

import { StyledGridWrap } from 'components/ConsultantComponents/HeaderConsultant/styled';
import { EConsultantEditingModals } from '../../models/consts';
import { ICertificateItem } from 'store/types/certificates';

import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';
import { modulesSelector, workTypesSelector } from 'store/selectors/getCommonSelector';
import ArrowLeftIcon from 'styles/icons/ArrowLeft';

interface IConsultantProps {
  expertiseSummary: IProductExperienceFull[];
  consultantKey: string | number;
  certificatesList: ICertificateItem[];
  user: IBasicUser;
  consultant: IBasicConsultant;
  isCurrentUser: boolean;
  isCongratsEnabled?: boolean;
}

const ConsultantView: FunctionComponent<IConsultantProps> = ({
  consultant,
  consultantKey,
  expertiseSummary,
  certificatesList,
  isCurrentUser,
  user,
  isCongratsEnabled,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const isMobileNative = Capacitor.isNativePlatform();

  const [modal, setModal] = useState<{
    open: boolean;
    type: EConsultantEditingModals;
    showIntro?: boolean;
  }>({
    open: false,
    type: EConsultantEditingModals.NO_MODAL,
  });
  const [showCongratulationsModal, setShowCongratulationsModal] = useState<boolean>(
    isCongratsEnabled ? isCongratsEnabled : false,
  );

  const dispatch = useAppDispatch();
  const certificates = useAppSelector(consultantCertificatesSelector(consultantKey));
  const modulesList = useAppSelector(modulesSelector);
  const worksList = useAppSelector(workTypesSelector);

  useEffect(() => {
    user?.id && dispatch(fetchConsultantCertificates({ key: consultantKey, userId: user.id }));
  }, [consultantKey, dispatch, user?.id]);

  useEffect(() => {
    dispatch(fetchProductsWithModulesWorks());
  }, [dispatch]);

  const sortedCertificates = useMemo(() => {
    return certificates
      ?.map(item => ({
        ...item,
        modulesString:
          item.modulesIds && item.modulesIds.length > 0
            ? modulesList
                .filter(module => item.modulesIds.includes(module.id))
                .map(module => module.name)
                .join(', ')
            : '',
        worksString:
          item.worksIds && item.worksIds.length > 0
            ? worksList
                .filter(work => item.worksIds.includes(work.id))
                .map(work => work.name)
                .join(', ')
            : '',
      }))
      .sort((a, b) => a.id - b.id);
  }, [certificates, modulesList, worksList]);

  return (
    <Box data-test="consultant-profile" sx={{ flexGrow: 1, mt: { xs: 2, md: 6.5 } }}>
      {isMobileNative && (
        <Box mb={2}>
          <IconButton onClick={() => navigate(-1)} sx={{ ml: -2.5 }}>
            <ArrowLeftIcon />
          </IconButton>
        </Box>
      )}
      <Grid container rowSpacing={5} columnSpacing={{ xs: 2, lg: 8 }}>
        <StyledGridWrap container xs={12}>
          <Grid container xs={12} md={5} lg={4} spacing={2} maxWidth={{ md: 420 }}>
            <Grid xs="auto">
              <AvatarBlock {...{ user, consultant }} />
            </Grid>
            <Grid xs>
              <ConsultantNameBlock
                expertiseSummary={expertiseSummary}
                isCurrentUser={isCurrentUser}
                onOpen={setModal}
                {...{ user, consultant }}
              />
            </Grid>
          </Grid>
          <Grid xs={12} md={7} lg>
            <DescriptionBlock
              isCurrentUser={isCurrentUser}
              onOpen={setModal}
              consultant={consultant}
            />
            {consultant?.agency && <AgencyBlock agency={consultant.agency} />}
          </Grid>
        </StyledGridWrap>
        <Grid container xs={12}>
          <Grid
            container
            xs={12}
            md={5}
            lg={4}
            spacing={2}
            maxWidth={{ md: 420 }}
            sx={{ pb: { xs: 0, md: 2.5 } }}
          >
            <Grid xs={12}>
              <LeftSide
                consultant={consultant}
                expertiseSummary={expertiseSummary}
                certificatesList={certificatesList}
                user={user}
                isCurrentUser={isCurrentUser}
                onOpen={setModal}
              />
            </Grid>
          </Grid>
          <Grid xs={12} md={7} lg sx={{ mt: { xs: 0, md: 3 }, pt: { xs: 0, md: 2.5 } }}>
            <ConsultantSummary
              consultantKey={consultantKey}
              expertiseSummary={expertiseSummary}
              isCurrentUser={isCurrentUser}
              onOpen={setModal}
              certificates={certificates}
            />
            <Certificates
              isCurrentUser={isCurrentUser}
              onOpen={setModal}
              certificates={sortedCertificates}
            />
            <Skills isCurrentUser={isCurrentUser} onOpen={setModal} tags={consultant?.tags} />
            {/**
             *  TODO: Will be refactor
             */}
            {!!consultant?.rate?.feedbacksCount && (
              <ConsultantFeedback rate={consultant?.rate} userId={user.id} />
            )}
          </Grid>
        </Grid>
        <ModalEditConsultantWrapper
          visible={modal.open}
          consultantPage={modal.type}
          close={setModal}
          showIntro={modal.showIntro}
        />
        <CongratulationsModal
          visible={showCongratulationsModal}
          close={() => setShowCongratulationsModal(false)}
        />
      </Grid>
    </Box>
  );
};

export default ConsultantView;
