import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const StyledWrap = styled(Box)(({ theme }) => theme.unstable_sx({
  p: 1.5,
  backgroundColor: '#FBFBFB',
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'space-between',
  my: 1,
  flexWrap: {
    xs: 'wrap',
    lg: 'nowrap'
  }
}))

const StyledBox = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  ml: 2,
  mt: .9,
}))

const StyledCompanyName = styled(Typography)(({ theme }) => theme.unstable_sx({
  alignItems: 'start',
  display: 'inline-flex',
  minHeight: 48,
  ml: 2,
  width: 148,
  pt: 1.75
}))

export { StyledBox, StyledCompanyName, StyledWrap }
