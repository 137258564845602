import React, { useEffect, useState } from 'react';
import { Popper } from '@mui/material';

const DelayedPopper = (props: any) => {
  const [showPopper, setShowPopper] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopper(true);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return showPopper ? (
    <Popper
      {...props}
      style={{ position: 'fixed', zIndex: 1300, width: '80%' }}
      placement="bottom-start"
    />
  ) : null;
};

export default DelayedPopper;
