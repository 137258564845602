import React, {FunctionComponent} from "react";

import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import {visuallyHidden} from "@mui/utils";

import TableRow from "./TableRow";

type Order = 'asc' | 'desc';

interface IEnhancedTableHeadSort {
  order: Order,
  orderBy: string,
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  headCells: any[],
}

const EnhancedTableHeadSort: FunctionComponent<IEnhancedTableHeadSort> = ({ headCells, order, orderBy, onRequestSort }) => {
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align && 'left'}
            sortDirection={orderBy === headCell.id ? order : undefined}
            // width={headCell.width}
            sx={{
              minWidth: headCell.minWidth || 'unset', px: { xs: 1, lg: 3},
              width: headCell.width,
            }}
          >
            {headCell.isSortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
export default EnhancedTableHeadSort
