import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';

import { SelectChangeEvent } from '@mui/material/Select';

import { outputQuery } from 'helpers/decorators';

import { LIMITS } from 'models/consts';
import { IEnterpriseUser } from 'store/types/enterprise';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getEnterpriseUsersByPageSelector,
  totalEnterpriseUsersSelector,
} from 'store/selectors/getEnterpriseSelector';

import usersTeam from 'store/query/enterprise/usersTeam';

import TableContainer from '../../TableCustomized/TableContainer';
import EnhancedTableHeadSort from '../../TableCustomized/EnhancedTableHeadSort';
import { teamHeadCells } from '../../TableCustomized/constants';
import ManageTableWrapper from 'components/TableCustomized/ManageTableWrapper';
import EnterpriseTeamRow from './EnterpriseTeamRow';

interface IManageTable {
  onChangeRole: (event: SelectChangeEvent, id: number) => void;
  onChangeStatus: (row: IEnterpriseUser, status: string) => void;
  page: number;
  setPage: (id: number) => void;
}

const ManageTable: FunctionComponent<IManageTable> = ({
  onChangeRole,
  onChangeStatus,
  page,
  setPage,
}) => {
  const dispatch = useAppDispatch();

  const users = useAppSelector(getEnterpriseUsersByPageSelector(page + 1));
  const totalUsers = useAppSelector(totalEnterpriseUsersSelector);

  const { getValues, setValue, watch } = useFormContext();

  const { filterByEmail, filterByRole, filterByStatus } = getValues();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = watch('property') === property && watch('type') === 'asc';

    setValue('property', property);
    setValue('type', isAsc ? 'desc' : 'asc');

    const filter = {
      filterByEmail,
      filterByRole,
      filterByStatus,
      property,
      type: isAsc ? 'desc' : 'asc',
    };

    dispatch(usersTeam({ filter: outputQuery(filter), limit: LIMITS.team, offset: 0 }))
      .unwrap()
      .then(() => setPage(0));
  };

  const handleChangePage = (
    newPage: number,
    limit: number,
    offset: number
  ) => {
    const { filterByEmail, filterByRole, filterByStatus, property, type } = getValues();

    const filter = {
      filterByEmail,
      filterByRole,
      filterByStatus,
      property,
      type,
    };
    setPage(newPage);
    dispatch(
      usersTeam({ filter: outputQuery(filter), limit, offset }),
    );
  };

  return (
    <TableContainer sx={{ mb: 3 }}>
      <EnhancedTableHeadSort
        order={watch('type')}
        orderBy={watch('property')}
        headCells={teamHeadCells}
        onRequestSort={handleRequestSort}
      />
      <ManageTableWrapper
        page={page}
        total={totalUsers}
        defaultLimit={LIMITS.team}
        count={users.length}
        onPageChange={handleChangePage}
        TableContent={
          <>
            {users.map(row => (
              <EnterpriseTeamRow
                key={row.email}
                row={row}
                onChangeStatus={onChangeStatus}
                onChangeRole={onChangeRole}
              />
            ))}
          </>
        }
      />
    </TableContainer>
  );
};

export default ManageTable;
