import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const AgencyLocationIcon: FunctionComponent<ISvgProps> = ({className}) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 21L23 21" stroke="currentColor" strokeWidth="1.1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5 21V5.02161C5 3.01055 6.1476 2 8.4312 2H15.5804C17.864 2 19 3.01055 19 5.02161V21" stroke="currentColor" strokeWidth="1.1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 7H15" stroke="currentColor" strokeWidth="1.1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 11H15" stroke="currentColor" strokeWidth="1.1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 21V17H14V19.9333" stroke="currentColor" strokeWidth="1.1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
