import React, { FunctionComponent } from 'react';
import { Link as RouteLink, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Capacitor } from '@capacitor/core';

import { useMediaQuery } from '@mui/material';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

import { useTheme } from '@mui/material/styles';

import { Logo } from 'styles/icons/Logo';
import { LogoWhite } from 'styles/icons/LogoWhite';

import { StyledBox, StyledDiv, StyledHeader } from './styled';

import ROUTES from 'router/constants';

import { ELocalStoreKeys } from 'models/consts';
import { IBasicUser } from 'store/types/user';
import { IBasicConsultant } from 'store/types/consultant';

import { useAppSelector } from 'store/hooks';
import useMatchMultiple from 'hooks/useMatchUrls';

import { getMeLoadingSelector, getMeSelector, isAuthorized } from 'store/selectors/getUserSelector';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';

import RightSideContainer from './Components/RightSideContainer';
import FinalizeConsultant from './Components/FinalizeConsultant';
import ConsultantImpersonation from './Components/ConsultantImpersonation';

interface IHeader {
  isCreationPage?: boolean;
}

const Header: FunctionComponent<IHeader> = ({ isCreationPage }) => {
  const [cookies] = useCookies([
    ELocalStoreKeys.IMPERSONATE_TOKEN,
    ELocalStoreKeys.GLOBAL_IMPERSONATION,
  ]);

  const { id } = useParams();

  const match = useMatchMultiple([
    `${ROUTES.CONSULTANT_REGISTRATION}/${ROUTES.STEP}`,
    `${ROUTES.AGENCY_CREATION}/${ROUTES.STEP}`,
  ]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  const isMobileNative = Capacitor.isNativePlatform();
  const getMobilePlatform = Capacitor.getPlatform();
  const isMarginEnabled = useMatchMultiple([`${ROUTES.ORDER_CREATION}/*`]);

  const isAuth = useAppSelector(isAuthorized);
  const user = useAppSelector(getMeSelector) as IBasicUser;
  const loading = useAppSelector(getMeLoadingSelector);
  const consultant = useAppSelector(getBasicConsultantSelector(id || user?.id)) as IBasicConsultant;

  const impersonateToken =
    typeof document !== 'undefined' && cookies[ELocalStoreKeys.IMPERSONATE_TOKEN];

  const getLogoLink = () => {
    if (!isAuth) {
      return ROUTES.AUTH;
    }

    return isMobileNative ? ROUTES.HOME : ROUTES.ORDER_LIST;
  };

  return (
    <>
      {!loading && impersonateToken && <ConsultantImpersonation user={user} />}
      {!loading && user.id === consultant?.userId && <FinalizeConsultant consultant={consultant} />}
      {!match && (
        <Container
          maxWidth={(!isCreationPage && 'xxl') || false}
          fixed={(!isCreationPage && matches) || false}
          disableGutters={isCreationPage}
          sx={{
            px: { xs: 2, sm: 3, md: (!isCreationPage && 5) || 0, lg: (!isCreationPage && 8) || 0 },
            py:
              isMobileNative && getMobilePlatform === 'android' && isMarginEnabled ? 2 : undefined,
          }}
        >
          <StyledBox>
            <StyledHeader isCreationPage={isCreationPage}>
              <StyledDiv isCreationPage={isCreationPage}>
                <Link data-test="logo-block" component={RouteLink} to={getLogoLink()}>
                  {isCreationPage ? <LogoWhite /> : <Logo />}
                </Link>
              </StyledDiv>
              <RightSideContainer isAuth={isAuth} isCreationPage={isCreationPage} />
            </StyledHeader>
          </StyledBox>
        </Container>
      )}
    </>
  );
};

export default Header;
