import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const MapMarkIcon: FunctionComponent<ISvgProps> = () => {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 9.18182C17 15.5455 9 21 9 21C9 21 1 15.5455 1 9.18182C1 7.01187 1.84285 4.93079 3.34315 3.3964C4.84344 1.86201 6.87827 1 9 1C11.1217 1 13.1566 1.86201 14.6569 3.3964C16.1571 4.93079 17 7.01187 17 9.18182Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.9987 11.9091C10.4715 11.9091 11.6654 10.6881 11.6654 9.18186C11.6654 7.67563 10.4715 6.45459 8.9987 6.45459C7.52594 6.45459 6.33203 7.67563 6.33203 9.18186C6.33203 10.6881 7.52594 11.9091 8.9987 11.9091Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
