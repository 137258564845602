import {styled} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";

const CellSticky = styled(TableCell)({
  position: "sticky",
  left: 0,
  background: "white",
  zIndex: 1,
  borderBottom: 'none',
  // whiteSpace: 'nowrap'
})

const StyledTableCell = styled(TableCell)({
  backgroundColor: '#F5F5F5',
  borderBottom: 'none',
  padding: '0 10px',
})

const StyledLevelWrap = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  flexDirection: {
    xs: 'column-reverse',
    lg: 'row'
  },
  mb: 1
}))

export { CellSticky, StyledLevelWrap, StyledTableCell }
