import React, { useMemo } from 'react';

import { calcWeekCount } from 'utils/dateFormatter';

import { useAppSelector } from 'store/hooks';

import { IWeeklyBillings } from 'store/types/order';

import { productPricesSelector, expertiseLevelIdSelector } from 'store/selectors/getCommonSelector';
import { isConsultantSelector } from 'store/selectors/getUserSelector';
import {
  currentOrderBalanceSelector,
  currentOrderSharedLinkSelector,
  currentWeeklyBillingsOrderSelector,
} from 'store/selectors/getOrderSelector';
import Tooltip from '@mui/material/Tooltip';

const useCalculatePrice = (order: any, isInternal?: boolean) => {
  const isConsultant = useAppSelector(isConsultantSelector);
  const orderBalance = useAppSelector(currentOrderBalanceSelector(order?.id));
  const weeklyBillings = useAppSelector(
    currentWeeklyBillingsOrderSelector(order?.id),
  ) as IWeeklyBillings[];
  const sharedLink = useAppSelector(currentOrderSharedLinkSelector(order?.id || -1));

  const seniority = useAppSelector(expertiseLevelIdSelector(order?.levelId || -1));

  /**
   * Will be fix
   */
  const productPrices = useAppSelector(productPricesSelector(order?.expertise?.product?.id || 0));

  // Description: calculating payment according matching rules
  const calculatedLevelId = useMemo(() => {
    if (order?.levelId && order.worker && order.expertise && order.expertise.modules?.length) {
      const moduleLvl = order?.worker?.levelsModules?.find(
        ({ modulesId }: any) =>
          order?.expertise?.modules?.length && modulesId === order?.expertise?.modules[0].id,
      );

      if (moduleLvl && moduleLvl.levelId <= order.levelId) {
        return moduleLvl.levelId;
      }

      return order.levelId;
    }

    return 0;
  }, [order]);

  const productPrice = useMemo(() => {
    let newProductPrice = 0;
    if (productPrices && calculatedLevelId) {
      newProductPrice =
        productPrices.find(item => item.levelId === calculatedLevelId)?.stripePriceCents || 0;
    } else if (productPrices && order?.levelId) {
      newProductPrice =
        productPrices.find(item => item.levelId === order.levelId)?.stripePriceCents || 0;
    }
    return newProductPrice;
  }, [productPrices, order?.levelId, calculatedLevelId]);

  // calculating price rate per hour
  const calculatedPrice = useMemo(() => {
    let price = 0;
    let coefficient = 0.8;
    if (order?.taxes) {
      coefficient = (100 - order.taxes) / 100;
    }

    if (order?.customPrice) {
      price = order.customPrice.priceCents / 100;
    } else if (order?.orderPrice) {
      price = order.orderPrice.stripePriceCents / 100;
    } else if (order?.price) {
      price = parseInt(order.price) / 100;
    } else if (productPrice) {
      price = productPrice / 100;
    } else if (seniority?.cost) {
      price = seniority?.cost;
    }

    return isConsultant ? Math.round(price * coefficient) : price;
  }, [productPrice, order, isConsultant, seniority?.cost]);

  // calculating total sum
  const totalSum = useMemo(
    () => calculatedPrice * (order?.timeLine?.actualHours || order?.timeLine?.allHours || 1),
    [calculatedPrice, order?.timeLine?.actualHours, order?.timeLine?.allHours],
  );
  // calculating duration in weeks
  const duration = useMemo(
    () => Math.round(calcWeekCount(order?.timeLine?.startDate, order?.timeLine?.endDate)),
    [order?.timeLine?.startDate, order?.timeLine?.endDate],
  );
  // calculating hours per week
  const weeklyNeeds = useMemo(
    () => order?.timeLine?.availabilityWeek || Math.round(order?.timeLine?.allHours / duration),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order?.timeLine?.allHours, duration],
  );

  // calculating biweekly price
  const biweekly = useMemo(
    () => (duration > 2 ? calculatedPrice * weeklyNeeds * 2 : totalSum),
    [duration, calculatedPrice, weeklyNeeds, totalSum],
  );

  // calculating weekly price
  const weeklyPrice = useMemo(() => calculatedPrice * weeklyNeeds, [calculatedPrice, weeklyNeeds]);

  // building billedToDate
  const billedToDate = useMemo(() => {
    // const text = `${orderBalance?.approvedHours || 0}h / $${(orderBalance?.approvedHours || 0) * calculatedPrice}`;
    const billedToDateHours = weeklyBillings.reduce((acc: number, item: IWeeklyBillings) => {
      return acc + item.hours;
    }, 0);
    if (isInternal) {
      return `${billedToDateHours}h`;
    }
    return `${billedToDateHours}h / $${billedToDateHours * calculatedPrice}`;
  }, [weeklyBillings, calculatedPrice, isInternal]);

  // building paidToDate
  const paidToDate = useMemo(() => {
    let text: React.JSX.Element | string = '';
    if (isConsultant) {
      if (isInternal) {
        text = `${orderBalance?.transferredHours || 0}h`;
      } else {
        // text = `${orderBalance?.transferredHours || 0}h / $${orderBalance?.transferredAmount ? (orderBalance.transferredAmount / 100).toLocaleString('en-US') : 0}`;
        text = (
          <>
            {orderBalance?.transferredHours || 0}h /{' '}
            <Tooltip title="USD">
              <span>$</span>
            </Tooltip>
            {orderBalance?.transferredAmount
              ? (orderBalance.transferredAmount / 100).toLocaleString('en-US')
              : 0}
          </>
        );
      }
    } else {
      // text = `${orderBalance?.payedHours || 0}h / $${orderBalance?.payedAmount ? (orderBalance.payedAmount / 100).toLocaleString('en-US') : 0}`;
      text = (
        <>
          {orderBalance?.payedHours || 0}h /{' '}
          <Tooltip title="USD">
            <span>$</span>
          </Tooltip>
          {orderBalance?.payedAmount ? (orderBalance.payedAmount / 100).toLocaleString('en-US') : 0}
        </>
      );
    }
    return text;
  }, [isConsultant, orderBalance, isInternal]);

  // building approvedToDate
  const approvedToDate = useMemo(
    () => (
      <>
        {orderBalance?.approvedHours || 0}h /{' '}
        <Tooltip title="USD">
          <span>$</span>
        </Tooltip>
        {orderBalance?.approvedHours
          ? (calculatedPrice * orderBalance.approvedHours).toLocaleString('en-US')
          : 0}
      </>
    ),
    [orderBalance, calculatedPrice],
  );

  // biweekly charge
  const biweeklySum = useMemo(() => {
    return (sharedLink?.hours || 0) * calculatedPrice;
  }, [calculatedPrice, sharedLink]);

  // calculating duration in weeks for biweekly
  const biweeklyDuration = useMemo(() => {
    if (sharedLink && sharedLink.startPeriod && sharedLink.endPeriod) {
      return Math.round(calcWeekCount(sharedLink.startPeriod, sharedLink.endPeriod));
    }
    return 0;
  }, [sharedLink]);

  return {
    billedToDate,
    biweekly,
    calculatedLevelId,
    calculatedPrice,
    duration,
    paidToDate,
    totalSum,
    weeklyNeeds,
    weeklyPrice,
    approvedToDate,
    biweeklySum,
    biweeklyDuration,
  };
};

export default useCalculatePrice;
