import {createAsyncThunk} from "@reduxjs/toolkit";

import { EConsultant } from "../../constants";
import { IProductExperienceFull } from "../../types/consultant";

interface IProps {
  data: IProductExperienceFull[],
  userId: number
}
const getConsultantProductsFull = createAsyncThunk<IProps, IProps>(
  EConsultant.setExperiences,
  async (payload) => {
    return await new Promise<IProps>((resolve) => {
      resolve(payload);
    });
  }
)

export default getConsultantProductsFull

