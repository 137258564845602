import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from "react-router-dom";

import Box from '@mui/material/Box';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IBasicUser } from "store/types/user";
import { ICertificateItem } from "store/types/certificates";

import { deleteConsultantCertificate } from 'store/query/consultant';

import { consultantCertificatesSelector } from 'store/selectors/getConsultantCerеificates';
import { getMeSelector } from "store/selectors/getUserSelector";

import CertificateListItem from '../Certificates/CertificateListItem';
import CertificateForm from '../CertificateForm';

import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';
import { modulesSelector, workTypesSelector } from 'store/selectors/getCommonSelector';

interface ICertificatesListEditingProps {
  onSubmitted: () => void;
}

const CertificatesListEditing: React.FunctionComponent<ICertificatesListEditingProps> = ({ onSubmitted }) => {
  const { id: key } = useParams()
  const dispatch = useAppDispatch();
  const user = useAppSelector(getMeSelector) as IBasicUser;
  const modulesList = useAppSelector(modulesSelector);
  const worksList = useAppSelector(workTypesSelector);

  const [editingId, setEditingId] = useState<number | undefined>(undefined);

  const certificates = useAppSelector(consultantCertificatesSelector(key || user.id));
  
  const handleCertificateDelete = (id: number) => {
    dispatch(deleteConsultantCertificate({
      ids: [id],
      key: key || user.id
    }));
  };

  const handleCertificateEdit = (id?: number) => {
    setEditingId(id);
    onSubmitted()
  };

  useEffect(() => {
    dispatch(fetchProductsWithModulesWorks())
  }, [dispatch]); 

  const sortedCertificates = useMemo(() => {
    return certificates?.map(item => ({
      ...item,
      modulesString: item.modulesIds && item.modulesIds.length > 0 ? modulesList.filter(module => item.modulesIds.includes(module.id)).map(module => module.name).join(', ') : '',
      worksString: item.worksIds && item.worksIds.length > 0 ? worksList.filter(work => item.worksIds.includes(work.id)).map(work => work.name).join(', ') : ''
    })).sort((a, b) => a.id - b.id);
  }, [certificates, modulesList, worksList]);
  
  return (
    <Box>
      {!!sortedCertificates?.length && sortedCertificates.map((item: ICertificateItem) => {
        if (editingId === item.id) {
          return (
            <Box mt={2} key={item.id}>
              <CertificateForm
                onClose={() => handleCertificateEdit(undefined)}
                defaultData={item}
              />
            </Box>
          )
        }
        return (
          <CertificateListItem
            key={item.id}
            item={item}
            isEditable
            onDelete={handleCertificateDelete}
            onEdit={handleCertificateEdit}
          />
        );
      })}
    </Box>
  );
};

export default CertificatesListEditing;
