import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { EOrder } from '../../constants';
import { IOrderExtendHours } from '../../types/order';

interface IRequestParams {
  orderId: number;
  isPurchase: boolean;
}

const fetchExtendHours = createAsyncThunk<IOrderExtendHours[], IRequestParams>(
  EOrder.fetchExtendHours,
  async ({ orderId, isPurchase }) => {
    let url = `orders/${orderId}/extend-hours`;
    if (isPurchase) {
      url = `enterprise/orders/${orderId}/extend-hours`;
    }
    const { data } = await instance.get(url);

    return data;
  },
);

export default fetchExtendHours;
