import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';
import markActionedNotification from '../notification/markActionedNotification';

interface IRejectChanges {
  extendHoursId: number;
  hours: number;
  orderId: number;
  notificationId: number;
}

const rejectChanges = createAsyncThunk<AxiosResponse, IRejectChanges>(
  EOrder.rejectChanges,
  async ({ orderId, extendHoursId, hours, notificationId }, thunkAPI) => {
    const { data } = await instance.post(`orders/${orderId}/extend-hours/${extendHoursId}/reject`, {
      hours,
    });
    thunkAPI.dispatch(markActionedNotification({ ids: [notificationId] }));
    return data;
  },
);

export default rejectChanges;
