import { createAsyncThunk} from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EEnterprise } from "store/constants/enterprise";

import {IPurchaseOrder} from "../../types/enterprise";

interface IResponsePOEnterprise {
    count: number;
    page: number;
    purchaseOrders: IPurchaseOrder[];
}

const getPurchaseOrders = createAsyncThunk<IResponsePOEnterprise, any>(
    EEnterprise.getPurchaseOrders,
    async ({ filter, key, ...params }) => {
        const { data } = await instance.get(`enterprise/purchase-orders`, {
            params: { ...filter, ...params },
        });
        return data;
    },
);

export default getPurchaseOrders;
