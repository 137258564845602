import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {ECommon} from "../../constants";

const fetchProductPrices = createAsyncThunk(
  ECommon.productsPrices,
  async (productId: number) => {
    const { data } = await instance.get(`products/${productId}/stripe-products-with-fee`)
    return await data
  }
)

export default fetchProductPrices
