import React, { FC, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { IProduct } from 'store/types/categoryProducts';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { productModulesSelector, productWorksSelector } from 'store/selectors/getCommonSelector';
import fetchModulesWithWorks from 'store/query/common/fetchModulesWithWorks';

import { StyledLevelWrap } from './styled';
import ModulesSettings from './ModulesSettings';
import CustomTooltip from '../../../CustomTooltip';
import SeniorityLevel from '../../../SeniorityLevel';
import { Step } from 'react-joyride';

interface IExperiencesContainer {
  expertise: any;
  name: string;
  product: IProduct;
  setSteps?: React.Dispatch<React.SetStateAction<Step[]>>;
  setRun?: React.Dispatch<React.SetStateAction<boolean>>;
  showIntro?: boolean;
}

const ModulesContainer: FC<IExperiencesContainer> = ({
  expertise,
  name,
  product,
  showIntro,
  setSteps,
  setRun,
}) => {
  const dispatch = useAppDispatch();

  const modules = useAppSelector(productModulesSelector(product?.id));
  const works = useAppSelector(productWorksSelector(product?.id));

  useEffect(() => {
    if (product?.id && !modules.length && !works.length) {
      dispatch(fetchModulesWithWorks(product?.id));
    }
  }, [dispatch, modules.length, product?.id, works.length]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} md="auto">
          <Box sx={{ maxWidth: { md: 224 } }}>
            <Typography variant="h6" gutterBottom>
              Expertise Level
              <CustomTooltip
                placement="bottom"
                title="Please specify your proficiency in each product module on this screen, 
                  and highlight any areas you'd prefer not to work in. This information is 
                  crucial as it allows us to align your expertise with the right customer orders."
              />
            </Typography>
            <Typography variant="body1" width={'85%'}>
              Use the matrix to specify your expertise for each module and type of work:
            </Typography>
          </Box>
        </Grid>
        <Grid xs mb={{ xs: 1, md: 0 }}>
          <StyledLevelWrap>
            <SeniorityLevel disabled value={{ id: 0, name: '' }} />
            <Typography mt={1} variant="body1">
              <b>Opt-out</b> - Not interested in this type of work
            </Typography>
          </StyledLevelWrap>
          <StyledLevelWrap>
            <SeniorityLevel disabled value={{ id: 1, name: '' }} />
            <Typography mt={1} variant="body1">
              <b>Proficient</b> - Solid understanding and practical experience
            </Typography>
          </StyledLevelWrap>
          <StyledLevelWrap>
            <SeniorityLevel disabled value={{ id: 2, name: '' }} />
            <Typography mt={1} variant="body1">
              <b>Advanced</b> - Deep knowledge and extensive experience
            </Typography>
          </StyledLevelWrap>
          <StyledLevelWrap>
            <SeniorityLevel disabled value={{ id: 3, name: '' }} />
            <Typography mt={1} variant="body1">
              <b>Expert</b> - Recognized authority and thought leader
            </Typography>
          </StyledLevelWrap>
        </Grid>
      </Grid>
      <ModulesSettings
        showIntro={showIntro}
        setSteps={setSteps}
        setRun={setRun}
        modules={modules}
        works={works}
        name={name}
        modulesWorks={expertise?.modulesWorks || []}
      />
    </>
  );
};

export default ModulesContainer;
