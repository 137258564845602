import React, { FC } from 'react';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { ArrowDropDownIcon } from 'styles/icons/ArrowDropDown';
import { StyledBoxArrow, StyledCloseIcon } from './styled';
import CloseIcon from 'styles/icons/CloseIcon';

const BootstrapInput: FC<any> = ({
  formControlSx,
  dataTest,
  inputProps,
  InputProps,
  readOnly,
  helperText,
  inputRef,
  success,
  successMessage,
  withArrow,
  withCloseIcon,
  onCloseIconClick,
  closeIconSx,
  arrowIconSx,
  ...params
}) => (
  <FormControl error={params.error} fullWidth={true} sx={formControlSx}>
    <InputBase
      inputRef={inputRef}
      {...InputProps}
      {...params}
      inputProps={{
        ...inputProps,
        readOnly: readOnly,
      }}
      data-test={dataTest}
      id={dataTest}
    />
    {params.error && <FormHelperText>{helperText}</FormHelperText>}
    {!params.error && success && successMessage && (
      <FormHelperText sx={{ color: '#41C756' }}>{successMessage}</FormHelperText>
    )}
    {withArrow && (
      <StyledBoxArrow sx={arrowIconSx}>
        <ArrowDropDownIcon />
      </StyledBoxArrow>
    )}
    {withCloseIcon && (
      <StyledCloseIcon onClick={onCloseIconClick} sx={closeIconSx}>
        <CloseIcon />
      </StyledCloseIcon>
    )}
  </FormControl>
);

export default BootstrapInput;
