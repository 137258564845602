import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M9.75 8.2501L15.9 2.1001" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.4999 5.1V1.5H12.8999" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V9.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};
