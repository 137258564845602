import { Components } from '@mui/material/styles';

const MuiDialogContent: Components['MuiDialogContent'] = {
  styleOverrides: {
    root: ({ theme }: any) => theme.unstable_sx({
      px: {
        xs: 2,
        md: 11
      },
      py: {
        xs: 1.5,
        md: 2
      },
      WebkitOverflowScrolling: 'auto'
    })
  },
}

export default MuiDialogContent
