import { createAsyncThunk } from '@reduxjs/toolkit';
import { EUser } from 'store/constants';

import { AxiosResponse } from "axios";

import instance from 'store/middlewares/api';

interface IResponseCustomers {
  count: number;
  page: number;
  customer: any[]
}

const getCustomerList = createAsyncThunk<IResponseCustomers, any>(
  EUser.getCustomerList,
  async ({ filter, key, ...params }) => {
    const { data } = await instance.get(`/admin/customers`, { params: { ...filter, ...params } });
    if (
      filter?.filterByEmail !== undefined && filter?.filterByEmail !== ''
      || filter?.filterByEmailOrName !== undefined && filter?.filterByEmailOrName !== ''
    ) {
      const filteredData = {
        ...data,
        users: data.users.filter((user: any) => !user.isVirtual),
      };
      return filteredData
    }
    return data
  }
);

export default getCustomerList;