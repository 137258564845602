import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const Atention: FunctionComponent<ISvgProps> = ({className, style}) => {
  return (
    <svg className={className} style={style} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="19" stroke="#FFCA28" strokeWidth="2"/>
      <rect x="18.667" y="7.33325" width="2.66667" height="20" fill="#FFCA28"/>
      <rect x="18.667" y="30" width="2.66667" height="2.66667" fill="#FFCA28"/>
    </svg>
  );
}
