import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EOrder } from '../../constants';
import { ERole } from '../../../models/consts';
import { IRole } from '../../types/common';

import { IOrdersHistory } from 'store/types/order';

interface IOrderHistoryProps {
  id: string;
  role: IRole;
  isPurchase?: boolean;
}

const fetchOrderHistory = createAsyncThunk<IOrdersHistory[], IOrderHistoryProps>(
  EOrder.getOrderHistory,
  async ({ id, role, isPurchase }) => {
    let url = `orders/${id}/change-log`;
    if (role.name === ERole.CUSTOMER_ADMIN || role.name === ERole.CUSTOMER) {
      url = isPurchase ? `enterprise/orders/${id}/change-log` : `orders/${id}/change-log`;
    } else if (role.name === ERole.AGENCY_ADMIN || role.name === ERole.RESOURCE_MANAGER) {
      url = `agency/orders/${id}/change-log`;
    } else if (role.name === ERole.LEAD_MANAGER) {
      url = `/lead-managers/orders/${id}/change-log`;
    }

    const { data } = await instance.get(url);

    return data;
  },
);

export default fetchOrderHistory;
