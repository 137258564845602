import { createSelector } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import { DefaultRootState } from 'react-redux';
import { ChatAssistantState } from '../reducers/chat-assistant';

const chatAssistantState = (state: DefaultRootState) => state.chatAssistant;

export const chatAssistantMessagesSelector = createSelector(
  chatAssistantState,
  ({ chatHistory }: ChatAssistantState) => chatHistory,
);

export const chatAssistantFilterSelector = createSelector(
  chatAssistantState,
  ({ filter }: ChatAssistantState) => filter,
);
export const selectIsLoading = createSelector(
  chatAssistantState,
  ({ loading }: ChatAssistantState) => loading,
);
