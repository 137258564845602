import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

// @ts-ignore
interface IExtendTypographyProps extends TypographyProps {
  component: string;
}

const StyledDetailKey = styled(Typography)<IExtendTypographyProps>(({ theme }) =>
  theme.unstable_sx({
    flexBasis: '40%',
    '& b': {
      color: 'text.secondary',
    },
  }),
);

interface IPurchaseItemContainer
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isSelectedPaymentType: boolean;
}

const PurchaseItemContainer = styled('div')<IPurchaseItemContainer>(
  ({ theme, isSelectedPaymentType }) =>
    theme.unstable_sx({
      display: 'flex',
      flex: '0 0 auto',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: '1px solid #E5E5E5',
      padding: theme.spacing(3),
      width: '222px',
      maxWidth: '100%',
      margin: '0 10px 15px',
      borderColor: isSelectedPaymentType ? '#FFC107' : '#E5E5E5',
    }),
);

const TitleBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    fontWeight: 500,
    marginBottom: '11px',
    alignSelf: 'flex-start',
    fontSize: '22px',
    lineHeight: '28px',
  }),
);

const DescriptionBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '14px',
    lineHeight: '18px',
  }),
);

const DescriptionContent = styled('span')(({ theme }) =>
  theme.unstable_sx({
    color: '#5D5D5E',
  }),
);

export { StyledDetailKey, PurchaseItemContainer, TitleBox, DescriptionBox, DescriptionContent };
