import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";

const StyledWrap = styled(Grid)(({ theme }) => theme.unstable_sx({
  py: 1.5,
  px: {
    xs: 0,
    sm: 2
  },
  mt: 2,
  backgroundColor: '#F9F9F9',
  borderRadius: 4,
}))

const StyledBaseWrap = styled(Box)({
  display: 'flex',
  alignItems: 'center',

})
const StyledLeftWrap = styled(StyledBaseWrap)({
  display: 'flex'
})
const StyledRightWrap = styled(StyledBaseWrap)({
  display: 'flex'
})
const StyledActionWrap = styled(StyledBaseWrap)({
  flexDirection: 'column'
})

export { StyledActionWrap, StyledLeftWrap, StyledRightWrap, StyledWrap }
