import React from 'react';

import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

export default (props: SvgIconProps) => (
  <SvgIcon  {...props} viewBox="0 0 165 28">
    <g clipPath="url(#clip0_7_5440)">
      <path d="M37.1211 10.7212H40.2372L42.3612 18.162L42.6992 20.3591H42.7507L43.1182 18.162L45.3964 10.7212H48.2553L50.4601 18.162L50.8497 20.3591H50.9084L51.2465 18.162L53.3925 10.7212H56.4866L52.6062 23.5888H49.3798L46.8149 14.2732L44.272 23.5888H41.053L37.1211 10.7212Z" fill="black"/>
      <path d="M64.6219 23.8451C63.367 23.862 62.1281 23.5622 61.0207 22.9735C59.9959 22.4144 59.1444 21.5862 58.5587 20.5787C57.9894 19.5267 57.6914 18.3502 57.6914 17.1549C57.6914 15.9597 57.9894 14.7832 58.5587 13.7311C59.1427 12.7243 59.9948 11.898 61.0207 11.3436C62.1304 10.7464 63.3756 10.4437 64.6366 10.4648C65.8884 10.4424 67.1245 10.7453 68.2231 11.3436C69.2434 11.9069 70.082 12.7476 70.641 13.7677C71.2287 14.8138 71.5373 15.9927 71.5373 17.1915C71.5373 18.3904 71.2287 19.5692 70.641 20.6153C70.0707 21.6245 69.228 22.4541 68.2084 23.0102C67.1044 23.5901 65.8694 23.8775 64.6219 23.8451V23.8451ZM64.6219 21.2452C65.1419 21.2639 65.6603 21.1783 66.1464 20.9933C66.6325 20.8084 67.0763 20.5279 67.4514 20.1686C67.8265 19.7652 68.1166 19.291 68.3047 18.774C68.4928 18.2571 68.575 17.7078 68.5465 17.1586C68.5779 16.6124 68.497 16.0657 68.3087 15.5518C68.1204 15.038 67.8287 14.5679 67.4514 14.1705C67.0835 13.7975 66.6424 13.5042 66.1555 13.3088C65.6686 13.1135 65.1465 13.0204 64.6219 13.0354C64.0918 13.0202 63.5641 13.113 63.0712 13.3082C62.5784 13.5034 62.1307 13.7968 61.7557 14.1705C61.376 14.5666 61.0822 15.0362 60.8926 15.5503C60.7029 16.0643 60.6215 16.6118 60.6533 17.1586C60.6245 17.7084 60.7073 18.2583 60.8967 18.7755C61.0861 19.2927 61.3783 19.7665 61.7557 20.1686C62.1378 20.5286 62.588 20.8093 63.0801 20.9941C63.5723 21.179 64.0964 21.2643 64.6219 21.2452V21.2452Z" fill="black"/>
      <path d="M74.25 10.721H77.1897V12.3029C77.6435 11.7357 78.2174 11.2755 78.8704 10.9552C79.5235 10.6349 80.2395 10.4624 80.9673 10.45C81.3526 10.4359 81.7364 10.5059 82.0917 10.6551L81.7096 13.0353L81.6067 13.1158C81.3217 13.0556 81.0307 13.0286 80.7395 13.0353C80.0204 13.0343 79.318 13.2514 78.7257 13.6578C78.0726 14.1107 77.5443 14.7202 77.1897 15.4301V23.5886H74.25V10.721Z" fill="black"/>
      <path d="M84.5391 4.80371H87.4788V15.152H87.5817L91.7488 10.7578H95.4234L89.9996 16.4703L95.8423 23.6254H92.1677L87.5596 18.0448H87.4788V23.6254H84.5391V4.80371Z" fill="black"/>
      <path d="M102.809 23.8451C101.613 23.8705 100.431 23.588 99.3769 23.0249C98.3952 22.471 97.5941 21.6473 97.0692 20.652C96.5092 19.5736 96.2287 18.3727 96.2535 17.1587C96.2328 15.9398 96.5426 14.738 97.1501 13.6799C97.7318 12.6946 98.5631 11.8789 99.5607 11.3144C100.549 10.7498 101.67 10.4567 102.809 10.4649C103.96 10.4426 105.097 10.7176 106.109 11.2631C107.08 11.7709 107.875 12.5599 108.387 13.5261C108.951 14.6162 109.229 15.8304 109.196 17.0561V17.9569H99.1638C99.192 18.8983 99.5875 19.7916 100.266 20.447C100.986 21.0617 101.913 21.3834 102.861 21.3478C103.638 21.3799 104.407 21.1755 105.065 20.7619C105.645 20.3474 106.077 19.7601 106.3 19.0848L108.916 19.2386L108.997 19.3704C108.663 20.6941 107.885 21.8644 106.792 22.688C105.627 23.4972 104.228 23.9037 102.809 23.8451V23.8451ZM106.366 15.7892C106.345 15.3698 106.234 14.9598 106.04 14.587C105.846 14.2143 105.574 13.8875 105.242 13.6287C104.553 13.0805 103.69 12.7972 102.809 12.8304C101.953 12.8102 101.117 13.0931 100.45 13.6287C99.7862 14.1827 99.3537 14.9635 99.2373 15.8184L106.366 15.7892Z" fill="black"/>
      <path d="M111.732 10.721H114.724V12.3029C115.176 11.7359 115.748 11.2757 116.4 10.9554C117.052 10.635 117.767 10.4624 118.494 10.45C118.879 10.4359 119.263 10.5059 119.618 10.6551L119.221 13.0353L119.119 13.1158C118.833 13.056 118.542 13.029 118.251 13.0353C117.53 13.0342 116.825 13.2512 116.23 13.6578C115.583 14.1109 115.062 14.7208 114.716 15.4301V23.5886H111.732V10.721Z" fill="black"/>
      <path d="M129.187 23.8452C128.411 23.8693 127.638 23.7335 126.918 23.4461C126.197 23.1588 125.543 22.7262 124.998 22.1754V23.5888H122.021V4.80371H125.005V12.0102C125.542 11.5219 126.165 11.137 126.843 10.875C127.595 10.596 128.392 10.457 129.194 10.4649C130.263 10.4506 131.314 10.7266 132.237 11.2632C133.183 11.7936 133.95 12.5912 134.442 13.5555C135.014 14.6686 135.292 15.9087 135.25 17.1587C135.279 18.4143 134.98 19.656 134.383 20.7619C133.866 21.7305 133.083 22.5329 132.127 23.0762C131.229 23.5798 130.217 23.8446 129.187 23.8452V23.8452ZM128.511 21.3258C129.009 21.3444 129.506 21.2604 129.97 21.0789C130.434 20.8974 130.856 20.6225 131.208 20.2712C131.957 19.4124 132.334 18.2937 132.259 17.1587C132.299 16.5998 132.226 16.0387 132.046 15.508C131.865 14.9774 131.581 14.4879 131.208 14.0681C130.63 13.5049 129.887 13.1391 129.087 13.0233C128.286 12.9075 127.47 13.0477 126.754 13.4236C126.228 13.6853 125.783 14.0854 125.468 14.5808C125.158 15.0747 124.995 15.6461 124.998 16.2286V18.1108C124.995 18.6933 125.158 19.2646 125.468 19.7586C125.808 20.2429 126.259 20.6396 126.784 20.9157C127.319 21.1869 127.911 21.3275 128.511 21.3258Z" fill="black"/>
      <path d="M143.82 23.8452C142.626 23.8682 141.447 23.5858 140.395 23.0249C139.413 22.471 138.612 21.6473 138.087 20.6521C137.533 19.5718 137.255 18.3718 137.279 17.1587C137.263 15.9385 137.577 14.7366 138.19 13.68C138.774 12.6965 139.605 11.8814 140.601 11.3144C141.589 10.7508 142.71 10.4577 143.849 10.4649C145 10.4403 146.137 10.7155 147.149 11.2632C148.122 11.7687 148.917 12.5582 149.427 13.5262C149.991 14.6162 150.272 15.83 150.243 17.0562V17.957H140.182C140.204 18.8981 140.598 19.7927 141.277 20.447C141.998 21.0605 142.924 21.3819 143.871 21.3478C144.648 21.3771 145.416 21.173 146.076 20.7619C146.651 20.3448 147.081 19.7582 147.303 19.0848L149.927 19.2386L150 19.3704C149.666 20.6941 148.888 21.8644 147.796 22.688C146.633 23.4967 145.236 23.9032 143.82 23.8452ZM147.384 15.7892C147.361 15.376 147.25 14.9724 147.058 14.6051C146.867 14.2377 146.6 13.915 146.274 13.658C145.584 13.1081 144.718 12.8247 143.834 12.8597C142.979 12.8439 142.144 13.1263 141.475 13.658C140.81 14.2 140.372 14.9708 140.248 15.8185L147.384 15.7892Z" fill="black"/>
      <path d="M158.606 23.8452C157.41 23.8695 156.228 23.587 155.174 23.0249C154.2 22.4677 153.407 21.6443 152.888 20.6521C152.334 19.5718 152.056 18.3718 152.08 17.1587C152.057 15.9405 152.364 14.7388 152.969 13.68C153.553 12.6965 154.384 11.8814 155.379 11.3144C156.368 10.7518 157.489 10.4588 158.628 10.4649C159.779 10.4403 160.916 10.7155 161.928 11.2632C162.901 11.7687 163.695 12.5582 164.206 13.5262C164.77 14.6162 165.051 15.83 165.022 17.0562V17.957H154.968C154.99 18.8981 155.384 19.7927 156.063 20.447C156.784 21.0605 157.71 21.3819 158.657 21.3478C159.434 21.3771 160.203 21.173 160.862 20.7619C161.437 20.3448 161.867 19.7582 162.089 19.0848L164.713 19.2386L164.787 19.3704C164.452 20.6941 163.674 21.8644 162.582 22.688C161.419 23.4967 160.022 23.9032 158.606 23.8452V23.8452ZM162.17 15.7892C162.149 15.3698 162.038 14.9598 161.844 14.5871C161.65 14.2143 161.378 13.8875 161.046 13.6287C160.355 13.0788 159.489 12.7954 158.606 12.8304C157.75 12.8146 156.916 13.097 156.247 13.6287C155.583 14.1726 155.146 14.9424 155.019 15.7892H162.17Z" fill="black"/>
      <path d="M2.00637 28.3564C3.11446 28.3564 4.01274 27.4612 4.01274 26.357C4.01274 25.2528 3.11446 24.3577 2.00637 24.3577C0.898282 24.3577 0 25.2528 0 26.357C0 27.4612 0.898282 28.3564 2.00637 28.3564Z" fill="black"/>
      <path d="M3.99072 15.4376L13.1406 24.5629C13.3506 24.7705 13.5173 25.0175 13.631 25.2897C13.7448 25.5618 13.8033 25.8537 13.8033 26.1484C13.8033 26.4432 13.7448 26.7351 13.631 27.0072C13.5173 27.2794 13.3506 27.5264 13.1406 27.734C12.9323 27.9433 12.6844 28.1093 12.4113 28.2227C12.1382 28.336 11.8453 28.3944 11.5495 28.3944C11.2537 28.3944 10.9608 28.336 10.6877 28.2227C10.4146 28.1093 10.1667 27.9433 9.95838 27.734L0.801106 18.6088C0.380587 18.1875 0.144531 17.6174 0.144531 17.0232C0.144531 16.429 0.380587 15.8589 0.801106 15.4376V15.4376C1.22513 15.0181 1.7984 14.7827 2.39591 14.7827C2.99342 14.7827 3.56669 15.0181 3.99072 15.4376Z" fill="black"/>
      <path d="M4.23361 5.53609L23.2978 24.5336C23.5078 24.7412 23.6744 24.9882 23.7882 25.2604C23.9019 25.5325 23.9605 25.8244 23.9605 26.1192C23.9605 26.414 23.9019 26.7058 23.7882 26.978C23.6744 27.2501 23.5078 27.4971 23.2978 27.7047C23.0894 27.914 22.8416 28.0801 22.5685 28.1934C22.2954 28.3067 22.0025 28.3651 21.7067 28.3651C21.4108 28.3651 21.1179 28.3067 20.8449 28.1934C20.5718 28.0801 20.3239 27.914 20.1155 27.7047L1.05135 8.70722C0.841361 8.49959 0.674696 8.25258 0.560961 7.98045C0.447226 7.70832 0.388672 7.41645 0.388672 7.12166C0.388672 6.82687 0.447226 6.535 0.560961 6.26287C0.674696 5.99074 0.841361 5.74373 1.05135 5.53609V5.53609C1.25972 5.32684 1.50759 5.16076 1.78067 5.04742C2.05376 4.93408 2.34666 4.87573 2.64248 4.87573C2.9383 4.87573 3.2312 4.93408 3.50429 5.04742C3.77737 5.16076 4.02524 5.32684 4.23361 5.53609V5.53609Z" fill="black"/>
      <path d="M10.1279 1.26632L27.2666 18.3377C27.6893 18.7593 27.9268 19.3309 27.9268 19.9269C27.9268 20.5229 27.6893 21.0946 27.2666 21.5162V21.5162C26.8438 21.9352 26.2717 22.1704 25.6754 22.1704C25.0791 22.1704 24.5071 21.9352 24.0843 21.5162L6.94564 4.43745C6.52512 4.01615 6.28906 3.4461 6.28906 2.85188C6.28906 2.25767 6.52512 1.68761 6.94564 1.26632V1.26632C7.15401 1.05706 7.40188 0.890982 7.67496 0.777645C7.94805 0.664308 8.24095 0.605957 8.53677 0.605957C8.83259 0.605957 9.12549 0.664308 9.39858 0.777645C9.67166 0.890982 9.91953 1.05706 10.1279 1.26632Z" fill="black"/>
      <path d="M20.4288 1.46857C19.5506 0.593397 18.1267 0.593398 17.2484 1.46858C16.3702 2.34375 16.3702 3.76269 17.2484 4.63787L24.1237 11.4891C25.002 12.3643 26.4259 12.3643 27.3042 11.4891C28.1824 10.614 28.1824 9.19503 27.3042 8.31985L20.4288 1.46857Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_7_5440">
        <rect width="165" height="27.7859" fill="white" transform="translate(0 0.607178)"/>
      </clipPath>
    </defs>
  </SvgIcon>
);
