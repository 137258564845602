import { Components } from '@mui/material/styles';

const MuiDrawer: Components['MuiDrawer'] = {
  styleOverrides: {
    root: ({ theme }: any) => theme.unstable_sx({
      zIndex: 1300
    }),
  }
}

export default MuiDrawer
