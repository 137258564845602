import React, { FunctionComponent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { outputQuery } from 'helpers/decorators';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getAgencyUsersByPageSelector,
  totalAgencyUsersSelector,
} from 'store/selectors/getAgencySelector';
import usersTeam from 'store/query/agency/usersTeam';

import TableContainer from '../../TableCustomized/TableContainer';
import EnhancedTableHeadSort from '../../TableCustomized/EnhancedTableHeadSort';
import AgencyTeamRow from './AgencyTeamRow';
import ManageTableWrapper from 'components/TableCustomized/ManageTableWrapper';

import { agencyTeamHeadCells } from '../../TableCustomized/constants';
import { LIMITS } from 'models/consts';
import { IAgencyUser } from 'store/types/agency';

interface IManageTable {
  onChangeStatus: (row: IAgencyUser, status: string) => void;
  onImpersonate: (id: number) => void;
  page: number;
  setPage: (id: number) => void;
}

const ManageTable: FunctionComponent<IManageTable> = ({
  onChangeStatus,
  onImpersonate,
  page,
  setPage,
}) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const users = useAppSelector(getAgencyUsersByPageSelector(page + 1));
  const totalUsers = useAppSelector(totalAgencyUsersSelector);

  const { getValues, setValue, watch } = useFormContext();
  const { filterByEmailOrName, filterByEmail, filterByRole, filterByStatus } = getValues();
  const paramProperty = searchParams.get('property');
  const paramType = searchParams.get('type');
  const fieldProperty = watch('property');
  const fieldType = watch('type');

  useEffect(() => {
    if (paramProperty && paramProperty !== fieldProperty) {
      setValue('property', paramProperty);
    } else if (!paramProperty && fieldProperty) {
      setValue('property', undefined);
    }
    if (paramType && paramType !== fieldType) {
      setValue('type', paramType);
    }
  }, [fieldProperty, fieldType, paramProperty, paramType, searchParams, setValue]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = fieldProperty === property && fieldType === 'asc';

    setValue('property', property);
    setValue('type', isAsc ? 'desc' : 'asc');

    const filter = {
      filterByEmailOrName,
      filterByEmail,
      filterByRole,
      filterByStatus,
      property,
      type: isAsc ? 'desc' : 'asc',
    };

    const normalizedSearchParams: any = { ...filter };
    for (const key in normalizedSearchParams) {
      if (!normalizedSearchParams[key]) {
        delete normalizedSearchParams[key];
      }
    }
    setSearchParams(normalizedSearchParams);
  };

  const handleChangePage = (newPage: number, limit: number, offset: number) => {
    const { filterByEmail, filterByRole, filterByStatus, property, type } = getValues();

    const filter = {
      filterByEmailOrName,
      filterByEmail,
      filterByRole,
      filterByStatus,
      property,
      type,
    };
    setPage(newPage);
    dispatch(usersTeam({ filter: outputQuery(filter), limit, offset }));
  };

  return (
    <TableContainer sx={{ mb: 3, overflowX: 'hidden' }}>
      <EnhancedTableHeadSort
        order={fieldType}
        orderBy={fieldProperty}
        headCells={agencyTeamHeadCells}
        onRequestSort={handleRequestSort}
      />
      <ManageTableWrapper
        page={page}
        total={totalUsers}
        defaultLimit={LIMITS.team}
        count={users.length}
        onPageChange={handleChangePage}
        TableContent={
          <>
            {users.map((row, index) => (
              <AgencyTeamRow
                key={row.email}
                row={row}
                index={index}
                onImpersonate={onImpersonate}
                onChangeStatus={onChangeStatus}
              />
            ))}
          </>
        }
      />
    </TableContainer>
  );
};

export default ManageTable;
