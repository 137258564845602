import React, { FC, forwardRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { IExtendCategoryWithProducts, IProduct } from 'store/types/categoryProducts';
import { IConsultantProduct } from 'store/types/consultant';

import { StyledInputLabel, StyledFormGroup } from 'components/CustomFields/styled';

import CustomAutocomplete from '../../../CustomFields/CustomAutocomplete';
import { StyledBoxImpersonate } from 'components/Header/styled';
import RequestProductModal from 'components/Modal/RequestProductModal';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import { StyledMasonry } from '../../../CustomFields/CustomAutocomplete/styled';

interface IProductSelect {
  control: Control;
  flatList: IExtendCategoryWithProducts[];
  handleChange: (product: IProduct) => void;
  name: string;
  selectedOptions: IConsultantProduct[];
  showAddProduct?: boolean;
  bypassIsValid?: boolean;
  setBypassIsValid?: (value: boolean) => void;
  setJoyrideZIndex?: React.Dispatch<React.SetStateAction<number>>;
}

const ProductSelect: FC<IProductSelect> = ({
  control,
  flatList,
  handleChange,
  name,
  selectedOptions,
  showAddProduct,
  bypassIsValid,
  setBypassIsValid,
  setJoyrideZIndex,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleComponentOpen = () => {
    if (mobile) {
      setJoyrideZIndex?.(0);
    }
  };

  const handleComponentClose = () => {
    if (mobile) {
      setJoyrideZIndex?.(10000);
    }
  };

  const [isRequestModalVisible, setRequestModalVisible] = useState(false);
  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);

  return (
    <>
      <StyledFormGroup container spacing={1}>
        <Grid xs={12} md={3}>
          <StyledInputLabel>Product</StyledInputLabel>
        </Grid>
        <Grid xs={12} md={9}>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <CustomAutocomplete
                autocompleteProps={{
                  groupBy: option => option.categoryName,
                  ListboxComponent: forwardRef((props: any, ref: any) => (
                    <StyledMasonry
                      columns={(mobile && 1) || 2}
                      component="ul"
                      spacing={2}
                      {...props}
                    />
                  )),
                  multiple: undefined,
                  // classes,
                  getOptionDisabled: (option: IProduct) =>
                    selectedOptions.some(item => item.productId === option.id) || !option.isActive,
                }}
                field={field}
                handleChange={handleChange}
                onOpen={handleComponentOpen}
                onClose={handleComponentClose}
                options={flatList || []}
                placeholder="Select a Product"
                CustomBannerView={
                  showAddProduct
                    ? (props: any) => (
                        <StyledBoxImpersonate sx={{ justifyContent: 'flex-start' }}>
                          <Typography variant="body1">
                            <Link
                              onMouseDown={() => {
                                setRequestModalVisible(true);
                                props && props.setOpen && props.setOpen(false);
                              }}
                              sx={{
                                color: 'text.primary',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontWeight: 500,
                                pl: 2,
                              }}
                            >
                              Can’t find your product? Request it here.
                            </Link>
                          </Typography>
                        </StyledBoxImpersonate>
                      )
                    : undefined
                }
              />
            )}
            rules={{ required: 'This field is required' }}
          />
        </Grid>

        {bypassIsValid && (
          <Box alignContent={'center'} sx={{ width: '100%', mt: { xs: 4.5, md: 14.5 } }}>
            <Typography fontSize="18px" fontWeight="700" align="center">
              Thank you for requesting a new product!
            </Typography>

            <Typography
              fontSize={mobile ? '16px' : '18px'}
              fontWeight="400"
              align="center"
              sx={{ pt: 2 }}
            >
              A copy of your request has been sent to your email.
              <br />
              Our team will review and connect with you shortly.
            </Typography>

            <Typography
              fontSize={mobile ? '16px' : '18px'}
              fontWeight="400"
              align="center"
              sx={{ pt: 2 }}
            >
              You may skip to the next step or continue adding more products.
            </Typography>
          </Box>
        )}
      </StyledFormGroup>

      <RequestProductModal
        visible={isRequestModalVisible}
        close={() => setRequestModalVisible(false)}
        afterSubmit={() => {
          setConfirmationModalVisible(true);
          setBypassIsValid && setBypassIsValid(true);
        }}
      />

      <ConfirmationModal
        visible={isConfirmationModalVisible}
        close={() => setConfirmationModalVisible(false)}
        title="Your product request has been sent to our team."
        subtitle="A copy of your request has been sent to your email."
        primaryButtonText="Request Another Product"
        onPrimaryButtonClick={() => {
          setConfirmationModalVisible(false);
          setRequestModalVisible(true);
        }}
      />
    </>
  );
};

export default ProductSelect;
