import React, {FunctionComponent, useMemo} from 'react';

import dayjs from 'utils/dayjs'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import ROUTES from 'router/constants';
import {BILLING_COMPANY, EOrderFilter, ERole} from "models/consts";
import {IBasicPreOrder} from "store/types/preOrder";
import {IRole} from "store/types/common";

import {capitalizeFirstLetter, statusText} from 'utils/textFormatter';

import {
  StyledColaborator,
  StyledItemHeader,
  StyledItemWrap,
  StyledLink,
  StyledPaper,
  StyledTimelineWrap,
} from './styled'
import LogoExtraSmall from 'styles/icons/LogoExtraSmall';

import {subTabsForLeadManager, subTabsForUsers} from "../OrderList/tabOrder";
import PreOrderMenu from './PreOrderMenu';

export interface IOrderItemProps {
  onAction: (id: number) => void;
  preOrder: IBasicPreOrder;
  role: IRole
}

const PreOrderItem: FunctionComponent<IOrderItemProps> = ({ onAction, preOrder, role }) => {
  
  const orderTimeline = useMemo(() =>
    preOrder.timeLine?.startDate && preOrder.timeLine?.endDate ?
    `${dayjs.utc(preOrder.timeLine?.startDate).format('DD MMM, YYYY')} - ${dayjs.utc(preOrder.timeLine?.endDate).format('DD MMM, YYYY')}` :
    '-'
  , [preOrder.timeLine?.startDate, preOrder.timeLine?.endDate]);

  if (preOrder.loading) {
    return null;
  }

  return (
    <StyledPaper elevation={3}>
      <StyledItemWrap p={2}>
        <StyledItemHeader display="flex" alignItems="start">
          <Box display="flex" alignItems="center" flexGrow={1} justifyContent="space-between">
            <StyledLink to={`${ROUTES.PRE_ORDER}/${preOrder.id}`} state={{previousLocationPathname: location.pathname}}>
              <p>{preOrder.expertise?.product.name}</p>
              <p>{preOrder.expertise?.modules.map(({name}) => name).join(' / ')}</p>
            </StyledLink>
            <PreOrderMenu
              preOrder={preOrder}
              role={role}
            />
          </Box>
        </StyledItemHeader>
        <Divider sx={{my: 1}} />
        <Box display={{xs: 'flex', md: 'block'}} alignItems="center" justifyContent="space-between">
          <Typography color="text.secondary" gutterBottom>Preorder: <Typography color="text.primary" component="span">#{preOrder.id}</Typography></Typography>
          <Typography color="text.secondary" gutterBottom>SR: <Typography color="text.primary" component="span">#{preOrder.lead.reference}</Typography></Typography>
          <Typography color="text.secondary" gutterBottom>Billing: <Typography color="text.primary" component="span">
            {!!preOrder.billingType ? capitalizeFirstLetter(BILLING_COMPANY.find((item) => item.value === preOrder.billingType)?.name || '') : 'Not Assigned'}
          </Typography></Typography>
          <Chip sx={{mr: 1.5, mb: 2}} size="small" label={statusText(preOrder.status)} className={preOrder.status} />
        </Box>
        <StyledTimelineWrap mb={2} px={1.5} py={2.5}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography component="span"><strong>Total hours</strong></Typography>
            <Typography component="span">
              {preOrder.timeLine?.allHours} hours
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography component="span"><strong>Timeline</strong></Typography>
            <Typography component="span">
              {orderTimeline}
            </Typography>
          </Box>
        </StyledTimelineWrap>
        <Box display="flex" alignItems="center" mb={2}>
          <StyledColaborator>
            {role.name === ERole.LEAD_MANAGER && (
              <>
                <Typography component="span" color="textSecondary">Agency: </Typography> {preOrder.agency?.name || 'Not assigned'}
              </>
            )}
          </StyledColaborator>
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
          <StyledColaborator>
            <Typography component="span" color="textSecondary">Customer: </Typography> {preOrder.customerEmail}
          </StyledColaborator>
        </Box>
        <Box display="flex" justifyContent="center" px={2}>
          {role.name === ERole.LEAD_MANAGER && ([
            subTabsForLeadManager[EOrderFilter.NEED_ACTION][0].filter.includes(preOrder.status) && (
              <Button key={preOrder.status} color="secondary" fullWidth variant="contained" onClick={() => onAction(preOrder.id)}>
                Review Opportunity
              </Button>
            ),
            subTabsForLeadManager[EOrderFilter.NEED_ACTION][1].filter.includes(preOrder.status) && (
              <Button
                key={preOrder.status}
                color="secondary"
                fullWidth
                variant="contained"
                onClick={() => onAction(preOrder.id)}
                startIcon={<LogoExtraSmall color="primary" />}
                sx={{'&:hover svg': { color: 'text.primary' } }}
              >
                Send Customer Invitation
              </Button>
            ),
            subTabsForLeadManager[EOrderFilter.NEED_ACTION][2].filter.includes(preOrder.status) && preOrder.billingType !== 'customer' && (
              <Button key={preOrder.status} color="secondary" fullWidth variant="contained" onClick={() => onAction(preOrder.id)}>
                Accept Preorder
              </Button>
            )
          ])}
          {role.name !== ERole.LEAD_MANAGER && ([
            subTabsForUsers[0].filter.includes(preOrder.status) && (
              <Button key={preOrder.status} color="secondary" fullWidth variant="contained" onClick={() => onAction(preOrder.id)}>
                Review Opportunity
              </Button>
            ),
            subTabsForUsers[1].filter.includes(preOrder.status) && (
              <Button key={preOrder.status} color="secondary" fullWidth variant="contained" onClick={() => onAction(preOrder.id)}>
                Send a Message
              </Button>
            )
          ])}
        </Box>
      </StyledItemWrap>
    </StyledPaper>
  );
};

export default PreOrderItem;
