import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {EUser} from "../../constants";

const updateConsultantInfo = createAsyncThunk(
  EUser.updateConsultant,
  async (payload: any) => {
    const { data } = await instance.patch('consultant', payload)
    return data
  }
)

export default updateConsultantInfo
