import React from 'react';
import { Capacitor } from '@capacitor/core';

import ROUTES from 'router/constants';
import useMatchMultiple from 'hooks/useMatchUrls';

import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Logo } from 'styles/icons/Logo';

const AuthHeader = () => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileNative = Capacitor.isNativePlatform();

  const isMarginEnabled = useMatchMultiple([
    `${ROUTES.SIGN_UP}`,
    `${ROUTES.CUSTOMER_REGISTRATION}`,
  ]);

  return (
    <AppBar
      color="transparent"
      elevation={0}
      position={isMobileNative && isMarginEnabled ? 'fixed' : 'static'}
      sx={{
        top: isMobileNative && isMarginEnabled ? 20 : undefined,
        backgroundColor: isMobileNative && isMarginEnabled ? 'white' : undefined,
      }}
    >
      <Container maxWidth="xxl" fixed>
        <Toolbar disableGutters>
          <Grid container xs={12} alignItems="center" my={{ xs: 4, sm: 4 }} sx={{ height: 42 }}>
            <Grid xs={2} sm={4} md={6}>
              <Logo height={(small && 45) || 28} width={(small && 260) || 165} />
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AuthHeader;
