import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';

import { notificationDateTime, notificationTitle } from 'utils/parseNotification';

import useNotifyMsg from 'hooks/useNotifyMsg';

import { useAppSelector } from 'store/hooks';
import {
  getMeRoleSelector,
  isAgencySelector,
  isConsultantSelector,
} from 'store/selectors/getUserSelector';

import CheckIcon from 'styles/icons/CheckIcon';
import InfoIcon from 'styles/icons/InfoIcon';

import Avatar from 'components/Avatar';
import { StyledListItem, StyledListItemText, StyledListItemStatus } from './styled';
import { ENotificationsType } from 'models/consts';
import { INotifications } from 'store/types/common';

interface INotificationItemProps {
  notification: INotifications;
  close: () => void;
}

const NotificationItem: FunctionComponent<INotificationItemProps> = ({ notification, close }) => {
  const isConsultant = useAppSelector(isConsultantSelector);
  const isAgency = useAppSelector(isAgencySelector);
  const role = useAppSelector(getMeRoleSelector);

  const { isShow, notificationType, createdAt } = notification;
  const notificationData = JSON.parse(notification.notification);

  const title =
    notificationData.title ||
    notificationTitle({ type: notificationType, isConsultant, roleName: role.name });

  const msg = useNotifyMsg({
    notification,
    notificationData,
    close,
    isConsultant,
    isAgency,
    roleName: role.name,
  });

  return (
    <StyledListItem alignItems="flex-start" isShow={isShow}>
      <ListItemAvatar sx={{ mt: 0 }}>
        {notificationType !== ENotificationsType.RECEIVE_MESSAGE_CHAT ? (
          <StyledListItemStatus>
            {notificationType === ENotificationsType.END_ORDER ? <InfoIcon /> : <CheckIcon />}
          </StyledListItemStatus>
        ) : (
          <Avatar firstName={'F'} lastName={'L'} />
        )}
      </ListItemAvatar>
      <StyledListItemText
        className="notification-item__info1"
        primary={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography component="span">
              <b>{title}</b>
            </Typography>
            <Typography
              component="span"
              color={(isShow && 'text.disabled') || 'common.white'}
              variant="body2"
            >
              {notificationDateTime(createdAt)}
            </Typography>
          </Box>
        }
        secondary={
          <Typography variant="body2" component="div">
            {msg}
          </Typography>
        }
      />
    </StyledListItem>
  );
};

export default NotificationItem;
