import React, { FunctionComponent, MouseEvent, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import find from 'lodash/fp/find';

import { useStateMachine } from 'little-state-machine';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ArrowDown from 'styles/icons/ArrowDown';
import LogoutIcon from 'styles/icons/LogoutIcon';
import PlusIcon from 'styles/icons/PlusIcon';

import ROUTES from 'router/constants';

import { IBasicUser } from 'store/types/user';
import { IBasicConsultant } from 'store/types/consultant';

import { ICustomer } from 'models';
import { ELocalStoreKeys, ERole, ERoleId, getNameByRoleId } from 'models/consts';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import logout from 'store/query/user/logout';
import logoutImpersonate from 'store/query/agency/logoutImpersonate';

import {
  getExtendMeByRoleSelector,
  getMeSelector,
  isAgencyAdminSelector,
  isAgencyResourceManagerSelector,
  isConsultantSelector,
  isInfluencerSelector,
  isLeadManagerSelector,
} from 'store/selectors/getUserSelector';
import { getMeAgencySelector } from 'store/selectors/getAgencySelector';
import updateAction from 'store/actions/updateAction';

import NotificationPopover from 'components/Notification';
import Avatar from 'components/Avatar';
import useDeviceInfo from 'hooks/useDeviceInfo';

interface IMailto {
  mailto: string;
  label: string;
}

const ButtonMailto: FunctionComponent<IMailto> = ({ label, mailto }) => {
  return (
    <Typography
      component="span"
      onClick={(e: MouseEvent<HTMLSpanElement>) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Typography>
  );
};

const AuthorizedMenu: FunctionComponent = () => {
  const theme = useTheme();
  const [cookies] = useCookies([ELocalStoreKeys.IMPERSONATE_TOKEN]);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const tablet = useMediaQuery(theme.breakpoints.down('lg'));
  const { deviceType } = useDeviceInfo();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { step } = useParams();

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const extendMeByRole = useAppSelector(getExtendMeByRoleSelector);
  const agency = useAppSelector(getMeAgencySelector);
  const isConsultant = useAppSelector(isConsultantSelector);
  const isInfluencer = useAppSelector(isInfluencerSelector);
  const isAgencyAdmin = useAppSelector(isAgencyAdminSelector);
  const isResourceManager = useAppSelector(isAgencyResourceManagerSelector);
  const isLeadManager = useAppSelector(isLeadManagerSelector);

  const impersonateToken =
    typeof document !== 'undefined' && cookies[ELocalStoreKeys.IMPERSONATE_TOKEN];

  const { actions } = useStateMachine({ updateAction });

  const onLogout = () => {
    try {
      if (impersonateToken) {
        dispatch(logoutImpersonate())
          .unwrap()
          .then(() => navigate(ROUTES.TEAM));
      } else {
        dispatch(logout())
          .unwrap()
          .then(() => navigate(ROUTES.AUTH));
      }
    } finally {
      actions.updateAction({
        experiences: [],
        expertise: {},
        levelId: 0,
        timeLine: {},
        worker: {},
        searchParams: {},
      });
    }
    handleClose();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewPayment = useMemo(() => {
    switch (true) {
      case ERole.CUSTOMER_ADMIN === user?.role.name:
      case ERole.AGENCY_ADMIN === user?.role.name:
      case ERole.CONSULTANT === user?.role.name && !(extendMeByRole as IBasicConsultant)?.agencyId:
      case ERole.SELF_CUSTOMER === user?.role.name: {
        return true;
      }
      default:
        return false;
    }
  }, [extendMeByRole, user?.role.name]);

  const subTitle = useMemo(() => {
    return user?.role.id === ERoleId.CONSULTANT
      ? user.agencyId && !user.isVirtual
        ? 'Regular '
        : user.isVirtual
          ? 'Virtual '
          : ''
      : '';
  }, [user.agencyId, user.isVirtual, user?.role.id]);

  return (
    <Box data-test="top-bar-user-info" display="flex" alignItems="center">
      {!isConsultant &&
        !isAgencyAdmin &&
        !isLeadManager &&
        !isResourceManager &&
        !step &&
        !mobile &&
        ![ERole.INFLUENCER, ERole.ADMIN, ERole.SALES, ERole.TALENT].includes(user?.role.name) && (
          <Button
            data-test="create-order-button-header-lg"
            component={Link}
            variant="contained"
            color="secondary"
            startIcon={<PlusIcon />}
            sx={{ px: 2 }}
            to={ROUTES.ORDER_CREATION + '/1'}
          >
            Create an order
          </Button>
        )}

      <NotificationPopover />

      <Avatar
        image={
          isConsultant || isInfluencer
            ? (extendMeByRole as IBasicConsultant).avatarUrl
            : ((extendMeByRole as ICustomer)?.companies &&
                (extendMeByRole as ICustomer)?.companies[0]?.logo) ||
              agency?.logo
        }
        firstName={user?.firstName || agency?.name || ''}
        lastName={user?.lastName || ''}
        size="small"
        dataTest="user-avatar-header"
      />

      {deviceType !== 'mobile' && deviceType !== 'tablet' && !tablet && (
        <Box ml={1} mr={2}>
          <Typography data-test="user-name-header" fontWeight={700} mt={1} sx={{ lineHeight: 1 }}>
            {user?.firstName || agency?.name} {user?.lastName || ''}
          </Typography>
          <Typography data-test="user-role-header">
            {subTitle}
            {(find({ id: user?.role.id })(getNameByRoleId) as any)?.name}
          </Typography>
        </Box>
      )}

      <IconButton data-test="account-menu-button" disableRipple onClick={handleClick}>
        <ArrowDown />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        data-test="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              width: 240,
              mt: 1.5,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        keepMounted
      >
        {(isConsultant || user.role.name === ERole.INFLUENCER) && (
          <MenuItem
            component={Link}
            onClick={handleClose}
            to={`${ROUTES.CONSULTANT}`}
            data-test="my-profile-link"
          >
            <ListItemText primary="My Profile" />
          </MenuItem>
        )}
        {(isAgencyAdmin || isResourceManager) && (
          <MenuItem
            component={Link}
            onClick={handleClose}
            to={`${ROUTES.AGENCY_PROFILE}`}
            data-test="agency-profile-link"
          >
            <ListItemText primary="Agency Profile" />
          </MenuItem>
        )}
        <MenuItem
          component={Link}
          data-test="contact-link"
          onClick={handleClose}
          to={ROUTES.CONTACT_DETAILS}
        >
          <ListItemText primary="Contact" />
        </MenuItem>
        <MenuItem
          component={Link}
          data-test="account-link"
          onClick={handleClose}
          to={ROUTES.SECURITY}
        >
          <ListItemText primary="Account" />
        </MenuItem>
        {viewPayment && (
          <MenuItem
            component={Link}
            data-test="payment-link"
            onClick={handleClose}
            to={ROUTES.PAYMENT_DETAILS}
          >
            <ListItemText primary="Payment" />
          </MenuItem>
        )}
        {user?.role.name === ERole.CUSTOMER_ADMIN && (
          <MenuItem
            key="team"
            component={Link}
            data-test="team-link"
            onClick={handleClose}
            to={ROUTES.TEAM}
          >
            <ListItemText primary="Team" />
          </MenuItem>
        )}
        {(user?.role.name === ERole.CUSTOMER || user?.role.name === ERole.CUSTOMER_ADMIN) && (
          <MenuItem
            key="Purchase_Orders"
            component={Link}
            data-test="order-forms-link"
            onClick={handleClose}
            to={ROUTES.PURCHASE_ORDERS}
          >
            <ListItemText primary="Payment Authorizations" />
          </MenuItem>
        )}
        <MenuItem component={Link} onClick={handleClose} to="mailto:support@myworkerbee.com">
          <ButtonMailto label="Contact us" mailto="mailto:support@myworkerbee.com" />
        </MenuItem>
        {tablet && isAgencyAdmin && (
          <MenuItem component={Link} onClick={handleClose} to={ROUTES.PRODUCT_AND_EXPERIENCE}>
            <ListItemText primary="Products & Experience" />
          </MenuItem>
        )}
        {user?.role.name === ERole.ADMIN && (
          <MenuItem component={Link} onClick={handleClose} to={ROUTES.IMPERSONATION}>
            <ListItemText primary="Impersonation" />
          </MenuItem>
        )}
        <Divider />
        <MenuItem component="span" onClick={onLogout} data-test="logout">
          <ListItemText primary="Logout" />
          <ListItemIcon sx={{ color: '#D4D4D4' }}>
            <LogoutIcon />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AuthorizedMenu;
