import React, { useEffect, useState } from "react";
import { SafeArea } from 'capacitor-plugin-safe-area';
import { Capacitor } from '@capacitor/core';

const useSafeAreaView = () => {
    const [safeAreaPadding, setSafeAreaPadding] = useState({top: 0, bottom: 0});
  
    useEffect(() => {
      const initialize = async () => {
        if (Capacitor.isNativePlatform()) {
          try {
            const { insets } = await SafeArea.getSafeAreaInsets();
            setSafeAreaPadding({top: insets.top / 10, bottom: (insets.bottom / 4)});
          } catch (error) {
            console.error('Error getting safe area insets:', error);
          }
        }
      };
    
      initialize();
    }, []);
  
    return { safeAreaPadding };
  };

  export default useSafeAreaView;