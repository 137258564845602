import React from 'react'
import DatePicker from "react-datepicker";
import { Controller, useFormContext } from "react-hook-form";

import dayjs from "utils/dayjs";
import { getEndDate, isFriday, isMonday } from "utils/dateFormatter";
import { handlerTimeLineChange, setDate } from "utils/orderCreationHelpers";

import Grid from "@mui/material/Unstable_Grid2";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import CalendarIcon from "styles/icons/CalendarIcon";

import DateTextInput from "../DateTextInput";

const RangeDateTimeline = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    watch
  } = useFormContext()

  const selectedAvailability = watch('availabilityWeek');
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const totalTime = watch('allHours');

  return (
    <>
      <Grid container xs={12} spacing={2}>
        <Grid xs={12} md={12} lg={6}>
          <InputLabel>Start date</InputLabel>
          <Controller
            name="startDate"
            control={control}
            rules={{
              required: 'This field is required',
              validate: {
                beforeEnd: (date) => {
                  if (!date
                    || (
                      endDate
                      // && selectedAvailability
                      && dayjs(date).isSameOrAfter(getEndDate(date, totalTime, selectedAvailability) || endDate)
                    )
                  ) {
                    return 'Incorrect date range';
                  }
                  return undefined;
                },
              }
            }}
            render={({ field }) => (
              <DatePicker
                selected={!!startDate ? dayjs(startDate).toDate() : null}
                onChange={(date:Date) => {
                  if (date && !!selectedAvailability) {
                    handlerTimeLineChange(totalTime, selectedAvailability, dayjs(date).format('YYYY-MM-DD'), setValue, clearErrors);
                  }
                  setDate(date, field);
                }}
                calendarStartDay={1}
                customInput={
                  <DateTextInput
                    className="text-custom-input"
                    onReset={() => setDate(null, field)}
                    icon={<CalendarIcon />}
                    dataTest="startDate"
                  />
                }
                isClearable
                showPopperArrow={false}
                filterDate={isMonday}
                minDate={dayjs().add(1, 'days').toDate()}
                placeholderText="MM/DD/YYYY"
                withPortal={xs}
                dayClassName={(date) => isMonday(date) && 'available-start-date' || null}
              />
            )}
          />
          {!!errors.startDate && (
            <Typography color="error">
              {(errors.startDate as any)?.message || 'Incorrect data'}
            </Typography>
          )}
        </Grid>
        <Grid xs={12} md={12} lg={6} sx={{mb: 1}}>
          <InputLabel>End date</InputLabel>
          <Controller
            name="endDate"
            control={control}
            rules={{
              required: 'This field is required',
              validate: {
                afterStart: (date) => !date || (!startDate || dayjs(startDate).isSameOrBefore(date)) || 'Incorrect date range'
              }
            }}
            render={({ field }) => (
              <DatePicker
                selected={!!endDate ? dayjs(endDate).toDate() : null}
                onChange={(date: Date) => {
                  setDate(date, field);
                  if(startDate && dayjs(startDate).isSameOrBefore(date)) {
                    clearErrors('startDate')
                  }
                  setValue('availabilityWeek', 0);
                  setValue('flexibleHours', true);
                }}
                calendarStartDay={1}
                customInput={
                  <DateTextInput
                    className="text-custom-input"
                    onReset={() => setDate(null, field)}
                    icon={<CalendarIcon/>}
                    dataTest="endDate"
                  />
                }
                showPopperArrow={false}
                minDate={startDate ? dayjs(startDate).toDate() : dayjs().toDate()}
                filterDate={isFriday}
                isClearable
                placeholderText="MM/DD/YYYY"
                withPortal={xs}
                dayClassName={(date) => isFriday(date) && 'available-end-date' || null}
              />
            )}
          />
          {!!errors.endDate && (
            <Typography color="error">
              {(errors.endDate as any)?.message || 'Incorrect data' }
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid xs={12} sx={{mb: 3}}>
        <Controller
          control={control}
          render={({field}) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked);

                    if (event.target.checked) {
                      setValue('availabilityWeek', 0);
                    }
                    if (!event.target.checked && !!selectedAvailability) {
                      handlerTimeLineChange(totalTime, selectedAvailability, startDate, setValue, clearErrors);
                    }
                  }}
                  size="small"
                  checkedIcon={<span />}
                  icon={<span />}
                />
              }
              label={
                <Typography component="span">I have flexibility when the hours are worked</Typography>
              }
              sx={{ml: .125}}
            />
          )}
          name="flexibleHours"
          rules={{
            validate: {
              optionalRequired: (value) => !!value || !!selectedAvailability
            }
          }}
        />
      </Grid>
    </>
  )
}

export default RangeDateTimeline
