import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

import Persons from 'styles/icons/persons.png';

const StyledLayout = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    backgroundSize: {
      xs: 'auto',
      sm: 'contain',
    },
    backgroundPosition: {
      xs: 'right bottom -25px',
      sm: 'bottom -250px right 0',
      lg: 'right bottom',
    },
    backgroundRepeat: 'no-repeat',
    height: '100%',
    minHeight: '-webkit-fill-available',
    overflow: 'hidden',
  }),
);

const StyledLayoutPerson = styled(StyledLayout)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: {
      lg: `url(${Persons})`,
    },
  }),
);

const StyledFab = styled(Fab)(({ theme }) =>
  theme.unstable_sx({
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  }),
);

export { StyledFab, StyledLayout, StyledLayoutPerson };
