import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import { useParams } from 'react-router-dom';
import ReactJoyride, { CallBackProps, EVENTS, ACTIONS, STATUS, Step } from 'react-joyride';
import deepmerge from 'deepmerge';

import flatMapDepth from 'lodash/fp/flatMapDepth';
import groupBy from 'lodash/fp/groupBy';
import isEqual from 'lodash/fp/isEqual';
import cloneDeep from 'lodash/fp/cloneDeep';
import omit from 'lodash/fp/omit';
import sortBy from 'lodash/fp/orderBy';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { StyledH1Mobile } from 'views/Auth/styled';

import { DEFAULT_EXPERIENCE, DEFAULT_PRODUCTS, ERole } from 'models/consts';

import { activeSort } from '../../../helpers/decorators';
import updateAction from 'store/actions/updateAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { IBasicUser } from 'store/types/user';
import { IBasicConsultant } from 'store/types/consultant';
import { IConsultantExperience } from 'store/types/experiences';
import { IConsultantRegister } from 'store/types/inner-models';
import { IProduct } from 'store/types/categoryProducts';

import fetchCategoriesWithProducts from 'store/query/common/fetchCategoriesWithProducts';
import updateConsultantExperience from 'store/query/consultant/updateConsultantExperience';
import addConsultantExperience from 'store/query/consultant/addConsultantExperience';
import deleteProductExperiences from 'store/query/consultant/deleteProductExperiences';

import { categoryProductsSelector, productByNameSelector } from 'store/selectors/getCommonSelector';
import {
  getBasicConsultantSelector,
  productsSummarySelector,
} from 'store/selectors/getConsultantSelector';
import { getMeSelector } from 'store/selectors/getUserSelector';

import { ProductSelect, TabsList } from './Common';
import { ExperiencesContainer } from './Experienses';
import useDeviceInfo from 'hooks/useDeviceInfo';
import {
  IProductExperienseCopyModalConfig,
  ProductExperienseCopyModal,
} from './ProductExperienseCopyModal';
import { IExperienceBasis } from '../../../models/inner-models';

interface IProductExperiences extends IConsultantRegister {
  bypassIsValid?: boolean;
  setBypassIsValid?: (value: boolean) => void;
}

const StyledTabPanel = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const ProductsExperienses: FunctionComponent<IProductExperiences> = ({
  isModal,
  onSubmitted,
  bypassIsValid,
  setBypassIsValid,
  showIntro,
}) => {
  const customButtonStyles = {
    buttonNext: {
      fontFamily: 'Visuelt Pro, Arial',
      color: '#000',
    },
  };
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { deviceType } = useDeviceInfo();
  const isMobile = deviceType === 'mobile' && mobileScreen;

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getBasicConsultantSelector(user?.id)) as IBasicConsultant;
  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);
  const productsSummary = useAppSelector(productsSummarySelector(id || user?.id));

  const [showCopyProductModal, setShowCopyProductModal] =
    useState<IProductExperienseCopyModalConfig>({ isOpen: false });
  const [showExpertiseForm, setShowExpertiseForm] = useState(false);
  const {
    actions,
    state: { experiences },
  } = useStateMachine({ updateAction });

  const {
    control,
    formState: { isDirty, isValid },
    getValues,
    watch,
    setValue,
    handleSubmit,
    unregister,
  } = useFormContext();

  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);
  const [joyrideZIndex, setJoyrideZIndex] = useState(10000);

  const [tab, setTab] = React.useState(`0`);
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'experiences',
    keyName: '_id',
  });

  const watchFieldArray = watch('experiences');
  const controlledFields = fields.map((field, index) => {
    const controlledField = {
      ...field,
      ...(watchFieldArray && watchFieldArray[index]),
    };
    if (user.role.name === ERole.INFLUENCER && index === 0) {
      return {
        ...controlledField,
        readOnly: true,
      };
    }
    return controlledField;
  });

  useEffect(() => {
    return () => unregister('experiences');
  }, [unregister]);

  useEffect(() => {
    if (!productsSummary.length && !controlledFields.length && !experiences?.length) {
      append(DEFAULT_PRODUCTS);
    } else if (!controlledFields.length && experiences?.length) {
      replace(experiences);
    }
  }, [
    append,
    controlledFields.length,
    dispatch,
    experiences,
    user?.id,
    productsSummary.length,
    replace,
  ]);

  useEffect(() => {
    if (!categoriesWithProductsList?.length) {
      dispatch(fetchCategoriesWithProducts());
    }
  }, [categoriesWithProductsList?.length, dispatch]);

  useEffect(() => {
    if (productsSummary?.length && !controlledFields.length) {
      replace(productsSummary);
      setTab('0');
      actions.updateAction({ experiences: productsSummary });
    }
  }, [actions, append, isDirty, productsSummary, controlledFields.length, replace]);

  const addProduct = () => {
    append(DEFAULT_PRODUCTS);
    setTab(`${fields?.length}`);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const removeProduct = (index: number, experiences: IConsultantExperience[]) => {
    const ids = (experiences && experiences.map(({ id }) => id).filter(Boolean)) || [];

    if (ids.length) {
      dispatch(deleteProductExperiences({ ids, index, userId: id || user?.id, removeAll: true }));
    }
    remove(index);

    const values = cloneDeep(getValues());

    actions.updateAction(values);
    if (controlledFields.length <= 1) {
      addProduct();
    }
    setTab(`${(index && index - 1) || 0}`);
  };

  const onSubmit = async (value: any) => {
    const experiences = flatMapDepth(({ experiences }) => experiences, 1)(value.experiences);

    const newExperiences = experiences
        ?.filter(
          ({ id, company, description, startDate }) => !id && description && company && startDate,
        )
        .map(omit(['company', 'product'])),
      oldExperiences = experiences
        ?.filter(({ id }: { id: any }) => id)
        .map(omit(['company', 'product']));

    if (oldExperiences.length) {
      await dispatch(updateConsultantExperience({ data: oldExperiences, userId: id || user?.id }));
    }

    if (newExperiences.length) {
      await dispatch(addConsultantExperience({ data: newExperiences, userId: id || user?.id }))
        .unwrap()
        .then(data => {
          const grouped = groupBy('productId')(data);
          const newExperiences = [] as any[];
          value.experiences.map((product: any, index: number) => {
            const oldExperiences = product.experiences.filter(({ id }: { id: number }) => id);
            if (grouped[`${product.productId}`]) {
              newExperiences.push({
                ...product,
                experiences: [...oldExperiences, ...grouped[`${product.productId}`]],
              });
              setValue(`experiences.${index}.experiences`, [
                ...oldExperiences,
                ...grouped[`${product.productId}`],
              ]);
            } else {
              newExperiences.push(product);
            }
          });
          actions.updateAction({ experiences: newExperiences });
        });
    }

    onSubmitted && onSubmitted();
  };

  const onCopyExperience = (productId: number) => {
    setShowCopyProductModal({ isOpen: true, currentProductId: productId });
  };

  const onCloseCopyModal = () => {
    setShowCopyProductModal({ isOpen: false });
  };

  const onSaveCopyModal = (selectedExperiences: IExperienceBasis[]) => {
    const selectedProductId = getValues().experiences[parseInt(tab)].productId;
    const existingExperiences = getValues().experiences[parseInt(tab)].experiences;
    const updatedExperiences = selectedExperiences.map(exp => {
      return {
        activities: exp.activities,
        description: exp.description,
        startDate: exp.startDate,
        endDate: exp.endDate,
        companyId: exp.companyId,
        productId: selectedProductId,
        isShowCompanyLogo: exp.isShowCompanyLogo,
        company: exp.company,
      };
    });
    const newExperiences = [...existingExperiences, ...updatedExperiences];

    const experiences = sortBy(['endDate', 'startDate'], 'desc')(newExperiences);
    setValue(`experiences.${parseInt(tab)}.experiences`, experiences);
    const values = cloneDeep(getValues());
    actions.updateAction(values);
  };

  useEffect(() => {
    if (showIntro) {
      const timeoutId: any = setTimeout(() => {
        let stepsList: Step[] = [];

        if (!controlledFields[0]?.product) {
          stepsList.push({
            target: '.product-list-input',
            title: 'Products',
            content: 'What products do you specialize in?',
            placement: 'right',
            disableBeacon: true,
          });
        }
        setSteps(stepsList);
        if (stepsList.length) {
          setRun(true);
        }
      }, 100);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showIntro, controlledFields[0]?.product]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, type, index } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
    } else if (action === ACTIONS.CLOSE || action === ACTIONS.STOP) {
      setRun(false);
    } else if (type === EVENTS.STEP_AFTER) {
      setRun(false);
      setTimeout(() => {
        setRun(true);
      }, 500);
    }
  };

  return (
    <>
      <ReactJoyride
        continuous
        run={run}
        steps={steps}
        callback={handleJoyrideCallback}
        showProgress
        disableOverlay
        scrollToFirstStep={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          open: 'Open the dialog',
          skip: 'Skip',
        }}
        styles={deepmerge(
          {
            options: {
              backgroundColor: '#171717',
              arrowColor: '#171717',
              textColor: '#FFFFFF',
              primaryColor: '#FFCA28',
              zIndex: joyrideZIndex,
            },
          },
          customButtonStyles,
        )}
      />
      <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
        {!isModal && (
          <StyledH1Mobile component="div">
            <Typography data-test="consultant-register-header-2" component="h1" variant="h4" mb={2}>
              Share your work and project history to determine your seniority and hourly rate
            </Typography>
            <Typography component="p" mb={1}>
              Your work history impacts your pay and appeals to clients. This helps us match you
              with suitable opportunities and ensure fair compensation. Be specific, including:
            </Typography>
          </StyledH1Mobile>
        )}
        <ProductExperienseCopyModal
          modalConfig={showCopyProductModal}
          closeModal={onCloseCopyModal}
          onSave={onSaveCopyModal}
        />
        <Box
          ml={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            maxWidth: !isMobile ? '76%' : undefined,
            marginBottom: 5,
          }}
        >
          <Box style={{ flex: '0 0 50%', position: 'relative', paddingRight: '10px' }}>
            <Typography style={{ position: 'absolute', fontSize: !isMobile ? '18px' : '14px' }}>
              &bull;
            </Typography>
            <Typography style={{ paddingLeft: '10px', fontSize: !isMobile ? '18px' : '14px' }}>
              Roles and responsibilities
            </Typography>
          </Box>
          <Box style={{ flex: '0 0 50%', position: 'relative', paddingRight: '10px' }}>
            <Typography style={{ position: 'absolute', fontSize: !isMobile ? '18px' : '14px' }}>
              &bull;
            </Typography>
            <Typography style={{ paddingLeft: '10px', fontSize: !isMobile ? '18px' : '14px' }}>
              Industries and domains worked in
            </Typography>
          </Box>
          <Box style={{ flex: '0 0 50%', position: 'relative', paddingRight: '10px' }}>
            <Typography style={{ position: 'absolute', fontSize: !isMobile ? '18px' : '14px' }}>
              &bull;
            </Typography>
            <Typography style={{ paddingLeft: '10px', fontSize: !isMobile ? '18px' : '14px' }}>
              Technologies and tools used
            </Typography>
          </Box>
          <Box style={{ flex: '0 0 50%', position: 'relative', paddingRight: '10px' }}>
            <Typography style={{ position: 'absolute', fontSize: !isMobile ? '18px' : '14px' }}>
              &bull;
            </Typography>
            <Typography style={{ paddingLeft: '10px', fontSize: !isMobile ? '18px' : '14px' }}>
              Key projects and achievements
            </Typography>
          </Box>
        </Box>

        <TabContext value={tab}>
          <TabsList
            onAddProduct={addProduct}
            onHandleChange={handleChange}
            onRemoveProduct={removeProduct}
            tabsList={controlledFields}
            disabled={
              !isValid ||
              !isEqual(experiences, getValues().experiences) ||
              user.role.name === ERole.INFLUENCER
            }
            tab={tab}
          />
          {controlledFields?.map(({ product }, index) => (
            <StyledTabPanel
              className="product-list-input"
              key={product?.id || `new_product_${index}`}
              data-test="tab-experience"
              hidden={parseInt(tab) !== index}
            >
              {!product?.id && (
                <ProductSelect
                  control={control}
                  flatList={activeSort(categoriesWithProductsList)}
                  name={`experiences.${index}.product`}
                  selectedOptions={controlledFields}
                  handleChange={(product: IProduct) => {
                    setValue(`experiences.${index}.productId`, product.id);
                    if (controlledFields.length == 1 && index == 0) {
                      setValue(`experiences.${index}.experiences`, [
                        {
                          ...DEFAULT_EXPERIENCE,
                          productId: product.id,
                          company: index === 0 && consultant.agency,
                          companyId: index === 0 && consultant.agencyId,
                        },
                      ]);
                      setShowExpertiseForm(true);
                    } else {
                      setValue(`experiences.${index}.experiences`, []);
                      setShowExpertiseForm(false);
                    }
                  }}
                  showAddProduct={!isModal}
                  setJoyrideZIndex={setJoyrideZIndex}
                  bypassIsValid={bypassIsValid}
                  setBypassIsValid={setBypassIsValid}
                />
              )}
              {product?.id && (
                <ExperiencesContainer
                  product={product}
                  indexProduct={index}
                  userId={id || user?.id}
                  productCount={controlledFields.length}
                  onCopyExperience={onCopyExperience}
                  showExpertiseForm={showExpertiseForm}
                  isModal={isModal}
                  showIntro={showIntro}
                  setSteps={setSteps}
                  setRun={setRun}
                />
              )}
            </StyledTabPanel>
          ))}
        </TabContext>
      </form>
    </>
  );
};

export default ProductsExperienses;
