import Box, { BoxProps } from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {styled} from "@mui/material/styles";

interface IStyledBarWrap extends BoxProps {
  disabled?: boolean;
  isActive?: boolean;
}

const StyledBarWrap = styled(Box)<IStyledBarWrap>(({ disabled, theme }) => theme.unstable_sx({
  alignItems: 'center',
  flexWrap: 'nowrap',
  display: 'inline-flex',
  position: 'relative',
  mx: {
    lg: 2
  },
  ...(!disabled) && {
    justifyContent: 'center',
  },
  '& > div': {
    backgroundColor: '#FFFFFF',
    borderColor: '#D4D4D4',
  },
  '&:hover:has(div:hover) > div': {
    backgroundColor: disabled ? '#FFFFFF' : '#1F1F1F',
    borderColor: disabled ? '#D4D4D4' : '#1F1F1F',
  },
  '&:hover > div:hover ~ div': {
    backgroundColor: '#FFFFFF',
    borderColor: '#D4D4D4',
  },
  '&:hover:has(div:hover) > p': {
    display: disabled ? 'block' : 'none',
  },
}))
const StyledBarItem = styled('div')<IStyledBarWrap>(({ isActive, disabled, theme }) => theme.unstable_sx({
  background: isActive ? '#1F1F1F!important' : '#FFFFFF',
  border: '1px solid',
  borderColor: isActive ? '#1F1F1F!important' : '#D4D4D4',
  borderRadius: '100px',
  width: 27,
  height: 8,
  boxSizing: 'border-box',
  margin: '0 2px',
  '&:hover': {
    cursor: disabled ? 'default' : 'pointer',
  },
  '&:hover > div': {
    visibility: disabled ? 'hidden' : 'visible',
    opacity: disabled ? 0 : 1,
  },
}))

const StyledIconBtn = styled(IconButton)({
  position: 'absolute',
  right: -32
})

export { StyledBarItem, StyledBarWrap, StyledIconBtn }
