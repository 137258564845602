import React, {FunctionComponent} from "react";
import cn from 'classnames'
import {Dayjs, OpUnitType} from "dayjs";
import dayjs from "utils/dayjs";

import OrderCellPreview from "./OrderCellPreview";

import { StyledBodyCell } from "./styled";

interface IOrderRowPreviewProps {
  date: Dayjs;
  disabled: boolean;
  open: boolean;
  orders: any;
  type: string;
  unit: OpUnitType;
}

const OrderRowPreview: FunctionComponent<IOrderRowPreviewProps> = ({ date, disabled, open, orders, type, unit}) => (
  <StyledBodyCell
    className={cn(type, {
      'hidden': open,
      'currentDay': dayjs().isSame(date, unit),
      'disabled': disabled,
      'nonBottomBorder': open && disabled,
      'lastPeriod': date
        .endOf('month')
        .endOf('isoWeek')
        .isSame(date.endOf('isoWeek')),
    })}
    colSpan={1}
  >
    {orders.map((order: any) => (
      <OrderCellPreview key={order.id} order={order} date={date} unit={unit} />
    ))}
  </StyledBodyCell>
)

export default OrderRowPreview
