import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {ECommon} from "../../constants";

const fetchModulesWithWorks = createAsyncThunk(
  ECommon.productModulesWithWorks,
  async (productId: number) => {
    const { data: modules } = await instance.get(`products-modules/${productId}/modules`)
    const { data: works } = await instance.get(`products-works/${productId}/works`)
    return {modules, works};
  }
)

export default fetchModulesWithWorks
