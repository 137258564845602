import { createAsyncThunk} from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';
import { IBillingData } from "../../types/order";

const orderPayByHashCode = createAsyncThunk(
  EOrder.payOrderByHashCode,
  async ({ hashCode, payload }: { hashCode: string, payload: IBillingData }) => {
    const { data } = await instance.patch(`shared-links/${hashCode}/payment`, {...payload});
    return data
  }
)

export default orderPayByHashCode;
