import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EPreOrder } from '../../constants';
import { IBasicPreOrder } from "../../types/preOrder";

const confirmOpportunity = createAsyncThunk<IBasicPreOrder, {id: number}>(
  EPreOrder.confirmOpportunity,
  async ({id}) => {
    const { data } = await instance.post(`/enterprise/pre-orders/${id}/confirm`);

    return data;
  }
)

export default confirmOpportunity;
