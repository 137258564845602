import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EUser } from "../../constants";
import {IUserLanguages} from "../../types/language";

interface IUpdateUserLanguagesProps {
  languages: any;
  userId: string | number
}

const updateUserLanguages = createAsyncThunk<IUserLanguages[], IUpdateUserLanguagesProps>(
  EUser.updateUserLanguages,
  async ({ languages }) => {
    await instance.patch('consultant/languages', languages)

    /**
     *  TODO: Add response from Backend
     */
    return languages
  }
)

export default updateUserLanguages
