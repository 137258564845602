import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import { Capacitor } from '@capacitor/core';

const getMobilePlatform = Capacitor.getPlatform()

const StyledPaper = styled(Paper)(({ theme }) => theme.unstable_sx({
  ...(getMobilePlatform === 'android' && {
    position: 'fixed',
  }),
  bottom: 0,
  left: 0,
  right: 0,
  border: 0,
  zIndex: 1049,
  display: {
    xs: 'block',
    sm: 'none'
  },
}))

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => theme.unstable_sx({
  justifyContent: 'space-around',
  paddingTop: '0px !important'
}))

const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => theme.unstable_sx({
  px: 0,
  paddingTop: '7px'
}))

export { StyledBottomNavigation, StyledBottomNavigationAction, StyledPaper }
