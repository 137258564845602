import { useState, useEffect, useRef } from 'react';

const useVisibleElements = () => {
  const parentContainerRef = useRef<HTMLDivElement>(null);

  const [visibleChildElements, setVisibleChildElements] = useState<any[]>([]);
  const [hiddenChildElements, setHiddenChildElements] = useState<any[]>([]);

  useEffect(() => {
    const updateVisibleElements = () => {
      if (parentContainerRef.current) {
        const containerWidth = parentContainerRef?.current?.getBoundingClientRect().width - 55;
        const childElements = parentContainerRef?.current?.querySelectorAll('.child-element');

        let totalWidth = 0;
        const visibleChildren: any[] = [];
        const hiddenChildren: any[] = [];

        childElements.forEach((child: any) => {
          const childWidth = child.getBoundingClientRect().width + 6;
          if (totalWidth + childWidth <= containerWidth) {
            visibleChildren.push(child);
          } else {
            hiddenChildren.push(child);
          }
          totalWidth += childWidth;
        });

        setVisibleChildElements(visibleChildren);
        setHiddenChildElements(hiddenChildren);
      }
    };

    window.addEventListener('resize', updateVisibleElements);
    updateVisibleElements();

    return () => {
      window.removeEventListener('resize', updateVisibleElements);
    };
  }, []);

  return {
    hiddenChildElements,
    parentContainerRef,
    visibleChildElements
  }
};

export default useVisibleElements;
