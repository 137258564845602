import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import find from 'lodash/fp/find';

import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import PlusIcon from 'styles/icons/PlusIcon';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ERole, availabilityOptions, byAgencyRoles } from 'models/consts';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IAgencyUser } from 'store/types/agency';
import updateUsersTeamAvailability from 'store/query/agency/updateUsersTeamAvailability';

import TableCell from '../../TableCustomized/TableCell';
import TableRow from '../../TableCustomized/TableRow';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import Loader from 'components/Loader/Loader';
import { StatusChip, TableLabel } from '../../TableCustomized/styled';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { IBasicUser } from 'store/types/user';
import ActionsList from './ActionsList';
import ActionsMenu from './ActionsMenu';
import { theme } from './styles';
import TableActionCell from '../../TableCustomized/TableActionCell';

interface IAgencyTeamRowProps {
  row: IAgencyUser;
  index: number;
  onChangeStatus: (row: IAgencyUser, status: string) => void;
  onImpersonate: (id: number) => void;
}

const invited = (user: IAgencyUser) => {
  return !user.isActive && !user.lastChangeActiveAt;
};

const AgencyTeamRow: FunctionComponent<IAgencyTeamRowProps> = ({
  row,
  index,
  onImpersonate,
  onChangeStatus,
}) => {
  const dispatch = useAppDispatch();
  const isiPad = useMediaQuery(theme.breakpoints.between('sm', 'xxl'));

  const me = useAppSelector(getMeSelector) as IBasicUser;
  const { control } = useFormContext();
  const [availabilityIndex, setAvailabilityIndex] = React.useState<any>(null);
  const [impersonationIndex, setImpersonationIndex] = React.useState<any>(null);

  const handleChange = (value: any, userData: any, index: any) => {
    setAvailabilityIndex(index);
    const payloadData = [
      {
        availabilityWeek: parseInt(value),
        userId: userData.id,
      },
    ];
    dispatch(updateUsersTeamAvailability(payloadData))
      .unwrap()
      .then(() => {
        setAvailabilityIndex(null);
      });
  };

  const onImpersonateClick = (rowId: any, index: any) => {
    setImpersonationIndex(index);
    onImpersonate(rowId);
  };

  return (
    <TableRow data-test="team-member-row" key={row.email} sx={{ height: 64 }}>
      <TableCell data-test="name-cell">
        <TableLabel sx={{ width: { xs: 76, lg: 'auto' } }}>
          {row.role.name === ERole.AGENCY_ADMIN
            ? row?.agency?.name
            : `${row.firstName || ''} ${row.lastName || ''}`}
        </TableLabel>
      </TableCell>
      <TableCell data-test="email-cell">
        <TableLabel fontWeight={500} sx={{ width: { lg: 170 }, wordBreak: 'break-word' }}>
          {row.role.id === 2 && row.isVirtual ? '' : row.email}
        </TableLabel>
      </TableCell>
      <TableCell data-test="status-cell">
        <StatusChip
          className={invited(row) ? 'created' : (row.isActive && 'active') || 'terminated'}
          label={invited(row) ? 'Pending Invitation' : (row.isActive && 'Active') || 'Inactive'}
          variant="filled"
        />
      </TableCell>
      <TableCell data-test="role-cell">
        <TableLabel sx={{ fontSize: { xs: '0.6rem', lg: '0.865rem' } }}>
          {row.role.id === 2 && row.isVirtual
            ? 'Virtual Specialist'
            : (find({ id: row.role.id })(byAgencyRoles) as any)?.name}
        </TableLabel>
      </TableCell>
      <TableCell data-test="impersonate-cell">
        {row.role.name === ERole.CONSULTANT && row.isActive && (
          <MuiLink
            color="secondary"
            onClick={() => onImpersonateClick(row.id, index)}
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              stroke: 'none !important',
              flexDirection: 'column',
            }}
            underline="none"
          >
            {index !== impersonationIndex ? (
              <Box display="flex" flexDirection="column" alignItems={'center'}>
                <PlusIcon />
                <Typography
                  component="span"
                  lineHeight="normal"
                  ml={1}
                  sx={{ fontSize: { xs: '0.6rem', lg: '0.865rem' } }}
                >
                  Impersonate
                </Typography>
              </Box>
            ) : (
              <Box ml={4}>
                <Loader size="20px" />
              </Box>
            )}
          </MuiLink>
        )}
      </TableCell>
      <TableCell data-test="availability-cell">
        {row?.availability && row.role.name === ERole.CONSULTANT && (
          <Grid xs={12} md={3} sx={{ width: { xs: 77, lg: 83 } }}>
            <Controller
              control={control}
              name={`availability-${row.id}`}
              render={({ field }) => (
                <Box display="flex" justifyContent="center">
                  {index !== availabilityIndex ? (
                    <CustomAutocomplete
                      field={{
                        ...field,
                        value:
                          availabilityOptions.find(
                            option =>
                              option.name === row?.availability?.availabilityWeek?.toString(),
                          ) || null,
                      }}
                      // value={{id:1, name: '10'}}
                      options={availabilityOptions}
                      placeholder=""
                      isSortable
                      handleChange={value => handleChange(value.name, row, index)}
                      sx={{ '& .MuiAutocomplete-input': { fontSize: { xs: 14, lg: 18 } } }}
                    />
                  ) : (
                    <Loader size="20px" />
                  )}
                </Box>
              )}
            />
          </Grid>
        )}
      </TableCell>
      <TableActionCell data-test="action-cell">
        {isiPad && (
          <ActionsMenu user={row} onChangeStatus={(user, status) => onChangeStatus(user, status)} />
        )}
        {!isiPad && (
          <ActionsList user={row} onChangeStatus={(user, status) => onChangeStatus(user, status)} />
        )}
      </TableActionCell>
    </TableRow>
  );
};

export default AgencyTeamRow;
