import { createAsyncThunk} from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { EOrder } from '../../constants';

const fetchOrderByHashCode = createAsyncThunk(
  EOrder.getOrderByHashLink,
  async (hash_code: string) => {
    const { data } = await instance.get(`shared-links/${hash_code}`);
    return data
  }
)

export default fetchOrderByHashCode;
