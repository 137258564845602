import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 70 147">
    <path
      d="M65.5 140L8 106.752L8 40.2484L65.5 7.00002"
      stroke="#FFB300"
      strokeWidth="16"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
