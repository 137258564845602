import { ITags } from 'models';
import { generateUniqId } from 'utils/generateUniqId';

export const tagsFilter = (options: ITags[], inputValue: string) => {
  const trimmed = inputValue.trim();
  // Suggest the creation of a new value
  const isExisting = options.some((option) => inputValue === option.name);
  if (!!trimmed && !isExisting) {
    return [
      {
        inputValue: trimmed,
        name: trimmed,
        create: `Add "${trimmed}"`,
        id: generateUniqId(),
      },
      ...options,
    ];
  }
  return options;
};
