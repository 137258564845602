import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const StyledTypographyWithBorder = styled(Typography)(({ theme }) => ({
  marginTop: 20,
  marginBottom: 10,
  paddingBottom: theme.spacing(.5),
  fontSize: '1rem'
}))

const StyledLabel = styled(Typography)({
  flex: '1 0 90px',
  width: 90,
})
const StyledValueBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  backgroundColor: '#F9F9F9',
  borderRadius: 5,
  height: 45,
  paddingLeft: theme.spacing(2)
}))

export { StyledLabel, StyledTypographyWithBorder, StyledValueBox }
