import React, { FunctionComponent } from 'react';
import DialogContent from '@mui/material/DialogContent';

import { useAppSelector } from 'store/hooks';

import { IModalProps } from 'store/types/common';
import { IBasicOrders, IFullOrders } from 'store/types/order';
import { IBasicUser } from "store/types/user";

import { getMeSelector } from 'store/selectors/getUserSelector';

import ModalWrapper from 'components/Modal/Modal';
import OrderForm from 'views/ConsultantOrderCreation/OrderForm';

interface IEditModal extends IModalProps {
  order: IFullOrders | IBasicOrders;
}

const EditModal: FunctionComponent<IEditModal> = ({ visible, close, order }) => {
  const me = useAppSelector(getMeSelector) as IBasicUser;

  const onClose = () => close();

  return (
    <ModalWrapper
      dialogProps={{
        open: visible,
        onClose,
      }}
      title={{title: order.name}}
    >
      <DialogContent>
        <OrderForm
          consultant={me}
          isModal
          order={order}
          onSubmitForm={onClose}
        />
      </DialogContent>
    </ModalWrapper>
  );
};

export default EditModal;
