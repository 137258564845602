import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EAgency } from 'store/constants';

const addAgencyStripePayment = createAsyncThunk(
  EAgency.addAgencyStripePayment,
  async (payload: { refreshUri: string, returnUri: string }) => {
    const response = await instance.post('agency/payment/stripe', {...payload});
    return await response.data;
  }
);

export default addAgencyStripePayment;
