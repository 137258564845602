import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { ESearchTalent } from 'store/constants/talent-search';
import { ISearchDataDto } from 'store/types/inner-models';

const searchTalent = createAsyncThunk<any, any>(
  ESearchTalent.searchTalent,
  async (payload) => {
    const { data } = await instance.post('consultant/talent-search', payload);

    return data;
  }
);

export default searchTalent;
