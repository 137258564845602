import React, { useEffect, useRef, useState } from 'react';

import { StyledAgreementContainer, StyledScrollInnerContainer } from './styled';
import Loader from 'components/Loader/Loader';
import { Box } from '@mui/material';

const ConsultantAgreementBlock = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        try {
          // OnLoad is not working on Mobile apps, try accessing the contentDocument to see if the iframe has loaded
          const doc = iframe.contentWindow.document;
          if (doc.readyState === 'complete') {
            setLoading(false);
            clearInterval(intervalId);
          }
        } catch (e) {
          setLoading(false);
          clearInterval(intervalId);
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <StyledAgreementContainer>
      <StyledScrollInnerContainer>
        {loading && (
          <Box
            id="spinner"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}
          >
            <Box>
              <Loader />
            </Box>
          </Box>
        )}
        <iframe
          ref={iframeRef}
          src="https://myworkerbee.com/specialist-agreement/"
          loading="lazy"
          width="100%"
          height="100%"
          style={{ border: 'none', padding: 0 }}
        />
      </StyledScrollInnerContainer>
    </StyledAgreementContainer>
  );
};

export default ConsultantAgreementBlock;
