import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EConsultantTags } from 'store/constants';

const searchConsultantTags = createAsyncThunk(
  EConsultantTags.searchTags,
  async (tag: string) => {
    const response = await instance.get(`tags/search?search=${tag}`)
    return await response.data
  }
);

export default searchConsultantTags;
