import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const TypographyEmail = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
);

export default TypographyEmail;
