import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Box)(({ theme}) => theme.unstable_sx({
  mx: {
    xs: -2,
    sm: -3
  },
  my: 2,
  borderTop: '1px solid #ebebeb'
}))


export default StyledContainer
