import { Manager, Socket } from 'socket.io-client';
import { Cookies } from 'react-cookie';

import { ChangeOrderNotificationList, ELocalStoreKeys } from 'models/consts';
import { setNewNotification, setWebsocketId } from 'store/reducers/notification';
import store from '../store';
import config from '../../app.config';
import { updateOrderById } from 'store/reducers/order';
import { parseNotification } from 'utils/parseNotification';
import { IBasicOrders } from 'store/types/order';

export let socketInstance: Socket;

const cookies = new Cookies(null, { path: '/', sameSite: 'lax' });

export const connectSocket: any = () => {
  const token = cookies.get(ELocalStoreKeys.ACCESS_TOKEN);
  const impToken = cookies.get(ELocalStoreKeys.IMPERSONATE_TOKEN);

  const manager = new Manager(`${config.REACT_APP_API_URL}`, {
    transports: ['websocket'],
  });

  const socketNotification = manager.socket('/socket-notification', {
    auth: {
      token: impToken ? impToken : token,
    },
  });

  socketInstance = socketNotification;

  socketNotification.on('connect', () => {
    store.dispatch(setWebsocketId(socketNotification));
  });

  socketNotification.on('disconnect', () => {
    console.info('Socket disconnect');
  });

  socketNotification.on('error', (data: any) => {
    console.info(data);
  });

  socketNotification.on('notification', (data: any) => {
    store.dispatch(setNewNotification(data));
    if (ChangeOrderNotificationList.includes(data.notificationType)) {
      const notificationData = parseNotification(data.notification);
      const order = notificationData.order as IBasicOrders;
      store.dispatch(
        updateOrderById({
          order,
        }),
      );
    }
  });
};
