import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const LinkedinIcon: FunctionComponent<ISvgProps> = ({className}) => {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10Z" fill="black"/>
      <path d="M7.25 15H5.125V8.3125H7.25V15ZM6.1875 7.375C5.5 7.375 5 6.875 5 6.1875C5 5.5 5.5625 5 6.1875 5C6.875 5 7.375 5.5 7.375 6.1875C7.375 6.875 6.875 7.375 6.1875 7.375ZM15 15H12.875V11.375C12.875 10.3125 12.4375 10 11.8125 10C11.1875 10 10.5625 10.5 10.5625 11.4375V15H8.4375V8.3125H10.4375V9.25C10.625 8.8125 11.375 8.125 12.4375 8.125C13.625 8.125 14.875 8.8125 14.875 10.875V15H15Z" fill="white"/>
    </svg>
  );
}