import React, { FunctionComponent, ReactNode } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

import { StyledHeaderWrap } from './styled';

interface IHeaderFlow {
  id?: string;
  dataTest?: string;
  disableBtn?: boolean;
  formName?: string;
  loading?: boolean;
  btn?: string;
  title: ReactNode | string;
  subTitle?: ReactNode | string;
}

const HeaderFlow: FunctionComponent<IHeaderFlow> = ({
  id,
  dataTest,
  disableBtn,
  formName,
  loading,
  btn,
  title,
  subTitle,
}) => (
  <StyledHeaderWrap container maxWidth={'auto'}>
    <Grid xs={12} justifyContent="space-between" display="flex">
      <Box sx={{width: '100%'}}>
        {title && (
          <Typography id={id} data-test={dataTest} variant="h1" sx={{ mb: { xs: 2, sm: 0, lg: 2.5 } }}>
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography component="div" mb={5} sx={{ fontSize: '1.125rem' }}>
            {subTitle}
          </Typography>
        )}
      </Box>
      <Box display={{ xs: 'none', md: 'flex' }}>
        {btn && (
          <LoadingButton
            data-test="header-flow-button"
            loading={loading}
            form={formName || 'hook-form'}
            type="submit"
            disabled={disableBtn}
            variant="contained"
          >
            {btn}
          </LoadingButton>
        )}
      </Box>
    </Grid>
  </StyledHeaderWrap>
);

export default HeaderFlow;
