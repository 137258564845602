import React, { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

import dayjs from 'utils/dayjs';

import ROUTES from 'router/constants';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import changeOrderStatus from 'store/query/order/changeOrderStatus';
import updateOrderTimeline from 'store/query/order/updateOrderTimeline';
import {
  newMatchingScoreSelector,
  timeLineOrderSelector,
} from 'store/selectors/getOrderCreationSelector';
import { currentOrderSelector } from 'store/selectors/getOrderSelector';
import { getMeRoleSelector, isEnterpriseCustomerSelector } from 'store/selectors/getUserSelector';

import Loader from 'components/Loader/Loader';
import './style.scss';
import { ITimeLine } from '../../../../store/types/common';

const MatchingModal: FunctionComponent<any> = ({ close }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id = '' } = useParams();

  const role = useAppSelector(getMeRoleSelector);
  const order = useAppSelector(currentOrderSelector(id));
  // const timeLine = useAppSelector(currentOrderTimelineSelector(id)) as ITimeLines;
  const timeLine = useAppSelector(timeLineOrderSelector) as ITimeLine;

  const matchingScore = useAppSelector(newMatchingScoreSelector);
  const enterpriseCustomer = useAppSelector(isEnterpriseCustomerSelector);

  const approveChangeTimeline = () => {
    if (order && matchingScore !== undefined) {
      dispatch(
        updateOrderTimeline({
          timeline: {
            ...timeLine,
            id: order.timeLineId,
            paymentSuccessRedirectUri: `${ROUTES.ORDER}/${order.id}${order.purchaseOrderId ? '/enterprise' : ''}`,
          },
          orderId: order.id,
          matching: matchingScore,
          isPurchase: !!order.purchaseOrder,
        }),
      )
        .unwrap()
        .then(response => {
          if (
            response[0]?.payments &&
            response[0].payments[0] &&
            response[0].payments[0].sharedLink
          ) {
            navigate(`${ROUTES.PAYMENT}/${response[0].payments[0].sharedLink?.hashCode}`);
          }
        });
      // dispatch(resetOrderCreation());
      close();
    }
  };

  const cancelOrder = () => {
    if (order) {
      dispatch(
        changeOrderStatus({
          id: order?.id,
          status: 'canceled',
          role,
        }),
      );
      // dispatch(setOrderId(null));
      close();
      navigate(`${ROUTES.ORDER_CREATION}/1`);
    }
  };

  const cancelChange = () => {
    // dispatch(resetOrderCreation());
    close();
  };

  return (
    <>
      <DialogContent>
        <div id="new-matching">
          {matchingScore !== undefined ? (
            <>
              {matchingScore > 60 &&
                order?.workerMatching &&
                order?.workerMatching <= matchingScore && (
                  <Typography fontSize="1rem">
                    Changes in the timeline don&apos;t affect the consultant&apos;s matching score.
                    The updated matching score is {matchingScore}%. Please, click the &apos;Update
                    timeline&apos; button to accept changes.
                  </Typography>
                )}
              {matchingScore > 60 &&
                (!order?.workerMatching || order?.workerMatching > matchingScore) && (
                  <>
                    <Typography fontSize="1rem">
                      Changes in timeline decreased consultant&apos;s matching score. The updated
                      matching score is {matchingScore}%. Please, see below a new consultant&apos;s
                      availability
                    </Typography>
                    <Box display="flex" justifyContent="space-between" className="total-stat">
                      <Box
                        sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}
                        className="hours-stat"
                      >
                        <div className="bold">Initial availability</div>
                        <div>Total hours - {order?.timeLine.allHours}</div>
                        <div>
                          Weekly capacity - {order?.timeLine.availabilityWeek || 'flexible'}
                        </div>
                        <div>
                          Start date - {dayjs(order?.timeLine.startDate).format('DD MMM, YYYY')}
                        </div>
                        <div>
                          End date - {dayjs(order?.timeLine.endDate).format('DD MMM, YYYY')}
                        </div>
                      </Box>
                      <Box
                        sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}
                        className="hours-stat"
                      >
                        <div className="bold">Updated availability</div>
                        <div>Total hours - {timeLine.allHours}</div>
                        <div>Weekly capacity - {timeLine.availabilityWeek || 'flexible'}</div>
                        <div>Start date - {dayjs(timeLine.startDate).format('DD MMM, YYYY')}</div>
                        <div>End date - {dayjs(timeLine.endDate).format('DD MMM, YYYY')}</div>
                      </Box>
                    </Box>
                  </>
                )}
              {matchingScore < 60 && (
                <Typography fontSize="1rem">
                  Unfortunately, a consultant doesn&apos;t match with the updated timeline. Please,
                  find new consultant
                </Typography>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {!!matchingScore &&
          matchingScore > 60 &&
          order?.workerMatching &&
          order?.workerMatching <= matchingScore && (
            <>
              <Button color="secondary" fullWidth variant="outlined" onClick={cancelChange}>
                Cancel changes
              </Button>
              <Button
                color="secondary"
                fullWidth
                variant="contained"
                onClick={approveChangeTimeline}
              >
                Update timeline
              </Button>
            </>
          )}
        {!!matchingScore &&
          matchingScore > 60 &&
          (!order?.workerMatching || order?.workerMatching > matchingScore) && (
            <>
              {/*<Button color="secondary" fullWidth variant="outlined" onClick={cancelChange}>Cancel changes</Button>*/}
              <Button color="secondary" fullWidth variant="outlined" onClick={cancelOrder}>
                Find new consultant
              </Button>
              <Button
                color="secondary"
                fullWidth
                variant="contained"
                onClick={approveChangeTimeline}
              >
                Update timeline
              </Button>
            </>
          )}
        {matchingScore !== undefined && matchingScore < 60 && (
          <>
            <Button color="secondary" fullWidth variant="outlined" onClick={cancelChange}>
              Cancel changes
            </Button>
            <Button color="secondary" fullWidth variant="contained" onClick={cancelOrder}>
              Find new consultant
            </Button>
          </>
        )}
      </DialogActions>
    </>
  );
};

export default MatchingModal;
