import React, { FunctionComponent } from 'react';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { EOrderFilter } from "models/consts";
import { useAppSelector } from 'store/hooks';
import { subTabsForLeadManager } from "components/Order/OrderList/tabOrder";

import { getMeRoleSelector } from "store/selectors/getUserSelector";

import { ToggleOrders } from "components/Order/OrderList";
import GroupedListPreOrder from "components/Order/GroupedList/GroupedListPreOrder";
import ROUTES from 'router/constants';

const PreOrderList: FunctionComponent = () => {
  const [filter, setFilter] = React.useState<EOrderFilter>(EOrderFilter.NEED_ACTION);

  const role = useAppSelector(getMeRoleSelector)

  const handleChange = (event: any, newFilter: any) => {
    if(newFilter !== null) {
      setFilter(newFilter);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={{xs: 2.5, md: 7}}>
        <Typography variant="h1">Manage Leads</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <ToggleOrders value={filter} handleChange={handleChange} userRole={role} />
      </Box>
      {(subTabsForLeadManager[filter]?.map((tab: any) => (
        <GroupedListPreOrder
          key={tab.key}
          subType={tab}
          filter={filter}
          role={role}
          route={ROUTES.AGENCY_PREORDER}
        />
      )))}
    </>
  );
};

export default PreOrderList;
