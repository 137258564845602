import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { ENotification } from 'store/constants';
import { connectSocket } from '../../middlewares/socket';

const initNotifications = createAsyncThunk(ENotification.init, async () => {
  const { data } = await instance.get(`notification?limit=10`);

  connectSocket();
  return data;
});

export default initNotifications;
