import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';

import { EChat } from '../../constants';
import { ERole } from '../../../models/consts';

import { IRole } from '../../types/common';
import { IChatResponse } from '../../types/chat';

interface IParamsProps {
  limit: number;
  offset: number;
  role: IRole;
}

const fetchChatList = createAsyncThunk<IChatResponse[], IParamsProps>(
  EChat.getList,
  async ({ role, ...params }) => {
    let url = '/chats';

    if ([ERole.AGENCY_ADMIN, ERole.RESOURCE_MANAGER].includes(role.name)) {
      url = '/agency' + url;
    } else if ([ERole.CUSTOMER_ADMIN, ERole.CUSTOMER].includes(role.name)) {
      url = '/enterprise/companies' + url;
    }

    const { data } = await instance.get(url, { params });
    return data;
  },
);

export default fetchChatList;
