import React, { FunctionComponent } from 'react';

export const HexagonStep: FunctionComponent<any> = ({
  className,
  state,
}) => {
  return (
    <svg className={className} viewBox="0 0 45 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      { state === 'prev' &&
        <>
          <path
            d="M22.5 50.8451L0.999997 38.4235L1 13.5765L22.5 1.15491L44 13.5765L44 38.4235L22.5 50.8451Z"
            fill="#FFC107"
            stroke="#FFC107"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path d="M22.5 50.8451L0.999997 38.4235L1 13.5765L22.5 1.15491L44 13.5765L44 38.4235L22.5 50.8451Z" stroke="#FFC107" strokeWidth="2" strokeMiterlimit="10"/>
          <path d="M21.4125 32.475C21.2625 32.475 21.1208 32.45 20.9875 32.4C20.8542 32.35 20.7292 32.2667 20.6125 32.15L16.3125 27.85C16.0958 27.6333 15.9918 27.3623 16.0005 27.037C16.0085 26.7123 16.1208 26.4417 16.3375 26.225C16.5542 26.0083 16.8252 25.9 17.1505 25.9C17.4752 25.9 17.7375 26.0083 17.9375 26.225L21.4125 29.7L29.7875 21.325C29.9875 21.1083 30.2542 21 30.5875 21C30.9208 21 31.1958 21.1083 31.4125 21.325C31.6125 21.5417 31.7125 21.8123 31.7125 22.137C31.7125 22.4623 31.6125 22.7333 31.4125 22.95L22.2125 32.15C22.0958 32.2667 21.9708 32.35 21.8375 32.4C21.7042 32.45 21.5625 32.475 21.4125 32.475Z" fill="#1F1F1F"/>
        </>
      }
      { (state === 'next' || state === 'active') &&
        <>
          <path
            d="M22.5 50.8451L0.999997 38.4235L1 13.5765L22.5 1.15491L44 13.5765L44 38.4235L22.5 50.8451Z"
            fill={'transparent'}
            stroke="#FFC107"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M5 16.4995L5 35.5005L22.5 45L40 35.5005L40 16.4995L22.5 7L5 16.4995Z"
            fill={state === 'active' ? '#FFC107' : 'transparent'}
          />
        </>
      }
    </svg>
  );
}
