import React, { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input/input';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import { getPhoneNumber } from 'utils/transformers';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import updateAgency from 'store/query/agency/updateAgency';
import { getMeAgencySelector } from 'store/selectors/getAgencySelector';
import { getMeSelector } from 'store/selectors/getUserSelector';

import UserEmail from '../UserEmail/UserEmail';
import { PhoneNumber } from 'components/CustomFields';

import { IMobilePhone } from 'store/types/inner-models';
import { ERole } from 'models/consts';

const AdminSettings: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const agency = useAppSelector(getMeAgencySelector);
  const user = useAppSelector(getMeSelector);

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, errors },
    reset,
    watch,
  } = useForm<{ mobilePhone: IMobilePhone }>({
    mode: 'onChange',
    defaultValues: {
      mobilePhone: {
        country: parsePhoneNumber(agency?.mobilePhone || '')?.country || 'US',
        countryCallingCode: parsePhoneNumber(agency?.mobilePhone || '')?.countryCallingCode,
        // country: agency.countryIso ? agency.countryIso : 'US',
        nationalNumber: agency.mobilePhone
          ? getPhoneNumber(agency.mobilePhone, agency.countryIso)
          : '',
      },
    },
  });

  const mobilePhone = watch('mobilePhone');

  const onSubmit: SubmitHandler<any> = ({ mobilePhone }: any) => {
    dispatch(
      updateAgency({
        mobilePhone: [
          '+',
          getCountryCallingCode(mobilePhone.country),
          mobilePhone.nationalNumber,
        ].join(''),
      }),
    )
      .unwrap()
      .then(() =>
        reset({
          mobilePhone: {
            country: mobilePhone.country,
            countryCallingCode: mobilePhone.countryCallingCode,
            nationalNumber: mobilePhone.nationalNumber,
          },
        }),
      );
  };

  return (
    <>
      <Typography mt={{ xs: 3.5, md: 7 }} variant="h1" data-test="contact-page">
        Contact details
      </Typography>
      <Divider sx={{ mb: 3, mt: 1 }} />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container md={6} spacing={2}>
          <Grid xs={12}>
            <UserEmail visible />
          </Grid>
          <Grid xs={12}>
            <FormGroup sx={{ mb: 4 }}>
              <InputLabel>Phone number</InputLabel>
              <PhoneNumber
                control={control}
                name="mobilePhone"
                defaultValue={mobilePhone}
                rules={{
                  required: 'This field is required',
                }}
                error={!!errors.mobilePhone?.nationalNumber || !!errors.mobilePhone?.country}
                errorText={
                  (errors.mobilePhone?.nationalNumber as any)?.message ||
                  (errors.mobilePhone?.country as any)?.message
                }
                disabled={user?.role.name === ERole.RESOURCE_MANAGER}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent={{ xs: 'center', md: 'start' }}>
          <Button
            disabled={!isValid || !isDirty || user?.role.name === ERole.RESOURCE_MANAGER}
            variant="contained"
            type="submit"
          >
            Save updates
          </Button>
        </Box>
      </form>
    </>
  );
};

export default AdminSettings;
