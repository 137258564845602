import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { Link, useNavigate } from 'react-router-dom';

import Autocomplete from "@mui/material/Autocomplete";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingButton from '@mui/lab/LoadingButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import LinkIcon from 'styles/icons/LinkIcon';
import CalendarIcon from 'styles/icons/CalendarIcon';

import { setDate } from 'utils/orderCreationHelpers';
import dayjs from 'utils/dayjs';
import { isFriday, isMonday  } from 'utils/dateFormatter';
import { candidateAgenciesNormalize } from 'utils/candidateAgenciesNormalize';

import { BILLING_COMPANY, EMAIL_REGEX, ESeniority, TIMELINE_HOURS_NEEDED } from 'models/consts';
import { TAXES } from 'store/constants';
import ROUTES from 'router/constants';

import { IBasicPreOrder } from 'store/types/preOrder';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { categoryProductsSelector } from 'store/selectors/getCommonSelector';
import editPreorder from 'store/query/order/editPreorder';
import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';

import DateTextInput from 'components/CustomFields/DateTextInput';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import { BootstrapInput } from 'components/CustomFields';
import AgenciesTable from './AgenciesTable';
import ProductsModulesWork from "components/CustomFields/ProductsModulesWork";

export interface IReviewPreorderForm {
  order: IBasicPreOrder;
  role: any;
  onSubmitForm?: () => void;
}

const ReviewPreorderForm: React.FunctionComponent<IReviewPreorderForm> = ({ order, onSubmitForm, role }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);

  const defaultCustomerEmail = order ? order.customerEmail : '';
  const defaultAllHours = order ? order.timeLine?.allHours : 20;
  const defaultCustomerReadinessDate = order ? order.customerReadinessDate : dayjs().format('YYYY-MM-DD');
  const defaultRequestCompletionDate = order ? order.requestCompletionDate : dayjs().format('YYYY-MM-DD');

  const defaultAgencies = candidateAgenciesNormalize(order.candidateAgencies);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      product: order ? order.expertise?.product : undefined,
      modules: order ? order.expertise?.modules : [],
      works: order ? order.expertise?.works : [],
      primaryModule: order
        ? order.expertise?.modules.find((item) => item.PreOrdersExpertiseModules.isPrimary)?.id
        : undefined,
      primaryWorkType: order
        ? order.expertise?.works.find((item) => item.PreOrdersExpertiseWorks.isPrimary)?.id
        : undefined,
      allHours: defaultAllHours,
      customerReadinessDate: defaultCustomerReadinessDate,
      requestCompletionDate: defaultRequestCompletionDate,
      levelId: order
        ? order.customPrice
          ? ESeniority.CUSTOM
          : order.levelId
        : undefined,
      customerEmail: defaultCustomerEmail,
      leadOwnerEmail: order.leadManager?.email,
      billingType: !!order.billingType ? BILLING_COMPANY.find((item) => item.value === order.billingType) : undefined,
      description: order.description,
      agencies: defaultAgencies,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    watch,
    setValue,
  } = methods

  const totalTime = watch('allHours');
  const customerReadinessDate = watch('customerReadinessDate');
  const requestCompletionDate = watch('requestCompletionDate');
  const billingType = watch('billingType');
  const agencies = watch('agencies');

  useEffect(() => {
    if(!categoriesWithProductsList?.length) {
      dispatch(fetchProductsWithModulesWorks())
    }
  }, [categoriesWithProductsList?.length, dispatch]);

  const onSubmit = async (data: any) => {
    const candidateAgencies: any = [];
    if (data.agencies) {
      for (const key in data.agencies) {
        candidateAgencies.push({
          preOrderId: data.agencies[key].preOrderId,
          agencyId: data.agencies[key].agencyId,
          rank: parseInt(key),
          status: data.agencies[key].status,
          priceCents: parseInt(data.agencies[key].priceCents) * 100,
        });
      }
    }
    const preorder = {
      id: order.id,
      taxes: TAXES,
      levelId: order.levelId,
      description: data.description,
      billingType: data.billingType.value,
      customerReadinessDate: data.customerReadinessDate,
      requestCompletionDate: data.requestCompletionDate,
      timeLine: {
        ...order.timeLine,
        allHours: data.allHours,
      },
      expertise: {
        ...order.expertise,
        productId: data.product.id,
        modules: data.modules.map((item: any) => ({
          moduleId: item.id,
          isPrimary: data.primaryModule === item.id,
        })),
        works: data.works.map((item: any) => ({
          workId: item.id,
          isPrimary: data.primaryWorkType === item.id,
        })),
      },
      candidateAgencies,
    };
    dispatch(editPreorder({
      preorder,
      role: role,
    }))
      .unwrap()
      .then((response) => {
        if (!!response.length) {
          onSubmitForm && onSubmitForm();
        }
      });
  };

  const handleSaveClose = async (data: any) => {
    await onSubmit(data);
    navigate(`${ROUTES.PRE_ORDER}/${order.id}`);
  };

  return (
    <FormProvider {...methods}>
      <form id="review-preorder-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{mb: 3}}>
          <Grid xs={12} md={6}>
            <Typography component="div" variant="h4" sx={{mb: 3}}>Service Request</Typography>

            <Box display="flex" sx={{mb: 1, width: '100%'}}>
              <Typography
                component="div"
                variant="body1"
                flexGrow="0"
                sx={{
                  width: '110px',
                  flexBasis: '110px',
                  paddingRight: '10px',
                }}
              >SR</Typography>
              <Typography component="div" variant="body1" flexGrow="1">
                <Link
                  to={``}
                  style={{
                    textDecoration: 'none',
                    color: '#5D5D5E',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography sx={{mr: 1}}># {order.lead.reference}</Typography>
                  <LinkIcon
                    viewBox="0 0 18 18"
                    sx={{fill: "transparent", width: 18, height: 18}}
                  />
                </Link>
              </Typography>
            </Box>

            <Box display="flex" sx={{width: '100%'}}>
              <Typography
                component="div"
                variant="body1"
                flexGrow="0"
                sx={{
                  width: '110px',
                  flexBasis: '110px',
                  paddingRight: '10px',
                }}
              >Description</Typography>
              <Box flexGrow="1">
                <FormGroup>
                  <FormControl>
                    <Controller
                      control={control}
                      name="description"
                      render={({field: {ref, ...field}}) => (
                        <BootstrapInput
                          {...field}
                          type="text"
                          inputRef={ref}
                          placeholder="Description"
                          onChange={(event: any) => {
                            field.onChange(event);
                          }}
                          multiline
                          rows={4}
                        />
                      )}
                    />
                  </FormControl>
                </FormGroup>
              </Box>
            </Box>

            <Box display="flex" sx={{width: '100%'}}>
              <Typography
                component="div"
                variant="body1"
                flexGrow="0"
                sx={{
                  width: '110px',
                  flexBasis: '110px',
                  paddingRight: '10px',
                }}
              >Customer Readiness Date</Typography>
              <Box flexGrow="1">
                <FormGroup>
                  <FormControl>
                    <Controller
                      name="customerReadinessDate"
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                      render={({field}) => (
                        <DatePicker
                          selected={!!customerReadinessDate ? dayjs(customerReadinessDate).toDate() : null}
                          onChange={(date: Date) => {
                            setDate(date, field);
                          }}
                          calendarStartDay={1}
                          customInput={
                            <DateTextInput
                              className="text-custom-input"
                              onReset={() => setDate(null, field)}
                              icon={<CalendarIcon/>}
                              dataTest="customerReadinessDate"
                            />
                          }
                          isClearable
                          showPopperArrow={false}
                          // filterDate={isMonday}
                          minDate={dayjs().toDate()}
                          placeholderText="MM/DD/YYYY"
                          withPortal={xs}
                          dayClassName={(date) => isMonday(date) && 'available-start-date' || null}
                        />
                      )}
                    />
                  </FormControl>
                </FormGroup>
                {!!errors.customerReadinessDate && (
                  <Typography color="error" mt={-2.5} sx={{position: "relative"}}>
                    {(errors.customerReadinessDate as any)?.message || 'Incorrect data'}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box display="flex" sx={{width: '100%'}}>
              <Typography
                component="div"
                variant="body1"
                flexGrow="0"
                sx={{
                  width: '110px',
                  flexBasis: '110px',
                  paddingRight: '10px',
                }}
              >Requesting Completion Date</Typography>
              <Box flexGrow="1">
                <FormGroup>
                  <FormControl>
                    <Controller
                      name="requestCompletionDate"
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                      render={({field}) => (
                        <DatePicker
                          selected={!!requestCompletionDate ? dayjs(requestCompletionDate).toDate() : null}
                          onChange={(date: Date) => {
                            setDate(date, field);
                          }}
                          calendarStartDay={1}
                          customInput={
                            <DateTextInput
                              className="text-custom-input"
                              onReset={() => setDate(null, field)}
                              icon={<CalendarIcon/>}
                              dataTest="requestCompletionDate"
                            />
                          }
                          showPopperArrow={false}
                          minDate={dayjs().toDate()}
                          // filterDate={isFriday}
                          isClearable
                          placeholderText="MM/DD/YYYY"
                          withPortal={xs}
                          dayClassName={(date) => isFriday(date) && 'available-end-date' || null}
                        />
                      )}
                    />
                  </FormControl>
                </FormGroup>
                {!!errors.requestCompletionDate && (
                  <Typography color="error" mt={-2.5} sx={{position: "relative"}}>
                    {(errors.requestCompletionDate as any)?.message || 'Incorrect data'}
                  </Typography>
                )}
              </Box>
            </Box>

          </Grid>

          <Grid xs={12} md={6}>
            <Typography component="div" variant="h4" sx={{mb: 3}}>Contact Info</Typography>

            <FormGroup sx={{pb: 3, mb: 0}}>
              <InputLabel>Customer Email</InputLabel>
              <FormControl>
                <Controller
                  control={control}
                  name="customerEmail"
                  render={({field: {ref, ...field}}) => (
                    <BootstrapInput
                      {...field}
                      type="email"
                      inputRef={ref}
                      placeholder="Enter Customer Email"
                      disabled
                      onChange={(event: any) => {
                        field.onChange(event);
                      }}
                    />
                  )}
                  rules={{
                    required: 'This field is required',
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'Invalid email'
                    }
                  }}
                />
              </FormControl>
            </FormGroup>

            <FormGroup sx={{pb: 3, mb: 0}}>
              <InputLabel>Lead Owner Email</InputLabel>
              <FormControl>
                <Controller
                  control={control}
                  name="leadOwnerEmail"
                  render={({field: {ref, ...field}}) => (
                    <BootstrapInput
                      {...field}
                      type="email"
                      inputRef={ref}
                      placeholder="Enter Customer Email"
                      disabled
                      onChange={(event: any) => {
                        field.onChange(event);
                      }}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'Invalid email'
                    }
                  }}
                />
              </FormControl>
            </FormGroup>

          </Grid>
        </Grid>

        <Typography component="div" variant="h4">Scope</Typography>
        <Typography component="div" variant="body1">
          Please define the expertise, number of hours and type of billing
        </Typography>

        <ProductsModulesWork disabledProductEdit />

        <Grid container spacing={2} sx={{mb: 3}}>
          <Grid xs={12} md={6} lg={4}>
            <FormGroup>
              <InputLabel>Total hours</InputLabel>
              <FormControl>
                <Controller
                  control={control}
                  name="allHours"
                  render={({field: {ref, ...field}}) => (
                    <Autocomplete
                      {...field}
                      filterOptions={(options) => options}
                      freeSolo
                      getOptionLabel={(option) => String(option)}
                      onChange={(_, value) => {
                        field.onChange(value);
                      }}
                      options={TIMELINE_HOURS_NEEDED}
                      renderInput={({InputLabelProps, inputProps: {onChange, ...inputProps}, ...params}) => (
                        <BootstrapInput
                          {...params}
                          inputProps={inputProps}
                          label="Select"
                          value={totalTime}
                          onChange={(event: any) => {
                            const value = event.target.value;
                            const isValidInput = /^[1-9]\d*$/.test(value);
                            if (isValidInput || value === '') {
                              field.onChange(value);
                            }
                          }}
                        />
                      )}
                    />
                  )}
                  rules={{required: 'This field is required'}}
                />
              </FormControl>
              {!!errors?.allHours && (
                <FormHelperText error sx={{position: 'relative', marginTop: -2}}>
                  {(errors?.allHours as any).message || 'Incorrect data'}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <FormGroup>
              <InputLabel>Billing Company</InputLabel>
              <FormControl>
                <Controller
                  control={control}
                  name="billingType"
                  render={({field}) => (
                    <CustomAutocomplete
                      autocompleteProps={{
                        multiple: undefined,
                      }}
                      inputProps={{
                        readOnly: true,
                        error: !!errors.billingType,
                        helperText: (errors.billingType as any)?.message || 'Incorrect data'
                      }}
                      field={field}
                      options={BILLING_COMPANY}
                      placeholder="Select a billing company"
                    />)}
                  rules={{required: 'This field is required'}}
                />
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>

        <Typography component="div" variant="h4">Available Agencies</Typography>
        <Typography component="div" variant="body1" mb={2}>
          Sort and review the list of agencies that can do this work
        </Typography>
        <Grid container spacing={2}>
          <Grid xs={12} md={6}>
            <AgenciesTable billingType={billingType?.value} agencies={agencies} setValue={setValue} />
          </Grid>
        </Grid>

        <Grid container xs={12} spacing={2} justifyContent="center">
          <Grid xs={12} md="auto">
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              form="review-preorder-form"
              type="submit"
              fullWidth
              disabled={!isValid || isSubmitting}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Grid>
          <Grid xs={12} md="auto">
            <LoadingButton
              color="secondary"
              loading={isSubmitting}
              form="review-preorder-form"
              type="button"
              fullWidth
              disabled={!isValid || isSubmitting}
              variant="contained"
              onClick={handleSubmit(handleSaveClose)}
            >
              Save & Close
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ReviewPreorderForm;
