import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EConsultant } from "../../constants";
import { IConsultantAvailabilities } from "../../types/consultant";

interface IUpdateAvailabilityProps {
  availabilities: IConsultantAvailabilities[];
  userId: number | string;
}

const updateAvailability = createAsyncThunk<IConsultantAvailabilities[], IUpdateAvailabilityProps>(
  EConsultant.updateAvailability,
  async ({ availabilities }) => {
    const { data } = await instance.patch('availabilities', availabilities)
    return await data
  }
)

export default updateAvailability
