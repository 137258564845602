import {createSlice} from '@reduxjs/toolkit';
import { IError } from 'models/inner-models';

import searchConsultantTags from 'store/query/tags/searchConsultantTags';
import searchAgencyTags from 'store/query/tags/searchAgencyTags';
import getConsultantTagsById from 'store/query/tags/getConsultantTagsById';

export interface ITagItem {
  id: number;
  name: string;
  isActive?: boolean;
  isDeprecated?: boolean;
}
export interface ITagsList {
  [key: string]: ITagItem[]
}

export interface TagsState {
  actionError: IError | null;
  list: ITagsList;
  loaded: boolean;
  loading: boolean;
  agenciesTagsList: ITagsList;
  consultantByIdTagsList: ITagsList;
}

const initialState: TagsState = {
  actionError: null,
  list: {},
  loaded: false,
  loading: false,
  agenciesTagsList: {},
  consultantByIdTagsList: {},
}

const { reducer } = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers:  (builder) => { builder
    .addCase(searchConsultantTags.pending, (state) => {
      state.loading = true
    })
    .addCase(searchConsultantTags.fulfilled, (state, { meta, payload }) => {
      // Add user to the state array
      state.list[meta.arg] = payload
      state.loaded = true
      state.loading = false
    })
    .addCase(searchConsultantTags.rejected, (state, { error, type }) => {
      // Add user to the state array
      state.actionError = {
        message: error?.message || '',
        action: type
      }
      state.loaded = false
      state.loading = false
    })

    .addCase(searchAgencyTags.pending, (state) => {
      state.loading = true
    })
    .addCase(searchAgencyTags.fulfilled, (state, { meta, payload }) => {
      state.agenciesTagsList[meta.arg] = payload
      state.loaded = true
      state.loading = false
    })
    .addCase(searchAgencyTags.rejected, (state) => {
      state.loaded = false
      state.loading = false
    })

    .addCase(getConsultantTagsById.pending, (state) => {
      state.loading = true;
    })
    .addCase(getConsultantTagsById.fulfilled, (state, { meta, payload }) => {
      state.consultantByIdTagsList[meta.arg] = payload;
      state.loaded = true;
      state.loading = false;
    })
    .addCase(getConsultantTagsById.rejected, (state) => {
      state.loaded = false;
      state.loading = false;
    })
  },
});

export default reducer
