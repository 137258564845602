import React, { useCallback, useEffect, useState } from 'react';
import { Client as ConversationsClient, Conversation } from '@twilio/conversations';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { LIMITS } from 'models/consts';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { IChatItem } from 'store/types/chat';
import getChatToken from 'store/query/chat/getChatToken';
import fetchChatList from 'store/query/chat/fetchChatList';
import { getMeRoleSelector, getMeSelector } from 'store/selectors/getUserSelector';
import {
  chatListSelector,
  currentChatSelector,
  hasMoreChats,
  isChatListLoading,
} from 'store/selectors/getChatListSelector';
import { setCurrentConversation } from 'store/reducers/chat';

import ConversationsList from './ConversationsList';

import { StyledDrawer } from './styled';

import Loader from '../../Loader/Loader';

interface ILeftBar {
  chatList?: IChatItem[];
  conversations?: Map<string, Conversation>;
  hasMore?: boolean;
  onChangePage?: (offset: number) => void;
  room?: string;
  serviceSId?: string;
}

const LeftBar: React.FunctionComponent<ILeftBar> = ({ room, serviceSId }) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(getMeRoleSelector);

  const me = useAppSelector(getMeSelector);

  const chatList = useAppSelector(chatListSelector);
  const currentChat = useAppSelector(currentChatSelector(room));
  const isLoading = useAppSelector(isChatListLoading);
  const hasMore = useAppSelector(hasMoreChats);

  const [conversations, setConversations] = useState<Map<string, Conversation>>(new Map());
  const [conversationsClients, setConversationsClients] = useState<ConversationsClient | null>(
    null,
  );

  const connectionJoinedListener = (conversation: any) => {
    setConversations(prevState => {
      return new Map(prevState.set(conversation.sid, conversation));
    });
  };

  const connectionLeftListener = (conversation: any) => {
    setConversations(prevState => {
      prevState.delete(conversation.sid);
      return new Map(prevState);
    });
  };

  const connectionErrorListener = (error: any) => {
    console.error(error.message);
  };

  const initConversations = useCallback((token: string) => {
    if (token) {
      const newClient = new ConversationsClient(token);
      // newClient.on("connectionStateChanged", connectionStateListener);
      newClient.on('conversationJoined', connectionJoinedListener);
      newClient.on('conversationLeft', connectionLeftListener);
      newClient.on('connectionError', connectionErrorListener);
      setConversationsClients(newClient);
    }
  }, []);

  const handleChangePage = (length: number) => {
    if (!isLoading) {
      dispatch(fetchChatList({ limit: LIMITS.chat, offset: length, role }));
    }
  };

  useEffect(() => {
    if (serviceSId && !conversationsClients) {
      dispatch(getChatToken({ serviceChatSid: serviceSId, role, agencyId: me.agencyId }))
        .unwrap()
        .then(({ newToken }) => {
          initConversations(newToken);
        });
    }
    return () => {
      if (conversationsClients) {
        // conversationsClients.off("connectionStateChanged", connectionStateListener);
        conversationsClients.off('conversationJoined', connectionJoinedListener);
        conversationsClients.off('conversationLeft', connectionLeftListener);
        conversationsClients.off('connectionError', connectionErrorListener);
      }
    };
  }, [conversationsClients, initConversations, serviceSId, dispatch, role, me?.agencyId]);

  useEffect(() => {
    if (conversations.size > 0 && currentChat?.chatSId) {
      const selectedConversation = conversations.get(currentChat?.chatSId || '');
      dispatch(setCurrentConversation({ conversation: selectedConversation }));
    }
  }, [conversations, currentChat?.chatSId, dispatch]);

  const observerTarget = useInfiniteScroll(() => handleChangePage(chatList.length), {
    threshold: 0.1,
  });

  return (
    <StyledDrawer variant="permanent" sx={{ display: { xs: room && 'none', md: 'block' } }} open>
      <Typography data-test="messages-page" variant="h1" mt={{ md: 7, xs: 2 }} mb={1}>
        All Messages
      </Typography>
      <ConversationsList conversations={conversations} chatList={chatList} />
      {hasMore && (
        <Box ref={observerTarget} sx={{ py: 2 }}>
          <Loader />
        </Box>
      )}
    </StyledDrawer>
  );
};

export default LeftBar;
