import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {EConsultant} from "../../constants";

const updateConsultantExperience = createAsyncThunk(
  EConsultant.updateConsultantExperience,
  async (payload: any) => {
    const { data } = await instance.patch('experiences', payload.data)
    return data
  }
)

export default updateConsultantExperience
