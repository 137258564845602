import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EPreOrder } from 'store/constants';
import { IModuleCreateDto, IWorkCreateDto } from 'models';
import { ERole } from 'models/consts';

export type PreorderEditProps = {
  description?: string;
  name?: string;
  taxes: number;
  levelId: number;
  id: number;
  billingType: string;
  requestCompletionDate?: string;
  customerReadinessDate?: string;
  agencyReadinessDate?: string;
  timeLine: {
    allHours: number;
    startDate?: string;
    endDate?: string;
    availabilityWeek?: number;
    draftOrdersId?: number;
    flexibleHours?: boolean;
  };
  expertise: {
    productId: number;
    modules: IModuleCreateDto[];
    works: IWorkCreateDto[];
  };
};

const editPreorder = createAsyncThunk<any, any>(
  EPreOrder.editPreorder,
  async ({ preorder, role }: { preorder: PreorderEditProps; role: any }) => {
    let route = '';

    if (role.name === ERole.LEAD_MANAGER) {
      route = '/lead-managers/pre-orders';
    }
    if ([ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(role.name)) {
      route = '/agency/pre-orders';
    }
    if (role.name === ERole.CUSTOMER_ADMIN) {
      route = '/enterprise/pre-orders';
    }
    const response = await instance.patch(route, [preorder]);

    return response.data;
  },
);

export default editPreorder;
