import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EUser } from "../../constants";
import { ICompanyDto } from "../../types/company";

interface IAddCustomerCompanyProps {
  company: ICompanyDto;
  userId: number;
}

const addCustomerCompany = createAsyncThunk<any, IAddCustomerCompanyProps>(
  EUser.createCustomerCompany,
  async ({ company }) => {
    const { data } = await instance.post(`companies`, [company]);

    return data;
  }
)

export default addCustomerCompany;
