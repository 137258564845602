import React, { FunctionComponent } from 'react'
import Box from '@mui/material/Box';
import {
  StyledName,
  StyledShortDescriptions,
} from './styled';

import { IAgency } from "store/types/agency";

interface IAgencyNameBlock {
  agency: IAgency;
}

const AgencyNameBlock: FunctionComponent<IAgencyNameBlock> = ({ agency }) => (
  <>
    <Box display="flex" justifyContent="space-between">
      <StyledName>
        <b>{agency.name}</b>
      </StyledName>
    </Box>
    <StyledShortDescriptions variant="subtitle2">
      <strong>{agency.headlineText}</strong>
    </StyledShortDescriptions>
  </>
)
export default AgencyNameBlock;
