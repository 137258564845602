import React, { FunctionComponent, useState } from 'react';
import cn from 'classnames';

import dayjs from 'utils/dayjs';

import { ICustomer } from '../../models';
import { IFullOrders } from '../../store/types/order';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  StyledBodyCell,
  StyledCalendarHeadCell,
  StyledCalendarBodyRow,
  StyledCalendarCollapse,
  StyledHeadBox,
  StyledOrderBox,
} from './styled';

import { useAppSelector } from '../../store/hooks';
import { capacitiesSelector } from '../../store/selectors/getConsultantSelector';

import Avatar from '../Avatar';
import OrderCellContent from './OrderCellContent';
import OrderRowPreview from './OrderRowPreview';

interface ICalendarRowProps {
  customer: ICustomer;
  endDate: string;
  orders: IFullOrders[];
  preOrders: IFullOrders[];
  startDate: string;
  type: string;
}

const CalendarRow: FunctionComponent<ICalendarRowProps> = ({
  customer,
  endDate,
  orders,
  preOrders,
  startDate,
  type,
}) => {
  const [open, setOpen] = useState(() => false);

  const capacities = useAppSelector(capacitiesSelector([startDate, endDate].join('/')));

  return (
    <>
      {!!orders?.length && (
        <>
          <StyledCalendarBodyRow key={`${customer.companies[0].name}-row`}>
            <StyledCalendarHeadCell>
              <StyledHeadBox width={220}>
                <Box>
                  <Avatar
                    firstName={customer.companies[0].name || ''}
                    lastName={customer.companies[0].name}
                    size="small"
                  />
                </Box>
                <Typography mx={1.5} flexGrow={1}>
                  {customer.companies[0].name} ({orders.length})
                </Typography>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </StyledHeadBox>
            </StyledCalendarHeadCell>
            {capacities.map((capacity: any) =>
              ((type === 'quarter' && [0]) || [0, 1, 2, 3, 4]).map((index: any) => (
                <OrderRowPreview
                  key={`${capacity.startWeekDate}-${index}`}
                  date={dayjs(capacity.startWeekDate).add(index + 1, 'day')}
                  disabled={capacity.availability === 0}
                  open={open}
                  orders={orders}
                  type={type}
                  unit={(type === 'quarter' && 'week') || 'day'}
                />
              )),
            )}
          </StyledCalendarBodyRow>
          <StyledCalendarCollapse in={open} timeout="auto" unmountOnExit>
            {orders.map((order: any) => (
              <StyledCalendarBodyRow key={`${order.name}_collapse_row`}>
                <StyledCalendarHeadCell>
                  <StyledOrderBox width={220}>
                    <Typography component="span" key={order.name}>
                      {order.name}
                    </Typography>
                  </StyledOrderBox>
                </StyledCalendarHeadCell>
                {capacities.map((capacity: any, index: number) =>
                  ((type === 'quarter' && [0]) || [0, 1, 2, 3, 4]).map((dayIndex: number) => (
                    <StyledBodyCell
                      key={`${order.name}-${capacity.startWeekDate}-${index}-${dayIndex}`}
                      className={cn(type, {
                        disabled: capacity.availability === 0,
                        currentDay: dayjs().isSame(
                          dayjs(capacity.startWeekDate).add(dayIndex + 1, 'day'),
                          (type === 'quarter' && 'week') || 'day',
                        ),
                        lastPeriod: dayjs(capacity.startWeekDate)
                          .endOf('month')
                          .endOf('isoWeek')
                          .isSame(dayjs(capacity.startWeekDate).add(1, 'day').endOf('isoWeek')),
                      })}
                      colSpan={1}
                    >
                      <OrderCellContent
                        key={order.id}
                        order={order}
                        date={dayjs(capacity.startWeekDate).add(dayIndex + 1, 'day')}
                        firstWeekDay={index === 0 && dayIndex === 0}
                        isOpen={open}
                        unit={(type === 'quarter' && 'week') || 'day'}
                      />
                    </StyledBodyCell>
                  )),
                )}
              </StyledCalendarBodyRow>
            ))}
          </StyledCalendarCollapse>
        </>
      )}
      {!!preOrders?.length && (
        <>
          <StyledCalendarBodyRow key="preOrders">
            <StyledCalendarHeadCell>
              <StyledHeadBox width={220}>
                <Box>
                  <Avatar
                    firstName={
                      customer && customer.companies && !!customer.companies.length
                        ? customer.companies[0].name
                        : 'PreOrders'
                    }
                    size="small"
                    image={
                      customer && customer.companies && !!customer.companies.length
                        ? customer.companies[0].logo
                        : undefined
                    }
                  />
                </Box>
                <Typography mx={1.5} flexGrow={1}>
                  {customer && customer.companies && !!customer.companies.length
                    ? `${customer.companies[0].name} (${preOrders.length})`
                    : `Pre Orders (${preOrders.length})`}
                </Typography>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </StyledHeadBox>
            </StyledCalendarHeadCell>
            {capacities.map((capacity: any) =>
              ((type === 'quarter' && [0]) || [0, 1, 2, 3, 4]).map((index: any) => (
                <OrderRowPreview
                  key={`${capacity.startWeekDate}-${index}`}
                  date={dayjs(capacity.startWeekDate).add(index + 1, 'day')}
                  disabled={capacity.availability === 0}
                  open={open}
                  orders={preOrders}
                  type={type}
                  unit={(type === 'quarter' && 'week') || 'day'}
                />
              )),
            )}
          </StyledCalendarBodyRow>
          <StyledCalendarCollapse in={open} timeout="auto" unmountOnExit>
            {preOrders.map((order: any) => (
              <StyledCalendarBodyRow key={`${order.name}_collapse_row`}>
                <StyledCalendarHeadCell>
                  <StyledOrderBox width={220}>
                    <Typography component="span" key={order.name}>
                      {order.name}
                    </Typography>
                  </StyledOrderBox>
                </StyledCalendarHeadCell>
                {capacities.map((capacity: any, index: number) =>
                  ((type === 'quarter' && [0]) || [0, 1, 2, 3, 4]).map((dayIndex: number) => (
                    <StyledBodyCell
                      key={`${order.name}-${capacity.startWeekDate}-${index}-${dayIndex}`}
                      className={cn(type, {
                        disabled: capacity.availability === 0,
                        currentDay: dayjs().isSame(
                          dayjs(capacity.startWeekDate).add(dayIndex + 1, 'day'),
                          (type === 'quarter' && 'week') || 'day',
                        ),
                        lastPeriod: dayjs(capacity.startWeekDate)
                          .endOf('month')
                          .endOf('isoWeek')
                          .isSame(dayjs(capacity.startWeekDate).add(1, 'day').endOf('isoWeek')),
                      })}
                      colSpan={1}
                    >
                      <OrderCellContent
                        key={order.id}
                        order={order}
                        date={dayjs(capacity.startWeekDate).add(dayIndex + 1, 'day')}
                        firstWeekDay={index === 0 && dayIndex === 0}
                        isOpen={open}
                        unit={(type === 'quarter' && 'week') || 'day'}
                      />
                    </StyledBodyCell>
                  )),
                )}
              </StyledCalendarBodyRow>
            ))}
          </StyledCalendarCollapse>
        </>
      )}
    </>
  );
};

export default CalendarRow;
