import Box from '@mui/material/Box'
import { styled } from "@mui/material/styles";

const StyledBoxRoot = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  width: '100%',
  height: '100%'
})

const StyledBoxFluid = styled(Box)({
  position: 'relative',
  height: '100%',
  width: '100%',
  overflow: 'hidden'
})

const StyledBoxWrap = styled(Box)({
  display: 'flex',
  position: 'absolute',
  inset: 0
})

export { StyledBoxRoot, StyledBoxFluid, StyledBoxWrap }
