/**
 *  Refactor 12.23
 */
import { createSlice } from '@reduxjs/toolkit';

import union from 'lodash/fp/union';

import { DEFAULT_ENTITY_IDS, ERole, LIMITS } from 'models/consts';

import {
  IAgency,
  IAgencyUser,
  IAgencyInfoWithLeadSource,
  IBasicAgency,
  IFullAgency,
} from '../types/agency';
import { IDataArray, IDynamicKey, IEntitiesIds } from '../types/common';
import { IFullOrders } from '../types/order';

import usersTeam from '../query/agency/usersTeam';
import changeUserStatus from '../query/agency/changeUserStatus';
import inviteUser from '../query/agency/inviteUser';
import getCustomerOrders from '../query/agency/getCustomerOrders';
import fetchMe from '../query/user/fetchMe';
import updateAgencyInfo from '../query/agency/updateAgencyInfo';
import fetchAllProductsAndExperience from '../query/productAndExperience/fetchAllProductsAndExperience';
import fetchAllAgencies from '../query/agency/fetchAllAgencies';
import activateAgenciesWithLeadSource from 'store/query/agency/activateAgenciesWithLeadSource';
import updateUsersTeamAvailability from 'store/query/agency/updateUsersTeamAvailability';
import {
  removeProductAndExperienceRate,
  submitProductAndExperienceRate,
  updateProductAndExperienceRate,
} from 'store/query/productAndExperience';
import setUserFromSSR from '../query/user/setUserFromSSR';
import getMatchedAgencyById from '../query/agency/getMatchedAgencyById';
import deleteAgencyUsers from '../query/agency/deleteAgencyUsers';

const addUsers = (users: IAgencyUser[]) => (ordersMap: IDynamicKey<IAgencyUser>) =>
  users.reduce((userMap, user) => {
    userMap[user.id.toString()] = user;
    return userMap;
  }, ordersMap);

export interface AgencyState {
  agency: IFullAgency; // Temp
  allAgencies: IAgencyInfoWithLeadSource[];
  agencyById: IDynamicKey<IBasicAgency>;
  matchedAgency: IDynamicKey<IAgency>;
  customerOrders: IDynamicKey<IDataArray<number[]>>;
  loading: boolean;
  loaded: boolean;
  users: IDynamicKey<IEntitiesIds>;
  usersById: IDynamicKey<IAgencyUser>;
  usersFilter: string;
}

const initialState: AgencyState = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  agency: {},
  allAgencies: [],
  agencyById: {},
  matchedAgency: {},
  customerOrders: {},
  loading: false,
  loaded: false,
  users: {},
  usersById: {},
  usersFilter: '',
};

const { reducer, actions } = createSlice({
  name: 'agency',
  initialState,
  reducers: {
    removeCustomerOrderId: (state, { payload }) => {
      const currentCustomersKeys = Object.keys(state.customerOrders).filter(key =>
        key.includes(payload.key),
      );

      if (currentCustomersKeys.length) {
        currentCustomersKeys.forEach(key => {
          state.customerOrders[key] = {
            ...state.customerOrders[key],
            ids: state.customerOrders[key].ids.filter(id => payload.customerId !== id),
          };
        });
      }
    },
    addCustomerOrderId: (state, { payload }) => {
      const nextCustomersKeys = Object.keys(state.customerOrders).filter(key =>
        key.includes(payload.key),
      );

      if (nextCustomersKeys.length) {
        nextCustomersKeys.forEach(key => {
          if (!state.customerOrders[key].ids.includes(payload.customerId)) {
            state.customerOrders[key] = {
              ...state.customerOrders[key],
              ids: [...state.customerOrders[key].ids, payload.customerId],
            };
          }
        });
      }
    },
    setAgency: (state, action) => {
      const {
        payload: { agency, ...user },
      } = action;

      if ([ERole.AGENCY_ADMIN, ERole.RESOURCE_MANAGER].includes(user.role.name)) {
        state.agencyById[agency.id] = {
          ...state.agencyById[agency.id],
          ...agency,
          loading: false,
          loaded: true,
        };
      }
    },
  },
  extraReducers: builder =>
    builder
      .addCase(changeUserStatus.fulfilled, (state, action) => {
        const { meta } = action;

        for (const key in state.users) {
          if (key.includes('filterByStatus')) {
            state.users[key].ids = state.users[key].ids.filter(item => item !== meta.arg[0].userId);
          }
        }

        state.usersById[meta.arg[0].userId].isActive =
          !state.usersById[meta.arg[0].userId].isActive;
      })
      .addCase(inviteUser.fulfilled, (state, action) => {
        const { payload } = action;

        state.users[state.usersFilter].ids.push(payload.id);
        state.users[state.usersFilter].countAll = state.users[state.usersFilter].countAll + 1;
        state.usersById[payload.id] = {
          ...payload,
          isActive: null,
        };
      })
      .addCase(deleteAgencyUsers.fulfilled, (state, action) => {
        const { payload } = action;
        payload.map(({ entityId }) => delete state.usersById[entityId]);
      })

      /**
       *  Fetch Me
       */
      .addCase(fetchMe.fulfilled, (state, action) => {
        const {
          payload: { agency, ...user },
        } = action;

        if ([ERole.AGENCY_ADMIN, ERole.RESOURCE_MANAGER].includes(user.role.name)) {
          state.agencyById[agency.id] = {
            ...state.agencyById[agency.id],
            ...agency,
            loading: false,
            loaded: true,
          };
        }
      })
      .addCase(setUserFromSSR.fulfilled, (state, action) => {
        const {
          payload: { agency, ...user },
        } = action;

        if ([ERole.AGENCY_ADMIN, ERole.RESOURCE_MANAGER].includes(user.role.name)) {
          state.agencyById[agency.id] = {
            ...state.agencyById[agency.id],
            ...agency,
            loading: false,
            loaded: true,
          };
        }
      })

      /**
       *  Get Matched Agency By Id
       */
      .addCase(getMatchedAgencyById.pending, state => {
        state.loading = true;
      })
      .addCase(getMatchedAgencyById.rejected, state => {
        state.loading = false;
      })
      .addCase(getMatchedAgencyById.fulfilled, (state, action) => {
        const { payload } = action;
        state.matchedAgency[payload.id] = {
          ...state.matchedAgency[payload.id],
          ...payload,
        };
        state.loading = false;
      })

      /**
       *  Get Agency Team
       */
      .addCase(usersTeam.pending, (state, action) => {
        const { meta } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);
        state.usersFilter = key;
        if (state.users[key]) {
          state.users[key].loaded = false;
          state.users[key].loading = true;
        }
        if (!state.users[key]) {
          state.users[key] = DEFAULT_ENTITY_IDS;
        }
      })
      .addCase(usersTeam.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);

        if (!state.users[key]?.ids.length) {
          state.users[key].ids = Array(payload.count).fill(null);
        }

        if (payload.users.length) {
          state.users[key].ids.splice(
            meta.arg.offset,
            LIMITS.team,
            ...payload.users.map((user: IAgencyUser) => user.id),
          );
          addUsers(payload.users)(state.usersById);
        }

        state.users[key].page = meta.arg.offset / meta.arg.limit + 1;
        state.users[key].countAll = payload.count;
        state.users[key].hasMore = !!payload.users.length && state.users[key].ids.some(id => !id); //payload.users.length >= (meta.arg?.limit || LIMITS.team);
        state.users[key].loaded = true;
        state.users[key].loading = false;
      })

      /**
       *  Update agency
       */
      .addCase(updateAgencyInfo.pending, state => {
        state.loading = true;
      })
      .addCase(updateAgencyInfo.rejected, state => {
        state.loading = false;
      })
      .addCase(updateAgencyInfo.fulfilled, (state, action) => {
        const { payload } = action;
        state.agencyById[payload.id] = {
          ...state.agencyById[payload.id],
          ...payload,
        };
        state.loading = false;
      })

      /**
       *  Update availability for agency consultant
       */
      .addCase(updateUsersTeamAvailability.fulfilled, (state, action) => {
        const {
          meta: { arg },
          payload,
        } = action;
        state.usersById[arg[0].userId] = {
          ...state.usersById[arg[0].userId],
          ...payload[0],
        };
      })

      /**
       *  Fetch customer orders
       */
      .addCase(getCustomerOrders.pending, (state, action) => {
        const {
          meta: { arg },
        } = action;
        state.customerOrders[arg.key] = {
          ids: [],
          loaded: false,
          loading: true,
          hasMore: true,
        };
      })
      .addCase(getCustomerOrders.fulfilled, (state, action) => {
        const {
          meta: { arg },
          payload,
        } = action;
        state.customerOrders[arg.key].ids = union(
          state.customerOrders[arg.key].ids,
          payload.map((order: IFullOrders) => order.id),
        );

        state.customerOrders[arg.key].loading = false;
        state.customerOrders[arg.key].loaded = true;
      })

      /**
       *  Product and Experiences
       */
      .addCase(fetchAllProductsAndExperience.pending, state => {
        state.agency.allProductAndExperience = [];
        state.loading = false;
      })
      .addCase(fetchAllProductsAndExperience.fulfilled, (state, { payload }) => {
        state.agency.allProductAndExperience.push(...payload);
        state.loading = false;
        state.loaded = true;
      })

      /**
       *  Lead Manager / Sources
       */
      .addCase(fetchAllAgencies.pending, state => {
        state.loading = true;
      })
      .addCase(fetchAllAgencies.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.allAgencies = payload;
      })
      .addCase(activateAgenciesWithLeadSource.fulfilled, (state, action) => {
        const { payload } = action;
        state.allAgencies = payload;
      })

      /**
       *  Update Product and Experiences
       */
      .addCase(removeProductAndExperienceRate.fulfilled, (state, { meta }) => {
        state.agency.allProductAndExperience = [
          ...state.agency.allProductAndExperience.filter(item => meta.arg !== item.id),
        ];
      })
      .addCase(updateProductAndExperienceRate.fulfilled, (state, { payload }) => {
        state.agency.allProductAndExperience = [
          ...state.agency.allProductAndExperience.map(item => {
            const updatedProductAndEperience = payload.find(({ id }) => item.id === id);
            if (updatedProductAndEperience) {
              return updatedProductAndEperience;
            }
            return item;
          }),
        ];
      })
      .addCase(submitProductAndExperienceRate.fulfilled, (state, { payload }) => {
        state.agency.allProductAndExperience = [
          ...state.agency.allProductAndExperience,
          ...payload,
        ];
      }),
});

export const { addCustomerOrderId, removeCustomerOrderId, setAgency } = actions;
export default reducer;
