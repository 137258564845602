import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { ESearchTalent } from 'store/constants/talent-search';

const fetchProfileLink = createAsyncThunk<any, any>(
  ESearchTalent.fetchProfileLink,
  async payload => {
    const { data } = await instance.get(`/admin/consultants/${payload.userId}/uid-hash-code`);

    return data;
  },
);

export default fetchProfileLink;
