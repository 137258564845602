import React, { useMemo } from 'react'
import { Controller, useFormContext } from "react-hook-form";

import { handlerTimeLineChange } from "utils/orderCreationHelpers";

import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Unstable_Grid2";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";

import { TIMELINE_HOURS_NEEDED } from "models/consts";
import { useAppSelector } from "store/hooks";
import { availabilitiesSelector } from "store/selectors/getCommonSelector";

import { BootstrapInput, Select } from "../index";

const TotalDeliveredHours = () => {
  const availabilities = useAppSelector(availabilitiesSelector);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    watch
  } = useFormContext()

  const flexibleHours = watch('flexibleHours');
  const selectedAvailability = watch('availabilityWeek');
  const startDate = watch('startDate');
  const totalTime = watch('allHours');

  const availabilitiesOptions = useMemo(() => {
    return availabilities
      ? availabilities.map((item) => ({ name: `${item}h/week`, id: item }))
      : [];
  }, [availabilities]);

  return (
    <Grid container xs={12} spacing={2} sx={{mb: 1}}>
      <Grid xs={12} md={12} lg={6}>
        <InputLabel>Total hours</InputLabel>
        <Controller
          control={control}
          name="allHours"
          render={({field: {ref, ...field}}) => (
            <Autocomplete
              {...field}
              filterOptions={(options) => options}
              freeSolo
              getOptionLabel={(option) => String(option)}
              onChange={(_, value) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                handlerTimeLineChange(parseInt(value), (selectedAvailability || 0), startDate, setValue, clearErrors);
                field.onChange(value);
              }}
              options={TIMELINE_HOURS_NEEDED}
              renderInput={({InputLabelProps, inputProps: {onChange, ...inputProps}, ...params}) => (
                <BootstrapInput
                  {...params}
                  inputProps={inputProps}
                  label="Select"
                  value={totalTime}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    const isValidInput = /^[1-9]\d*$/.test(value);
                    if (isValidInput || value === '') {
                      field.onChange(value);
                    }
                  }}
                />
              )}
            />
          )}
          rules={{required: 'This field is required'}}
        />
        {!!errors?.allHours && (
          <FormHelperText error>
            {(errors?.allHours as any).message || 'Incorrect data'}
          </FormHelperText>
        )}
      </Grid>
      <Grid xs={12} md={12} lg={6}>
        <InputLabel>Delivered</InputLabel>
        <Controller
          control={control}
          name="availabilityWeek"
          render={({field: {ref, ...field}}) => (
            <Select
              {...field}
              value={field.value}
              onChange={(event) => {
                if (event.target.value) {
                  setValue('flexibleHours', false);
                }
                field.onChange(event);
                handlerTimeLineChange(totalTime, event.target.value, startDate, setValue, clearErrors);
              }}
              inputRef={ref}
              label="Select available time per week"
              options={availabilitiesOptions}
            />
          )}
          rules={{
            validate: {
              optionalRequired: (value) => !!value || flexibleHours || 'This field is required! '
            }
          }}
        />
        {!!errors?.availabilityWeek && (
          <FormHelperText error>
            {(errors?.availabilityWeek as any).message || 'Incorrect data'}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  )
}

export default TotalDeliveredHours
