import { Components } from '@mui/material/styles';

const MuiCheckbox: Components['MuiCheckbox'] = {
  styleOverrides: {
    root: {
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      border: '1px solid #D4D4D4',
      borderRadius: 0,
      padding: 7,
      marginRight: 8,
      width: 18,
      height: 18,
      '&.Mui-checked': {
        backgroundColor: '#1F1F1F',
        borderColor: '#1F1F1F',
      },
    },
  },
};

export default MuiCheckbox;
