import React, { FunctionComponent, useEffect } from 'react';
import { Autocomplete, createFilterOptions, SxProps, Theme, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchCategoriesWithProducts } from 'store/query/common';
import { categoryProductsSelector } from 'store/selectors/getCommonSelector';
import { IExtendCategoryWithProducts } from 'store/types/categoryProducts';

import { BootstrapInput } from 'components/CustomFields';

interface IProductAutoCompleteProps {
  searchValue: IExtendCategoryWithProducts | string | null;
  setSearchValue: React.Dispatch<React.SetStateAction<string | IExtendCategoryWithProducts | null>>;
  onSearch: () => void;
  onClear?: () => void;
  placeholderText: string;
  sx?: SxProps<Theme>;
}

const ProductAutoComplete: FunctionComponent<IProductAutoCompleteProps> = ({
  searchValue,
  setSearchValue,
  onSearch,
  onClear,
  placeholderText,
  sx,
}) => {
  const dispatch = useAppDispatch();
  const filter = createFilterOptions<IExtendCategoryWithProducts>();

  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);

  useEffect(() => {
    if (!categoriesWithProductsList?.length) {
      dispatch(fetchCategoriesWithProducts());
    }
  }, [categoriesWithProductsList?.length, dispatch]);

  return (
    <Autocomplete
      value={searchValue}
      onChange={(event, newValue) => setSearchValue(newValue)}
      filterOptions={(options, params) => filter(options, params)}
      selectOnFocus
      blurOnSelect={true}
      handleHomeEndKeys
      openOnFocus={false}
      id="directory-product-search"
      options={categoriesWithProductsList}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      renderOption={({ id, ...props }: any, option: any) => {
        return (
          <Typography
            {...props}
            disabled={props['aria-disabled']}
            key={id}
            component="li"
            sx={{ justifyContent: 'space-between !important' }}
            id={`autocomplete-${option.id}`}
          >
            {option.name}
          </Typography>
        );
      }}
      sx={sx}
      freeSolo
      renderInput={({
        InputLabelProps,
        inputProps: { onChange, ...inputProps },
        ...params
      }: any) => {
        return (
          <BootstrapInput
            {...params}
            inputProps={{ ...inputProps, enterKeyHint: 'search' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (/^(?!.* {2,})[\S ]*$/.test(event.target.value)) {
                onChange(event);
                setSearchValue(event.target.value);
              }
            }}
            onMouseDownCapture={(event: React.ChangeEvent<HTMLInputElement>) =>
              event.stopPropagation()
            }
            onTouchStartCapture={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();

              //Need to manually clear the input field on mobile press because of MUI bug
              if (event.target.closest('.MuiAutocomplete-clearIndicator')) {
                setSearchValue('');
                onClear?.();
              }
            }}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                onSearch();
              }
            }}
            placeholder={placeholderText}
          />
        );
      }}
    />
  );
};

export default ProductAutoComplete;
