import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EPreOrder } from '../../constants';
import { IBasicPreOrder } from "../../types/preOrder";

const paidOpportunity = createAsyncThunk<IBasicPreOrder, {id: number}>(
  EPreOrder.paidOpportunity,
  async ({id}) => {
    const { data } = await instance.post(`/lead-managers/pre-orders/${id}/paid`);

    return data
  }
)

export default paidOpportunity;
