import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

import find from 'lodash/fp/find';
import matches from 'lodash/fp/matches';

import { IMatchedCard } from '../types/consultant';
import { TalentSearchState } from 'store/reducers/talent-search';

const getElementByIdAndCardType = (
  selector: { id: number | null; cardType: string },
  data: IMatchedCard[],
) => find(matches(selector), data);

const GetMatchedtalentsState = (state: DefaultRootState) => state.talentSearch;

export const matchedTalentsSelector = createSelector(
  GetMatchedtalentsState,
  ({ matchedTalents }: TalentSearchState) => matchedTalents.items,
);

export const getMatchedTalentCardSelector = (selector: { cardType: string; id: number | null }) =>
  createSelector(GetMatchedtalentsState, ({ matchedTalents }) =>
    getElementByIdAndCardType(selector, matchedTalents.items),
);

export const matchedTalentLoadingSelector = createSelector(
  GetMatchedtalentsState,
  ({ matchedTalents }: TalentSearchState) => matchedTalents.loading,
);

export const matchedTalentResultsHasMoreSelector = createSelector(
  GetMatchedtalentsState,
  ({ matchedTalents }: TalentSearchState) => matchedTalents.hasMore,
);

export const matchedTotalTalentSelector = createSelector(
  GetMatchedtalentsState,
  ({ matchedTalents }: TalentSearchState) => matchedTalents.countAll,
);