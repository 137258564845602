import { createTheme } from '@mui/material/styles';

import 'react-datepicker/dist/react-datepicker.css';

import breakpoints from './breakpoints';
import components from './Components';
import palette from './palette';
import typography from './typography';

export default createTheme({
  breakpoints,
  palette,
  typography,
  components,
});
