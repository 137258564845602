import React, { FunctionComponent } from 'react';

import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

import { emailHelper } from 'utils/textFormatter';

import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';

import { StyledInfoFieldValue } from 'components/CustomFields/styled'

interface IUserEmail {
  visible?: boolean;
}

const UserEmail: FunctionComponent<IUserEmail> = ({ visible }) => {
  const user = useAppSelector(getMeSelector);

  return (
    <FormGroup sx={{mb: 0}}>
      <InputLabel>Email</InputLabel>
      <StyledInfoFieldValue>
        <Typography>{visible && user.email || emailHelper(user?.email || '')}</Typography>
      </StyledInfoFieldValue>
    </FormGroup>
  )
}

export default UserEmail;
