import {createAsyncThunk} from "@reduxjs/toolkit";

import instance from "../../middlewares/api";
import { EUser } from "../../constants";

const sendVerifyCode = createAsyncThunk(
  EUser.sendVerifyCode,
  async () => {
    const { data } = await instance.post('auth/confirm-code');

    return data;
  }
)

export default sendVerifyCode;
