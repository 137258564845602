import React, { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';

import { sortingBy } from 'utils/transformers';

import { RavenRatingIcon } from 'styles/icons/RavenRatingIcon';
import { RavenIcon } from 'styles/icons/RavenIcon';
import StarFilledIcon3 from 'styles/icons/StarFilledIcon3';

import {
  StyledAvatarWrap,
  StyledCardActionArea,
  StyledClamp,
  StyledCostPerWeek,
  StyledCompanyIconsTags,
  StyledCompanyLogo,
  StyledMatchingValue,
  StyledMoreTags,
  StyledPaper,
  StyledStarChip,
  StyledTagsWrap,
  StyledRavenRated,
  StyledRavenRatedText,
  StyledFavoriteIcon,
  StyledRecommended,
  StyledBadgeImage,
} from './styled';

import useCalculatePrice from 'hooks/useCalculatePrice';
import useVisibleElements from 'hooks/useVisibleElements';

import { IAgency } from 'store/types/agency';

import Avatar from 'components/Avatar';
import CustomTooltip from 'components/CustomTooltip';
import { UKGPartnerBadge } from '../../../styles/icons/UKGPartnerBadge';
import { UKGPartnerMobileBadge } from '../../../styles/icons/UKGPartnerMobileBadge';

import useDeviceInfo from '../../../hooks/useDeviceInfo';
import CameraIcon from '../../../styles/icons/CameraIcon';
import { groupByProduct } from '../../CalendarComponents/utils';
import CommunitySearchMatchInfo from 'views/TalentSearch/CommunitySearchConsultantInfo';

export interface IAgencyCard {
  agency?: IAgency;
  isSearchView?: boolean;
  isSelectedExpert?: boolean;
  onOpenPreview?: () => void;
  onSelectPreview?: (worker: any) => void;
  order: any;
  workerMatching: number;
  isCommunitySearch?: boolean;
}

const AgencyCard: React.FunctionComponent<IAgencyCard> = ({
  agency,
  isSearchView,
  isSelectedExpert,
  onOpenPreview,
  onSelectPreview,
  order,
  workerMatching,
  isCommunitySearch,
}) => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { deviceType } = useDeviceInfo();

  const isMobile = deviceType === 'mobile' && mobileScreen;

  const agencyStyles = agency && JSON.parse(agency?.style);

  const { weeklyPrice } = useCalculatePrice(order);

  const { hiddenChildElements, parentContainerRef, visibleChildElements } = useVisibleElements();

  const agencyProductGroup = useMemo(() => {
    return groupByProduct(agency?.agencyExpertises);
  }, [agency?.agencyExpertises]);

  const ravenRated = false;
  const recommended = false;
  const ukgCertified = false;

  return (
    <StyledPaper sx={{ mb: 2, borderWidth: { xs: 0, md: 1 } }}>
      <StyledCardActionArea onClick={onOpenPreview}>
        <StyledAvatarWrap backgroundColor={agencyStyles?.backgroundColor || '#D4D4D4'}>
          {ukgCertified &&
            (isMobile ? (
              <StyledBadgeImage>
                <UKGPartnerMobileBadge />
              </StyledBadgeImage>
            ) : (
              <StyledBadgeImage>
                <UKGPartnerBadge />
              </StyledBadgeImage>
            ))}
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar size="medium" image={agency?.logo} firstName={agency?.name || ''} lastName="" />
            <Typography
              variant="h6"
              sx={{
                my: 1,
                color: agencyStyles?.textColor || '#000',
                textAlign: 'center',
                display: {
                  xs: 'none',
                  md: 'block',
                },
              }}
            >
              {agency?.name}
            </Typography>
          </Box>
          {/*TODO: add logic for showing*/}
          <>
            {ravenRated ? (
              <Box display="flex" flexDirection="column" alignItems="center">
                <StyledStarChip
                  icon={<RavenRatingIcon />}
                  // TODO: get from BE
                  // label={`${agency.rating}/5`}
                  label={`4.5/5`}
                  variant="filled"
                />
                <Typography
                  sx={{
                    color: '#5D5D5E',
                    fontSize: '12px',
                    marginTop: '8px',
                  }}
                >
                  {/*TODO: get from BE*/}
                  101 reviews
                </Typography>
              </Box>
            ) : (
              <StyledStarChip
                icon={<StarFilledIcon3 sx={{ fontSize: '1rem' }} />}
                // TODO: get from BE
                // label={`${agency.rating}/5`}
                label={`4.5/5`}
                variant="filled"
              />
            )}

            {ravenRated && (
              <StyledRavenRated>
                <RavenIcon />
                <StyledRavenRatedText>Raven Rated</StyledRavenRatedText>
              </StyledRavenRated>
            )}

            {recommended && (
              <StyledRecommended>
                <StyledFavoriteIcon />
                <StyledRavenRatedText>Recommended Expert</StyledRavenRatedText>
              </StyledRecommended>
            )}
          </>
        </StyledAvatarWrap>
        <StyledCompanyIconsTags>
          <Box height={{ md: 98 }}>
            <Box
              display={{
                md: 'none',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography gutterBottom variant="h6" sx={{ my: 1 }}>
                {agency?.name}
              </Typography>
            </Box>
            <Typography
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
              variant="subtitle2"
              gutterBottom
            >
              {agency?.headlineText}
            </Typography>
            <StyledClamp sx={{ mb: 2 }} variant="body2">
              {agency?.description}
            </StyledClamp>
          </Box>
          <Box position="relative">
            <StyledCompanyLogo>
              {agencyProductGroup?.map(
                (item: any) =>
                  !!item?.logo && (
                    <Box key={`logo-${item.id}`} mr={1} mb={1}>
                      <Avatar
                        icon={<CameraIcon />}
                        image={item.logo}
                        firstName={''}
                        round
                        size="xs-small"
                      />
                    </Box>
                  ),
              )}
            </StyledCompanyLogo>
            {!!agency?.companyTags?.length && (
              <StyledTagsWrap
                ref={parentContainerRef}
                sx={{ opacity: 0, position: 'absolute', width: '100%' }}
              >
                {sortingBy(agency?.companyTags, 'CompaniesCompanyTags.showIndex').map(
                  (item: any) => (
                    <Chip
                      sx={{ mr: 0.5, mb: 0.5 }}
                      key={item.id}
                      label={item.name}
                      className="child-element"
                      variant="outlined"
                    />
                  ),
                )}
              </StyledTagsWrap>
            )}
            {!!agency?.companyTags?.length && (
              <StyledTagsWrap>
                {sortingBy(agency?.companyTags, 'CompaniesCompanyTags.showIndex')
                  .splice(0, visibleChildElements.length)
                  .map((item: any) => (
                    <Chip
                      sx={{ mr: 0.5, mb: 0.5 }}
                      key={item.id}
                      label={item.name}
                      variant="outlined"
                    />
                  ))}
                {!!hiddenChildElements.length && (
                  <StyledMoreTags variant="body2">
                    {`+ ${hiddenChildElements.length} More`}
                  </StyledMoreTags>
                )}
              </StyledTagsWrap>
            )}
          </Box>
        </StyledCompanyIconsTags>
      </StyledCardActionArea>
      <Divider flexItem sx={{ mx: 2.5, my: 0, display: { md: 'none' } }} orientation="horizontal" />
      <Divider
        flexItem
        sx={{ mx: 0, my: 2.5, display: { xs: 'none', md: 'block' } }}
        orientation="vertical"
      />
      {!isCommunitySearch && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            p={2.5}
            sx={{ textAlign: 'center', width: { xs: '100%', md: 200 } }}
          >
            <Box
              display="flex"
              flexDirection={{ md: 'column' }}
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '100%' }}
            >
              {!isMobile && (
                <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: { xs: 'row' } }}>
                  <StyledMatchingValue>{workerMatching || 90}%</StyledMatchingValue>
                  <CustomTooltip
                    placement="bottom"
                    title="The matching score ranks the specialists' seniority, availability and expertise against the order needs"
                  />
                </Box>
              )}
              {isMobile && (
                <Box
                  sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Box mr={1} sx={{ fontSize: 22 }}>
                    {workerMatching || 90}%
                  </Box>
                  <Box
                    sx={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    match
                    <CustomTooltip
                      placement="bottom"
                      title="The matching score ranks the specialists' seniority, availability and expertise against the order needs"
                    />
                  </Box>
                </Box>
              )}
              <Box
                width={{ xs: '100%', md: 130 }}
                sx={{ display: 'flex', justifyContent: { xs: 'flex-end', md: 'center' } }}
              >
                <Box>
                  <StyledCostPerWeek>
                    <b>
                      <Tooltip title="USD">
                        <span>$</span>
                      </Tooltip>
                      {weeklyPrice.toLocaleString('en-US')}/wk
                    </b>
                  </StyledCostPerWeek>
                </Box>
              </Box>
              {isSearchView && (
                <Button
                  data-test="select-button-agency-card-md"
                  color={(!isSelectedExpert && 'secondary') || 'primary'}
                  onClick={() => onSelectPreview && onSelectPreview({ agency })}
                  sx={{ display: { xs: 'none', md: 'inline-block' }, mt: 2 }}
                  variant="contained"
                >
                  {isSelectedExpert ? 'Selected' : 'Select'}
                </Button>
              )}
            </Box>
          </Box>
          {isSearchView && (
            <Button
              data-test="select-button-agency-card-xs"
              color={(!isSelectedExpert && 'primary') || 'secondary'}
              fullWidth
              onClick={() => onSelectPreview && onSelectPreview({ agency })}
              sx={{ display: { md: 'none' } }}
              variant="contained"
            >
              {isSelectedExpert ? 'Selected' : 'Select'}
            </Button>
          )}
        </>
      )}
      {isCommunitySearch && (
        <div onClick={onOpenPreview}>
          <CommunitySearchMatchInfo agency={agency} isAgency={true} />
          {/* <Button
              data-test="select-button-agency-card-xs"
              color={(!isSelectedExpert && 'primary') || 'secondary'}
              fullWidth
              onClick={() => onSelectPreview && onSelectPreview({ agency })}
              sx={{ display: { md: 'none' } }}
              variant="contained"
            >
              {isSelectedExpert ? 'Selected' : 'Select'}
            </Button> */}
        </div>
      )}
    </StyledPaper>
  );
};
export default AgencyCard;
