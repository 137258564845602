import mapValues from 'lodash/fp/mapValues';
import isObject from 'lodash/isObject';
import flatMap from 'lodash/fp/flatMap';
import flowRight from 'lodash/fp/flowRight';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';

import {
  ICategoryWithProducts,
  IExtendCategoryWithProducts,
  IProduct,
} from '../store/types/categoryProducts';
import { flow, orderBy } from 'lodash/fp';

export const outputQuery = mapValues((value: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isObject(value) && value.id !== undefined) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return value.id;
  }
  return value;
});

export const flatProductsList = flowRight(
  flatMap((category: any) =>
    category.products.map((product: IProduct) => ({
      categoryName: category.name,
      categoryId: category.id,
      ...product,
      isBasic: category.isBasic,
    })),
  ),
  map((o: ICategoryWithProducts) => ({
    ...o,
    products: [...o.products].sort((a, b) => {
      // isActive comparison - true values sorted before false
      const activeCompare = b.isActive === a.isActive ? 0 : a.isActive ? -1 : 1;
      if (activeCompare !== 0) return activeCompare;

      // Finally, sort by the item's name
      return a.name.localeCompare(b.name);
    }),
  })),
);

export const filterIsDeprecated = (list: any[], id: number) =>
  list.filter(({ productId, isDeprecated }) => productId === id && !isDeprecated);

export const toActive = map((obj: any) => ({ ...obj, isActive: true }));

export const activeSort: (
  categories: IExtendCategoryWithProducts[],
) => IExtendCategoryWithProducts[] = flow(
  map((obj: IExtendCategoryWithProducts) => ({ ...obj, isActive: true })),
  orderBy(['categoryId', 'isActive', 'name'], ['asc', 'desc', 'asc']),
  (sortedCategories): IExtendCategoryWithProducts[] =>
    sortedCategories as IExtendCategoryWithProducts[],
);
