import React, {FunctionComponent, useMemo} from 'react';
import {BarChart, Bar, Cell, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip} from 'recharts';

import CustomizedTooltip from './CustomizedTooltip';

import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import {StyledFormGroup, StyledInputLabel} from "../CustomFields/styled";

interface IWorkTimeChart {
  chartData: any[];
  totalWeeks: number;
  withLabels?: boolean;
  estimatedTime?: number;
  flexibleHours?: boolean;
  isMarginLeft?: boolean;
}

const WorkTimeChart: FunctionComponent<IWorkTimeChart> = ({
  withLabels,
  estimatedTime,
  flexibleHours,
  chartData,
  totalWeeks,
  isMarginLeft = true,
}) => {
  const actualizedTime = useMemo(() => {
    return (flexibleHours && estimatedTime && totalWeeks && estimatedTime % totalWeeks) ? Math.ceil(estimatedTime / totalWeeks) : null
  }, [estimatedTime, flexibleHours, totalWeeks]);

  const renderGroupTick = (data: any) => {
    let currentGroupSize = 1;
    return (tickProps: { x: any; y: any; payload: any; index: any; height: any, }) => {
      const { x, y, payload, height } = tickProps;
      const { value, offset, index } = payload;
      const isLast = data.length === index + 1;
      const svgWidth = currentGroupSize * offset * 2;

      // tick label
      if (data[index].isLine) {
        return undefined;
      } else if (isLast || value !== data[index + 1]?.month) {
        currentGroupSize = 1;

        return (
          <svg width={svgWidth} y={y - height} x={x - svgWidth + offset}>
            <text x="50%" y="10" alignmentBaseline="middle" textAnchor="middle">
              {value}
            </text>
          </svg>
        );
      } else {
        currentGroupSize++;
      }
      return undefined;
    };
  };

  return (
    <>
      <Grid container spacing={2} mt={1}>
        {!!estimatedTime && (
          <StyledFormGroup container xs={12} rowSpacing={{xs: 1, md: 2}}>
            <Grid xs={5}>
              <StyledInputLabel>
                Estimated timeline
              </StyledInputLabel>
            </Grid>
            <Grid xs={7}>
              <Typography variant="h6" >
                {estimatedTime} hours
              </Typography>
            </Grid>
          </StyledFormGroup>
        )}
        {!!actualizedTime && totalWeeks && (
          <StyledFormGroup container xs={12} rowSpacing={{xs: 1, md: 2}}>
            <Grid xs={5}>
              <StyledInputLabel>
                Actualized timeline
              </StyledInputLabel>
            </Grid>
            <Grid xs={7}>
              <Typography variant="h6">
                {actualizedTime * totalWeeks} hours
              </Typography>
            </Grid>
          </StyledFormGroup>
        )}
        {!!chartData?.length && (
          <Grid xs={12} mdOffset={isMarginLeft ? 5 : 0} md={isMarginLeft ? 7 : 12}>
            <ResponsiveContainer height={228} width="100%">
              <BarChart
                data={chartData}
                layout="horizontal"
                maxBarSize={24}
                margin={{top: 40, right: 0, left: 0, bottom: 20}}
              >
                <CartesianGrid vertical={false} stroke="#F5F5F5"/>
                <XAxis
                  dataKey="time"
                  padding={{left: 0, right: 30}}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={() => ``}
                  minTickGap={0}
                  interval={0}
                  dy={0}
                  // interval="preserveStartEnd"
                />
                <XAxis
                  dataKey="month"
                  padding={{left: 0, right: 30}}
                  tickLine={false}
                  axisLine={false}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  tick={renderGroupTick(chartData)}
                  xAxisId="month"
                  interval={0}
                  minTickGap={0}
                  type="category"
                />
                <YAxis
                  type="number"
                  dataKey="name"
                  tickFormatter={(value: string) => `${value}h`}
                  orientation="right"
                  axisLine={false}
                  tickLine={false}
                  tick={{fill: '#1f1f1f'}}
                />
                {withLabels &&(
                  <Tooltip
                    content={<CustomizedTooltip withLabels />}
                    wrapperStyle={{
                      backgroundColor: "transparent",
                      visibility: "visible",
                    }}
                  />
                )}
                <Bar
                  dataKey="value"
                  stackId="a"
                  label={undefined}
                  radius={4}
                  width={24}
                  onMouseEnter={undefined}
                  onMouseLeave={undefined}
                >
                  {chartData && chartData.map((entry) => (
                    <Cell
                      key={entry.time}
                      fill={entry.updated ? '#1F1F1F' : '#D4D4D4'}
                    />
                  ))}
                </Bar>
                <Bar
                  dataKey="newValue"
                  fill="#FFC107"
                  radius={4}
                  width={24}
                  stackId="a"
                />
                <Bar
                  dataKey="line"
                  fill="#1F1F1F"
                  stackId="a"
                  onMouseEnter={undefined}
                  onMouseLeave={undefined}
                >
                  {chartData && chartData.map((entry) => (
                    <Cell
                      key={entry.time}
                      fill="#1F1F1F"
                      width={1}
                      style={{ marginLeft: 12, left: 12 }}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default WorkTimeChart;
