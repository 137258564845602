import {createAsyncThunk} from "@reduxjs/toolkit";

import instance from "../../middlewares/api";
import { EStripe } from "../../constants/stripe";

interface IStripeAccountLinkProps {
  refreshUri: string;
  returnUri: string;
}

const getConsultantStripeAccountLink = createAsyncThunk<{ url: string }, IStripeAccountLinkProps>(
  EStripe.getConsultantStripeAccountLink,
  async (payload)=> {
    const { data } = await instance.post('consultant/payment/stripe', {...payload})
    return data;
  }
);

export default getConsultantStripeAccountLink;
