import { createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosResponse} from "axios";

import instance from 'store/middlewares/api';

import { EEnterprise } from "store/constants/enterprise";

interface IChangeUserStatus {
  userId: number
}

const changeUserStatus = createAsyncThunk<AxiosResponse, IChangeUserStatus[]>(
  EEnterprise.changeUserStatus,
  async (values)=> {
    const { data } = await instance.post('/enterprise/companies/users-team/deactive-users', { data: values });

    return data
  }
);

export default changeUserStatus;
