import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {EUser} from "../../constants";

const updateBasicInfo = createAsyncThunk(
  EUser.updateUserInfo,
  async (payload: any) => {
    await instance.patch('consultant/user-profile', payload)
    return payload
  }
)

export default updateBasicInfo
