import React, { useState, useEffect } from 'react';

import { StyledBarWrap, StyledIconBtn } from './styled';
import CloseIcon from 'styles/icons/CloseIcon';

import { SENIORITY_LEVELS } from 'models/consts';
import { SeniorityLevelType } from './constants';

import BarItem from './BarItem';

interface ISeniorityLevelProps {
  onChange?: ({
    moduleId,
    workId,
    levelId,
    level,
  }: {
    moduleId?: number;
    workId?: number;
    levelId?: number;
    level?: SeniorityLevelType;
  }) => void;
  moduleId?: number;
  workId?: number;
  value?: SeniorityLevelType;
  disabled?: boolean;
}

const SeniorityLevel: React.FunctionComponent<ISeniorityLevelProps> = ({
  onChange,
  moduleId,
  workId,
  value,
  disabled,
}) => {
  const [level, setLevel] = useState<SeniorityLevelType | undefined>(value);

  const handleClearLevel = () => {
    if (onChange) {
      setLevel(undefined);
      onChange({ moduleId, workId });
    }
  };

  const handleChangeLevel = (newLevel: SeniorityLevelType) => {
    if (onChange) {
      if (level?.id !== newLevel.id) {
        setLevel(newLevel);
        onChange({ moduleId, workId, levelId: newLevel.id, level: newLevel });
      } else {
        handleClearLevel();
      }
    }
  };

  useEffect(() => {
    setLevel(value);
  }, [value]);

  return (
    <StyledBarWrap data-test="proficiency-bar" disabled={disabled}>
      <BarItem
        level={SENIORITY_LEVELS.junior}
        isActive={!!level && level.id > 0}
        onToggle={handleChangeLevel}
        disabled={disabled}
      />
      <BarItem
        level={SENIORITY_LEVELS.intermediate}
        isActive={level && level.id > 1}
        onToggle={handleChangeLevel}
        disabled={disabled}
      />
      <BarItem
        level={SENIORITY_LEVELS.senior}
        isActive={level && level.id > 2}
        onToggle={handleChangeLevel}
        disabled={disabled}
      />
      {onChange && level?.id && (
        <StyledIconBtn data-test="proficiency-clear-button" onClick={handleClearLevel} size="small">
          <CloseIcon />
        </StyledIconBtn>
      )}
    </StyledBarWrap>
  );
};

export default SeniorityLevel;
