import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

const approveDraftOrder = createAsyncThunk<any, { id: number }>(
  EOrder.approveDraftOrder,
  async ({ id}) => {
    const { data } = await instance.patch(`draft-orders/approve`, {ids: [id]});

    return { ...data, id };
  }
)

export default approveDraftOrder;
