import { createSelector } from "@reduxjs/toolkit";
import { DefaultRootState } from "react-redux";
import { TagsState } from "../reducers/tags";

export const getSearchTagsSelector = (search: string) => createSelector((state: DefaultRootState) => state.tags, ({ list }: TagsState) => list[search]);
export const getSearchAgencyTagsSelector = (search: string) => createSelector((state: DefaultRootState) => state.tags, ({ agenciesTagsList }: TagsState) => agenciesTagsList[search]);
export const getTagsLoadingSelector = createSelector((state: DefaultRootState) => state.tags, ({ loading }: TagsState) => loading);

export const getConsultantTagsByIdSelector = (userId: number) => createSelector((state: DefaultRootState) => state.tags, ({ consultantByIdTagsList }: TagsState) => consultantByIdTagsList[userId]);

