import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {EAgency} from "../../constants/agency";

const updateAgency = createAsyncThunk(
  EAgency.updateAgency,
  async (payload: any) => {
    const response = await instance.patch('agency', payload)
    return response.data;
  }
)

export default updateAgency
