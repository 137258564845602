import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { EOrder } from '../../constants';
import { ERole } from '../../../models/consts';
import { IRole } from '../../types/common';

interface IRequestParams {
  id: number;
  role: IRole;
  status: string;
  isPurchase?: boolean;
}

const changeOrderStatus = createAsyncThunk<any, IRequestParams>(
  EOrder.changeOrderStatus,
  async ({ role, isPurchase, ...payload }) => {
    let url = `orders/status`;
    if (role.name === ERole.CUSTOMER_ADMIN || role.name === ERole.CUSTOMER) {
      url = isPurchase ? 'enterprise/orders/status' : `orders/status`;
    }
    const { data } = await instance.patch(url, [payload]);

    return data;
  },
);

export default changeOrderStatus;
