import React, { useState, useRef } from 'react';
import dayjs from 'dayjs';

import { Box, Button, Typography, Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { IBasicConsultant, IWorker } from 'store/types/consultant';
import { IAgency } from 'store/types/agency';
import { useAppDispatch } from 'store/hooks';
import downloadAnyConsultantResume from 'store/query/consultant/downloadAnyConsultantResume';
import fetchProfileLink from 'store/query/talent-search/fetchProfileLink';

import isDev, { isLocal, isPreStaging, isProd, isStaging } from 'helpers/environmentDetector';
import { ToastService } from 'services';
import ToastMessage from 'components/Toast/ToastMessage';

interface ICommunitySearchMatchInfo {
  consultant?: IBasicConsultant;
  agency?: IAgency; //Change it to IBasicAgency
  isAgency?: boolean;
  worker?: IWorker;
}

const CommunitySearchMatchInfo: React.FC<ICommunitySearchMatchInfo> = ({
  consultant,
  agency,
  isAgency,
  worker,
}) => {
  const dispatch = useAppDispatch();
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const [openClipboard, setOpenClipboard] = useState(false);

  const theme = useTheme();
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'xxl'));

  const handleClick = (clipboardText: string) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(clipboardText)
        .then(() => setOpenClipboard(true))
        .catch(err => {
          ToastService.error(
            ToastMessage({
              title: 'Error',
              body: 'Unable to copy to clipboard. Please try again later!',
            }),
            console.error('Failed to copy with clipboard:', err),
          );
        });
    } else {
      ToastService.error(
        ToastMessage({
          title: 'Error',
          body: 'Unable to copy to clipboard. Please try again later!',
        }),
      );
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenClipboard(false);
  };

  const handleCVDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      const fileId: number | undefined = consultant?.cvFileId;
      if (fileId) {
        const data = await dispatch(downloadAnyConsultantResume(fileId)).unwrap();
        if (data && data.url) {
          const response = await fetch(data.url);
          const blob = await response.blob();
          const downloadUrl = window.URL.createObjectURL(blob);
          const fileName = data.originalName || 'cv-file-name';
          if (isiPadOrMobile) {
            window.open(data.url, '_blank');
          } else {
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          window.URL.revokeObjectURL(downloadUrl);
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const generateLinkedInUrl = (linkedInValue: string | undefined) => {
    const baseUrl = 'https://www.linkedin.com/in/';
    if (linkedInValue) {
      if (linkedInValue.includes(baseUrl) || linkedInValue.includes('https://linkedin.com/in/')) {
        return linkedInValue;
      }
    }
    return `${baseUrl}${linkedInValue}`;
  };

  const handleGetConsultantLink = async () => {
    const baseUrlMap = {
      local: 'http://app-dev.myworkerbee.com/',
      dev: 'http://app-dev.myworkerbee.com/',
      staging: 'https://app-stage.myworkerbee.com/',
      preStaging: 'https://app-pre-stage.myworkerbee.com/',
      prod: 'https://app.myworkerbee.com/',
    };

    const getEnvironment = () => {
      if (isProd()) return 'prod';
      if (isStaging()) return 'staging';
      if (isPreStaging()) return 'preStaging';
      if (isDev() || isLocal()) return 'dev';
      return 'prod';
    };
    const baseUrl = baseUrlMap[getEnvironment()];

    if (!consultant?.userId) {
      ToastService.error(
        ToastMessage({
          title: 'Error',
          body: 'User id is missing',
        }),
      );
      return '';
    }

    if (!consultant.uidHashCode) {
      try {
        const data = await dispatch(fetchProfileLink({ userId: consultant.userId })).unwrap();
        if (data?.uidHashCode) {
          return `${baseUrl}sharable-specialist/${data.uidHashCode}`;
        } else {
          ToastService.error(
            ToastMessage({
              title: 'Error',
              body: 'Failed to retrieve profile link for consultant',
            }),
          );
          return '';
        }
      } catch (error) {
        console.error('Error fetching profile link:', error);
        ToastService.error(
          ToastMessage({
            title: 'Error',
            body: 'Unable to copy to clipboard. Please try again later!',
          }),
          console.error('Failed to create hashcode:', error),
        );
        return '';
      }
    }
    return `${baseUrl}sharable-specialist/${consultant.uidHashCode}`;
  };

  return (
    <Box
      display="flex"
      flexDirection={!isiPadOrMobile ? 'column' : 'column-reverse'}
      justifyContent="space-between"
      px={2.5}
      py={{ xs: 1.5, md: 2.5 }}
      sx={{ textAlign: 'center', width: { xs: '100%', md: '376px' } }}
    >
      <Box>
        {(agency || consultant) && (
          <>
            <Box display={'flex'} flexDirection={'row'}>
              <Typography mr={7} fontSize={'18px'} fontWeight={500}>
                Email:
              </Typography>
              <Typography
                fontSize={'18px'}
                fontWeight={400}
                textAlign={'left'}
                sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
              >
                {isAgency ? agency?.emailDomain : consultant?.email}
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'row'}>
              <Typography mr={6} fontSize={'18px'} fontWeight={500}>
                Phone:
              </Typography>
              <Typography fontSize={'18px'} fontWeight={400} textAlign={'left'}>
                {isAgency ? agency?.mobilePhone : consultant?.mobilePhone}
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'row'}>
              <Typography mr={isAgency ? 2 : 6} fontSize={'18px'} fontWeight={500}>
                {isAgency ? 'Registered:' : 'Vetted:'}
              </Typography>
              <Typography fontSize={'18px'} fontWeight={400} textAlign={'left'}>
                {isAgency
                  ? dayjs(agency?.registrationAt).format('DD/MM/YYYY')
                  : dayjs(consultant?.approvedAt).format('DD/MM/YYYY')}
              </Typography>
            </Box>
            {!isAgency && (
              <Box display={'flex'} flexDirection={'row'}>
                <Typography mr={isAgency ? 3.5 : 2} fontSize={'18px'} fontWeight={500}>
                  Availability:
                </Typography>
                <Typography fontSize={'18px'} fontWeight={400} textAlign={'left'}>
                  {consultant?.availabilities[0]?.availabilityWeek}h/wk
                </Typography>
              </Box>
            )}
            {!isAgency && (
              <Box display={'flex'} flexDirection={'row'}>
                <Typography mr={4} fontSize={'18px'} fontWeight={500}>
                  Updated:
                </Typography>
                <Typography fontSize={'18px'} fontWeight={400} textAlign={'left'}>
                  {dayjs(consultant?.availabilities[0]?.updatedAt).format('DD/MM/YYYY')}
                </Typography>
              </Box>
            )}
            <Box display={'flex'} flexDirection={'row'}>
              <Typography mr={4} fontSize={'18px'} fontWeight={500}>
                Location:
              </Typography>
              <Typography fontSize={'18px'} fontWeight={400} textAlign={'left'}>
                {isAgency ? agency?.location : consultant?.location}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      {!isAgency && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={'row'}
          mt={2}
          mb={isiPadOrMobile ? 2 : undefined}
        >
          <Box mr={1}>
            <Button
              data-test="select-button-agency-card-md"
              color={'secondary'}
              onClick={handleCVDownload}
              variant="contained"
              disabled={!consultant?.cvFileId}
              sx={{ width: '80px', height: '33px' }}
            >
              Resume
            </Button>
          </Box>
          <Box mr={1}>
            <Button
              data-test="select-button-agency-card-md"
              color={'secondary'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleClick(generateLinkedInUrl(consultant?.linkedInLink || ''));
              }}
              variant="contained"
              disabled={!consultant?.linkedInLink}
              sx={{ width: '80px', height: '33px' }}
            >
              LinkedIn
            </Button>
          </Box>
          <Box>
            <Button
              data-test="select-button-agency-card-md"
              color={'secondary'}
              onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleClick(await handleGetConsultantLink());
              }}
              variant="contained"
              sx={{ width: '133px', height: '33px' }}
            >
              Copy Profile Link
            </Button>
            <Snackbar
              open={openClipboard}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={handleClose}
                sx={{
                  backgroundColor: '#FFCA28',
                  width: '100%',
                  color: '#000',
                  marginTop: isiPadOrMobile ? 10 : undefined,
                }}
              >
                Copied to clipboard!
              </Alert>
            </Snackbar>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CommunitySearchMatchInfo;
