export enum EConsultantTags {
  addConsultantTags = 'tags/addConsultantTags',
  searchTags = 'tags/searchTags',
  getConsultantTagsById = 'tags/getConsultantTagsById',
}

export enum EAgencyTags {
  addAgencyTags = 'tags/addAgencyTags',
  searchAgencyTags = 'tags/searchAgencyTags'
}
