import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import RefreshArrowIcon from '@mui/icons-material/Autorenew';

import { standartDateToMonth } from 'utils/dateFormatter';
import { rateFormat } from 'utils/textFormatter';
import { pluralizeWord } from 'utils/transformers';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  FeedbackContainer,
  FeedbackHeaderContainer,
  FeedbackHeader,
  Feedback,
  FeedbackRatingContainer,
  RatingContainer,
  TotalRateContainer,
  TotalContainer,
  DetailsContainer,
  RatingItem,
  ProgressContainer,
  StyledLinearProgress,
} from './styled';
import { StyledStarChip } from 'components/ConsultantComponents/HeaderConsultant/styled';

import StarFilledIcon3 from 'styles/icons/StarFilledIcon3';

import { ESize, LIMITS } from 'models/consts';
import { IConsultantFeedbackProps } from 'models/inner-models';
import {
  feedbacksConsultantSelector,
  feedbacksLoadedSelector,
  hasMoreFeedbacksSelector,
} from 'store/selectors/getConsultantSelector';
import fetchConsultantReview from 'store/query/feedbacks/fetchConsultantReview';

import Avatar from 'components/Avatar';
import Loader from 'components/Loader/Loader';
import StarRating from 'components/StarRating';

const OrderFeedback: FunctionComponent<IConsultantFeedbackProps> = ({ rate, userId }) => {
  const dispatch = useAppDispatch();
  const feedbacksRaw = useAppSelector(feedbacksConsultantSelector(userId));
  const loaded = useAppSelector(feedbacksLoadedSelector);
  const hasMore = useAppSelector(hasMoreFeedbacksSelector);
  const [feedbacks, setFeedbacks] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    if (userId && !loaded) {
      dispatch(
        fetchConsultantReview({
          userId,
          limit: LIMITS.feedback,
          offset: feedbacks?.length,
        }),
      );
    }
  }, [dispatch, feedbacks?.length, userId, loaded]);

  const loadMore = useCallback(() => {
    dispatch(
      fetchConsultantReview({
        userId,
        limit: LIMITS.feedback,
        offset: feedbacks?.length,
      }),
    );
  }, [dispatch, feedbacks?.length, userId]);

  useEffect(() => {
    if (feedbacksRaw && feedbacksRaw.length > 0) {
      const feedbacks = feedbacksRaw.filter(item => item.isApprove);
      setFeedbacks(feedbacks);
    }
  }, [feedbacksRaw]);

  return (
    <Box id="order-feedback">
      {!!feedbacks?.length && (
        <RatingContainer>
          <TotalRateContainer>
            <Typography variant="h4">Reviews</Typography>
            <Box>
              <TotalContainer>
                <StyledStarChip
                  color="secondary"
                  icon={<StarFilledIcon3 sx={{ fontSize: '1rem' }} />}
                  variant="filled"
                  label={rateFormat(rate?.totalRate)}
                />
                <Typography sx={{ ml: 1 }}>
                  {rate.feedbacksCount} {pluralizeWord('Review', rate.feedbacksCount)}
                </Typography>
              </TotalContainer>
              <Typography color="textSecondary" variant="body2" mt={0.5} ml={2}>
                out of 5
              </Typography>
            </Box>
          </TotalRateContainer>
          <DetailsContainer>
            <RatingItem>
              <Typography component="div" sx={{ minWidth: 110, mr: 1 }}>
                Expertise
              </Typography>
              <ProgressContainer>
                <StyledLinearProgress
                  variant="determinate"
                  value={(100 / 5) * rate?.expertiseRate}
                />
                <Typography>{rateFormat(rate?.expertiseRate)}</Typography>
              </ProgressContainer>
            </RatingItem>
            <RatingItem>
              <Typography component="div" sx={{ minWidth: 110, mr: 1 }}>
                Communication
              </Typography>
              <ProgressContainer>
                <StyledLinearProgress
                  variant="determinate"
                  value={(100 / 5) * rate?.communicationRate}
                />
                <Typography>{rateFormat(rate?.communicationRate)}</Typography>
              </ProgressContainer>
            </RatingItem>
            <RatingItem>
              <Typography component="div" sx={{ minWidth: 110, mr: 1 }}>
                Availability
              </Typography>
              <ProgressContainer>
                <StyledLinearProgress
                  variant="determinate"
                  value={(100 / 5) * rate?.availabilityRate}
                />
                <Typography>{rateFormat(rate?.availabilityRate)}</Typography>
              </ProgressContainer>
            </RatingItem>
            <RatingItem>
              <Typography component="div" sx={{ minWidth: 110, mr: 1 }}>
                Timely delivery
              </Typography>
              <ProgressContainer>
                <StyledLinearProgress
                  variant="determinate"
                  value={(100 / 5) * rate?.deliveryRate}
                />
                <Typography>{rateFormat(rate?.deliveryRate)}</Typography>
              </ProgressContainer>
            </RatingItem>
          </DetailsContainer>
        </RatingContainer>
      )}
      {loaded ? (
        <>
          {!!feedbacks?.length && (
            <Box>
              {feedbacks.map(feedback => (
                <FeedbackContainer key={`feedback-${feedback.id}`}>
                  <Feedback>
                    <Avatar
                      image={feedback.order?.company?.logo}
                      firstName={feedback.order?.customer?.user?.firstName || ''}
                      lastName={feedback.order?.customer?.user?.lastName || ''}
                    />
                    <FeedbackHeaderContainer>
                      <FeedbackHeader>
                        <Typography variant="subtitle1">
                          {feedback.order?.company?.name || 'Company'}
                        </Typography>
                        <Typography variant="body2">
                          {standartDateToMonth(feedback?.createdAt || '')}
                        </Typography>
                      </FeedbackHeader>
                      <FeedbackRatingContainer>
                        <StarRating
                          value={feedback.totalRate}
                          size={ESize.SMALL}
                          onRatingChange={() => {}}
                          rate={feedback.totalRate}
                          readonly={true}
                        />
                      </FeedbackRatingContainer>
                      <Box>
                        {feedback?.feedbackText && <Typography>{feedback.feedbackText}</Typography>}
                      </Box>
                    </FeedbackHeaderContainer>
                  </Feedback>
                </FeedbackContainer>
              ))}
              {!!hasMore && (
                <Box
                  sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
                  onClick={loadMore}
                >
                  <Button color="secondary" startIcon={<RefreshArrowIcon />}>
                    More items
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </>
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default OrderFeedback;
