import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EConsultant } from "../../constants";

const deleteOutDates = createAsyncThunk(
  EConsultant.deleteOutDates,
  async (ids: any) => {
    const { data } = await instance.delete('out-dates', { data: { ids } })
    return await data
  }
)

export default deleteOutDates
