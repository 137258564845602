import React, {FunctionComponent} from 'react'

import { StyledCalendarBody } from "./styled";

import { useAppSelector } from "store/hooks";

import { calendarSelector } from "store/selectors/getConsultantSelector";

import CalendarRow from "./CalendarRow";

interface ICalendarBodyProps {
  endDate: string;
  startDate: string;
  type: string;
}

const CalendarBody: FunctionComponent<ICalendarBodyProps> = ({ endDate, startDate, type }) => {
  const calendar = useAppSelector(calendarSelector([startDate, endDate].join('/')))

  return (
    <StyledCalendarBody>
      {calendar?.map((item: any, index: number) => (
        <CalendarRow key={`row-${index}`} {...item} endDate={endDate} startDate={startDate} type={type} />
      ))}
    </StyledCalendarBody>
  )
}

export default CalendarBody
