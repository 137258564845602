import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';

import { ToastService } from 'services';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import PlusIcon from 'styles/icons/PlusIcon';

import { useAppDispatch } from 'store/hooks';
import { IAgencyUser } from 'store/types/agency';
import ROUTES from 'router/constants';

import changeUserStatus from 'store/query/agency/changeUserStatus';
import resendInviteUser from 'store/query/agency/resendInviteUser';
import impersonateToken from 'store/query/agency/impersonateToken';
import createVirtualSpecialist from 'store/query/agency/createVirtualSpecialist';
import deleteAgencyUsers from 'store/query/agency/deleteAgencyUsers';

import FilterTeam from 'components/AgencyComponents/FilterTeam';
import ModalWrapper from 'components/Modal/Modal';
import AddMember from 'components/AgencyComponents/AddMember';
import ManageTable from 'components/AgencyComponents/ManageTable';
import ManageListMobile from 'components/AgencyComponents/ManageListMobile';
import ToastMessage from 'components/Toast/ToastMessage';

const TeamList: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileNative = Capacitor.isNativePlatform();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [page, setPage] = React.useState(0);
  const [virtualCreatingLoading, setVirtualCreatingLoading] = React.useState(false);

  const methods = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      filterByEmail: undefined,
      filterByStatus: undefined,
      filterByRole: undefined,
      property: undefined,
      type: 'asc',
    },
  });

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = (user: IAgencyUser, status?: any) => {
    if (status === 'cancel' || status === 'delete') {
      dispatch(deleteAgencyUsers([{ userId: user.id }]))
        .unwrap()
        .then((data: any) => {
          if (data && data.length > 0 && data[0].success) {
            ToastService.success(
              ToastMessage({ title: 'Success', body: 'User has been removed successfully' }),
              { toastId: user.id },
            );
          } else {
            ToastService.error(
              ToastMessage({ title: 'Error', body: 'Unable to remove user due to active orders' }),
            );
          }
        });
    } else if (status !== 'refresh') {
      dispatch(changeUserStatus([{ userId: user.id }]))
        .unwrap()
        .then(() => {
          ToastService.success(ToastMessage({ title: 'Success', body: 'Status is changed!' }), {
            toastId: user.id,
          });
        });
    } else {
      dispatch(resendInviteUser({ email: user.email, roleId: user.role.id }))
        .unwrap()
        .then(() => {
          ToastService.success(ToastMessage({ title: 'Success', body: 'Resend invite!' }), {
            toastId: user.id,
          });
        });
    }
  };

  const handleCreateUser = () => {
    setVirtualCreatingLoading(true);
    dispatch(createVirtualSpecialist())
      .unwrap()
      .finally(() => {
        setVirtualCreatingLoading(false);
        navigate(`${ROUTES.CONSULTANT_REGISTRATION}/1`, { state: { isAgencyAddingUser: true } });
      });
  };

  const handleImpersonate = (id: number) => {
    dispatch(impersonateToken(id));
  };

  return (
    <Box data-test="team-page">
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          mt: { xs: 2.5, md: 7 },
          mb: 3,
          justifyContent: 'space-between',
          alignItems: { xs: 'start', md: 'center' },
        }}
      >
        <Typography variant="h1" mb={{ xs: 2, md: 0 }}>
          Team Roster
        </Typography>
        <Box display="flex" width={{ xs: '100%', md: 'auto' }}>
          <LoadingButton
            loading={virtualCreatingLoading}
            variant="outlined"
            color="secondary"
            onClick={handleCreateUser}
            startIcon={<PlusIcon />}
            size={(mobile && 'small') || 'medium'}
            sx={{
              mr: 2,
              mb: { xs: 2, md: 0 },
              '&:active, &:focus, &:hover': {
                backgroundColor: isMobileNative ? 'transparent' : undefined,
                color: isMobileNative ? 'transparent' : undefined,
              },
            }}
            data-test="add-virtual-specialist-button"
          >
            Create Virtual Specialist
          </LoadingButton>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickListItem}
            startIcon={<PlusIcon />}
            size={(mobile && 'small') || 'medium'}
            data-test="add-team-member-button"
          >
            Add User
          </Button>
        </Box>
      </Box>

      <FormProvider {...methods}>
        <FilterTeam setPage={setPage} />
        {!mobile && (
          <ManageTable
            {...{ page, setPage }}
            onChangeStatus={handleChangeStatus}
            onImpersonate={handleImpersonate}
          />
        )}
        {mobile && (
          <ManageListMobile
            {...{ page, setPage }}
            onChangeStatus={handleChangeStatus}
            onImpersonate={handleImpersonate}
          />
        )}
      </FormProvider>

      <ModalWrapper
        dialogProps={{
          open,
          onClose: handleClose,
        }}
        title={{ title: 'Add Team Member', titleTopPadding: 1 }}
      >
        <AddMember onClose={() => handleClose()} />
      </ModalWrapper>
    </Box>
  );
};

TeamList.whyDidYouRender = true;

export default TeamList;
