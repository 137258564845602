import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledEmptyBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  textAlign: 'center',
});

const StyledOrdersBlock = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    backgroundColor: 'tertiary.light',
    mt: 1,
    mb: 2,
    p: 2,
  }),
);

const StyledTitleOrdersBlock = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);

export { StyledEmptyBlock, StyledOrdersBlock, StyledTitleOrdersBlock };
