import CustomScroll from 'react-custom-scroll';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledAgreementContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    height: {
      xs: '33vh',
      md: 'calc(100vh - 592px)',
    },
    minHeight: '30vh',
    backgroundColor: 'tertiary.main',
    fontSize: '.875rem',
  }),
);
const StyledCustomScroll: any = styled(CustomScroll)(({ theme }) =>
  theme.unstable_sx({
    pb: {
      xs: 0,
      md: 0,
    },
    pt: {
      xs: 0,
      md: 0,
    },
    overflow: 'hidden',
  }),
);
const StyledScrollInnerContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    pl: {
      xs: 0,
      md: 0,
    },
    pr: {
      xs: 0,
      md: 0,
    },
    textAlign: 'justify',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
  }),
);

export { StyledAgreementContainer, StyledCustomScroll, StyledScrollInnerContainer };
