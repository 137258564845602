import { createAsyncThunk} from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

interface IRequestParams {
  orderId: number;
  id: number;
  index: number;
  text: string;
}

const declineWeeklyBillings = createAsyncThunk<any, IRequestParams>(
  EOrder.declineWeeklyBillings,
  async ({ orderId, id, text })=> {
    const { data } = await instance.post(`orders/${orderId}/weekly-billings/${id}/disputed`, {text: text});

    return data;
  }
);

export default declineWeeklyBillings;
