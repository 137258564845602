import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';

interface IStyledBase extends BoxProps {
  isCreationPage?: boolean;
}

const StyledGrid = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      justifyContent: 'end',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
  }),
);

const StyledBoxImpersonate = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    backgroundColor: theme.palette.primary.main,
    p: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  // zIndex: 1,
  backgroundColor: theme.palette.common.white,
}));

const StyledDiv = styled('div')<IStyledBase>(({ isCreationPage, theme }) =>
  theme.unstable_sx({
    flex: '1 1 12%',
    maxWidth: {
      xs: 'none',
      sm: 400,
    },
    height: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      minWidth: 188,
    },
    '& svg': {
      width: {
        xs: 142,
        md: 165,
      },
    },
    '& .word': {
      fill: {
        xs: '#1f1f1f',
        md: 'white',
      },
    },
    ...(isCreationPage && {
      backgroundColor: {
        xs: 'common.white',
        md: 'secondary.main',
      },
      justifyContent: {
        md: 'center',
      },
      minWidth: {
        xs: 188,
        md: 230,
      },
      '& header': {
        border: '0',
      },
    }),
  }),
);

const StyledHeader = styled('header')<IStyledBase>(({ isCreationPage }) => ({
  borderBottom: '1px solid rgba(0,0,0,.1)',
  flex: '0 1 100%',
  display: 'flex',
  zIndex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  ...(isCreationPage && {
    border: 'none',
  }),
}));

interface IStyledRightSide {
  isCreationPage?: boolean;
}
const StyledRightSide = styled('nav')<IStyledRightSide>(({ isCreationPage, theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: '1 1 70%',
  justifyContent: 'flex-end',
  fontSize: '1.125rem',
  textDecoration: 'none',
  [theme.breakpoints.up('md')]: {
    ...(isCreationPage && {
      paddingBottom: 4,
      borderBottom: '1px solid rgba(0,0,0,.1)',
    }),
  },
}));

const StyledListItem = styled(Box)(({ theme }) => ({
  '& .MuiListItem-root': {
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    '&.active:after': {
      backgroundColor: theme.palette.primary.main,
      bottom: -12,
      content: '""',
      height: 3,
      left: 0,
      position: 'absolute',
      width: '100%',
    },
    '@media (min-width: 992px) and (max-width: 1247px)': {
      whiteSpace: 'normal',
      lineHeight: 1.2,
      width: '130px',
      textAlign: 'center',
    },
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export {
  StyledBox,
  StyledBoxImpersonate,
  StyledDiv,
  StyledHeader,
  StyledGrid,
  StyledRightSide,
  StyledListItem,
};
