import { createSlice } from '@reduxjs/toolkit';

import { Socket } from 'socket.io-client';

import { ENotification } from 'store/constants';

import { INotifications } from '../types/common';
import { IError } from 'models/inner-models';
import markActionedNotification from 'store/query/notification/markActionedNotification';
import initNotifications from '../query/notification/initNotifications';
import shownNotification from '../query/notification/shownNotification';

export interface NotificationState {
  notifications: INotifications[] | null;
  actionError: IError | null;
  loading: boolean;
  loaded: boolean;
  socket?: Socket;
}

const initialState: NotificationState = {
  notifications: null,
  actionError: null,
  loading: true,
  loaded: false,
};

const { actions, reducer } = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setWebsocketId: (state, { payload }) => {
      state.socket = payload;
    },
    setNewNotification: (state, { payload }) => {
      state.notifications = [payload, ...(state.notifications as INotifications[])];
    },
  },
  extraReducers: builder =>
    builder
      .addCase(ENotification.init, state => {
        state.loading = true;
        state.actionError = null;
        state.loaded = false;
      })
      .addCase(initNotifications.pending, state => {
        state.loading = true;
        state.actionError = null;
        state.loaded = false;
      })
      .addCase(initNotifications.fulfilled, (state, action) => {
        const { payload } = action;
        state.notifications = payload.length ? [...payload] : [];
        state.loading = false;
      })
      .addCase(shownNotification.fulfilled, (state, action) => {
        const { meta } = action;
        if (state.notifications) {
          state.notifications = [
            ...state.notifications.map(item => {
              if (meta.arg.ids.some((id: number) => item.id === id)) {
                return {
                  ...item,
                  isShow: true,
                };
              }
              return item;
            }),
          ];
        }
      })
      .addCase(markActionedNotification.fulfilled, (state, { payload, meta }) => {
        if (state.notifications && payload) {
          state.notifications = [
            ...state.notifications.map(item => {
              if (meta.arg.ids.some((id: number) => item.id === id)) {
                return {
                  ...item,
                  isAction: true,
                };
              }
              return item;
            }),
          ];
        }
      }),
});

export const { setNewNotification, setWebsocketId } = actions;
export default reducer;
