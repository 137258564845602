export const headCells: any[] = [
  {
    id: 'order',
    label: 'Order',
    width: '45',
  },
  {
    id: 'name',
    label: 'Name',
    width: 'auto',
  },
  {
    id: 'rate',
    label: 'Rate',
    width: '130',
    align: 'center',
    only: 'lead_source',
  },
  {
    id: 'sort',
    label: 'Sort',
    width: '70',
  },
  {
    id: 'actions',
    label: '',
    width: '30',
  },
];
