import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EAgency } from "../../constants";

const getMatchedAgencyById = createAsyncThunk(
  EAgency.getMatchedAgencyById,
  async (payload: number | string) => {
    const { data } = await instance.get(`/agency/${payload}`)
    return data
  }
)

export default getMatchedAgencyById
