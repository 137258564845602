import { createAsyncThunk} from '@reduxjs/toolkit';
import { AxiosResponse } from "axios";

import instance from 'store/middlewares/api';
import { ENotification } from 'store/constants';

interface IMarkActioned {
  ids: number[];
}

const markActionedNotification = createAsyncThunk<AxiosResponse, IMarkActioned>(
  ENotification.actionedNotification, async ( payload ) => {
    const { data } = await instance.post('notification/action', payload);

    return data?.success;
  }
)

export default markActionedNotification;
