import React, { FunctionComponent, useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

import CloseIcon from 'styles/icons/CloseIcon';
import { StyledCloseBtn, StyledSubtitle } from './styled';

interface IDialogTitle {
  title?: string;
  subTitle?: string;
  titleTopPadding?: number;
}
interface IExtendDialogProps extends DialogProps {
  'data-test'?: string;
}

interface IDialogProps {
  children?: React.ReactNode;
  title?: IDialogTitle;
  dialogProps: IExtendDialogProps;
  closeIcon?: React.ReactElement;
  withoutTitle?: boolean;
}

const ModalWrapper: FunctionComponent<IDialogProps> = ({
  children,
  closeIcon,
  dialogProps,
  title,
  withoutTitle,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isMobileNative = Capacitor.isNativePlatform();

  return (
    <Dialog {...dialogProps} fullScreen={fullScreen} fullWidth={true}>
      {!withoutTitle && (
        <DialogTitle
          mt={{
            xs: isMobileNative ? title?.titleTopPadding : undefined,
            md: 4,
          }}
        >
          {title?.title && (
            <Typography
              data-test="modal-title"
              component="p"
              fontSize="inherit"
              lineHeight="normal"
              fontWeight="700"
            >
              {title && title.title}
            </Typography>
          )}
          {title?.subTitle && (
            <StyledSubtitle id="customer-register-header" component="p" variant="h6">
              {title && title.subTitle}
            </StyledSubtitle>
          )}
          {dialogProps.onClose && (
            <StyledCloseBtn
              onClick={e => dialogProps.onClose && dialogProps.onClose(e, 'escapeKeyDown')}
              data-test="modal-close-button"
            >
              {(closeIcon && closeIcon) || <CloseIcon />}
            </StyledCloseBtn>
          )}
        </DialogTitle>
      )}
      <Box>{children}</Box>
    </Dialog>
  );
};

export default ModalWrapper;
