const formatDurationYearsOnly = (months: number) => {
  let years = Math.floor(months / 12);

  if (months % 12 > 5) {
    years += 1;
  }

  let formattedDuration = '';

  if (years > 0) {
    formattedDuration += `${years} ${years === 1 ? 'year' : 'years'}`;
  }

  return formattedDuration;
};

export default formatDurationYearsOnly;
