import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";

import {StarFilledIcon2} from "../../../styles/icons/StarFilledIcon2";

const StyledBoxTotal = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  alignItems: 'center',
  flexBasis: {xs: 'auto', md: '100%'},
}))

const StyledRate = styled(Typography)(({ theme }) => theme.unstable_sx({
  background: '#1F1F1F',
  color: '#FFF',
  borderRadius: 100,
  marginRight: theme.spacing(),
  padding: '3px 10px',
  fontWeight: 700,
}))

const StyledIcon = styled(StarFilledIcon2)(({ theme }) => theme.unstable_sx({
  width: 15,
  height: 15,
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(),
  marginTop: -2
}))

const StyledButton = styled(Button)(({ theme }) => theme.unstable_sx({
  width: {xs: '316px', md: 'auto'},
  margin: {xs: '0 auto', md: 0},
  display: 'block',
}));

const StyledTitle = styled(Typography)(({ theme }) => theme.unstable_sx({
  borderBottom: {xs: '1px solid #ebebeb', md: 'none'},
  paddingBottom: {xs: 2, md: 0},
  marginBottom: {xs: 2, md: 0},
}));

const StyledReviewsHeader = styled(Grid)(({ theme }) => theme.unstable_sx({
  marginBottom: {xs: 1.5, md: 0},
  borderBottom: {xs: '1px solid #ebebeb', md: 'none'},
  paddingBottom: {xs: 1.5, md: 0},
}));

export {
  StyledBoxTotal,
  StyledIcon,
  StyledRate,
  StyledButton,
  StyledTitle,
  StyledReviewsHeader,
}
