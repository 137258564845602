import { createAsyncThunk } from "@reduxjs/toolkit";

import instance from "../../middlewares/api";
import { EConsultant } from "../../constants";
import { ICompaniesLogos } from "../../types/company";

interface IGetCompaniesLogos {
  orderId: number,
  workerId: number
}

const getCompaniesLogos = createAsyncThunk<ICompaniesLogos[], IGetCompaniesLogos>(
  EConsultant.getCompaniesLogos,
  async ({ workerId }) => {
    const { data } = await instance.get(`/consultant/${workerId}/companies-logos`);
    return data
  }
)

export default getCompaniesLogos

