import axios, { AxiosError, AxiosResponse } from 'axios';
import { Store } from '@reduxjs/toolkit';
import {
  CONSULTANT_URL,
  ELocalStoreKeys,
  EServerStatusCode,
  ORDER_URL,
  PIPEDRIVE_URL,
} from '../../models/consts';
import { removeAuthTokens, removeImpersonateTokens, saveTokens } from '../../utils/apiCallHelpers';

import config from '../../app.config';

import { ToastService } from '../../services';

import ToastMessage from 'components/Toast/ToastMessage';
import { Cookies } from 'react-cookie';

const cookies = new Cookies(null, { path: '/', sameSite: 'lax' });

export const getToken = () => {
  return (
    (typeof window !== 'undefined' &&
      (cookies.get(ELocalStoreKeys.IMPERSONATE_TOKEN) ||
        cookies.get(ELocalStoreKeys.ACCESS_TOKEN))) ||
    ''
  );
};

let isRefreshing = false;
let failedQueue: Array<any> = [];

const processQueue = (error: AxiosError | Error | null, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const defaultHeaders = { 'Content-Type': 'application/json' };
const instance = axios.create({
  baseURL: config.REACT_APP_API_URL,
  headers: defaultHeaders,
});

const resetData = () => {
  removeAuthTokens();
  removeImpersonateTokens();
};

const handleRequest = (config: any, store: Store) => {
  if (!store.getState().mobile.isOnline) {
    return Promise.reject(new Error('No internet connection'));
  }

  const token = getToken();

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};
const handleResponse = (response: AxiosResponse) => {
  return response;
};
const handleResponseError = (error: any, store: Store) => {
  const { response } = error;
  const refreshToken =
    response.config?.headers['Refresh-Auth'] ||
    cookies.get(ELocalStoreKeys.IMPERSONATE_REFRESH_TOKEN) ||
    cookies.get(ELocalStoreKeys.REFRESH_TOKEN);

  if (response) {
    if (response.status >= 500 && response.status < 600) {
      console.error({
        title: 'Internal server error',
        text: 'Server response with error, please try again later',
      });
    }

    if (response.status === EServerStatusCode.EXPIRED_TOKEN) {
      if (refreshToken) {
        const originalRequest = error.config;
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(() => {
              instance(originalRequest);
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }

        isRefreshing = true;

        return new Promise((resolve, reject) => {
          axios
            .post(
              `${config.REACT_APP_API_URL}/auth/new-tokens`,
              {},
              {
                headers: {
                  'Refresh-Auth': refreshToken,
                },
              },
            )
            .then(({ data }) => {
              originalRequest.headers['Authorization'] = data.accessToken;
              saveTokens(data.accessToken, data.refreshToken);
              processQueue(null, data.accessToken);
              resolve(instance(originalRequest));
            })
            .catch(err => {
              processQueue(err, null);
              resetData();
              typeof window !== 'undefined' ? (window.location.href = '/auth') : null;
              reject(err.response.data);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      } else {
        typeof window !== 'undefined' ? (window.location.href = '/auth') : null;
        resetData();
      }
    }
  }

  if (
    !(
      response.status === 404 &&
      (ORDER_URL.test(response.config.url) ||
        CONSULTANT_URL.test(response.config.url) ||
        PIPEDRIVE_URL.test(response.config.url))
    )
  ) {
    ToastService.error(ToastMessage({ title: 'Error', body: response.data.message }), {
      toastId: error.config?.url,
    });
  }

  return Promise.reject(error);
};

export const setupResponseInterceptor = (store: Store) => {
  instance.interceptors.request.use(config => handleRequest(config, store));
  instance.interceptors.response.use(handleResponse, error => handleResponseError(error, store));
};

export default instance;
