import {createAsyncThunk} from "@reduxjs/toolkit";

import instance from "../../middlewares/api";
import { EStripe } from "../../constants/stripe";

interface IStripeDashboardLinkProps {
  redirectUri: string;
}

const getConsultantStripeDashboardLink = createAsyncThunk<{ url: string }, IStripeDashboardLinkProps>(
  EStripe.getConsultantStripeDashboardLink,
  async (payload)=> {
    const { data } = await instance.post('consultant/payment/stripe-consultant-dashboard', {...payload})
    return data;
  }
);

export default getConsultantStripeDashboardLink;
