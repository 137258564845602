import {createAsyncThunk} from "@reduxjs/toolkit";

import instance from "../../middlewares/api";
import { EStripe } from "../../constants/stripe";

interface IStripeDashboardLinkProps {
  redirectUri: string;
}

const getCustomerStripeDashboardLink = createAsyncThunk<{ url: string }, IStripeDashboardLinkProps>(
  EStripe.getCustomerStripeDashboardLink,
  async (payload)=> {
    const { data } = await instance.post('/customer/payment/stripe-customer-dashboard', {...payload})
    return data;
  }
);

export default getCustomerStripeDashboardLink;
