import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EConsultant } from 'store/constants';

const downloadCV = createAsyncThunk(
  EConsultant.downloadCV,
  async () => {
    const { data } = await instance.get(`/consultant/cv-file`);
    return data;
  }
)

export default downloadCV;
