import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const CertifiedIcon: FunctionComponent<ISvgProps> = ({className}) => {
  return (
    <svg className={className} width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.28689 17.2322L2.58197 16.6421C2.33607 16.5929 2.13934 16.4657 1.9918 16.2604C1.84426 16.0558 1.78689 15.8306 1.81967 15.5847L2.09016 12.806L0.245902 10.6913C0.0819672 10.5109 0 10.2978 0 10.0519C0 9.80601 0.0819672 9.5929 0.245902 9.41257L2.09016 7.29781L1.81967 4.51913C1.78689 4.27322 1.84426 4.04798 1.9918 3.84339C2.13934 3.63814 2.33607 3.51093 2.58197 3.46175L5.28689 2.87158L6.71312 0.461749C6.84426 0.248634 7.02459 0.10929 7.2541 0.0437159C7.48361 -0.0218579 7.71312 -0.0136612 7.94262 0.068306L10.5 1.15027L13.0574 0.068306C13.2869 -0.0136612 13.5164 -0.0218579 13.7459 0.0437159C13.9754 0.10929 14.1557 0.248634 14.2869 0.461749L15.7131 2.87158L18.418 3.46175C18.6639 3.51093 18.8607 3.63814 19.0082 3.84339C19.1557 4.04798 19.2131 4.27322 19.1803 4.51913L18.9098 7.29781L20.7541 9.41257C20.918 9.5929 21 9.80601 21 10.0519C21 10.2978 20.918 10.5109 20.7541 10.6913L18.9098 12.806L19.1803 15.5847C19.2131 15.8306 19.1557 16.0558 19.0082 16.2604C18.8607 16.4657 18.6639 16.5929 18.418 16.6421L15.7131 17.2322L14.2869 19.6421C14.1557 19.8552 13.9754 19.9945 13.7459 20.0601C13.5164 20.1257 13.2869 20.1175 13.0574 20.0355L10.5 18.9536L7.94262 20.0355C7.71312 20.1175 7.48361 20.1257 7.2541 20.0601C7.02459 19.9945 6.84426 19.8552 6.71312 19.6421L5.28689 17.2322Z" fill="#F4B63F"/>
      <path d="M5 26.5V11H16V27L10.2381 21.5L5 26.5Z" fill="#F4B63F"/>
    </svg>
  );
};
