import {useState, useEffect, useCallback} from 'react';

type DeviceType = 'pc' | 'tablet' | 'mobile' | 'bot';
type OS = 'ios' | 'android' | 'other' | 'bot' | 'Mac OS';
type Browser = 'chrome' | 'firefox' | 'safari' | 'edge' | 'ie' | 'other' | 'bot';
const clientStrings = [
  {s:'Windows 10', r:/(Windows 10.0|Windows NT 10.0)/},
  {s:'Windows 8.1', r:/(Windows 8.1|Windows NT 6.3)/},
  {s:'Windows 8', r:/(Windows 8|Windows NT 6.2)/},
  {s:'Windows 7', r:/(Windows 7|Windows NT 6.1)/},
  {s:'Windows Vista', r:/Windows NT 6.0/},
  {s:'Windows Server 2003', r:/Windows NT 5.2/},
  {s:'Windows XP', r:/(Windows NT 5.1|Windows XP)/},
  {s:'Windows 2000', r:/(Windows NT 5.0|Windows 2000)/},
  {s:'Windows ME', r:/(Win 9x 4.90|Windows ME)/},
  {s:'Windows 98', r:/(Windows 98|Win98)/},
  {s:'Windows 95', r:/(Windows 95|Win95|Windows_95)/},
  {s:'Windows NT 4.0', r:/(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
  {s:'Windows CE', r:/Windows CE/},
  {s:'Windows 3.11', r:/Win16/},
  {s:'Android', r:/Android/},
  {s:'Open BSD', r:/OpenBSD/},
  {s:'Sun OS', r:/SunOS/},
  {s:'Chrome OS', r:/CrOS/},
  {s:'Linux', r:/(Linux|X11(?!.*CrOS))/},
  {s:'iOS', r:/(iPhone|iPad|iPod)/},
  {s:'Mac OS X', r:/Mac OS X/},
  {s:'Mac OS', r:/(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
  {s:'QNX', r:/QNX/},
  {s:'UNIX', r:/UNIX/},
  {s:'BeOS', r:/BeOS/},
  {s:'OS/2', r:/OS\/2/},
  {s:'Search Bot', r:/(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
];

interface DeviceInfo {
  deviceType: DeviceType;
  os: string;
  browser: Browser;
  screenDimensions: string;
  userAgent: string;
}

const useDeviceInfo = (): DeviceInfo => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    deviceType: 'pc',
    os: 'other',
    browser: 'other',
    screenDimensions: 'unknown',
    userAgent: ''
  });

  const handleResize = useCallback(() => {
    const { userAgent, appVersion } = window.navigator;

    const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(userAgent);

    let os = 'other';
    // if (isBot) {
    //   os = 'bot';
    // } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    //   os = 'ios';
    // } else if (/Android/.test(userAgent)) {
    //   os = 'android';
    // } else if (/Mac OS/.test(userAgent)) {
    //   os = 'Mac OS';
    // }

    for (const id in clientStrings) {
      const clientString = clientStrings[id];
      if (clientString.r.test(userAgent)) {
        os = clientString.s;
        break;
      }
    }

    let osVersion: string | null = '';

    if (/Windows/.test(os)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      osVersion = /Windows (.*)/.exec(os)?.length[1];
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS':
      case 'Mac OS X':
      case 'Android':
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([._\d]+)/.exec(userAgent)[1];
        break;

      case 'iOS':
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(appVersion);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
        break;
    }

    let browser: Browser = "other";

    if (isBot) {
      browser = "bot";
    } else if (/Chrome/.test(userAgent)) {
      browser = "chrome";
    } else if (/Firefox/.test(userAgent)) {
      browser = "firefox";
    } else if (/Safari/.test(userAgent)) {
      browser = "safari";
    } else if (/Edge/.test(userAgent)) {
      browser = "edge";
    } else if (/Trident/.test(userAgent)) {
      browser = "ie";
    }

    let deviceType: DeviceType = "pc";

    const isTablet = /iPad|Android(?!.*Mobile)|Tablet|Silk/i.test(userAgent);
    const isMobile =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );

    if (isBot) {
      deviceType = "bot";
    } else if (isTablet) {
      deviceType = "tablet";
    } else if (isMobile) {
      deviceType = "mobile";
    } else {
      deviceType = "pc";
    }

    const screenDimensions = `${screen.width}x${screen.height}`

    setDeviceInfo({
      deviceType,
      os: os + ' ' + osVersion,
      browser,
      screenDimensions,
      userAgent,
    });
  }, []);

  useEffect(() => {

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ handleResize ]);


  return deviceInfo;
};

export default useDeviceInfo;
