import { createAsyncThunk} from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { EOrder } from '../../constants';

interface IRequestParams {
  ids: number[];
}

const earlyOrderComplete = createAsyncThunk<any, IRequestParams>(
  EOrder.earlyOrderComplete,
  async (payload) => {
    const { data } = await instance.patch('orders/early-complete', payload);

    return data
  }
)

export default earlyOrderComplete;
