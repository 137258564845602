import { createSlice } from '@reduxjs/toolkit';

import map from 'lodash/fp/map';
import cond from 'lodash/fp/cond';
import has from 'lodash/fp/has';
import get from 'lodash/fp/get';

import { IMatchedCard } from '../types/consultant';
import { CommonEntities } from '../types/common';

import { DEFAULT_ENTITY } from '../../models/consts';
import { ITimelineBasis } from '../../models/inner-models';

import searchWithMatching from '../query/order-creation/searchWithMatching';
import getMatchedCardById from '../query/order-creation/getMatchedCardById';
import searchTalent from 'store/query/talent-search/searchTalent';

const transformArray = map(
  cond([
    [
      has('specialist'),
      (item: IMatchedCard) => ({ ...item, id: get('specialist.consultant.id')(item) }),
    ],
    [has('agency'), (item: IMatchedCard) => ({ ...item, id: get('agency.id')(item) })],
  ]),
);

export interface TalentSearchState {
  matchedTalents: CommonEntities<IMatchedCard[]>;
}

const initialState: TalentSearchState = {
  matchedTalents: DEFAULT_ENTITY,
};

const { actions, reducer } = createSlice({
  name: 'talent-search',
  initialState,
  reducers: {
    /**
     * TODO: Will be refactored
     * @param state
     */
    resetMatchedTalents: state => {
      state.matchedTalents = DEFAULT_ENTITY;
    },
  },
  extraReducers: builder =>
    builder
      /**
       *  Matching Consultant and Agency
       */
      .addCase(searchTalent.pending, (state, action) => {
        const { meta } = action;

        state.matchedTalents = {
          ...state.matchedTalents,
          items: [...((meta.arg.offset && state.matchedTalents.items) || [])],
          loading: true,
          loaded: false,
        };
      })
      .addCase(searchTalent.fulfilled, (state, action) => {
        const {
          payload: { cards, countAll },
        } = action;

        state.matchedTalents = {
          countAll,
          items: [...state.matchedTalents.items, ...transformArray(cards)],
          hasMore: countAll > state.matchedTalents.items.length + cards.length,
          loading: false,
          loaded: true,
        };
      }),
});

export const { resetMatchedTalents } = actions;

export default reducer;
