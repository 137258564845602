import React, { useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import useDeviceInfo from 'hooks/useDeviceInfo';

import { IAgency } from 'store/types/agency';

import Avatar from 'components/Avatar';

import {
  StyledAgencyRecommendedContainer,
  StyledAgencyRecommendedWrap,
  StyledCarouselControl,
} from './styled';

interface IAgencyConsultantsList {
  agency: IAgency;
  activeConsultant?: number;
  onSelect: (index: number) => void;
  isSalesOrTalent?: boolean;
}

const AgencyConsultantsList: React.FunctionComponent<IAgencyConsultantsList> = ({
  agency,
  activeConsultant,
  onSelect,
  isSalesOrTalent,
}) => {
  const [index, setIndex] = useState(0);
  const theme = useTheme();

  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { deviceType } = useDeviceInfo();

  const isMobile = deviceType === 'mobile' && mobileScreen;

  return (
    <>
      <Typography variant="h3" mb={3}>
        Consultants
      </Typography>
      <StyledAgencyRecommendedContainer>
        {agency.matchedConsultants &&
          !!agency.matchedConsultants.data.length &&
          agency.matchedConsultants.data.map((item: any) => {
            const isActive = activeConsultant === item.userId;
            return (
              <StyledAgencyRecommendedWrap key={`cons-${item.userId}`}>
                <Fade in={isActive}>
                  <Box
                    px={isMobile ? 2 : 4}
                    pb={1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    maxWidth="144px"
                    position="relative"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => onSelect(item.userId)}
                  >
                    <Avatar
                      size={isMobile ? 'medium' : 'pre-large'}
                      image={item.avatarUrl}
                      firstName={item.initials || ''}
                    />
                    <Typography textAlign="center" variant="subtitle1" mt={1}>
                      {!isSalesOrTalent
                        ? item.initials
                        : (item?.firstName && [item?.firstName, item?.lastName].join(' ')) ||
                          item?.initials}
                    </Typography>
                  </Box>
                </Fade>
              </StyledAgencyRecommendedWrap>
            );
          })}
        <StyledCarouselControl>
          <IconButton
            disabled={index === 0}
            onClick={() => {
              setIndex(i => --i);
              onSelect(agency.matchedConsultants.data[index - 1].userId);
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            disabled={index === agency.matchedConsultants.data.length - 1}
            onClick={() => {
              setIndex(i => ++i);
              onSelect(agency.matchedConsultants.data[index + 1].userId);
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
        </StyledCarouselControl>
      </StyledAgencyRecommendedContainer>
    </>
  );
};

export default AgencyConsultantsList;
