import { createAsyncThunk} from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';

import { IAgencyUser } from "../../types/agency";
import { EAgency } from "../../constants/";

interface IResponseAgency {
  count: number;
  page: number;
  users: IAgencyUser[]
}

const usersTeam = createAsyncThunk<IResponseAgency, any>(
  EAgency.usersTeam,
  async ({ filter, key, ...params }) => {
    const { data } = await instance.get(`agency/users-team`, { params: { ...filter, ...params } });
    if (
      filter?.filterByEmail !== undefined && filter?.filterByEmail !== ''
      || filter?.filterByEmailOrName !== undefined && filter?.filterByEmailOrName !== ''
    ) {
      const filteredData = {
        ...data,
      };
      return filteredData
    }
    return data
  }
);


export default usersTeam;
