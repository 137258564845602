import React, { FC, useEffect, useRef, useState } from 'react';
import { Controller, RefCallBack, useFormContext } from 'react-hook-form';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { MAX_AVATAR_IMAGE_SIZE } from 'models/consts';

import Avatar from '../../Avatar';
import ImageCropperModal from '../../Modal/ImageCropperModal/ImageCropperModal';
import { ToastService } from 'services';
import ToastMessage from 'components/Toast/ToastMessage';

interface IUploadAvatar {
  firstName?: string;
  onUploadPhoto?: (blob: Blob, uploadImage?: string) => void;
  icon: any;
  image?: string | null;
  inputRef?: RefCallBack;
  lastName?: string;
  round?: boolean;
  size: 'small' | 'medium' | 'large' | undefined;
  uploadDisabled?: boolean;
  onDialogToggle?: (value: boolean) => void;
}

// @ts-ignore
interface IIconButton extends IconButtonProps {
  component?: string;
}

const StyledIconButton = styled(IconButton)<IIconButton>({
  '&.large': {
    height: 104,
    width: 104,
    flex: '0 0 104px',
  },
  '&.small': {
    height: 48,
    width: 48,
    flex: '1 0 48px',
    fontSize: '0.875em',
  },
  '&.medium': {
    height: 60,
    width: 60,
    flex: '0 0 60px',
  },
});

const UploadPhoto: FC<IUploadAvatar> = ({
  firstName,
  onUploadPhoto,
  icon,
  image,
  lastName,
  round,
  size,
  uploadDisabled,
  onDialogToggle,
}) => {
  const { control } = useFormContext();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [uploadImage, setUploadImage] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(image || null);

  const [isOpenCropper, setCropperState] = useState<boolean>(false);

  const onImageUpload = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      if (file.size < MAX_AVATAR_IMAGE_SIZE) {
        const reader = new FileReader();
        reader.onload = () => {
          setUploadImage(reader.result as any);
          setCropperState(true);
          e.target.value = null;
          onDialogToggle && onDialogToggle(true);
        };
        reader.readAsDataURL(file);
      } else if (file.size > MAX_AVATAR_IMAGE_SIZE) {
        ToastService.error(
          ToastMessage({
            title: 'Error',
            body: 'Please upload a photo smaller than 5Mb',
          }),
        );
      } else {
        onDialogToggle && onDialogToggle(true);
      }
    }
  };

  const handleCropperClose = (uploadImage?: string, blob?: Blob) => {
    if (uploadImage && blob) {
      setCroppedImage(uploadImage);
      onUploadPhoto && onUploadPhoto(blob, uploadImage);
    }
    onDialogToggle && onDialogToggle(false);
    setCropperState(false);
    setUploadImage('');
  };

  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      const handleFocus = () => {
        onDialogToggle && onDialogToggle(true);
      };

      const handleBlur = () => {
        setTimeout(() => {
          if (document.activeElement !== input) {
            onDialogToggle && onDialogToggle(false);
          }
        }, 0);
      };

      input.addEventListener('focus', handleFocus);
      input.addEventListener('blur', handleBlur);

      return () => {
        input.removeEventListener('focus', handleFocus);
        input.removeEventListener('blur', handleBlur);
      };
    }
  }, [onDialogToggle]);

  const resetFileInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <Box display="inline-flex">
      {uploadDisabled && (
        <Avatar
          size={size}
          icon={icon}
          image={image || ''}
          firstName={firstName || ''}
          lastName={lastName || ''}
          round={round}
        />
      )}
      {!uploadDisabled && (
        <Controller
          name="image"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <label htmlFor="icon-button-file">
              <input
                id="icon-button-file"
                {...field}
                onChange={onImageUpload}
                hidden
                accept=".png, .jpeg, .jpg, .PNG, .JPEG, .JPG"
                type="file"
                ref={inputRef}
                onClick={resetFileInput}
              />
              <StyledIconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                className={size}
              >
                <Avatar
                  round={round}
                  size={size}
                  icon={icon}
                  image={croppedImage || image || ''}
                  firstName={firstName || ''}
                  lastName={lastName || ''}
                />
              </StyledIconButton>
            </label>
          )}
        />
      )}
      <ImageCropperModal
        visible={isOpenCropper}
        image={uploadImage || ''}
        close={handleCropperClose}
      />
    </Box>
  );
};
export default UploadPhoto;
