import { Components } from '@mui/material/styles';
import { menuItemClasses } from '@mui/material/MenuItem';

const MuiMenuItem: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      '&:hover, &:hover svg, &.Mui-selected:hover, .Mui-selected:hover svg, &.Mui-selected, .Mui-selected svg ': {
        backgroundColor: '#1F1F1F',
        color: '#FFF'
      },
      [`&.${menuItemClasses.disabled}.${menuItemClasses.selected}, &.${menuItemClasses.focusVisible}.${menuItemClasses.selected}, &.${menuItemClasses.focusVisible}:hover`]: {
        backgroundColor: '#1F1F1F',
        color: '#FFF',
        opacity: 1
      },
      [`&.${menuItemClasses.focusVisible}`]: {
        backgroundColor: '#fff',
        color: '#1F1F1F',
      },
    },
  }
}

export default MuiMenuItem
