import { createAsyncThunk} from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EEnterprise } from "store/constants/enterprise";

import {IEnterpriseUser} from "../../types/enterprise";

interface IAcceptUsersOrders {
  userId: number;
}
const acceptUsersOrders = createAsyncThunk<IEnterpriseUser[], [number, IAcceptUsersOrders[]]>(
  EEnterprise.acceptUsersOrders,
  async ([id, users]) => {
    const { data } = await instance.post(`enterprise/purchase-orders/${id}/accept-users`, { data: users });

    return data
  }
);

export default acceptUsersOrders;
