import Box from '@mui/material/Box';
import { dividerClasses } from '@mui/material/Divider';
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

interface IStyledCertificateGridProps extends Grid2Props {
  isEditable?: boolean;
};

interface IStyledBase {
  children?: any;
  className?: string;
}

interface IStyledImageProps extends IStyledBase {
  alt?: string;
  src: string;
  title?: string;
  unique: string;
}

const StyledBox = styled(Box)(({theme}) => theme.unstable_sx({
  pb: .5,
  borderBottom: '1px solid #ebebeb',
}));

const StyledGridCertificateContainer = styled(Grid)<IStyledCertificateGridProps>(({ isEditable, theme }) => theme.unstable_sx({
  position: 'relative',
  backgroundColor: isEditable ? '#F9F9F9' : 'none',
  mt: isEditable ? 3 : 0,
  p: 1,
  [`&:last-child .${dividerClasses.root}`]: {
    display: 'none'
  }
}));

const StyledImageBox = styled(Box)(({theme}) => theme.unstable_sx({
  boxSizing: 'border-box',
  position: 'relative',
  width: {
    xs: '63px',
    lg: '75px',
  },
  height: {
    xs: '69px',
    lg: '85px',
  },
  [theme.breakpoints.down('md')]: {
    m: 'auto'
  }
}));

const StyledActionsGrid = styled(Grid)(({ theme }) => theme.unstable_sx({
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}))

const FeatureTitleContainer = styled(Grid)({
  width: '130px',
  boxSizing: 'border-box',
  flexGrow: 0,
  flexShrink: 0,
});

const FeatureDataContainer = styled(Grid)({
  flexGrow: 0,
  width: 'calc(100% - 130px)',
});

const FeatureDataText = styled(Typography)({
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
});

const StyleLink = styled('a')(({theme}) => theme.unstable_sx({
  position: 'absolute',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'tertiary.main',
  right: -6,
  bottom: -10,
  cursor: 'pointer',
  zIndex: 1,
}));

const StyledPreviewImg = styled('img')<IStyledImageProps>(({unique, theme}) => theme.unstable_sx({
  WebkitClipPath: `url(#${unique})`,
  clipPath: `url(#${unique})`,
  backgroundSize: 'cover',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));

const StyledPreviewPdf = styled('embed')<IStyledImageProps>(({unique, theme}) => theme.unstable_sx({
  WebkitClipPath: `url(#${unique})`,
  clipPath: `url(#${unique})`,
  backgroundSize: 'cover',
  width: {
    xs : '63px',
    md: '100%',
  },
  height: {
    xs : '69px',
    md: '100%',
  },
}));

export {
  FeatureTitleContainer,
  FeatureDataContainer,
  FeatureDataText,
  StyledActionsGrid,
  StyledBox,
  StyledImageBox,
  StyleLink,
  StyledGridCertificateContainer,
  StyledPreviewImg,
  StyledPreviewPdf,
};
