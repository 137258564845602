import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';

import { EOrder } from '../../constants';
import { EOrderFilter, ERole, ESorting } from 'models/consts';

import { IPagination } from '../../types/inner-models';
import { IRole } from '../../types/common';
import { IBasicOrders } from '../../types/order';

export interface IOrderPaginated extends IPagination {
  params: IOrderParams;
  filter: EOrderFilter;
  sortStartDate?: ESorting;
  sort?: ESorting;
  id?: string | number;
  key: string;
  role: IRole;
}

interface IOrderParams {
  isAssignWorker?: boolean;
  limit: number;
  sortStartDate?: string;
  offset?: number;
  statuses?: string[];
  addStatuses?: string[];
}

const fetchOrderList = createAsyncThunk<IBasicOrders[], IOrderPaginated>(
  EOrder.getList,
  async ({ params: { addStatuses, ...params }, filter, role, ...payload }) => {
    let url;

    if (filter === EOrderFilter.PAST_ORDERS || filter === EOrderFilter.PAST_PURCHASE_ORDERS) {
      params['statuses'] = ['completed', 'terminated', 'terminated_during_trial'];
    } else if (filter === EOrderFilter.WAITING_FOR_BILLING) {
      params['statuses'] = ['created', 'waiting_for_payment'];
    } else if (filter === EOrderFilter.PREPAID) {
      params['statuses'] = ['ready_to_start', 'active', 'on_hold'];
    } else if (filter === EOrderFilter.UNREALIZED_ORDERS) {
      params['statuses'] = ['expired', 'canceled'];
    } else if (filter === EOrderFilter.UNASSIGNED || filter === EOrderFilter.ASSIGNED) {
      params['statuses'] = [
        'created',
        'waiting_for_payment',
        'ready_to_start',
        'active',
        'on_hold',
      ];
      params['isAssignWorker'] = filter === EOrderFilter.ASSIGNED;
    } else if (
      filter === EOrderFilter.CURRENT ||
      filter === EOrderFilter.PURCHASE_ORDERS ||
      filter === EOrderFilter.CURRENT_PURCHASE_ORDERS
    ) {
      params['statuses'] = [
        'created',
        'waiting_for_payment',
        'ready_to_start',
        'active',
        'on_hold',
      ];
    }

    if (addStatuses) {
      params.statuses?.push(...addStatuses);
    }

    if (
      (filter === EOrderFilter.PURCHASE_ORDERS ||
        filter === EOrderFilter.CURRENT_PURCHASE_ORDERS ||
        filter === EOrderFilter.PAST_PURCHASE_ORDERS) &&
      (role.name === ERole.CUSTOMER_ADMIN || role.name === ERole.CUSTOMER)
    ) {
      url = `enterprise/purchase-orders/${payload.id}/orders`;
    } else if (
      role.name === ERole.AGENCY_ADMIN ||
      (role.name === ERole.RESOURCE_MANAGER && filter !== EOrderFilter.DRAFT_ORDERS)
    ) {
      url = `agency/customer/${payload.id}/orders`;
    } else if (filter === EOrderFilter.DRAFT_ORDERS) {
      url = `draft-orders`;
    } else {
      url = `orders`;
    }
    const { data } = await instance.get(url, { params });

    return data;
  },
);

export default fetchOrderList;
