import { createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosResponse} from "axios";

import instance from 'store/middlewares/api';

import { EAgency } from "../../constants";

interface IChangeUserStatus {
  userId: number
}

const changeUserStatus = createAsyncThunk<AxiosResponse, IChangeUserStatus[]>(
  EAgency.changeUserStatus,
  async (values)=> {
    const { data } = await instance.post('/agency/users-team/deactive-users', { data: values });

    return data
  }
);

export default changeUserStatus;
