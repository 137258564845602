export enum EPreOrder {
  acceptOpportunity = 'preorder/acceptOpportunity',
  cancelOpportunity = 'preorder/cancelOpportunity',
  confirmOpportunity = 'preorder/confirmOpportunity',
  declineOpportunity = 'preorder/declineOpportunity',
  editPreorder = 'preorder/editPreorder',
  fetchPreOrderList = 'preorder/fetchPreOrderList',
  getOrderById = 'preorder/getOrderById',
  paidOpportunity = 'preorder/paidOpportunity',
  sendCustomerInvitation = 'preorder/sendCustomerInvitation',
  sendToAgency = 'preorder/sendToAgency',
  getInviteCustomersLinkByPreOrderId = 'preorder/getInviteCustomersLinkByPreOrderId',
  finalizePreorder = 'preorder/finalize',
}
