import React, {FunctionComponent} from 'react'
import cn from "classnames";

import {Dayjs, OpUnitType} from "dayjs";
import dayjs from "utils/dayjs";

import { IFullOrders } from "store/types/order";

import { StyledBoxStatus, StyledHoursPerWeek } from "./styled";

import OrderMenu from "./OrderMenu";

interface IOrderCellContentProps {
  date: Dayjs;
  firstWeekDay?: boolean;
  isOpen?: boolean;
  order: IFullOrders | any;
  unit: OpUnitType;
}

const OrderCellContent: FunctionComponent<IOrderCellContentProps> = ({ date, firstWeekDay, order, unit }) => (
  <StyledBoxStatus
    className={
      cn({
        startOrder: date.isSame(dayjs(order.timeLine.startDate), unit),
        endOrder: date.isSame(dayjs(order.timeLine.endDate), unit),
        [order.status || 'drafts']: date.isSameOrAfter(dayjs(order.timeLine.startDate), unit) && date.isSameOrBefore(dayjs(order.timeLine.endDate), unit),
        'isOpen': true
      })
    }
    sx={{my: 1}}
    key={order.id}
  >
    {(firstWeekDay && date.isSameOrAfter(dayjs(order.timeLine.startDate), unit) || date.isSame(dayjs(order.timeLine.startDate), unit)) && (
      <StyledHoursPerWeek>{order.timeLine.availabilityWeek} h/week</StyledHoursPerWeek>
    )}
    {(date.isSame(dayjs(order.timeLine.endDate), unit)) && (
      <OrderMenu order={order} />
    )}
  </StyledBoxStatus>
)

export default OrderCellContent
