import { createSlice } from '@reduxjs/toolkit';
import { ECompany } from 'store/constants';

import {ICompany} from "../types/company";

import saveCompanyLogo from '../query/company/saveCompanyLogo';
import checkCompanyByName from '../query/company/checkCompanyByName';
import createConsultantCompany from '../query/consultant/createConsultantCompany';

export interface CompanyState {
  companies: Array<ICompany>;
  loading: boolean;
  isCheckingCompanyByName: boolean;
}

const initialState: CompanyState = {
  companies: [],
  loading: false,
  isCheckingCompanyByName: false,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const {actions, reducer} = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    .addCase(checkCompanyByName.pending, state => {
      state.isCheckingCompanyByName = true;
    })
    .addCase(checkCompanyByName.rejected, state => {
      state.isCheckingCompanyByName = false;
    })
    .addCase(checkCompanyByName.fulfilled, (state) => {
      state.isCheckingCompanyByName = false;
    })

    .addCase(ECompany.companies, (state ) => {
      state.loading = true;
    })

    /**
     *  Refactored 12.23
     */

    /**
     *  Consultant Company
     */
    .addCase(createConsultantCompany.fulfilled, (state, action ) => {
      const { payload } = action
      state.companies.push(...payload)
    })
    .addCase(saveCompanyLogo.pending, state => {
      state.loading = true;
    })
    .addCase(saveCompanyLogo.rejected, state => {
      state.loading = false;
    })
    .addCase(saveCompanyLogo.fulfilled, (state, action ) => {
      const { payload } = action
      state.loading = false;
      state.companies = state.companies.map((item) => item.id === payload.id && {...item, logo: payload.link} || item);
    })

});

export default reducer;
