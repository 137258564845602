import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const StarFilledIcon2: FunctionComponent<ISvgProps> = ({className}) => {
  return (
    <svg className={className} width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.05858 0.829713C8.24667 0.476166 8.75333 0.476167 8.94142 0.829713L11.0062 4.71072C11.0817 4.85273 11.221 4.94972 11.3804 4.97134L15.9333 5.58877C16.3548 5.64594 16.5169 6.16997 16.2013 6.4552L12.9572 9.38689C12.8284 9.50326 12.7692 9.67808 12.8008 9.84875L13.5728 14.0249C13.6474 14.4285 13.2307 14.7452 12.8618 14.5652L8.71926 12.5439C8.58088 12.4764 8.41912 12.4764 8.28074 12.5439L4.13818 14.5652C3.76928 14.7452 3.35264 14.4285 3.42725 14.0249L4.19924 9.84875C4.23079 9.67808 4.17157 9.50326 4.04281 9.38689L0.798685 6.4552C0.483052 6.16997 0.645169 5.64595 1.06673 5.58878L5.61961 4.97134C5.77902 4.94972 5.91828 4.85273 5.99384 4.71072L8.05858 0.829713Z" fill="currentColor"/>
    </svg>
  );
}
