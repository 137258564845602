import { Components } from '@mui/material/styles';

import 'react-datepicker/dist/react-datepicker.css';

const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: {
    body: {
      color: '#1F1F1F',
      height: '100%',
      fontFamily: 'Visuelt Pro',
      fontSize: '0.875rem',
    },
    a: {
      color: '#FFC107',
    },
    html: {
      color: '#1F1F1F',
      height: '100%',
    },
    '#root': {
      height: '100%',
    },
    '&.react-datepicker-wrapper': {
      width: '100%',
    },
    '&.react-datepicker': {
      boxShadow: '-8px 8px 12px rgba(0, 0, 0, 0.03)',
      borderRadius: 12,
      border: 'none',
      '&__header': {
        borderBottom: 'none',
        backgroundColor: 'white',
      },
      '&__current-month': {
        marginBottom: 10,
        lineHeight: '24px',
      },
      '&__navigation-icon::before': {
        borderColor: '#171717',
        borderWidth: '2px 2px 0 0',
        top: 12,
      },
      '&__day-names &__day-name': {
        color: '#D4D4D4',
      },
      '&__day': {
        borderRadius: '50%',
        '&:hover': {
          borderRadius: '50%',
        },
        '&--selected': {
          backgroundColor: '#FFA000',
        },
      },
      '&__close-icon': {
        padding: '0 12px 0 0',
        '&::after': {
          backgroundColor: '#D4D4D4',
        },
      },
      '& .react-datepicker__month-wrapper': {
        display: 'flex',
        justifyContent: 'space-around',
        '& .react-datepicker__month-text': {
          fontSize: '1rem',
          '@media (max-width: 767px)': {
            paddingTop: 14,
            paddingBottom: 14,
            fontSize: 18,
          },
        },
        '& .react-datepicker__month-text:hover': {
          borderRadius: 100,
        },
      },
      '.react-datepicker__month--selected, .react-datepicker__month--selected:hover, .react-datepicker__month-text--keyboard-selected, .react-datepicker__day--selected,.react-datepicker__day--keyboard-selected':
        {
          backgroundColor: '#FFC107',
          color: 'white',
          borderRadius: 100,
          '&.react-datepicker__day--disabled': {
            backgroundColor: '#F5F5F5',
            color: '#9A9A9B',
          },
          '&:focus-visible': {
            outline: 'none',
          },
        },
      '.react-datepicker__day': {
        transition: 'background-color 0.15s ease-in-out',
      },
      '.react-datepicker__day--selected': {
        transition: 'color 0.15s ease-in-out',
        '&:hover': {
          color: '#F9F9F9',
        },
      },
      '@media (max-width: 767px)': {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    '.react-datepicker__portal': {
      backgroundColor: 'rgba(31, 31, 31, .4)',
      alignItems: 'flex-end',
      height: '-webkit-fill-available',
      '> div, .react-datepicker, .react-datepicker__month-container': {
        width: '100vw',
      },
      '.react-datepicker': {
        borderRadius: 0,
        fontFamily: 'Visuelt Pro',
        color: '#1F1F1F',
        '&__navigation': {
          top: 28,
        },
        '&-year-header, &__current-month': {
          fontSize: '1.125rem',
          padding: '32px 0 12px',
          fontWeight: 500,
        },
        '&__month': {
          margin: '20px 0',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      },
    },
    '.react-datepicker-popper': {
      zIndex: 10,
    },
    '*': {
      overscrollBehavior: 'none',
    },
    'input:-webkit-autofill': {
      webkitBoxShadow: '0 0 0 30px #f5f5f5 inset !important',
      '&:focus, &:hover, &:active': {
        webkitBoxShadow: '0 0 0 30px #f5f5f5 inset !important',
      },
    },
    strong: {
      fontWeight: 500,
    },
    '.rcs-custom-scroll .rcs-inner-handle': {
      backgroundColor: '#aeaeaf !important',
    },
    '.PhoneInputInput': {
      minHeight: 46,
      border: 'none',
      appearance: 'none',
      webkitAppearance: 'none',
      mozAppearance: 'none',
      outline: 'none',
      padding: '0 12px',
      fontSize: '1.125rem',
      color: '#171717',
      backgroundColor: '#f5f5f5',
    },
    '@media (max-width: 767px)': {
      h1: {
        fontSize: '22px !important',
      },
    },
  },
};

export default MuiCssBaseline;
