import React, { FunctionComponent } from 'react';
import { useAppSelector } from 'store/hooks';

import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { isOnlineSelector } from 'store/selectors/getMobileSelector';

const StyledOfflineBannerWrap = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: theme.palette.secondary.main,
  padding: '0.5rem',
}));

const BANNER_MSG = `You're currently offline. Some features won't work until you're back online. Please check your connection and try again.`;

const Loader: FunctionComponent = () => {
  const isOnline = useAppSelector(isOnlineSelector);

  return isOnline ? null : (
    <StyledOfflineBannerWrap data-test="offline-banner">
      <Typography color="white">{BANNER_MSG}</Typography>
    </StyledOfflineBannerWrap>
  );
};

export default Loader;
