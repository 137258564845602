import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';

import { EChat } from '../../constants';
import { ERole } from '../../../models/consts';

import { IRole } from '../../types/common';

interface IParamsProps {
  agencyId: number;
  serviceChatSid: string;
  role: IRole;
}

const getChatToken = createAsyncThunk<any, IParamsProps>(
  EChat.getToken,
  async ({ agencyId, role, serviceChatSid }) => {
    let url = '/chats/token';

    if (
      [ERole.AGENCY_ADMIN, ERole.CONSULTANT, ERole.RESOURCE_MANAGER].includes(role.name) &&
      agencyId
    ) {
      url = '/agency' + url;
    } else if ([ERole.CUSTOMER_ADMIN, ERole.CUSTOMER].includes(role.name)) {
      url = '/enterprise/companies' + url;
    }

    const { data } = await instance.post(url, { serviceChatSid });
    return data;
  },
);

export default getChatToken;
