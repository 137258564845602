import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {ECommon} from "../../constants";

const getLanguages = createAsyncThunk(
  ECommon.languages,
  async () => {
    const { data } = await instance.get('languages')
    return await data
  }
)

export default getLanguages
