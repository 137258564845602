import {createAsyncThunk} from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EConsultant } from 'store/constants';
import { IModuleCreateDto, IWorkCreateDto } from 'models';

export type CreateOrderDraftProps = {
  description?: string,
  name: string,
  taxes?: number,
  customerEmail?: string,
  customerId?: number,
  levelId: number,
  timeLine: {
    allHours: number,
    startDate: string,
    endDate: string,
    availabilityWeek: number,
    flexibleHours?: boolean,
  },
  expertise: {
    productId: number,
    modules: IModuleCreateDto [],
    works: IWorkCreateDto[],
  }
}

const createOrderDraft = createAsyncThunk(
  EConsultant.createOrderDraft,
  async (payload: CreateOrderDraftProps) => {
    const { data } = await instance.post('draft-orders', [payload]);

    return data[0];
  }
)

export default createOrderDraft;
