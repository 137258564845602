import { ERole } from 'models/consts';
import ROUTES from 'router/constants';

export const redirectBasedOnUserTypeStatus = (user: any, navigate: any) => {
  const roleName = user?.role?.name;

  const isCustomer = [ERole.CUSTOMER, ERole.SELF_CUSTOMER, ERole.CUSTOMER_ADMIN].includes(roleName);

  if (isCustomer) {
    navigate(`${ROUTES.ORDER_CREATION}/1`);
    return;
  }

  const isSales = user?.role?.name === ERole.SALES;
  const isTalent = user?.role?.name === ERole.TALENT;

  if (isSales || isTalent) {
    navigate(ROUTES.TALENT_SEARCH_LANDING);
    return;
  }

  const isConsultant = user?.role?.name === ERole.CONSULTANT;

  if (!isConsultant) {
    navigate(ROUTES.ORDER_LIST);
    return;
  }

  const consultant = user?.consultant;
  const isApproved = consultant?.isApproved;
  const isConfirmed = consultant?.isConfirm;
  const callScheduled = consultant?.callScheduled;
  const registrationStep = consultant?.registrationStep;
  const agencyId = consultant?.agencyId;

  if (!isConfirmed) {
    navigate(ROUTES.SIGNUP_CODE);
  } else if (isApproved) {
    navigate(ROUTES.ORDER_LIST);
  } else if (!isApproved && (callScheduled || callScheduled === null)) {
    navigate(ROUTES.CONSULTANT);
  } else {
    const registrationPath = `${ROUTES.CONSULTANT_REGISTRATION}/${registrationStep + 1}`;
    const state = agencyId ? { state: { isAgencyAddingUser: true } } : {};
    navigate(registrationPath, state);
  }
};
