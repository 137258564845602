import React, { FunctionComponent, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { StyledLabel } from 'components/ConsultantComponents/LeftSide/styled';
import { StyledInfoFieldValue } from 'components/CustomFields/styled';
import {
  StyledOGIcon,
  StyledVerifiedIcon,
} from 'components/ConsultantComponents/ConsultantPreview/styled';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import useDeviceInfo from 'hooks/useDeviceInfo';
import useConsultantProductExperience from 'hooks/useConsultantProductExperience';

import { IWorker } from 'store/types/consultant';

import getConsultantProductsFull from 'store/query/consultant/getConsultantProductsFull';
import fetchConsultantCertificates from 'store/query/consultant/fetchConsultantCertificates';

import { productsSummarySelector } from 'store/selectors/getConsultantSelector';
import { consultantCertificatesSelector } from 'store/selectors/getConsultantCerеificates';

import ConsultantSummary from 'components/ConsultantComponents/ConsultantSummary';
import Skills from 'components/ConsultantComponents/Skills';
import OrderFeedback from '../Feedback/OrderFeedback/ConsultantFeedback';
import ConsultantNameBlock from 'components/ConsultantComponents/HeaderConsultant/ConsultantNameBlock';
import DescriptionBlock from 'components/ConsultantComponents/HeaderConsultant/DescriptionBlock';
import Certificates from 'components/ConsultantComponents/Certificates';
import Avatar from 'components/Avatar';
import AgencyBlock from 'components/ConsultantComponents/HeaderConsultant/AgencyBlock';

interface IConsultantViewProps {
  isBlur?: boolean;
  specialist: IWorker;
  onSelectPreview?: (worker: any) => void;
  isSelectedExpert?: boolean;
  isSalesOrTalent?: boolean;
}

const ConsultantView: FunctionComponent<IConsultantViewProps> = ({
  isBlur,
  specialist,
  onSelectPreview,
  isSelectedExpert,
  isSalesOrTalent
}) => {
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { deviceType } = useDeviceInfo();

  const { consultant, ...user } = specialist;

  const isMobile = deviceType === 'mobile' && mobileScreen;

  const expertiseSummary = useAppSelector(productsSummarySelector(consultant?.userId));
  const certificates = useAppSelector(consultantCertificatesSelector(consultant?.userId));

  useEffect(() => {
    if (consultant.userId && !expertiseSummary.length) {
      dispatch(getConsultantProductsFull(consultant?.userId));
    }
  }, [consultant.userId, dispatch, expertiseSummary.length]);

  useEffect(() => {
    if (consultant.userId && !certificates.length) {
      dispatch(
        fetchConsultantCertificates({ key: consultant?.userId, userId: consultant?.userId }),
      );
    }
  }, [consultant.userId, dispatch, certificates.length]);

  const { headLine, description } = useConsultantProductExperience(consultant, true);

  return (
    <Box sx={{ mt: { xs: 3, sm: 5, md: 3, lg: 2 }, overflow: 'hidden' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 4, sm: 5 }}>
        <Grid container xs={12}>
          <Grid xs="auto" display="flex" flexDirection="column" alignItems="center">
            <Avatar
              isBlur={!isSalesOrTalent ? isBlur : undefined}
              image={consultant?.avatarUrl}
              firstName={user?.initials || ''}
              size={(isMobile && 'medium') || 'large'}
            />
            {isMobile && (
              <Box display="flex" my={1.5}>
                {consultant?.isApproved && (
                  <Box>
                    <StyledVerifiedIcon />
                  </Box>
                )}
                <Box>
                  <StyledOGIcon />
                </Box>
              </Box>
            )}
          </Grid>
          <Grid xs>
            <ConsultantNameBlock
              isBlur={isBlur}
              expertiseSummary={expertiseSummary}
              isCurrentUser={false}
              isMobile={isMobile}
              onOpen={() => ({})}
              user={user}
              consultant={{
                ...consultant,
                shortDescriptions: headLine,
              }}
            />
          </Grid>
          {!isSalesOrTalent && (
            <Grid xs="auto" display={{ xs: 'none', sm: 'block' }}>
              <Button
                color={(!isSelectedExpert && 'secondary') || 'primary'}
                onClick={() => onSelectPreview && onSelectPreview({ ...user, consultant })}
                variant="contained"
                disabled={isSalesOrTalent}
              >
                {isSelectedExpert ? 'Selected' : 'Select'}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid xs={12} spacing={0}>
          <Divider sx={{ my: 0 }} />
        </Grid>
        {consultant?.agency && (
          <>
            <Grid xs={12}>
              <AgencyBlock agency={consultant.agency} isAccordion />
            </Grid>
            <Grid xs={12} spacing={0}>
              <Divider sx={{ my: 0 }} />
            </Grid>
          </>
        )}
        <Grid xs={12}>
          <DescriptionBlock
            isCurrentUser={false}
            onOpen={() => ({})}
            consultant={{
              ...consultant,
              description: description,
            }}
          />
        </Grid>
        <Grid xs={12} spacing={0}>
          <Divider sx={{ my: 0 }} />
        </Grid>



        <Grid container xs={12} spacing={2}>
          <Grid container xs={12} spacing={4}>
            <Grid
              xs={12}
              sm={5}
              sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}
            >
              <Typography mb={2} variant="h3">
                Availability
              </Typography>
              <Stack direction="row" alignItems="center">
                <StyledLabel variant="subtitle2">Capacity</StyledLabel>
                <StyledInfoFieldValue>
                  <Typography>{`${consultant?.availabilities[0]?.availabilityWeek} hours / week`}</Typography>
                </StyledInfoFieldValue>
              </Stack>
            </Grid>

            <Grid xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
              <Divider sx={{ mb: { xs: 0, md: 2 } }} />
            </Grid>
            {consultant?.languages && consultant?.languages?.length > 0 && (
              <Grid container xs={12} md={7} spacing={2}>
                <Grid xs={12}>
                  <Typography variant="h3">Languages</Typography>
                </Grid>
                <Grid xs={12}>
                  <Stack spacing={2} direction="row" useFlexGap flexWrap="wrap">
                    {consultant.languages.map(({ id, language, level }) => (
                      <Stack
                        key={`lang-${id}`}
                        sx={{ textTransform: 'capitalize' }}
                        direction="row"
                        alignItems="center"
                      >
                        <StyledLabel variant="subtitle2">
                          {(language && language.name.toLowerCase()) || ''}
                        </StyledLabel>
                        <StyledInfoFieldValue sx={{ width: 93 }}>
                          <Typography>{level}</Typography>
                        </StyledInfoFieldValue>
                      </Stack>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid xs={12}>
            <Divider sx={{ mb: { xs: 0, md: 2 } }} />
          </Grid>
          <Grid xs={12}>
            <ConsultantSummary
              consultantKey={consultant?.userId}
              expertiseSummary={expertiseSummary}
              isCurrentUser={false}
              certificates={certificates}
            />
          </Grid>
          <Grid xs={12}>
            <Certificates isCurrentUser={false} certificates={certificates} />
          </Grid>
          {!!consultant?.tags?.length && (
            <Grid xs={12}>
              <Skills tags={consultant?.tags} onOpen={() => ({})} />
            </Grid>
          )}
          <Grid xs={12}>
            {!!consultant?.rate && !!consultant?.rate?.feedbacksCount && (
              <div className="expertise-info">
                <OrderFeedback rate={consultant.rate} userId={consultant.userId} />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConsultantView;
