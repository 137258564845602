import { styled } from "@mui/material/styles";
import MuiTableCell, { tableCellClasses } from "@mui/material/TableCell";
import Box, { BoxProps } from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Collapse } from "@mui/material";

const TableCell = styled(MuiTableCell)(({ theme }) => theme.unstable_sx({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.body}`]: {
    borderColor: '#D4D4D4',
    borderRightStyle: 'dashed',
    borderRightWidth: 1,
  },
  [`&.${tableCellClasses.head}:first-of-type, &.${tableCellClasses.head}:nth-of-type(5n + 1)`]: {
    borderRightStyle: 'solid',
  },
  [`&.${tableCellClasses.body}:first-of-type, &.${tableCellClasses.body}:nth-of-type(5n + 1)`]: {
    borderRightStyle: 'solid',
  },
  [`&.${tableCellClasses.head}:last-of-type, &.${tableCellClasses.body}:last-of-type`]: {
    // borderRightWidth: 0,
  },
}));
const StyledDayCell = styled(TableCell)(({ theme }) => theme.unstable_sx({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'tertiary.main',
    borderRightStyle: 'dashed',
  },
  [`&.${tableCellClasses.body}`]: {
    p: 0,
  },
  status: {
    progress: 'green',
    completed: 'blue',
    created: 'yellow',
    drafts: 'grey'
  }
}));

const StyledWeekCell = styled(TableCell)(({ theme }) => theme.unstable_sx({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'secondary.main',
    color: 'common.white',
    borderRightStyle: 'solid',
  },
}));

/**
 * New
 */
interface IExtendDivProps extends BoxProps {
  colSpan: number;
  border?: any;
  justifyContent?: string;
  backgroundColor?: any;
}

const CustomTableCell = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderColor: '#D4D4D4',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderRightStyle: 'dashed',
  borderRightWidth: 1,
  [`&:first-of-type, &:nth-of-type(5n + 1)`]: {
    borderRightStyle: 'solid',
  },
  [`&:last-of-type`]: {
    borderRightWidth: 0,
  },
}))

const StyledCalendarContainer = styled(Box)(({ theme }) => theme.unstable_sx({
  mt: 3,
  overflowX: 'auto',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '.875rem',
  color: 'text.primary',
}))

const StyledCalendarHead = styled(Box)<IExtendDivProps>(({ theme }) => theme.unstable_sx({
  position: 'sticky',
  top: 0,
  zIndex: 4,
  '& div > div': {
    height: 27,
  }
}))

const StyledCalendarRow = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
}))

const StyledCalendarBodyRow = styled(StyledCalendarRow)(({ theme }) => theme.unstable_sx({
  '&:hover > div': {
    backgroundColor: '#EDF0FA'
  }
}))

const StyledCalendarCollapse = styled(Collapse)(({ theme }) => theme.unstable_sx({
  '& > div > div div': {
    borderBottom: 0,
  },
  '& > div > div > div:last-of-type > div': {
    borderBottom: '1px solid #D4D4D4',
  }
}))

const StyledCalendarHeadCell = styled(CustomTableCell)(({ justifyContent, border, theme }) => theme.unstable_sx({
  ...(border as object),
  justifyContent,
  backgroundColor: 'white',
  position: 'sticky',
  left: 0,
  minWidth: 260,
  flex: '1 1 260px',
  zIndex: 3,
  pl: 5
}))

const StyledCalendarHeadBodyCell = styled(CustomTableCell)<IExtendDivProps>(({ border, colSpan, backgroundColor, theme }) => theme.unstable_sx({
  ...(border as object),
  backgroundColor: backgroundColor || 'white',
  position: 'sticky',
  top: 0,
  width: colSpan * 46,
  minWidth: colSpan * 46,
  '&.quarter': {
    borderRightStyle: 'dashed',
    width: colSpan * 84,
    minWidth: colSpan * 84,
    '&.lastPeriod': {
      borderRightStyle: 'solid',
    }
  }
}))

const StyledWeekHeadCell = styled(StyledCalendarHeadCell)(({ justifyContent, theme }) => theme.unstable_sx({
  justifyContent,
  backgroundColor: 'secondary.main',
  color: 'common.white',
  borderRightStyle: 'solid',
}))

const StyledWeekHeadBodyCell = styled(StyledCalendarHeadBodyCell)(({ theme }) => theme.unstable_sx({
  backgroundColor: 'secondary.main',
  color: 'common.white',
  '& svg': {
    color: 'common.white'
  },
  borderRightStyle: 'solid',
  '&.disabled': {
    backgroundColor: 'tertiary.main',
    color: 'secondary.light',
    '& svg': {
      color: 'secondary.light',
    },
  }
}))

const StyledAvailabilityCell = styled(Box)(({ theme }) => theme.unstable_sx({
  ml: 1,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
}))

const StyledDayHeadCell = styled(StyledCalendarHeadCell)(({ theme }) => theme.unstable_sx({
  backgroundColor: 'tertiary.main',
}))

const StyledDayHeadBodyCell = styled(StyledCalendarHeadBodyCell)(({ theme }) => theme.unstable_sx({
  backgroundColor: 'tertiary.main',
  color: 'text.secondary',
  '&.currentDay': {
    alignItems: 'stretch',
    '& > *': {
      backgroundColor: 'status.completed.dark',
      borderRadius: 1,
      color: 'common.white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
    }
  }
}))

const StyledCalendarBody = styled(Box)(({ theme }) => theme.unstable_sx({
  pb: 1.5
}))

const StyledBodyCell = styled(CustomTableCell)<IExtendDivProps>(({ border, colSpan, theme }) => theme.unstable_sx({
  ...(border as object),
  flexDirection: 'column',
  flex: `0 1 ${colSpan  * 46}px`,
  minWidth: 46,
  '&.quarter': {
    flex: `0 1 ${colSpan  * 84}px`,
    minWidth: 84,
    borderRightStyle: 'dashed',
    '&.lastPeriod': {
      borderRightStyle: 'solid',
    }
  },
  '&.currentDay': {
    backgroundColor: '#EDF0FA'
  },
  '&.hidden > div': {
    opacity: 0,
    pointerEvents: 'none'
  },
  '&.disabled': {
    backgroundColor: 'tertiary.main',
    color: 'secondary.light',
    borderRightWidth: 0,
    '&.quarter': {
      borderRightWidth: 1,
      '&.lastPeriod': {
        borderRightStyle: 'solid',
      }
    },
    [`&:first-of-type, &:nth-of-type(5n + 1)`]: {
      borderRightWidth: 1,
    },
  },
  '&.nonBottomBorder': {
    borderBottomWidth: 0,
  }
}))

const StyledHeadBox = styled(Box)(({ theme }) => theme.unstable_sx({
  my: .5,
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  flex: '1 1 260px',
}))

const StyledOrderBox = styled(Box)(({ theme }) => theme.unstable_sx({
  my: .5,
  display: 'flex',
  // flexDirection: 'column',
  alignItems: 'center',
  flex: '1 1 260px',
}))

const StyledBoxStatus = styled(Box)(({ theme }) => theme.unstable_sx({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  mr: '-1px',
  height: 10,
  my: .25,
  width: 'calc(100% + 1px)',
  whiteSpace: 'nowrap',
  '&.in_progress, &.active': {
    backgroundColor: 'status.progress.light',
    color: 'status.progress.dark',
    '&:before': {
      backgroundColor: 'status.progress.main',
    },
    '& button:hover': {
      color: 'status.progress.main',
    }
  },
  '&.completed, &.terminated, &.terminated_during_trial': {
    backgroundColor: 'status.completed.light',
    color: 'status.completed.dark',
    '&:before': {
      backgroundColor: 'status.completed.main',
    },
    '& button:hover': {
      color: 'status.completed.main',
    }
  },
  '&.created, &.waiting_for_payment, &.ready_to_start': {
    backgroundColor: 'status.created.light',
    color: 'status.created.dark',
    '&:before': {
      backgroundColor: 'status.created.main',
    },
    '& button:hover': {
      color: 'status.created.main',
    }
  },
  '&.expired, &.canceled': {
    backgroundColor: 'status.canceled.light',
    color: 'status.canceled.dark',
    '&:before': {
      backgroundColor: 'status.canceled.main',
    },
    '& button:hover': {
      color: 'status.canceled.main',
    }
  },
  '&.drafts': {
    backgroundColor: 'status.drafts.light',
    color: 'status.drafts.dark',
    '&:before': {
      backgroundColor: 'status.drafts.main',
    },
    '& button:hover': {
      color: 'status.drafts.main',
    }
  },
  '&.on_hold': {
    backgroundColor: 'status.onHold.light',
    color: 'status.onHold.dark',
    '&:before': {
      backgroundColor: 'status.onHold.main',
    },
    '& button:hover': {
      color: 'status.onHold.main',
    }
  },
  '&.endOrder': {
    mr: 1,
    width: 'calc(100% - 8px)',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: 'end'
  },
  '&.startOrder': {
    ml: 1,
    width: 'calc(100% - 8px)',
    // width: 38,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    // overflow: 'hidden',
    '&:before': {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      position: 'absolute',
      top: 0,
      left: 0,
      content: '""',
      height: '100%',
      width: 6,
    },
  },
  '&.isOpen': {
    height: 35,
    my: 1,
  }
}));

const StyledHoursPerWeek = styled(Box)(({ theme }) => theme.unstable_sx({
  position: 'relative',
  mx: 2,
  zIndex: 2,
}))

const StyledPaginationText = styled(Button)(({ theme }) => theme.unstable_sx({
  border: '1px solid #1F1F1F',
  borderRadius: '100px',
  paddingX: 3,
  paddingY: 1,
  fontSize: '.750rem',
  marginX: 0.5,
  height: 35
}));

const StyledPaginationButton = styled(Button)(({ theme }) => theme.unstable_sx({
  padding: 0,
  width: '24px',
  height: '24px',
  minWidth: '24px',
}));

export {
  StyledAvailabilityCell,
  StyledBodyCell,
  StyledCalendarBody,
  StyledCalendarBodyRow,
  StyledCalendarCollapse,
  StyledCalendarContainer,
  StyledCalendarHead,
  StyledCalendarHeadBodyCell,
  StyledCalendarHeadCell,
  StyledCalendarRow,
  StyledDayHeadCell,
  StyledDayHeadBodyCell,
  StyledHeadBox,
  StyledHoursPerWeek,
  StyledOrderBox,
  StyledWeekHeadCell,
  StyledWeekHeadBodyCell,
  StyledDayCell, StyledWeekCell, StyledBoxStatus,
  StyledPaginationText,
  StyledPaginationButton,
}
