import {createAsyncThunk} from "@reduxjs/toolkit";

import instance from "../../middlewares/api";
import { EUser } from "../../constants";

const forgotPassword = createAsyncThunk(
  EUser.forgotPassword,
  async (payload: { email: string }) => {
    const { data } = await instance.post('auth/forgot-password', payload)

    return data;
  }
)

export default forgotPassword;
