import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { useTheme } from '@mui/material/styles';

import CalendarIcon from 'styles/icons/CalendarIcon';
import { StyledDetailKey } from './styled';

import dayjs from 'utils/dayjs';
import { calcWeeks, getEndDate } from 'utils/dateFormatter';
import { setDate } from 'utils/orderCreationHelpers';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import useChartData from 'hooks/useChartData';
import useTotalWeeks from 'hooks/useTotalWeeks';

import { IBasicPreOrder } from 'store/types/preOrder';

import ROUTES from 'router/constants';
import { TAXES } from 'store/constants';
import { EPreOrderStatus, ESeniority } from 'models/consts';

import {
  categoryProductsSelector,
  expertiseLevelsSelector,
  productPricesSelector
} from 'store/selectors/getCommonSelector';
import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';
import fetchAvailability from 'store/query/common/fetchAvailability';
import fetchProductPrices from 'store/query/common/fetchProductPrices';
import fetchExpertiseLevels from 'store/query/common/fetchExpertiseLevels';
import editPreorder from 'store/query/order/editPreorder';


import DateTextInput from 'components/CustomFields/DateTextInput';
import WorkTimeChart from 'components/WorkTimeChart';

import RangeDateTimeline from "components/CustomFields/RangeDateTimeline";
import ProductsModulesWork from "components/CustomFields/ProductsModulesWork";
import TotalDeliveredHours from "components/CustomFields/TotalDeliveredHours";
import LevelIdSelect from "../../components/CustomFields/LevelIdSelect";

export interface IEditPreorderFormAgency {
  order: IBasicPreOrder;
  role: any;
  onSubmitForm?: () => void;
}

const EditPreorderFormAgency: React.FunctionComponent<IEditPreorderFormAgency> = ({
  order,
  role,
  onSubmitForm,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);
  const seniorities = useAppSelector(expertiseLevelsSelector);

  const defaultFlexibleHours = order ? order.timeLine?.flexibleHours : false;
  const defaultAllHours = order ? order.timeLine?.allHours : 20;
  const defaultAvailabilityWeek = defaultFlexibleHours
    ? 0
    : order ? (order.timeLine?.availabilityWeek || 0) : 20;
  const defaultStartDate = order ? order.timeLine?.startDate : dayjs().day(1 + 14).format('YYYY-MM-DD');
  const defaultEndDate = order ? order.timeLine?.endDate : getEndDate(defaultStartDate, defaultAllHours, defaultAvailabilityWeek);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      product: order ? order.expertise?.product : undefined,
      modules: order ? order.expertise?.modules : [],
      works: order ? order.expertise?.works : [],
      primaryModule: order
        ? order.expertise?.modules.find((item) => item.PreOrdersExpertiseModules.isPrimary)?.id
        : undefined,
      primaryWorkType: order
        ? order.expertise?.works.find((item) => item.PreOrdersExpertiseWorks.isPrimary)?.id
        : undefined,
      allHours: defaultAllHours,
      availabilityWeek: defaultAvailabilityWeek,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      flexibleHours: defaultFlexibleHours,
      levelId: order.levelId
        ? order.levelId
        : order.customPrice
          ? ESeniority.CUSTOM
          : undefined,
      customPriseCents: order
        ? order.customPrice
          ? order.customPrice?.priceCents / 100
          : ''
        : '',
      agencyReadinessDate: order?.agencyReadinessDate,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    watch,
  } = methods

  const currentProduct = watch('product');
  const selectedAvailability = watch('availabilityWeek');
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const totalTime = watch('allHours');
  const flexibleHours = watch('flexibleHours');
  const agencyReadinessDate = watch('agencyReadinessDate');

  const productPrices = useAppSelector(productPricesSelector(currentProduct?.id || 0));

  const totalWeeks = useTotalWeeks({startDate, endDate});
  const chartData = useChartData({selectedAvailability: selectedAvailability || 0, startDate, totalTime, flexibleHours, totalWeeks});

  useEffect(() => {
    dispatch(fetchAvailability());
    dispatch(fetchExpertiseLevels());
  }, [ dispatch ]);

  useEffect(() => {
    if (currentProduct?.id) {
      dispatch(fetchProductPrices(currentProduct.id))
    }
  }, [ dispatch, currentProduct?.id ]);

  useEffect(() => {
    if(!categoriesWithProductsList?.length) {
      dispatch(fetchProductsWithModulesWorks())
    }
  }, [categoriesWithProductsList?.length, dispatch]);

  const onSubmit = async (data: any) => {
    let timeLine = order.timeLine;

    if (order.status !== EPreOrderStatus.PENDING_AGENCY) {
      const weeks = calcWeeks(data.endDate, data.startDate);
      const availabilityWeek = data.flexibleHours
        ? Math.ceil(totalTime / weeks)
        : data.availabilityWeek;
      const allHours = data.flexibleHours
        ? weeks * availabilityWeek
        : data.allHours;

      timeLine = {
        ...order.timeLine,
        startDate: data.startDate,
        endDate: data.endDate,
        availabilityWeek: availabilityWeek,
        allHours: allHours,
        flexibleHours: data.flexibleHours,
      };
    }
    const preorder: any = {
      id: order.id,
      taxes: TAXES,
      timeLine: timeLine,
      expertise: {
        ...order.expertise,
        productId: data.product.id,
        modules: data.modules.map((item: any) => ({
          moduleId: item.id,
          isPrimary: data.primaryModule === item.id,
        })),
        works: data.works.map((item: any) => ({
          workId: item.id,
          isPrimary: data.primaryWorkType === item.id,
        })),
      },
      customPrise: {
        priceCents: data.customPriseCents
          ? data.customPriseCents * 100
          : data.levelId && !!productPrices?.length
            ? productPrices.find(item => item.levelId === parseInt(data.levelId))?.stripePriceCents
            : 0,
      }
    };

    if (parseInt(data.levelId) !== ESeniority.CUSTOM) {
      preorder.levelId = parseInt(data.levelId);
    }
    if (order.status === EPreOrderStatus.PENDING_AGENCY) {
      preorder.agencyReadinessDate = data.agencyReadinessDate;
    }

    await dispatch(editPreorder({
      preorder,
      role: role,
    }))
      .unwrap()
      .then((response) => {
        if (!!response.length) {
          onSubmitForm && onSubmitForm();
        }
      });
  };

  const handleSaveClose = async (data: any) => {
    await onSubmit(data);
    navigate(`${ROUTES.PRE_ORDER}/${order.id}`);
  };

  return (
    <FormProvider {...methods}>
      <form id="order-form" onSubmit={handleSubmit(onSubmit)}>
        <Typography component="div" variant="h4">Expertise</Typography>
        <Typography component="div" variant="body1" sx={{mb: 1}}>
          Please review our AI recommendations
        </Typography>

        <ProductsModulesWork disabledProductEdit />

        {order.status === EPreOrderStatus.PENDING_AGENCY && (
          <>
            <Box mb={3}>
              <Typography component="div" variant="h4" mb={2}>Key Dates</Typography>
              <Box display="flex" alignItems="center" mb={1}>
                <StyledDetailKey component="div" variant="body1">Customer Readiness Date</StyledDetailKey>
                <Typography
                  component="div">{order?.customerReadinessDate && dayjs(order.customerReadinessDate).format('MM/DD/YYYY') || '--/--/----'}</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <StyledDetailKey component="div" variant="body2">Requesting Completion Date</StyledDetailKey>
                <Typography
                  component="div">{order?.requestCompletionDate && dayjs(order.requestCompletionDate).format('MM/DD/YYYY') || '--/--/----'}</Typography>
              </Box>
            </Box>

            <Grid container spacing={2} sx={{mb: 3}}>
              <Grid xs={12} md={6} lg={4}>
                <InputLabel>Agency Readiness Date</InputLabel>
                <Controller
                  name="agencyReadinessDate"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({field}) => (
                    <DatePicker
                      selected={!!agencyReadinessDate ? dayjs(agencyReadinessDate).toDate() : null}
                      onChange={(date: Date) => {
                        setDate(date, field);
                      }}
                      calendarStartDay={1}
                      customInput={
                        <DateTextInput
                          className="text-custom-input"
                          onReset={() => setDate(null, field)}
                          icon={<CalendarIcon/>}
                          dataTest="agencyReadinessDate"
                        />
                      }
                      showPopperArrow={false}
                      minDate={dayjs().toDate()}
                      isClearable
                      placeholderText="MM/DD/YYYY"
                      withPortal={xs}
                    />
                  )}
                />
                {!!errors.agencyReadinessDate && (
                  <Typography color="error" mt={-2.5} sx={{position: "relative"}}>
                    {(errors.agencyReadinessDate as any)?.message || 'Incorrect data'}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        )}

        {order.status !== EPreOrderStatus.PENDING_AGENCY && (
          <>
            <Typography component="div" variant="h4">Timeline</Typography>
            <Typography component="div" variant="body1" mb={2}>Define the timeline of this order</Typography>
            <Grid container>
              <Grid container xs={12} md={6}>
                <TotalDeliveredHours />
                <RangeDateTimeline />
              </Grid>
              <Grid container xs={12} md={5} mdOffset={1}>
                {chartData && (
                  <WorkTimeChart
                    withLabels
                    estimatedTime={totalTime}
                    flexibleHours={flexibleHours}
                    chartData={chartData}
                    totalWeeks={totalWeeks || 0}
                    isMarginLeft={false}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}

        {seniorities && !!productPrices?.length && order.billingType === 'customer' && (
          <LevelIdSelect productPrices={productPrices} />
        )}

        <Grid container spacing={2} justifyContent="center">
          <Grid xs={12} md="auto">
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              form="order-form"
              type="submit"
              fullWidth
              disabled={!isValid || isSubmitting}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Grid>
          <Grid xs={12} md="auto">
            <LoadingButton
              color="secondary"
              loading={isSubmitting}
              form="review-preorder-form"
              type="button"
              fullWidth
              disabled={!isValid || isSubmitting}
              variant="contained"
              onClick={handleSubmit(handleSaveClose)}
            >
              Save & Close
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default EditPreorderFormAgency;
