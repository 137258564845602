import React, { FunctionComponent } from 'react';

import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";

import BuildingIcon from "styles/icons/BuildingIcon";

import { IExtendUserCustomer } from "store/types/customer";
import { ICompany } from "store/types/company";

import Avatar from 'components/Avatar';

interface ICustomerPreviewProps {
  customer: IExtendUserCustomer;
  company?: ICompany;
  hashCode?: string;
}

const CustomerPreview: FunctionComponent<ICustomerPreviewProps> = ({customer, company}) => {
  return (
    <Box
      display="flex"
      sx={{alignItems: 'center'}}
    >
      {company ?
        <>
          <Avatar
            size={'medium'}
            image={company.logo}
            firstName={customer?.firstName || ''}
            lastName={customer?.lastName || ''}
            icon={<BuildingIcon />}
          />
          <Box sx={{minWidth: 0, flex: '1 1 auto'}}>
            <Typography
              variant="h4"
              sx={{
                textOverflow: 'ellipsis',
                flex: '1 0 auto',
                minWidth: 0,
                ml: 2,
                overflow: 'hidden',
              }}
            >
              {company.name}
            </Typography>
          </Box>
        </>
        :
        <Typography className="header-title">
          {customer.firstName} {customer.lastName}
        </Typography>
      }
    </Box>
  );
};

export default CustomerPreview;
