import {FC, useMemo} from "react";

import {ERole} from "models/consts";
import {useAppSelector} from "store/hooks";
import {getMeSelector} from "store/selectors/getUserSelector";
import {IBasicUser} from "store/types/user";
import {IAgencyUser} from "store/types/agency";

const usePermissions: FC<IAgencyUser> = (user) => {
  const me = useAppSelector(getMeSelector) as IBasicUser;

  return useMemo(() => {
    if (user.id === me.id) {
      return false;
    }
    if (user.agency?.owner.id === me.id) {
      return true;
    }
    if (me.role.name === ERole.AGENCY_ADMIN && user.agency?.owner.id !== user.id) {
      return true;
    }
    if (me.role.name === ERole.RESOURCE_MANAGER && (user.role.name !== ERole.AGENCY_ADMIN)) {
      return true;
    }

    return false
  }, [me.id, me.role.name, user.agency?.owner.id, user.id, user.role.name])
}

export default usePermissions
