import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';

import dayjs from '../../../utils/dayjs';
import { pluralizeWord } from 'utils/transformers';

import { CalculatedValue, ExtendCellBox } from './styled';

const NumberOfHoursTable: FunctionComponent<any> = ({
  order,
  outstanding,
  totalHours,
  totalWeeks,
  updated,
  watch,
}) => {
  const { endDate, hours } = watch();

  return (
    <>
      <Box display="table-row">
        <ExtendCellBox pl={0} pt={0.75}>
          {totalHours} {pluralizeWord('hour', totalWeeks)}
        </ExtendCellBox>
        <ExtendCellBox pt={0.75}>
          <b>
            {outstanding.hours} {pluralizeWord('hour', outstanding.hours)}
          </b>
        </ExtendCellBox>
        <ExtendCellBox pt={0.75} pr={0}>
          {hours || endDate ? (
            updated.hours ? (
              <CalculatedValue sx={{ backgroundColor: '#FED45B', borderRadius: '4px' }}>
                {updated.hours} {pluralizeWord('hour', updated.hours || outstanding.hours)}
              </CalculatedValue>
            ) : (
              <CalculatedValue>
                {outstanding.hours} {pluralizeWord('hour', outstanding.hours)}
              </CalculatedValue>
            )
          ) : (
            <CalculatedValue>-</CalculatedValue>
          )}
        </ExtendCellBox>
      </Box>
      <Box display="table-row">
        <ExtendCellBox pl={0}>
          Done by {dayjs(order.timeLine.endDate).format('MM/DD/YYYY')}
        </ExtendCellBox>
        <ExtendCellBox></ExtendCellBox>
        <ExtendCellBox pr={0}>
          {hours || endDate ? (
            <CalculatedValue sx={{ backgroundColor: '#FED45B', borderRadius: '4px' }}>
              {dayjs(endDate || order.timeLine.endDate).format('MM/DD/YYYY')}
            </CalculatedValue>
          ) : (
            <CalculatedValue>-</CalculatedValue>
          )}
        </ExtendCellBox>
      </Box>
    </>
  );
};

export default NumberOfHoursTable;
