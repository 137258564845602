import { Components } from '@mui/material/styles';

const MuiTooltip: Components['MuiTooltip'] = {
  styleOverrides: {
    arrow: ({ theme }: any) => ({
      color: theme.palette.common.black,
    }),
    tooltip: ({ theme }: any) => ({
      backgroundColor: theme.palette.common.black,
      fontSize: '0.875rem',
      fontWeight: 400,
    }),
  },
};

export default MuiTooltip;
