import React, {FunctionComponent, useMemo} from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { BriefcaseIcon } from "styles/icons/BriefcaseIcon";

import { experienceHumanized } from "utils/dateFormatter";
import { getProductWithMaxPeriodOfWork, getReducedExperiences } from "utils/getReducedExperience";

import { IProductExperienceFull } from "store/types/consultant";

interface IExperiencePeriodProps {
  expertiseSummary?: IProductExperienceFull[];
}

const ConsultantExperiencePeriod: FunctionComponent<IExperiencePeriodProps> = ({ expertiseSummary }) => {
  const reducedExperiences = useMemo(() => {
    if (expertiseSummary) {
      return getReducedExperiences(expertiseSummary);
    }
    return undefined;
  }, [expertiseSummary]);

  const maxExperience = useMemo(() => {
    if (reducedExperiences) {
      return getProductWithMaxPeriodOfWork(reducedExperiences);
    }
    return undefined;
  }, [reducedExperiences]);

  const maxExperiencePeriod = useMemo(() => {
    if (maxExperience) {
      return experienceHumanized(maxExperience.month);
    }
    return undefined;
  }, [maxExperience]);

  const maxExperienceProduct = useMemo(() => {
    if (maxExperience && expertiseSummary?.length) {
      return expertiseSummary.find((item) => item.productId === maxExperience.productId)?.product;
    }
    return undefined;
  }, [maxExperience, expertiseSummary]);

  return (
    <>
      {maxExperience && (
        <Box display="flex" alignItems="start" mb={1}>
          <Box sx={{width: 24, mr: 1}}>
            <BriefcaseIcon />
          </Box>
          <Typography component="div" alignSelf="end">
            <strong>{maxExperiencePeriod}</strong> in {maxExperienceProduct?.name}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default ConsultantExperiencePeriod
