import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(({theme}) => theme.unstable_sx({
  backgroundColor: 'secondary.main',
  color: 'common.white',
  fontSize: '0.875rem',
  fontWeight: 400,
  padding: `${theme.spacing(1/2)} ${theme.spacing(1 + 1/2)}`,
  borderRadius: theme.spacing(1/2)
}))

export { StyledBox }
