import { useLocation, matchPath } from 'react-router-dom';
import { useMemo } from 'react';

// Custom hook to check if the current path matches any of the specified paths
const useMatchMultiple = (paths: Array<string>): boolean => {
  const location = useLocation();

  return useMemo(
    () => paths.some(path => matchPath(path, location.pathname) !== null),
    [location.pathname, paths],
  );
};

export default useMatchMultiple;
