import { Components } from '@mui/material/styles';

const MuiDivider: Components['MuiDivider'] = {
  styleOverrides: {
    root: {
      borderColor: '#EBEBEB',
      margin: '1rem 0',
      opacity: 1,
    },
  },
};

export default MuiDivider;
