import {styled} from "@mui/material/styles";
import MuiSlider, {
  SliderMark as MuiSliderMark,
  SliderMarkLabel as MuiSliderMarkLabel,
  SliderRail as MuiSliderRail,
  SliderThumb as MuiSliderThumb,
  SliderTrack as MuiSliderTrack
} from "@mui/material/Slider";

const Slider = styled(MuiSlider)(({ theme }) => ({
  height: 20,
  backgroundColor: theme.palette.common.white,
  marginBottom: 0,
  '&.experienced': {
    '& .MuiSlider-track, .MuiSlider-thumb': {
      backgroundColor: '#ffe1ac',
    }
  },
  '&.advanced': {
    '& .MuiSlider-track, .MuiSlider-thumb': {
      backgroundColor: '#ffca69',
    }
  },
  '&.expert': {
    '& .MuiSlider-track, .MuiSlider-thumb': {
      backgroundColor: '#FFB300',
    }
  },
  '@media (pointer: coarse)': {
    padding: '13px 0'
  },
}))

const SliderMarkLabel = styled(MuiSliderMarkLabel)(({ theme }) => theme.unstable_sx({
  height: 16,
  width: '25%',
  textAlign: 'center',
  top: {
    xs: -8,
    md: 15
  },
  transform: 'translateX(0)',
  '@media (pointer: coarse)': {
    top: {
      xs: -8,
      md: 15
    },
  },
  '&.MuiSlider-markLabelActive:has(~ .MuiSlider-markLabelActive)': {
    height: 16,
    boxShadow: '0px 3px white, 0 -3px white, inset 0 1px #D4D4D4, inset 0 -1px #D4D4D4',
    '&:before': {
      width: 8,
      height: 16,
      borderRadius: '16px 0 0 16px',
      top: 0,
      left: -8,
      borderColor: '#D4D4D4',
      borderWidth: 1,
    },
    '&[data-index="0"]': {
      backgroundColor: '#ffe8c0',
    },
    '&[data-index="1"]': {
      backgroundColor: '#ffe1ac',
    },
    '&[data-index="2"]': {
      backgroundColor: '#ffca69',
    },
    '&[data-index="3"]': {
      backgroundColor: '#FFB300',
    },
    '&:after': {
      // content: '""',
      textIndent: {
        md: -9999
      },
    }
  },
  '&.MuiSlider-markLabelActive': {
    textIndent: -9999,
    top: 15,
    zIndex: 2,
    '&:before': {
      width: 10,
      height: 20,
      borderRadius: '20px 0 0 20px',
      top: -2,
      left: -10,
      borderColor: '#000',
      borderWidth: 2,
    },
    '&[data-index="0"]:before': {
      backgroundColor: '#ffe8c0',
    },
    '&[data-index="1"]:before': {
      backgroundColor: '#ffe1ac',
    },
    '&[data-index="2"]:before': {
      backgroundColor: '#ffca69',
    },
    '&[data-index="3"]:before': {
      backgroundColor: '#FFB300',
    },
    '&:after': {
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      top: {
        xs: -24,
        md: -30
      },
      left: {
        xs: 0,
      },
      fontSize: {
        xs: '.75rem',
        md: '1.125rem'
      },
      fontWeight: 700,
      textIndent: 0,
    },
    '&[data-index="0"]:after': {
      content: '""',
    },
    '&[data-index="1"]:after': {
      content: '"Experienced"',
    },
    '&[data-index="2"]:after': {
      content: '"Advanced"',
    },
    '&[data-index="3"]:after': {
      content: '"Expert"',
    },
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: 8,
    height: 16,
    borderRadius: '16px 0 0 16px',
    top: {
      xs: 23,
      md: 0
    },
    left: -8,
    borderColor: '#D4D4D4',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRight: 'none',
    backgroundColor: theme.palette.common.white,
    boxSizing: 'border-box'
  },
}))

const SliderTrack = styled(MuiSliderTrack)({
  borderTop: '2px solid #000',
  borderBottom: '2px solid #000',
  borderLeft: 'none',
  borderRight: 'none',
  borderRadius: 0,
  background: '#ffe8c0',
  zIndex: 1,
  boxSizing: 'border-box',
})

const SliderMark = styled(MuiSliderMark)({
  display: 'none',
})

const SliderThumb = styled(MuiSliderThumb)({
  borderTop: '2px solid #000',
  borderBottom: '2px solid #000',
  borderRight: '2px solid #000',
  borderRadius: '0 20px 20px 0',
  backgroundColor: 'transparent',
  width: 10,
  background: '#ffe8c0',
  transform: 'translate(0%, -50%)',
  '&:before': {
    boxShadow: 'none',
  }
})

const SliderRail = styled(MuiSliderRail)(({theme}) => ({
  height: 16,
  borderTop: '1px solid #D4D4D4',
  borderBottom: '1px solid #D4D4D4',
  borderRadius: 0,
  opacity: 1,
  backgroundColor: theme.palette.common.white,
  '&:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: 8,
    height: 16,
    borderRadius: '0 16px 16px 0',
    top: -1,
    right: -8,
    borderColor: '#D4D4D4',
    borderWidth: 1,
    borderStyle: 'solid',
    borderLeft: 'none',
    backgroundColor: theme.palette.common.white,
  },
}))

export { Slider, SliderMark, SliderMarkLabel, SliderRail, SliderThumb, SliderTrack }
