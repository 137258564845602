/**
 *  Refactored 12.23
 */
import { createSelector } from "@reduxjs/toolkit";
import { DefaultRootState } from "react-redux";

import getOr from "lodash/fp/getOr";
import flowRight from "lodash/fp/flowRight";
import compact from "lodash/fp/compact";
import map from "lodash/fp/map";

export const getCustomers = (customerById: Record<string, any>, type?: string) =>
  flowRight(
    compact,
    map((id: number) => id && (type && customerById[id][type] || customerById[id])),
  );

export const CustomerByIdState = (state: DefaultRootState) => state.customer.customerById

export const getBasicCustomerSelector = (key: string | number) => createSelector(
  CustomerByIdState,
  getOr(undefined)([key, 'customer'])
);

export const getCustomerCompanySelector = (key: string | number) => createSelector(
  CustomerByIdState,
  ({ [key]: { customer } }) => customer.companies[0]
);
