import React, {FC, useEffect} from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import DatePicker from "react-datepicker";

import dayjs from "utils/dayjs";
import {isFriday, isMonday} from "utils/dateFormatter";

import Grid from "@mui/material/Unstable_Grid2";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import CalendarIcon from "styles/icons/CalendarIcon";

import DateTextInput from "../../CustomFields/DateTextInput";

interface IRangeDate {
  nameIsCurrentWork?: string;
  nameEndDate: string;
  nameStartDate: string;
  isClearable?: boolean;
}

const RangeDate: FC<IRangeDate> = ({ nameIsCurrentWork = '', nameEndDate, nameStartDate, isClearable }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const {control, reset, watch } = useFormContext<FieldValues>();

  const watchStartDate = watch(nameStartDate)
  const watchEndDate = watch(nameEndDate)

  useEffect(() => {
    return () => reset()
  }, [ reset ])

  return (
    <>
      <Grid xs={6}>
        <Controller
          name={nameStartDate}
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <DatePicker
              {...field}
              selected={!!watchStartDate ? dayjs(watchStartDate).toDate() : null}
              calendarStartDay={1}
              customInput={
                <DateTextInput
                  className="text-custom-input"
                  icon={<CalendarIcon />}
                />
              }
              showPopperArrow={false}
              minDate={dayjs().toDate()}
              maxDate={dayjs(watchEndDate).toDate()}
              filterDate={isMonday}
              isClearable={isClearable}
              placeholderText="YYYY-MM-DD"
              withPortal={mobile}
            />
          )}
        />
      </Grid>
      <Grid xs={6}>
        <Controller
          control={control}
          name={nameEndDate}
          rules={{ validate: {
              isRequired: (date) => watch(nameIsCurrentWork) || !!date || 'This field is required',
              afterStart: (date) => !date || (!watchStartDate || dayjs(watchStartDate).isSameOrBefore(dayjs(date), 'month')) || 'Incorrect date range',
            }}}
          render={({ field }) => (
            <DatePicker
              {...field}
              selected={!!watchEndDate ? dayjs(watchEndDate).toDate() : null}
              calendarStartDay={1}
              customInput={
                <DateTextInput
                  className="text-custom-input"
                  icon={<CalendarIcon />}
                />
              }
              showPopperArrow={false}
              minDate={dayjs().toDate()}
              filterDate={isFriday}
              isClearable={isClearable}
              placeholderText="YYYY-MM-DD"
              withPortal={mobile}
            />
          )}
        />
      </Grid>
    </>
  )
}

export default RangeDate
