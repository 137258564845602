import {createAsyncThunk} from "@reduxjs/toolkit";

import instance from "../../middlewares/api";
import { EConsultant } from "../../constants";
import { IOutDates } from "../../types/consultant";

interface IAddOutDatesProps {
  keySelector?: string;
  outDates: IOutDates[],
  userId: number | string;
}
const addOutDates = createAsyncThunk<IOutDates[], IAddOutDatesProps>(
  EConsultant.addOutDates,
  async ({ outDates }) => {
    const { data } = await instance.post('out-dates', outDates)
    return data
  }
)

export default addOutDates
