import React, {FC} from "react";

import Chip from "@mui/material/Chip";

import {ITagItem} from "store/reducers/tags";

import {sortingBy} from "utils/transformers";

interface IAdditionalSkills {
  tags: ITagItem[]
}
const AdditionalSkills: FC<IAdditionalSkills> = ({ tags }) => (
  <>
    {!!tags?.length && sortingBy(tags, 'UserTags.showIndex')
      .map((item: any) => (
        <Chip sx={{mr: 1.5, mb: 2}} key={item.id} label={item.name} variant="outlined" size="small" />
      ))
    }
  </>
)

export default AdditionalSkills
