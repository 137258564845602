import { Components } from '@mui/material/styles';

const MuiAppBar: Components['MuiAppBar'] = {
  styleOverrides: {
    root: {
    },
    colorTransparent: {
      border: 'none',
    },
    colorDefault: {
      backgroundColor: 'white',
    },
  }
}

export default MuiAppBar
