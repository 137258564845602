import { Capacitor } from '@capacitor/core';
import { toast, Slide, Flip, ToastContent, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toasterDefaultOptions: ToastOptions = {
  position: 'top-center',
  autoClose: 5000,
  theme: 'colored',
  hideProgressBar: true,
  transition: Slide,
};

const mobileToast: ToastOptions = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  transition: Flip,
};

const toastOptions = Capacitor.isNativePlatform() ? mobileToast : toasterDefaultOptions;

export const ToastService: any = {
  ...toast,

  success: (content: ToastContent<unknown>, options?: ToastOptions) => {
    toast.success(content, {
      ...toastOptions,
      ...options,
    });
  },

  error: (content: ToastContent<unknown>, options?: ToastOptions) => {
    toast.error(content, {
      ...toastOptions,
      ...options,
    });
  },

  warn: (content: ToastContent<unknown>, options?: ToastOptions) => {
    toast.warn(content, {
      ...toastOptions,
      ...options,
    });
  },

  info: (content: ToastContent<unknown>, options?: ToastOptions) => {
    toast.info(content, {
      ...toastOptions,
      ...options,
    });
  },
};
