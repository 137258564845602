import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EConsultant } from 'store/constants';

export type UploadCV = {
  file: File,
};

const uploadConsultantCV = createAsyncThunk(
  EConsultant.addCVUpload,
  async (payload: UploadCV) => {
    const formData = new FormData();
    const fileNameWithFormat = payload.file.name;
    formData.append('file', payload.file as Blob, fileNameWithFormat);
    const { data } = await instance.post(
      `consultant/cv-upload`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' }},
    );
    return data;
  }
)

export default uploadConsultantCV;
