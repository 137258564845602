import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";

const StyledLabel = styled(Typography)(({theme}) => theme.unstable_sx({
  width: 90,
  flexShrink: 0
}))


export { StyledLabel }
