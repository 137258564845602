import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { ECommon } from "../../constants";

const fetchRoles = createAsyncThunk(
  ECommon.roles,
  async () => {
    const { data } = await instance.get('company-roles?offset=0&limit=25')
    return await data
  }
)

export default fetchRoles
