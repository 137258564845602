import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { rateFormat } from 'utils/textFormatter';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { StyledBoxTotal, StyledButton, StyledReviewsHeader, StyledTitle } from './styled';
import StarFilledIcon3 from 'styles/icons/StarFilledIcon3';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { EProgressValue } from 'models/consts';
import { IBasicOrders } from 'store/types/order';

import { standartDateToMonth } from 'utils/dateFormatter';

import fetchOrderFeedback from 'store/query/order/fetchOrderFeedback';

import {
  orderFeedbackLoadingSelector,
  orderFeedbackSelector,
} from 'store/selectors/getOrderSelector';

import Loader from '../../Loader/Loader';
import FeedbackModal from 'components/Modal/FeedbackModal';

interface IOrderFeedback {
  order: IBasicOrders;
}

const normalise = (value: number) =>
  ((value - EProgressValue.MIN) * 100) / (EProgressValue.MAX - EProgressValue.MIN);

const LinearProgressWithLabel = ({
  label,
  mb,
  value,
}: LinearProgressProps & { mb?: number; value: number; label: string }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', mb }}>
    <Box sx={{ minWidth: 120 }}>
      <Typography variant="body1">{label}</Typography>
    </Box>
    <Box sx={{ width: '100%', mr: 1 }}>
      <LinearProgress variant="determinate" value={normalise(value)} />
    </Box>
    <Box>
      <Typography variant="body1">
        <b>{value.toFixed(1)}</b>
      </Typography>
    </Box>
  </Box>
);

const Feedback: FunctionComponent<IOrderFeedback> = ({ order }) => {
  const dispatch = useAppDispatch();

  const { id = '' } = useParams();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const feedback = useAppSelector(orderFeedbackSelector(id));
  const loading = useAppSelector(orderFeedbackLoadingSelector(id));

  const [showFeedback, setFeedbackModal] = useState<boolean>(false);

  useEffect(() => {
    if (order?.status === 'completed') {
      dispatch(fetchOrderFeedback(id));
    }
  }, [dispatch, order?.status, id]);

  return (
    <>
      {!mobile && <Divider />}
      {!loading ? (
        <>
          {!feedback?.id && (
            <Grid container alignItems="center" mb={5}>
              <Grid item xs={12} md>
                <StyledTitle variant="h3" gutterBottom>
                  Review your experience with {order?.worker?.firstName}
                </StyledTitle>
                <Typography>
                  Please, leave feedback about your experience on this order with{' '}
                  {order?.worker?.firstName}
                </Typography>
              </Grid>
              <Grid item xs={12} md="auto" sx={{ mt: { xs: 2, md: 0 } }}>
                <StyledButton
                  variant="contained"
                  color={mobile ? 'tertiary' : 'secondary'}
                  onClick={() => setFeedbackModal(true)}
                >
                  Add review
                </StyledButton>
              </Grid>
            </Grid>
          )}
          {!!feedback?.id && (
            <>
              <Grid container mb={3}>
                <StyledReviewsHeader
                  item
                  xs={12}
                  md={6}
                  display="flex"
                  direction={mobile ? 'row' : 'column'}
                  justifyContent="space-between"
                >
                  <Typography component="div" variant="h3">
                    Reviews
                  </Typography>
                  <StyledBoxTotal>
                    <Chip
                      color="secondary"
                      icon={<StarFilledIcon3 sx={{ fontSize: '1rem' }} />}
                      label={rateFormat(feedback?.totalRate)}
                      sx={{
                        display: 'flex',
                        mr: 1,
                      }}
                      variant="filled"
                    />
                    <Typography variant="body2" color="text.secondary">
                      out of 5
                    </Typography>
                  </StyledBoxTotal>
                </StyledReviewsHeader>
                <Grid item xs={12} md={6}>
                  <LinearProgressWithLabel
                    label="Expertise"
                    mb={2}
                    value={feedback?.expertiseRate}
                  />
                  <LinearProgressWithLabel
                    label="Communication"
                    mb={2}
                    value={feedback?.communicationRate}
                  />
                  <LinearProgressWithLabel
                    label="Availability"
                    mb={2}
                    value={feedback?.availabilityRate}
                  />
                  <LinearProgressWithLabel label="Timely delivery" value={feedback?.deliveryRate} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs>
                  {feedback?.feedbackText && (
                    <>
                      <Typography variant="body1" color="text.secondary" gutterBottom>
                        {standartDateToMonth(feedback?.createdAt || '')}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {feedback.feedbackText}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
      <FeedbackModal
        visible={showFeedback}
        close={() => setFeedbackModal(false)}
        consultant={order?.worker}
      />
    </>
  );
};

export default Feedback;
