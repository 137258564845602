import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EPreOrder } from '../../constants';
import { IBasicPreOrder } from '../../types/preOrder';
import { IRole } from '../../types/common';
import { ERole } from '../../../models/consts';

interface IOrderByIdProps {
  id: string;
  role: IRole;
}

const fetchPreOrderById = createAsyncThunk<IBasicPreOrder, IOrderByIdProps>(
  EPreOrder.getOrderById,
  async ({ id, role }) => {
    let url = '/lead-managers/pre-orders';

    if ([ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(role.name)) {
      url = '/agency/pre-orders';
    } else if ([ERole.CUSTOMER_ADMIN, ERole.CUSTOMER].includes(role.name)) {
      url = '/enterprise/pre-orders';
    }

    const { data } = await instance.get(`${url}/${id}`);

    return data;
  },
);

export default fetchPreOrderById;
