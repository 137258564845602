import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";

import instance from "../../middlewares/api";
import { EFeedback } from "../../constants";

const fetchConsultantReview = createAsyncThunk<AxiosResponse, any>(
  EFeedback.submitFeedBacks,
  async (props) => {
    const { data } = await instance.post(`feedbacks`, props)
    return data
  }
)

export default fetchConsultantReview
