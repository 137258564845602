import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';
export const FavoriteIcon: FunctionComponent<ISvgProps> = ({className}) => {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g mask="url(#mask0_4116_26429)">
        <path d="M10 17.0001L8.95833 16.0626C7.56944 14.8265 6.42361 13.7674 5.52083 12.8855C4.61806 12.0035 3.90625 11.2188 3.38542 10.5313C2.86458 9.84381 2.50347 9.21534 2.30208 8.64589C2.10069 8.07645 2 7.49312 2 6.89589C2 5.65978 2.42361 4.61812 3.27083 3.77089C4.11806 2.92367 5.15972 2.50006 6.39583 2.50006C7.07639 2.50006 7.73611 2.64589 8.375 2.93756C9.01389 3.22923 9.55556 3.63895 10 4.16673C10.4444 3.63895 10.9861 3.22923 11.625 2.93756C12.2639 2.64589 12.9236 2.50006 13.6042 2.50006C14.8403 2.50006 15.8819 2.92367 16.7292 3.77089C17.5764 4.61812 18 5.65978 18 6.89589C18 7.49312 17.9028 8.06951 17.7083 8.62506C17.5139 9.18062 17.1562 9.79867 16.6354 10.4792C16.1146 11.1598 15.3993 11.948 14.4896 12.8438C13.5799 13.7396 12.4167 14.8265 11 16.1042L10 17.0001ZM10 14.9792C11.2917 13.8265 12.3542 12.8438 13.1875 12.0313C14.0208 11.2188 14.684 10.5105 15.1771 9.90631C15.6701 9.30214 16.0139 8.76395 16.2083 8.29173C16.4028 7.81951 16.5 7.35423 16.5 6.89589C16.5 6.07645 16.2222 5.38895 15.6667 4.83339C15.1111 4.27784 14.4236 4.00006 13.6042 4.00006C13.1181 4.00006 12.6632 4.10076 12.2396 4.30214C11.816 4.50353 11.4514 4.78478 11.1458 5.14589L10.4167 6.00006L9.58333 6.00006L8.85417 5.14589C8.54861 4.78478 8.17708 4.50353 7.73958 4.30214C7.30208 4.10076 6.85417 4.00006 6.39583 4.00006C5.57639 4.00006 4.88889 4.27784 4.33333 4.83339C3.77778 5.38895 3.5 6.07645 3.5 6.89589C3.5 7.35423 3.59028 7.80909 3.77083 8.26048C3.95139 8.71187 4.28125 9.23617 4.76042 9.83339C5.23958 10.4306 5.89931 11.139 6.73958 11.9584C7.57986 12.7778 8.66667 13.7848 10 14.9792Z" fill="currentColor"/>
      </g>
    </svg>
  );
};