import { styled } from '@mui/material/styles';
import TableCell from './TableCell';

const TableActionCell = styled(TableCell)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  }),
);

export default TableActionCell;
