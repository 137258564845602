import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import TabList from '@mui/lab/TabList';
import TabPanel from "@mui/lab/TabPanel";

import CloseIcon from "styles/icons/CloseIcon";

// @ts-ignore
const StyledTabs = styled(TabList)<any>({
  alignItems: 'center',
  display: 'inline-flex',
  minHeight: 38,
  width: '100%',
  borderBottom: '2px solid #1f1f1f',
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '& .MuiTabs-scrollButtons': {
    width: 30
  },
  '& .MuiTabs-scrollButtons:first-of-type': {
    justifyContent: 'start'
  },
  '& .MuiTabs-scrollButtons:last-child': {
    justifyContent: 'end'
  }
})

const StyledTabBase = styled(Tab)(({ theme }) => ({
  height: 36,
  minHeight: 36,
  textTransform: 'none',
  marginRight: theme.spacing(.5),
  fontWeight: 700,
  fontSize: '1rem',
  '& .MuiTabs-flexContainer': {
    alignItems: 'center'
  }
}))

const StyledTab = styled(StyledTabBase)(({ theme }) => ({
  margin: 0,
  color: 'rgb(31, 31, 31)',
  minWidth: 24,
  transition: theme.transitions.create([
    'color',
    'background-color',
  ]),
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: 'rgb(31, 31, 31)'
  },
  '&:after': {
    borderRight: `1px solid #D4D4D4`,
    position: 'absolute',
    right: 0,
    content: '""',
    height: '65%',
    margin: '6px 0'
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    border: 'none',
    '& .MuiButtonBase-root': {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, .2)',
        color: theme.palette.common.white,
      },
    },
    '&:after': {
      display: 'none',
    },
  },
}))

const StyledTabAdd = styled(StyledTabBase)({
  fontWeight: 400,
  fontSize: '0.875rem',
  color: 'rgb(31, 31, 31)',
  '&.Mui-selected': {
    color: 'rgb(31, 31, 31)',
  },
  '&.Mui-selected.Mui-disabled': {
    color: 'rgba(0, 0, 0, .38)',
  }
})

const StyledCloseButton = styled(CloseIcon)(({ theme }) => ({
  borderRadius: '50%',
  width: 18,
  height: 18,
  marginRight: theme.spacing(-1),
  padding: 2,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, .2)',
    color: theme.palette.text.primary,
  },
}))

const StyledTabPanel = styled(TabPanel)(({theme}) => ({
  paddingLeft: 0,
  paddingRight: 0,
  borderTop: '1px solid #ebebeb',
  marginTop: theme.spacing(1)
}))

export { StyledCloseButton, StyledTab, StyledTabAdd, StyledTabs, StyledTabPanel }
