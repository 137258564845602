import { Components } from '@mui/material/styles';

const MuiBottomNavigationAction: Components['MuiBottomNavigationAction'] = {
  styleOverrides: {
    root: ({ theme }: any) => theme.unstable_sx({
      flex: 0,
      minWidth: 100,
      borderTop: '3px solid white',
      color: 'secondary.main',
      '& svg': {
        transition: theme.transitions.create('stroke', {
          duration: theme.transitions.duration.standard,
        }),
        stroke: theme.palette.secondary.main,
        fill: 'none'
      },
      fill: 'none',
      '&.Mui-selected': {
        borderColor: 'primary.main',
        color: 'primary.main',
      },
      '&.Mui-selected svg': {
        stroke: theme.palette.primary.main
      },
    }),
    label: ({ theme }: any) => theme.unstable_sx({
      color: 'inherit',
      fontSize: '0.875rem',
    }),
  },
}

export default MuiBottomNavigationAction
