import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { ECompany } from 'store/constants';

const saveCompanyLogo = createAsyncThunk(
  ECompany.loadCompanyLogo,
  async (payload: any) => {
    const formData = new FormData();
    formData.append('logo_image', payload.logo as Blob, 'logo_image.jpeg');
    const { data } = await instance.post(
      `companies/${payload.companyId}/logo`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' }}
    );
    return data;
  }
)

export default saveCompanyLogo;
