import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {ECommon} from "../../constants";

const fetchTimePreferences = createAsyncThunk(
  ECommon.timePreferences,
  async () => {
    const { data } = await instance.get('time-preferences')
    return await data
  }
)

export default fetchTimePreferences
