import { createAsyncThunk} from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import {EOrder} from 'store/constants';

import {IFeedbackForm} from "../../types/form";

interface IParams {
  orderId: number | string;
  feedback: IFeedbackForm;
}
const createFeedback = createAsyncThunk<any, IParams>(
  EOrder.createFeedback,
  async ({ orderId, feedback }) => {
    const { data } = await instance.post(`orders/${orderId}/feedback`, feedback)

    return data
  }
);

export default createFeedback;
