import { createAsyncThunk} from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EPreOrder } from 'store/constants';


const finalizePreorder = createAsyncThunk<any, { preOrderId: number }>(
  EPreOrder.finalizePreorder,
  async ({ preOrderId }) => {
    const { data } = await instance.post(`/enterprise/pre-orders/${preOrderId}/finalize`);

    return data;
  }
);

export default finalizePreorder;
