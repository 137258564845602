import React, { forwardRef } from 'react';
import dayjs from 'utils/dayjs'
import cn from "classnames";
import StyledDateTextInput from "./styled";

const DateTextInput = forwardRef(({
  value,
  formatDate = 'MM/DD/YYYY',
  onClick,
  className,
  icon,
  placeholder,
  disabled,
  ...props
}: any, ref) => (
  <StyledDateTextInput
    {...props}
    className={cn(className, {
      ['selected']: value,
      ['disabled']: disabled,
    })}
    onClick={onClick}
    ref={ref}
  >
    {icon}
    {value ? dayjs(value).format(formatDate) : placeholder}
  </StyledDateTextInput>
));

export default DateTextInput;
