import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {EConsultant} from "../../constants";

const addConsultantExperience = createAsyncThunk(
  EConsultant.addConsultantExperience,
  async (payload: any) => {
    const { data } = await instance.post('experiences', payload.data)
    return data
  }
)

export default addConsultantExperience
