import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EAgency } from 'store/constants/agency';
import { IAgencyUpdateDto } from 'store/types/agency';

import dataToImgFile from 'utils/dataToImgFile';

const updateAgencyInfo = createAsyncThunk<any, IAgencyUpdateDto>(
  EAgency.updateAgency,
  async ({logo, ...values}) => {
    const formData = new FormData();
    for(const [name, value] of Object.entries(values)) {
      if(value) {
        formData.append(name, value)
      }
    }
    if (logo) {
      const { blob: logo_image } = dataToImgFile(logo);
      formData.append('file', logo_image, 'logo_image.jpg');
    }

    const response = await instance.patch('agency', formData, { headers: { 'Content-Type': 'multipart/form-data' }});

    return response.data;
  }
)

export default updateAgencyInfo;
