import React, { FunctionComponent, useMemo } from 'react';

import MuiLink from '@mui/material/Link';
import { Typography } from '@mui/material';
import PowerIcon from '@mui/icons-material/PowerSettingsNew';
import PauseCircleIcon from '@mui/icons-material/PauseCircleOutline';
import RefreshIcon from '@mui/icons-material/Autorenew';
import TrashIcon from '@mui/icons-material/DeleteOutline';

import { IAgencyUser } from 'store/types/agency';
import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { IBasicUser } from 'store/types/user';
import { ERole } from 'models/consts';
import usePermissions from './usePermissions';

const invited = (user: IAgencyUser) => {
  return !user.isActive && !user.lastChangeActiveAt;
};

export interface IActionsListProps {
  user: IAgencyUser;
  onChangeStatus: (user: IAgencyUser, selectedAction: string) => void;
}

const ActionsList: FunctionComponent<IActionsListProps> = ({ user, onChangeStatus }) => {
  const me = useAppSelector(getMeSelector) as IBasicUser;

  const isViewDeleteBtn = usePermissions(user);

  const showActivationButtons = useMemo(() => {
    const condition = !invited(user) && user.id !== me.id && user.agency?.owner.id !== user.id;
    if (me.role.name === ERole.RESOURCE_MANAGER && me.id !== user.agency?.owner.id) {
      return condition && user.role.name !== ERole.AGENCY_ADMIN;
    }
    return condition;
  }, [me, user]);

  return (
    <>
      {user.isActive && showActivationButtons && (
        <MuiLink
          color="secondary"
          onClick={() => onChangeStatus(user, 'active')}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            stroke: 'none !important',
            flexDirection: 'column',
            width: 52,
          }}
          underline="none"
        >
          <PauseCircleIcon />
          <Typography
            component="span"
            lineHeight="normal"
            sx={{ fontSize: { xs: '0.625rem', lg: '0.875rem' } }}
          >
            Pause
          </Typography>
        </MuiLink>
      )}
      {!user.isActive && showActivationButtons && (
        <MuiLink
          color="secondary"
          onClick={() => onChangeStatus(user, 'deactive')}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            stroke: 'none !important',
            flexDirection: 'column',
          }}
          underline="none"
        >
          <PowerIcon />
          <Typography
            component="span"
            lineHeight="20px"
            sx={{ fontSize: { xs: '0.625rem', lg: '0.875rem' } }}
          >
            Activate
          </Typography>
        </MuiLink>
      )}
      {invited(user) && (
        <MuiLink
          color="secondary"
          onClick={() => onChangeStatus(user, 'refresh')}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            stroke: 'none !important',
            flexDirection: 'column',
          }}
          underline="none"
        >
          <RefreshIcon />
          <Typography
            component="span"
            lineHeight="normal"
            sx={{ fontSize: { xs: '0.625rem', lg: '0.875rem' } }}
          >
            Resend
          </Typography>
        </MuiLink>
      )}
      {isViewDeleteBtn && (
        <MuiLink
          color="secondary"
          onClick={() => onChangeStatus(user, user.isActive === null ? 'cancel' : 'delete')}
          ml={1}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            stroke: 'none !important',
            flexDirection: 'column',
          }}
          underline="none"
        >
          <TrashIcon />
          <Typography
            component="span"
            lineHeight="normal"
            sx={{ fontSize: { xs: '0.625rem', lg: '0.875rem' } }}
          >
            {user.isActive === null ? 'Cancel' : 'Delete'}
          </Typography>
        </MuiLink>
      )}
    </>
  );
};
export default ActionsList;
