import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EAgencyTags } from 'store/constants';

const searchAgencyTags = createAsyncThunk(
  EAgencyTags.searchAgencyTags,
  async (tag: string) => {
    const response = await instance.get(`company-tags/search?search=${tag}`)
    return await response.data
  }
);

export default searchAgencyTags;
