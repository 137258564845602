import React, { FunctionComponent, useEffect } from 'react';
import dayjs from 'utils/dayjs';
import { roundTo } from 'utils/roundTo';
import { calcWeeks } from 'utils/dateFormatter';

import capitalize from 'lodash/capitalize';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { StyledDetailKey } from './styled';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import useCalculatePrice from 'hooks/useCalculatePrice';

import { IBasicOrders } from 'store/types/order';
import { IBasicPreOrder } from 'store/types/preOrder';

import fetchExpertiseLevels from 'store/query/common/fetchExpertiseLevels';
import { expertiseLevelsSelector } from 'store/selectors/getCommonSelector';
import {
  isAgencyAdminSelector,
  isAgencyResourceManagerSelector,
  isAgencySelector,
  isConsultantSelector,
  isInternalSelector,
} from 'store/selectors/getUserSelector';
import { TAXES } from 'store/constants';
import { ECustomRateOptionType } from 'models/consts';

interface IOrderDetails {
  order: IBasicOrders | IBasicPreOrder;
  isOrderCreation?: boolean;
  isPreOrder?: boolean;
}

const OrderDetails: FunctionComponent<IOrderDetails> = ({ order, isOrderCreation, isPreOrder }) => {
  const dispatch = useAppDispatch();
  const isConsultant = useAppSelector(isConsultantSelector);
  const isInternal = useAppSelector(isInternalSelector);
  const isResourceManager = useAppSelector(isAgencyResourceManagerSelector);
  const isAgencyAdmin = useAppSelector(isAgencyAdminSelector);
  const isAgency = useAppSelector(isAgencySelector);
  const {
    billedToDate,
    calculatedPrice,
    duration,
    paidToDate,
    totalSum,
    weeklyNeeds,
    approvedToDate,
  } = useCalculatePrice(order, isInternal);

  const moduleLevels = useAppSelector(expertiseLevelsSelector);

  useEffect(() => {
    if (!moduleLevels || !moduleLevels.length) {
      dispatch(fetchExpertiseLevels());
    }
  }, [dispatch, moduleLevels]);

  const totalHours = order.timeLine?.actualHours || order.timeLine?.allHours;
  const takeRate = order.taxes || TAXES;
  const totalWeeks = calcWeeks(
    dayjs(order?.timeLine?.endDate).format('MM/DD/YYYY'),
    dayjs(order?.timeLine?.startDate).format('MM/DD/YYYY'),
  );

  return (
    <>
      {order.expertise?.product && (
        <Box mb={3}>
          {isPreOrder && (
            <Box display="flex" alignItems="center" mb={1}>
              <StyledDetailKey component="div" variant="body1">
                Billings
              </StyledDetailKey>
              <Typography component="div">
                <b>{capitalize((order as IBasicPreOrder).billingType)}</b>
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center" mb={1}>
            <StyledDetailKey component="div" variant="body1">
              {(!order.timeLine.flexibleHours && 'Start Date') || 'Ready By'}
            </StyledDetailKey>
            <Typography component="div">
              <strong>
                {order.timeLine?.startDate
                  ? dayjs(order.timeLine?.startDate).format('DD MMM, YYYY')
                  : '-'}
              </strong>
            </Typography>
          </Box>
          {!order.timeLine.flexibleHours && (
            <>
              <Box display="flex" alignItems="center" mb={1}>
                <StyledDetailKey component="div" variant="body1">
                  Number of Weeks
                </StyledDetailKey>
                <Typography component="div">
                  {/*<b>{totalHours / order.timeLine?.availabilityWeek}</b>*/}
                  <b>{totalWeeks}</b>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <StyledDetailKey component="div" variant="body1">
                  Weekly Needs
                </StyledDetailKey>
                <Typography component="div">
                  {order.timeLine?.availabilityWeek || weeklyNeeds} hours / week
                </Typography>
              </Box>
            </>
          )}
          <Box display="flex" alignItems="center" mb={1}>
            <StyledDetailKey component="div" variant="body1">
              {(!order.timeLine.flexibleHours && 'End Date') || 'Done By'}
            </StyledDetailKey>
            <Typography component="div">
              <strong>
                {order.timeLine?.endDate
                  ? dayjs(order.timeLine?.endDate).format('DD MMM, YYYY')
                  : '-'}
              </strong>
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <StyledDetailKey component="div" variant="body1">
              {(order.timeLine.flexibleHours && 'Number of Hours') || 'Total Hours'}
            </StyledDetailKey>
            <Typography component="div">
              <b>{totalHours} hours</b>
            </Typography>
          </Box>
          {order.timeLine.flexibleHours && (
            <Box display="flex" alignItems="center" mb={1}>
              <StyledDetailKey component="div" variant="body1">
                Duration
              </StyledDetailKey>
              <Typography component="div">{duration ? `${duration} weeks` : '-'}</Typography>
            </Box>
          )}
        </Box>
      )}
      {order.expertise?.product && !isPreOrder && (
        <Box>
          {(!isInternal || isAgencyAdmin || isResourceManager) && (
            <Box display="flex" alignItems="center" mb={1}>
              <StyledDetailKey component="div" variant="body1">
                Hourly Rate
              </StyledDetailKey>
              <Typography component="div">
                <strong>
                  <Tooltip title="USD">
                    <span>$</span>
                  </Tooltip>
                  {calculatedPrice} / hour
                </strong>
              </Typography>
            </Box>
          )}
          {isAgency && (
            <>
              {order.agency?.customTakeRateOption === ECustomRateOptionType.PERCENT && (
                <Box display="flex" alignItems="center" mb={1}>
                  <StyledDetailKey component="div" variant="body1">
                    Placement Fee (%)
                  </StyledDetailKey>
                  <Typography component="div">
                    <strong>{roundTo(takeRate)}%</strong>
                  </Typography>
                </Box>
              )}
              <Box display="flex" alignItems="center" mb={1}>
                <StyledDetailKey component="div" variant="body1">
                  Net Hourly Rate
                </StyledDetailKey>
                <Typography component="div">
                  <strong>
                    <Tooltip title="USD">
                      <span>$</span>
                    </Tooltip>
                    {(calculatedPrice * (1 - takeRate / 100)).toFixed(2)} / hour
                  </strong>
                </Typography>
              </Box>
            </>
          )}

          {!isOrderCreation && billedToDate && isConsultant && (
            <Box display="flex" alignItems="center" mb={1}>
              <StyledDetailKey component="div" variant="body1">
                Billed To Date
              </StyledDetailKey>
              <Typography component="div">
                <strong>{billedToDate}</strong>
              </Typography>
            </Box>
          )}
          {!isOrderCreation && paidToDate && (
            <Box display="flex" alignItems="center" mb={1}>
              <StyledDetailKey component="div" variant="body1">
                Paid To Date
              </StyledDetailKey>
              <Typography component="div">
                <strong>{paidToDate}</strong>
              </Typography>
            </Box>
          )}
          {!isOrderCreation && approvedToDate && !isConsultant && (
            <Box display="flex" alignItems="center" mb={1}>
              <StyledDetailKey component="div" variant="body1">
                Approved To Date
              </StyledDetailKey>
              <Typography component="div">
                <strong>{approvedToDate}</strong>
              </Typography>
            </Box>
          )}

          {(!isInternal || isAgencyAdmin || isResourceManager) && (
            <Box display="flex" alignItems="center" mb={3}>
              <StyledDetailKey component="div" variant="body1">
                <b>Subtotal</b>
              </StyledDetailKey>
              <Typography component="div">
                <b>
                  <Tooltip title="USD">
                    <span>$</span>
                  </Tooltip>
                  {totalSum.toLocaleString('en-US')}
                </b>
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default OrderDetails;
