import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";

const StyledFormGroup = styled(Grid)(({ alignItems, theme }) => theme.unstable_sx({
  display: 'flex',
  alignItems: alignItems || 'center',
  flexGrow: 1,
}))

const StyledInputLabel = styled(InputLabel)(({ theme }) => theme.unstable_sx({
  m: 0,
  mr: 2,
  whiteSpace: 'normal', 
  overflow: 'hidden', 
  textOverflow: 'clip'
}))

const StyledCustomSwitch = styled(Switch)(({ theme }) => ({
  width: 148,
  height: 48,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    backgroundColor: 'white',
    border: '1px solid #1F1F1F',
    width: '50%',
    borderRadius: 46 / 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(100%)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#F5F5F5',
        opacity: 1,
        border: 0,
      },
      '& .MuiSwitch-thumb:before': {
        content: '"Role"'
      },
    },
  },
  '& .MuiSwitch-thumb': {
    borderRadius: 46 / 2,
    boxSizing: 'border-box',
    width: '100%',
    height: 46,
    '&:before': {
      content: "'Project'",
      position: 'absolute',
      width: '100%',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      color: theme.palette.text.primary,
      textAlign: 'center',
      fontWeight: 700,
      fontSize: '.750rem'
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 48 / 2,
    backgroundColor: '#F5F5F5',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    '&:before, &:after': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      color: theme.palette.text.secondary,
      textAlign: 'center',
      fontSize: '.750rem',
      width: '50%'
    },
    '&:before': {
      content: '"Project"',
    },
    '&:after': {
      content: '"Role"',
      right: 0
    },
  },
}));

const StyledInfoFieldValue = styled(Box)(({ theme }) => theme.unstable_sx({
  alignItems: 'center',
  display: 'flex',
  backgroundColor: '#F9F9F9',
  height: 48,
  px: 2,
  width: '100%'
}))


export { StyledCustomSwitch, StyledFormGroup, StyledInputLabel, StyledInfoFieldValue }
