import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const BriefcaseIcon: FunctionComponent<ISvgProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_205_13008" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_205_13008)">
        <path d="M9 7V5.625C9 5.15833 9.15433 4.771 9.463 4.463C9.771 4.15433 10.1583 4 10.625 4H13.375C13.8417 4 14.2293 4.15433 14.538 4.463C14.846 4.771 15 5.15833 15 5.625V7H19.375C19.8417 7 20.2293 7.15433 20.538 7.463C20.846 7.771 21 8.15833 21 8.625V18.375C21 18.8417 20.846 19.2293 20.538 19.538C20.2293 19.846 19.8417 20 19.375 20H4.625C4.15833 20 3.771 19.846 3.463 19.538C3.15433 19.2293 3 18.8417 3 18.375V8.625C3 8.15833 3.15433 7.771 3.463 7.463C3.771 7.15433 4.15833 7 4.625 7H9ZM10 7H14V5.625C14 5.45833 13.9373 5.31267 13.812 5.188C13.6873 5.06267 13.5417 5 13.375 5H10.625C10.4583 5 10.3127 5.06267 10.188 5.188C10.0627 5.31267 10 5.45833 10 5.625V7ZM20 14.5H14V15.2C14 15.4333 13.925 15.625 13.775 15.775C13.625 15.925 13.4333 16 13.2 16H10.8C10.5667 16 10.375 15.925 10.225 15.775C10.075 15.625 10 15.4333 10 15.2V14.5H4V18.375C4 18.5417 4.06267 18.6873 4.188 18.812C4.31267 18.9373 4.45833 19 4.625 19H19.375C19.5417 19 19.6873 18.9373 19.812 18.812C19.9373 18.6873 20 18.5417 20 18.375V14.5ZM11 15H13V13H11V15ZM4 13.5H10V12.8C10 12.5667 10.075 12.375 10.225 12.225C10.375 12.075 10.5667 12 10.8 12H13.2C13.4333 12 13.625 12.075 13.775 12.225C13.925 12.375 14 12.5667 14 12.8V13.5H20V8.625C20 8.45833 19.9373 8.31267 19.812 8.188C19.6873 8.06267 19.5417 8 19.375 8H4.625C4.45833 8 4.31267 8.06267 4.188 8.188C4.06267 8.31267 4 8.45833 4 8.625V13.5Z" fill="#1C1B1F"/>
      </g>
    </svg>
  );
}
