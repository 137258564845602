import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const IssueBlock: FunctionComponent = () => (
  <Box width={{ xs: '100%', sm: '45%', lg: '100%' }}>
    <Typography component="div" variant="h4">
      Have an issue?
    </Typography>
    <Divider />
    <Button
      color="tertiary"
      fullWidth
      variant="contained"
      href="mailto:support@myworkerbee.com"
      sx={{
        whiteSpace: 'normal',
        px: 1,
        textAlign: 'center',
      }}
    >
      Contact{' '}
      <Typography sx={{ display: { xs: 'none', sm: 'block', fontWeight: 500 } }}>
        &nbsp;Workerbee&nbsp;
      </Typography>{' '}
      support
    </Button>
  </Box>
);

export default IssueBlock;
