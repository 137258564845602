import React, {FC} from "react";

import {SeniorityLevelMarks} from "models/consts";

import { Slider, SliderMark, SliderMarkLabel, SliderRail, SliderThumb, SliderTrack } from './styled'

const ExperiencesTrack: FC<{ progress: number }> = ({ progress }) => (
  <Slider
    aria-labelledby="track-slider"
    defaultValue={progress}
    value={progress}
    marks={SeniorityLevelMarks}
    slots={{
      mark: SliderMark,
      markLabel: SliderMarkLabel,
      track: SliderTrack,
      thumb: SliderThumb,
      rail: SliderRail
    }}
    slotProps={{
      root: {
        className: progress >= 75 && 'expert' || progress >= 50 && 'advanced' || progress >= 25 && 'experienced' || ''
      }
    }}
    disabled
  />
)

export default ExperiencesTrack
