import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import debounce from 'lodash/debounce';

import Grid from '@mui/material/Unstable_Grid2';

import { outputQuery } from 'helpers/decorators';
import { byFilterRole, byFilterTeamStatus, LIMITS } from 'models/consts';

import { useAppDispatch } from 'store/hooks';
import usersTeam from 'store/query/enterprise/usersTeam';

import CustomAutocomplete from '../../CustomFields/CustomAutocomplete';
import { BootstrapInput } from '../../CustomFields';

interface IFilterTeam {
  setPage: (id: number) => void;
}

const FilterTeam: FunctionComponent<IFilterTeam> = ({ setPage }) => {
  const dispatch = useAppDispatch();

  const { control, getValues } = useFormContext();
  const { filterByEmail, filterByRole, filterByStatus, property, type } = getValues();

  const onSearch = (searchTerm: any) => {
    dispatch(usersTeam({ filter: searchTerm, limit: LIMITS.team, offset: 0 }))
      .unwrap()
      .then(() => setPage(0));
  };

  const debouncedSearch = debounce(onSearch, 500);

  const handleChange = (value: any, field: string) => {
    const filter = {
      filterByEmail,
      filterByRole,
      filterByStatus,
      property,
      type,
      [field]: value,
    };

    debouncedSearch(outputQuery(filter));
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={3}>
        <Controller
          control={control}
          name="filterByEmail"
          render={({ field: { ref, ...field } }) => (
            <BootstrapInput
              {...field}
              inputRef={ref}
              onChange={(event: any) => {
                handleChange(event.target.value, 'filterByEmail');
                field.onChange(event);
              }}
              placeholder="Filter by Email"
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={3}>
        <Controller
          control={control}
          name="filterByStatus"
          render={({ field }) => (
            <CustomAutocomplete
              autocompleteProps={{
                multiple: undefined,
              }}
              field={field}
              options={byFilterTeamStatus}
              placeholder="Filter by Status"
              handleChange={value => handleChange(value.id, 'filterByStatus')}
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={3}>
        <Controller
          control={control}
          name="filterByRole"
          render={({ field }) => (
            <CustomAutocomplete
              autocompleteProps={{
                multiple: undefined,
              }}
              field={field}
              options={byFilterRole}
              placeholder="Filter by Role"
              handleChange={value => handleChange(value.id, 'filterByRole')}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FilterTeam;
