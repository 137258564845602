import {createAsyncThunk} from "@reduxjs/toolkit";

import instance from "../../middlewares/api";
import { EConsultant } from "../../constants";
import { IProductExperienceFull } from "../../types/consultant";

const getConsultantProductsFull = createAsyncThunk<IProductExperienceFull[], string | number>(
  EConsultant.getExperiences,
  async (userId) => {
    // TODO: change limit when decision about pagination will be made
    const { data } = await instance.get(`/consultant-products/${userId}/full?limit=100`);
    return data
  }
)

export default getConsultantProductsFull

