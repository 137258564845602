import { styled } from '@mui/material/styles';

const StyledBoxArrow = styled('span')(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    right: '2px',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
  }),
);

const StyledCloseIcon = styled('span')(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    right: '2px',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'auto',
  }),
);

export { StyledBoxArrow, StyledCloseIcon };
