import React from 'react';

import Typography from "@mui/material/Typography";

import { StyledBox } from "./styled";

const CustomizedTooltip: React.FunctionComponent<any> = ({ payload }: any) => (
  <>
    {payload && payload[0] && !payload[0]?.payload.isLine &&
      <StyledBox>
        <Typography>{`Period: ${payload[0]?.payload.firstWeekDay} - ${payload[0]?.payload.lastWeekDay}`}</Typography>
        <Typography>
          {`Capacity: ${
            payload[0]?.payload.newValue
              ? payload[0]?.payload.value + payload[0]?.payload.newValue
              : payload[0]?.payload.value
          }h`}
        </Typography>
      </StyledBox>
    }
  </>
);

export default CustomizedTooltip;
