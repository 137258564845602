import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableSortLabel from '@mui/material/TableSortLabel';
import MuiLink from '@mui/material/Link';

import TableContainer from 'components/TableCustomized/TableContainer';
import CustomTableCell from 'components/TableCustomized/TableCell';
import { BootstrapInput } from 'components/CustomFields';
import TableRow from 'components/TableCustomized/TableRow';
import CloseIcon from 'styles/icons/CloseIcon';

import { headCells } from './consts';
import Avatar from '../../components/Avatar';
import { StyledHeaderCell } from './styled';

interface IAgenciesTable {
  billingType?: string;
  agencies?: any;
  setValue: (name: any, value: any) => void;
}

const AgenciesTable: React.FunctionComponent<IAgenciesTable> = ({
  billingType,
  agencies,
  setValue,
}) => {
  const [sortedAgencies, setSortedAgencies] = useState<any>(undefined);

  useEffect(() => {
    if (agencies) {
      const newArray: any[] = [];
      for (const key in agencies) {
        newArray.push({
          data: agencies[key],
          index: parseInt(key),
        });
      }
      setSortedAgencies(newArray.sort((first: any, second: any) => first.index - second.index));
    }
  }, [agencies]);

  const handleUp = (index: number, data: any) => {
    if (index > 1) {
      const cashData: any = agencies[index - 1];
      setValue('agencies', {
        ...agencies,
        [`${index - 1}`]: data,
        [`${index}`]: cashData,
      });
    }
  };

  const handleDown = (index: number, data: any) => {
    if (agencies[index + 1]) {
      const cashData: any = agencies[index + 1];
      setValue('agencies', {
        ...agencies,
        [`${index}`]: cashData,
        [`${index + 1}`]: data,
      });
    }
  };

  const handleDelete = (index: number) => {
    const newAgencies = { ...agencies };
    for (const key in newAgencies) {
      if (parseInt(key) > index) {
        newAgencies[parseInt(key) - 1] = newAgencies[key];
      }
      if (parseInt(key) === Object.keys(agencies).length) {
        delete newAgencies[parseInt(key)];
      }
    }
    setValue('agencies', newAgencies);
  };

  const handlePriceChange = (event: any, index: number) => {
    const value = event.target.value;
    setValue(`agencies.${index}.priceCents`, value);
  };

  return (
    <TableContainer sx={{ mb: 3 }}>
      <TableHead>
        <TableRow>
          {headCells
            .filter(headCell => !headCell.only || headCell.only === billingType)
            .map(headCell => (
              <StyledHeaderCell
                key={headCell.id}
                align={headCell.align || 'left'}
                width={headCell.width}
              >
                {headCell.label}
              </StyledHeaderCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedAgencies?.map((item: any) => {
          return (
            <TableRow key={item.data.id}>
              <CustomTableCell>
                <Typography>{item.index}</Typography>
              </CustomTableCell>
              <CustomTableCell>
                <Box display="flex" alignItems="center">
                  <Avatar
                    firstName={item.data.agency.name || ''}
                    lastName=""
                    image={item.data.agency.logo}
                    round
                  />
                  <Typography ml={1}>{item.data.agency.name}</Typography>
                </Box>
              </CustomTableCell>
              {billingType === 'lead_source' && (
                <CustomTableCell>
                  <BootstrapInput
                    label="Price"
                    value={item.data.priceCents}
                    onChange={(event: any) => handlePriceChange(event, item.index)}
                    sx={{
                      paddingRight: 0,
                      textAlign: 'center',
                    }}
                  />
                </CustomTableCell>
              )}
              <CustomTableCell>
                <Box display="flex">
                  <TableSortLabel
                    active={item.index !== 1}
                    direction="asc"
                    onClick={() => handleUp(item.index, item.data)}
                  />
                  <TableSortLabel
                    active={item.index !== sortedAgencies.length}
                    direction="desc"
                    onClick={() => handleDown(item.index, item.data)}
                  />
                </Box>
              </CustomTableCell>
              <CustomTableCell>
                <MuiLink
                  color="secondary"
                  onClick={() => handleDelete(item.index)}
                  sx={{ display: 'inline-flex', alignItems: 'center', stroke: 'none !important' }}
                  underline="none"
                >
                  <CloseIcon />
                </MuiLink>
              </CustomTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </TableContainer>
  );
};

export default AgenciesTable;
