import React, { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import dayjs from 'utils/dayjs'

import { IConsultantExperience } from "store/types/experiences";

interface IExperiencesItem {
  item: IConsultantExperience
}

const StyledWrap = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const ExperiencesGroupedItem: FC<IExperiencesItem> = ({ item}) => (
  <StyledWrap>
    <Typography variant="body2">{item?.description}</Typography>
    <StyledWrap my={1}>
      <Typography sx={{textAlign: 'end', width: 120}} variant="body2">
        {dayjs(item?.startDate).format('MMM YYYY')} - {item?.endDate ? dayjs(item?.endDate).format('MMM YYYY') : 'Present'}
      </Typography>
    </StyledWrap>
  </StyledWrap>
)

export default ExperiencesGroupedItem
