import {createAsyncThunk} from "@reduxjs/toolkit";

import instance from "../../middlewares/api";
import { EUser } from "../../constants";

interface IResponse {
  mailToken: string;
  success: boolean;
}

interface VerifyCodePayload {
  code: string;
  email: string;
}

const verifyCode = createAsyncThunk<IResponse, VerifyCodePayload>(
  EUser.verifyCode,
  async ({code, email}) => {
    const { data } = await instance.post('auth/confirm', {}, { headers: {
        'Confirm-Code': code,
        'Email': email,
    }});

    return data;
  }
)

export default verifyCode;
