import { createSlice } from '@reduxjs/toolkit';
import { IChatAssistantMessage } from '../types/chat-assistant';
import getAssistantResponse from '../query/chat-assistant/getAssistantResponse';
import { ISearchDataDto } from '../types/inner-models';

export type OptionalSearchDataDto = Partial<ISearchDataDto>;
export interface ChatAssistantState {
  chatHistory: IChatAssistantMessage[];
  filter: OptionalSearchDataDto;
  loading: boolean;
}

const initialState: ChatAssistantState = {
  chatHistory: [],
  filter: {},
  loading: false,
};

const { actions, reducer } = createSlice({
  name: 'chatAssistant',
  initialState,
  reducers: {
    addMessage: (state, { payload }) => {
      if (payload.message) {
        state.chatHistory.push({ role: 'user', content: payload.message });
      }
    },
  },
  extraReducers: builder =>
    builder
      .addCase(getAssistantResponse.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAssistantResponse.fulfilled, (state, action) => {
        const {
          payload: { message, filter },
        } = action;
        state.loading = false;
        state.chatHistory = [
          ...state.chatHistory,
          {
            role: 'assistant',
            content: message,
          },
        ];
        state.filter = filter;
      }),
});

export const { addMessage } = actions;

export default reducer;
