import React, { FunctionComponent, useEffect } from 'react';
import { useParams, useLocation, useLoaderData } from 'react-router-dom';
import { StateMachineProvider } from 'little-state-machine';

import NoSsr from '@mui/material/NoSsr';

import { IBasicUser } from 'store/types/user';
import { IFullConsultant } from 'store/types/consultant';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import getConsultantProductsFull from 'store/query/consultant/getConsultantProductsFull';
import fetchTimePreferences from 'store/query/common/fetchTimePreferences';
import { setConsultant } from 'store/reducers/consultant';

import { getMeSelector } from 'store/selectors/getUserSelector';
import { getFullConsultantSelector } from 'store/selectors/getConsultantSelector';
import { productsSummarySelector } from 'store/selectors/getConsultantSelector';

import HelmetAsync from './HelmetAsync';
import ConsultantView from './ConsultantView';

const Consultant: FunctionComponent = () => {
  const data = useLoaderData() as any;
  const location = useLocation();
  const { id } = useParams();

  const me = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(
    getFullConsultantSelector(id || data?.id || me?.id),
  ) as IFullConsultant;
  const expertiseSummary = useAppSelector(productsSummarySelector(id || data?.id || me?.id));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(setConsultant({ ...data, key: id || data?.id }));
    }
  }, [data, dispatch, id]);

  useEffect(() => {
    dispatch(fetchTimePreferences());
  }, [dispatch]);

  useEffect(() => {
    if (!expertiseSummary.length && (id || data?.id || me?.id)) {
      dispatch(getConsultantProductsFull(id || data?.id || me?.id));
    }
  }, [data?.id, dispatch, expertiseSummary.length, id, me?.id]);

  return (
    <>
      <StateMachineProvider>
        <HelmetAsync {...(data?.consultant.isPublic && data)} />
        <NoSsr>
          {consultant && (
            <ConsultantView
              consultantKey={id || data?.id || me?.id}
              expertiseSummary={expertiseSummary}
              {...consultant}
              isCurrentUser={me?.id === consultant?.user?.id}
              isCongratsEnabled={location?.state?.enableCongratsModal}
            />
          )}
        </NoSsr>
      </StateMachineProvider>
    </>
  );
};

export default Consultant;
