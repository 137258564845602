import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

import compact from "lodash/fp/compact";
import getOr from "lodash/fp/getOr";
import flowRight from "lodash/fp/flowRight";
import map from "lodash/fp/map";

const getChats = (chatById: Record<string, any>) =>
  flowRight(
    compact,
    map((id: number) => chatById[id]),
  );

const getMessages = (messageById: Record<string, any>) =>
  flowRight(
    compact,
    map((id: number) => id && messageById[id]),
  );

const chatState = (state: DefaultRootState) => state.chat;
const chatsByIdState = (state: DefaultRootState) => state.chat.chatsById;
const messagesByIdState = (state: DefaultRootState) => state.chat.messagesById;

export const selectChatIds = createSelector(
  chatState,
  getOr([])(['chatList', 'ids'])
);

export const selectMessageIds = (key?: string) => createSelector(
  chatState,
  getOr([])(['messageList', key || ''])
);

export const chatListSelector = createSelector(
  selectChatIds,
  chatsByIdState,
  (ids, chatsById) => getChats(chatsById)(ids)
);

export const isChatListLoading = createSelector(
  chatState,
  getOr(false)(['chatList', 'loading'])
);

export const hasMoreChats = createSelector(
  chatState,
  getOr(false)(['chatList', 'hasMore'])
);

export const currentChatSelector = (key?: string) => createSelector(
  chatsByIdState,
  getOr(undefined)(key || '')
);

export const currentMessagesSelector = (key?: string) => createSelector(
  selectMessageIds(key),
  messagesByIdState,
  (ids, messageById) => getMessages(messageById)(ids)
);

export const serviceSIdSelector = createSelector(
  chatState,
  getOr(undefined)('serviceSId')
);

export const chatTokenSelector = createSelector(
  chatState,
  getOr(undefined)('tokens')
);

