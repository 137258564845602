import React, { FunctionComponent, SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { Capacitor } from '@capacitor/core';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import updateCustomer from 'store/query/user/updateCustomer';
import addCustomerCompany from 'store/query/user/addCustomerCompany';
import updateCustomerCompany from 'store/query/user/updateCustomerCompany';
import createConsultantCompany from 'store/query/consultant/createConsultantCompany';

import { rolesSelector } from 'store/selectors/getCommonSelector';
import { getMeSelector } from 'store/selectors/getUserSelector';

import { customFilter } from 'helpers/customFilter';
import useSearchCompanyByName from 'hooks/useSearchCompanyByName';
import ROUTES from 'router/constants';

import { IBasicUser } from 'store/types/user';
import { ICompany } from 'store/types/company';

import fetchRoles from 'store/query/common/fetchRoles';

import { StyledActionGroup, StyledGrid, StyledRoot, StyledH1 } from '../styled';

import { BootstrapInput, PhoneNumber, Select } from 'components/CustomFields';
import LocationInput from 'components/CustomFields/LocationInput';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import { CloseButton } from 'components/CustomFields/Select/styled';

import { NAME_REGEX } from 'models/consts';
import { ICustomerForm } from 'store/types/customer';
import { fetchMe } from '../../../store/query/user';
import logout from '../../../store/query/user/logout';

import CloseIcon from 'styles/icons/CloseIcon';

interface ICustomerProfileRegistration {
  isModal?: boolean;
  onChangeCustomerStep?: () => void;
}

const CustomerProfileRegistration: FunctionComponent<ICustomerProfileRegistration> = ({
  isModal,
  onChangeCustomerStep,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    companies,
    setSearchTerm,
    isLoading: isCompaniesLoading,
    setLoading,
  } = useSearchCompanyByName({ availableForCustomer: true });

  const companyRolesList = useAppSelector(rolesSelector);

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const theme = useTheme();
  const roles = useAppSelector(rolesSelector);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    control,
    handleSubmit,
    formState: { isValid, errors, isSubmitting },
    setValue,
    trigger,
  } = useForm<ICustomerForm>({
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  useEffect(() => {
    if (!user) {
      navigate(`${ROUTES.SIGN_UP_CUSTOMER}`);
    }
  }, [user, navigate]);

  const onLogout = useCallback(() => {
    dispatch(logout())
      .unwrap()
      .then(() => navigate(ROUTES.AUTH));
  }, [dispatch, navigate]);

  const cancelButton = useMemo(
    () => (
      <CloseButton variant="text" onClick={onLogout} color="secondary">
        <CloseIcon />
      </CloseButton>
    ),
    [onLogout],
  );

  const onSubmit: SubmitHandler<any> = async ({ firstName, lastName, ...rest }) => {
    // 1 checking is company already exist
    // 2 update customer profile
    // 3 create company
    await dispatch(updateCustomer({ firstName, lastName }))
      .unwrap()
      .then(async () => {
        const { company, countryIso, description, location, mobilePhone, roleId } = rest;
        if (company.ownerId === user.id) {
          await dispatch(
            updateCustomerCompany({
              userId: user.id,
              company: {
                name: company.name,
                description,
                location: location.label,
                roleId: roleId,
                mobilePhone: [
                  '+',
                  getCountryCallingCode(mobilePhone.country),
                  mobilePhone.nationalNumber,
                ].join(''),
                countryIso: countryIso,
                id: company.id,
              },
            }),
          );
        } else {
          await dispatch(
            addCustomerCompany({
              userId: user.id,
              company: {
                name: company.name,
                description,
                location: location.label,
                roleId: roleId,
                mobilePhone: [
                  '+',
                  getCountryCallingCode(mobilePhone.country),
                  mobilePhone.nationalNumber,
                ].join(''),
                ownerId: user.id,
                countryIso: countryIso,
              },
            }),
          );
        }
      })
      .then(async () => {
        //refresh the user profile for downstream
        await dispatch(fetchMe());
      })
      .then(() => {
        if (isModal) {
          onChangeCustomerStep && onChangeCustomerStep();
        } else {
          navigate(ROUTES.ORDER_CREATION + '/1');
        }
      });
  };

  const handleCreateCompany = (company: ICompany) => {
    if (company?.create) {
      dispatch(
        createConsultantCompany({
          company: {
            name: company.name,
            roleId: roles?.find(({ name }) => name === 'Manager')?.id || 4,
            ownerId: user.id,
          },
        }),
      )
        .unwrap()
        .then(data => {
          setValue(`company`, {
            ...data[0],
            create: `Add new company: "${company.name}"`,
          });
          setValue(`companyId`, data[0].id);
        });
    }
    setValue(`companyId`, company?.id);
  };

  const handleCompanySearch = (event: SyntheticEvent, value: string) => {
    setLoading(true);
    setSearchTerm(value);
  };

  return (
    <StyledRoot component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ height: '100%' }}>
        <StyledGrid xs={12} lg={isModal ? 12 : 6}>
          <Box flexGrow={1}>
            {!isModal && (
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <StyledH1 id="customer-register-header" variant="h1">
                  Tell us about your business
                </StyledH1>
                {Capacitor.isNativePlatform() && cancelButton}
              </Box>
            )}
            <FormGroup>
              <InputLabel>First name</InputLabel>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    error={!!errors.firstName}
                    placeholder="Enter your first name"
                    helperText={errors.firstName?.message || 'Incorrect data'}
                    dataTest="firstName"
                  />
                )}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value: NAME_REGEX,
                    message: 'Too many spaces',
                  },
                  maxLength: {
                    message: 'The max count of characters is 100',
                    value: 100,
                  },
                }}
              />
            </FormGroup>
            <FormGroup>
              <InputLabel>Last name</InputLabel>
              <Controller
                control={control}
                name="lastName"
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    error={!!errors.lastName}
                    placeholder="Enter your last name"
                    helperText={errors.lastName?.message || 'Incorrect data'}
                    dataTest="lastName"
                  />
                )}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value: NAME_REGEX,
                    message: 'Too many spaces',
                  },
                  maxLength: {
                    message: 'The max count of characters is 100',
                    value: 100,
                  },
                }}
              />
            </FormGroup>
            <FormGroup>
              <InputLabel>Company</InputLabel>
              <Controller
                name="company"
                control={control}
                rules={{
                  required: 'This field is required',
                  validate: {
                    isExist: (value: ICompany) => {
                      if (!value.create && value.ownerId !== user.id) {
                        return 'Somebody already claimed this company as a customer, please reach out to your account manager to get an invite.';
                      }
                    },
                    maxLength: (value: ICompany) => {
                      if (value.name.length > 100) {
                        return 'The max count of characters is 100';
                      }
                    },
                  },
                }}
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      multiple: undefined,
                      onInputChange: handleCompanySearch,
                    }}
                    inputProps={{
                      error: errors.company as any,
                      helperText: (errors.company as any)?.message || 'Incorrect data',
                    }}
                    field={field}
                    options={companies || []}
                    placeholder="Type a company name"
                    customFilter={customFilter}
                    handleChange={handleCreateCompany}
                    isLoading={isCompaniesLoading}
                    disabledMobile
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <InputLabel>Job title</InputLabel>
              <Controller
                control={control}
                name="description"
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    placeholder="Title"
                    dataTest="description"
                  />
                )}
              />
            </FormGroup>
            <FormGroup data-test="location-control">
              <InputLabel>Location</InputLabel>
              <Controller
                control={control}
                name="location"
                render={({ field: { ref, ...field } }) => (
                  <LocationInput
                    {...field}
                    inputRef={ref}
                    onChange={(val: any) => {
                      geocodeByPlaceId(val.value.place_id)
                        .then(results => {
                          const countryCode = results[0].address_components.find(region =>
                            region.types.find(type => type === 'country'),
                          );
                          setValue('countryIso', countryCode?.short_name);
                          setValue('mobilePhone.country', countryCode?.short_name || 'US');
                        })
                        .catch(error => console.error(error));
                      field.onChange(val);
                    }}
                    error={!!errors.location}
                    errorText={'This field is required'}
                  />
                )}
                rules={{ required: 'This field is required' }}
              />
            </FormGroup>
            <FormGroup>
              <InputLabel>Current role</InputLabel>
              <FormControl variant="filled">
                <Controller
                  control={control}
                  name="roleId"
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      inputRef={ref}
                      options={companyRolesList || []}
                      label="Select role"
                      data-test="roleId"
                    />
                  )}
                />
              </FormControl>
            </FormGroup>
            <FormGroup sx={{ mb: 5 }}>
              <InputLabel>Phone number</InputLabel>
              <PhoneNumber
                control={control}
                defaultValue={{
                  country: 'US',
                }}
                name="mobilePhone"
                rules={{
                  required: 'This field is required',
                }}
                error={!!errors.mobilePhone?.nationalNumber || !!errors.mobilePhone?.country}
                errorText={
                  (errors.mobilePhone?.nationalNumber as any)?.message ||
                  (errors.mobilePhone?.country as any)?.message
                }
              />
            </FormGroup>
          </Box>
          <StyledActionGroup>
            <LoadingButton
              data-test="next-button"
              color="secondary"
              sx={{ mb: 2 }}
              variant="contained"
              disabled={!isValid || isSubmitting}
              type="submit"
              loading={isSubmitting}
            >
              Continue
            </LoadingButton>
          </StyledActionGroup>
        </StyledGrid>
      </Grid>
    </StyledRoot>
  );
};

export default CustomerProfileRegistration;
