import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EConsultant } from "../../constants";
import { LIMITS } from "../../../models/consts";

interface IResponse {
  limit: number;
  offset?: number;
  userId: number;
}
const fetchConsultantReview = createAsyncThunk<any, IResponse>(
  EConsultant.getOrderReview,
  async ({ limit, offset, userId }) => {
    const { data } = await instance.get(`consultant/${userId}/feedbacks`, {
      params: {
        limit: limit || LIMITS.consultant,
        offset
      }
    })
    return { consultantId: userId, data, offset }
  }
)

export default fetchConsultantReview
