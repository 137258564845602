import { createAsyncThunk}  from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EConsultantTags } from 'store/constants';
import {ITagItem} from "../../reducers/tags";

interface IAddTags {
  key: number |string;
  tags: ITagItem
}
const addConsultantTags = createAsyncThunk<ITagItem[], IAddTags>(
  EConsultantTags.addConsultantTags,
  async ({ tags }) => {
    const { data } = await instance.post('consultant/tags', tags)
    return data
  }
);

export default addConsultantTags;
