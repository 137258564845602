import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ROUTES from 'router/constants';
import { ERole } from 'models/consts';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IBasicOrders } from 'store/types/order';
import { IBasicPreOrder } from 'store/types/preOrder';

import fetchExtendHours from 'store/query/order/fetchExtendHours';
import InfoIcon from 'styles/icons/InfoIcon';

import { currentOrderSharedLinkSelector } from 'store/selectors/getOrderSelector';
import {
  getMeRoleSelector,
  isAgencyAdminSelector,
  isAgencyResourceManagerSelector,
  isConsultantSelector,
} from 'store/selectors/getUserSelector';

import ExtendHours from 'components/Order/ExtendHours/ExtendHours';
import ModalWrapper from 'components/Modal/Modal';
import MarkAsCompletedModal from 'components/Modal/MarkAsCompletedModal';
import PreorderActions from './PreorderActions';

interface IManageOrder {
  isPreOrder?: boolean;
  order: IBasicOrders | IBasicPreOrder;
}

const ManageOrder: FunctionComponent<IManageOrder> = ({ isPreOrder, order }) => {
  const dispatch = useAppDispatch();

  const isAdminAgency = useAppSelector(isAgencyAdminSelector);
  const isConsultant = useAppSelector(isConsultantSelector);
  const isResourceManager = useAppSelector(isAgencyResourceManagerSelector);

  const role = useAppSelector(getMeRoleSelector);

  const sharedLink = useAppSelector(currentOrderSharedLinkSelector(order.id));
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [disabledExtend, setDisabledExtend] = useState<boolean>(true);
  const [showExtendModal, setExtendModalDisplay] = useState<boolean>(false);
  const [showCompleteModal, setCompleteModalDisplay] = useState<boolean>(false);
  const [isSuccessExtend, setSuccessExtend] = useState<boolean>(true);

  useEffect(() => {
    if (!isPreOrder && !isConsultant && !isAdminAgency && !isResourceManager && isSuccessExtend) {
      dispatch(
        fetchExtendHours({
          orderId: order.id,
          isPurchase: !!order.purchaseOrderId,
        }),
      )
        .unwrap()
        .then(res => {
          if (
            !res.length ||
            res.every(
              ({ isNeedPaid, isPaid, isReject }) =>
                (isNeedPaid && (isPaid || isReject)) || !isNeedPaid,
            )
          ) {
            setDisabledExtend(false);
          }
          setSuccessExtend(false);
        });
    }
  }, [
    dispatch,
    isAdminAgency,
    isConsultant,
    isPreOrder,
    isResourceManager,
    order.id,
    order.purchaseOrderId,
    isSuccessExtend,
    sharedLink,
  ]);

  const closeExtendHours = useCallback((isSuccess?: boolean) => {
    setSuccessExtend(!!isSuccess);
    setExtendModalDisplay(false);
  }, []);

  return (
    <>
      {isPreOrder && <PreorderActions preOrder={order as IBasicPreOrder} />}
      {!isPreOrder && (!mobile || order.status === 'active') && (
        <Box mb={4} width={{ xs: '100%', sm: '45%', lg: '100%' }}>
          <Typography component="div" variant="h4">
            Manage Order
          </Typography>
          <Divider />
          {!mobile && order?.chat?.id && (
            <Button
              component={Link}
              to={`${ROUTES.CHAT}/${order.chat?.id}`}
              variant="contained"
              fullWidth
              sx={{ mb: order.status === 'active' ? 2 : 0 }}
            >
              {([ERole.CUSTOMER_ADMIN, ERole.SELF_CUSTOMER, ERole.CUSTOMER].includes(
                role?.name,
              ) && <>Contact your Workerbee</>) || <>Contact Customer</>}
            </Button>
          )}
          {order.status === 'active' && (
            <>
              {!isConsultant && !isAdminAgency && !isResourceManager && !order.isQuick && (
                <Tooltip
                  title={
                    (!!sharedLink || disabledExtend) &&
                    'You cannot extend the order as some transactions have not been paid. Please pay for the existing transactions first.'
                  }
                >
                  <Box>
                    <Button
                      color="tertiary"
                      fullWidth
                      onClick={() => setExtendModalDisplay(true)}
                      variant="contained"
                      sx={{ mb: 2 }}
                      disabled={!!sharedLink || disabledExtend}
                      endIcon={((!!sharedLink || disabledExtend) && <InfoIcon />) || undefined}
                    >
                      Buy extra hours
                    </Button>
                  </Box>
                </Tooltip>
              )}
              <Button
                variant="contained"
                color="tertiary"
                fullWidth
                onClick={() => setCompleteModalDisplay(true)}
              >
                Mark as completed
              </Button>
            </>
          )}
        </Box>
      )}
      <MarkAsCompletedModal
        order={order as IBasicOrders}
        onClose={() => {
          setCompleteModalDisplay(false);
        }}
        open={showCompleteModal}
      />

      <ModalWrapper
        dialogProps={{
          open: showExtendModal,
          onClose: () => setExtendModalDisplay(false),
        }}
        title={{ title: 'Extend Order' }}
      >
        <ExtendHours close={closeExtendHours} />
      </ModalWrapper>
    </>
  );
};

export default ManageOrder;
