import { Components } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    bgOutlined: true;
    bgPrimary: true;
  }
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root:  ({ theme }: any) => ({
      borderRadius: 0,
      textTransform: 'none',
      boxShadow: 'none',
      height: theme.spacing(6),
      // height: 48,
      paddingRight: 40,
      paddingLeft: 40,
      whiteSpace: 'nowrap',
      fontWeight: 500,
      '&:hover': {
        boxShadow: 'none'
      },
      '&.Mui-disabled': {
        backgroundColor: '#F5F5F5',
      },
    }),
    outlined: {
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: 'white'
    },
    outlinedPrimary: {
      borderColor: '#FFCA28',
    },
    outlinedSecondary: {
      borderColor: '#171717',
      color: '#171717',
      '&:hover': {
        backgroundColor: '#171717',
        color: 'white'
      }
    },
    containedPrimary: {
      '@media(hover:hover)': {
        '&:hover': {
          backgroundColor: '#171717',
          color: 'white'
        }
      }
    },
    containedSecondary: {
      '&:hover': {
        backgroundColor: '#FFCA28',
        color: '#171717'
      },
    },
    sizeMedium: {
      height: 48,
      fontSize: '0.875rem',
      lineHeight: '2rem'
    },
    sizeLarge: {
      height: 56,
      fontSize: '1.125rem',
      fontWeight: 700
    },
    sizeSmall: {
      height: 40,
      fontSize: '0.875rem',
      fontWeight: 400
    },
  },
  variants: [
    {
      props: {
        color: 'tertiary',
      },
      style: {
        '&:hover': {
          backgroundColor: '#1F1F1F',
          color: '#FFF'
        }
      }
    },
    {
      props: {
        color: 'secondary',
        variant: 'bgOutlined'
      },
      style: {
        border: '1px solid #1F1F1F',
        backgroundColor: '#1F1F1F',
        color: '#FFF',
        '&:hover': {
          backgroundColor: '#FFF',
          border: '1px solid #1F1F1F',
          color: '#1F1F1F'
        }
      }
    },
    {
      props: {
        color: 'primary',
        variant: 'bgOutlined'
      },
      style: {
        border: '1px solid #1F1F1F',
        '&:hover': {
          backgroundColor: '#FFF',
        }
      }
    },
    {
      props: {
        color: 'tertiary',
        variant: 'bgPrimary'
      },
      style: {
        backgroundColor: '#F5F5F5',
        color: '#1F1F1F',
        '&:hover': {
          backgroundColor: '#FFCA28',
          color: '#1F1F1F',
        }
      }
    },
  ]
}

export default MuiButton
