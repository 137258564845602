import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import { StyledSkillsBox } from './styled';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import getConsultantTagsById from 'store/query/tags/getConsultantTagsById';
import { getConsultantTagsByIdSelector } from 'store/selectors/getTagsSelector';

interface IAgencyConsultantSkills {
  activeConsultant?: number,
}

const AgencyConsultantSkills: React.FunctionComponent<IAgencyConsultantSkills> = ({ activeConsultant }) => {
  const dispatch = useAppDispatch();
  const consultantTags = useAppSelector(getConsultantTagsByIdSelector(activeConsultant || 0));

  useEffect(() => {
    if (activeConsultant) {
      dispatch(getConsultantTagsById(activeConsultant));
    }
  }, [dispatch, activeConsultant]);

  return !!consultantTags?.length ? (
    <StyledSkillsBox>
      <Box my={3} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">Additional Skills</Typography>
      </Box>
      {consultantTags.map((item: any) => (
        <Chip sx={{mr: 1.5, mb: 2}} key={item.id} label={item.name} variant="outlined" size="small" />
      ))}
    </StyledSkillsBox>
  ) : null;
};

export default AgencyConsultantSkills;
