import { useMemo } from 'react';
import dayjs from 'utils/dayjs';

type ChartData = {
  selectedAvailability: number,
  startDate: string,
  totalTime: number,
  totalWeeks?: number,
  flexibleHours?: boolean,
};

const useChartData = ({
  selectedAvailability,
  startDate,
  totalTime,
  flexibleHours,
  totalWeeks,
}: ChartData) => {
  return useMemo(() => {
    if (totalWeeks) {
      const data = [];
      let curDate = startDate;
      for (let i = 1; i <= totalWeeks; i++) {
        let itemData = undefined;
        const weekAvailability = flexibleHours ? totalTime / totalWeeks : selectedAvailability;
        const firstWeekDayMonth = dayjs(curDate).format("MMM");
        const lastWeekDayMonth = dayjs(curDate).add(4, 'day').format("MMM");
        const firstWeekDay = dayjs(curDate).format("DD MMM");
        const lastWeekDay = dayjs(curDate).add(4, 'day').format("DD MMM");

        if (weekAvailability) {
          const totalHoursCalc = flexibleHours ? totalTime : totalWeeks * weekAvailability;
          if (i === totalWeeks && totalHoursCalc > totalTime) {
            const lastWeekHours = totalTime - (totalWeeks - 1) * weekAvailability;

            itemData = {
              name: `40`,
              value: 0,
              newValue: (lastWeekHours || 0),
              time: i,
              updated: true,
              month: lastWeekDayMonth,
              isParticular: firstWeekDayMonth !== lastWeekDayMonth,
              firstWeekDay,
              lastWeekDay,
            };
          } else {
            itemData = {
              name: `40`,
              value: 0,
              newValue: (weekAvailability || 0),
              time: i,
              updated: true,
              month: lastWeekDayMonth,
              isParticular: firstWeekDayMonth !== lastWeekDayMonth,
              firstWeekDay,
              lastWeekDay,
            };
          }
        }
        itemData && data.push(itemData);
        curDate = dayjs(curDate).add(1, 'week').format();
      }
      return data;
    }
    return undefined
  }, [ selectedAvailability, startDate, totalTime, flexibleHours, totalWeeks ]);
}

export default useChartData;
