import React, { FunctionComponent } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';

import { byAgencyRoles, EMAIL_REGEX, ERole, NAME_REGEX } from 'models/consts';

import { useAppDispatch } from 'store/hooks';
import inviteUser from 'store/query/agency/inviteUser';

import BootstrapInput from '../../CustomFields/BootstrapInput';
import CustomAutocomplete from '../../CustomFields/CustomAutocomplete';

interface IAddMember {
  onClose: () => void;
}

const AddMember: FunctionComponent<IAddMember> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    register,
    unregister,
    watch,
  } = useForm({ mode: 'onChange' });

  const currentRole = watch('role');

  const onRoleChange = (newRole: any) => {
    if (newRole !== ERole.RESOURCE_MANAGER) {
      unregister('firstName');
      unregister('lastName');
    } else {
      register('firstName', { required: 'This field is required' });
      register('lastName', { required: 'This field is required' });
    }
  };

  const onSubmit: SubmitHandler<any> = async ({ email, firstName, lastName, role }) => {
    await dispatch(
      inviteUser({
        email: email,
        roleId: role.id,
        firstName,
        lastName,
      }),
    )
      .unwrap()
      .then(() => onClose());
  };

  return (
    <>
      <DialogContent>
        <form id="add-team-member" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <InputLabel>Email</InputLabel>
            <Controller
              control={control}
              name="email"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  inputRef={ref}
                  error={!!errors.email}
                  placeholder="Enter email"
                  helperText={errors.email?.message || 'Incorrect data'}
                  type="email"
                />
              )}
              rules={{
                required: 'This field is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email',
                },
              }}
            />
          </FormGroup>
          <FormGroup>
            <InputLabel>Choose role</InputLabel>
            <Controller
              control={control}
              name="role"
              render={({ field }) => (
                <CustomAutocomplete
                  autocompleteProps={{
                    multiple: undefined,
                  }}
                  field={field}
                  handleChange={onRoleChange}
                  options={byAgencyRoles}
                  placeholder="Select a Role"
                />
              )}
              rules={{ required: 'This field is required' }}
            />
          </FormGroup>
          {currentRole?.eRole === ERole.RESOURCE_MANAGER && (
            <>
              <FormGroup>
                <InputLabel>First Name</InputLabel>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field: { ref, ...field } }) => (
                    <BootstrapInput
                      {...field}
                      inputRef={ref}
                      error={!!errors.firstName}
                      placeholder="Buzz"
                      helperText={errors.firstName?.message || 'Incorrect data'}
                    />
                  )}
                  rules={{
                    required: 'This field is required',
                    pattern: {
                      value: NAME_REGEX,
                      message: 'Too many spaces',
                    },
                    maxLength: {
                      message: 'The max count of characters is 100',
                      value: 100,
                    },
                  }}
                />
              </FormGroup>
              <FormGroup>
                <InputLabel>Last Name</InputLabel>
                <Controller
                  control={control}
                  name="lastName"
                  render={({ field: { ref, ...field } }) => (
                    <BootstrapInput
                      {...field}
                      inputRef={ref}
                      error={!!errors.lastName}
                      placeholder="Honeycomb"
                      helperText={errors.lastName?.message || 'Incorrect data'}
                    />
                  )}
                  rules={{
                    required: 'This field is required',
                    pattern: {
                      value: NAME_REGEX,
                      message: 'Too many spaces',
                    },
                    maxLength: {
                      message: 'The max count of characters is 100',
                      value: 100,
                    },
                  }}
                />
              </FormGroup>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={!isValid || isSubmitting}
          loading={isSubmitting}
          color="secondary"
          form="add-team-member"
          fullWidth
          type="submit"
          variant="contained"
          data-test="add-team-member"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AddMember;
