import React, { useMemo } from 'react';

import { ERole } from 'models/consts';
import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';

import AgencyTeamList from 'views/Agency/TeamList';
import EnterpriseTeamList from 'views/Enterprise/TeamList';
import { Navigate } from 'react-router-dom';
import ROUTES from './constants';

const TeamListRoute = () => {
  const user = useAppSelector(getMeSelector);

  return useMemo(() => {
    switch (user?.role.name) {
      case ERole.AGENCY_ADMIN:
      case ERole.RESOURCE_MANAGER:
        return <AgencyTeamList />;
      case ERole.CUSTOMER_ADMIN:
        return <EnterpriseTeamList />;
      default:
        return <Navigate to={ROUTES.ORDER_LIST} replace />;
    }
  }, [user?.role.name]);
};

export default TeamListRoute;
