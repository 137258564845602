import React from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { PageButton } from './styled';

import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const generatedArray = Array.from(
    { length: Math.ceil(count / rowsPerPage) },
    (_, index) => index,
  );
  const handleChangePageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    page: number,
  ) => {
    onPageChange(event, page);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={event => handleChangePageButtonClick(event, 0)}
        disabled={page === 0}
        aria-label="first page"
        size="small"
        sx={{ fontSize: '1rem' }}
      >
        <KeyboardDoubleArrowLeft fontSize="small" />
      </IconButton>
      <IconButton
        onClick={event => handleChangePageButtonClick(event, page - 1)}
        disabled={page === 0}
        size="small"
        aria-label="previous page"
      >
        <KeyboardArrowLeft fontSize="small" />
      </IconButton>
      {/*{Array(Math.ceil(count / rowsPerPage)).map((_, index) => (*/}
      {generatedArray.map(pageNavigation => (
        <PageButton
          key={pageNavigation}
          variant="outlined"
          color="secondary"
          disabled={page === pageNavigation}
          onClick={event => handleChangePageButtonClick(event, pageNavigation)}
        >
          {pageNavigation + 1}
        </PageButton>
      ))}
      <IconButton
        onClick={event => handleChangePageButtonClick(event, page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="small"
      >
        <KeyboardArrowRight fontSize="small" />
      </IconButton>
      <IconButton
        onClick={event =>
          handleChangePageButtonClick(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
        }
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="small"
      >
        <KeyboardDoubleArrowRight fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default TablePaginationActions;
