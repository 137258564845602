import { createAsyncThunk} from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

interface IRequestParams {
  orderId: number;
  id: number;
  index: number;
}

const approveWeeklyBillings = createAsyncThunk<any, IRequestParams>(
  EOrder.approveWeeklyBillings,
  async ({ orderId, id })=> {
    const { data } = await instance.post(`orders/${orderId}/weekly-billings/${id}/approve`);

    return data;
  }
);

export default approveWeeklyBillings;
