import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { ECommon } from '../../constants';
import { ICategoryWithProducts } from '../../types/categoryProducts';

const fetchCategoriesWithProducts = createAsyncThunk<ICategoryWithProducts[]>(
  ECommon.categoryProducts,
  async () => {
    const { data } = await instance.get(`categories/products`);
    return data;
  },
);

export default fetchCategoriesWithProducts;
