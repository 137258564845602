import React, {FunctionComponent} from 'react'

import cn from 'classnames'

import dayjs from 'utils/dayjs';
import { getCalendarFromCurrentWeek, groupByMonth, getColor } from "./utils";

import Typography from "@mui/material/Typography";

import {
  StyledCalendarContainer,
  StyledCalendarHead,
  StyledCalendarHeadBodyCell,
  StyledCalendarHeadCell,
  StyledCalendarRow,
  StyledDayHeadCell,
  StyledDayHeadBodyCell,
  StyledWeekHeadCell,
} from './styled'

import { IConsultantCapacity } from "../../store/types/consultant";
import { useAppSelector } from 'store/hooks';
import {capacitiesSelector} from 'store/selectors/getConsultantSelector';

import CalendarBody from "components/CalendarComponents/CalendarBody";
import AvailabilityCell from "./AvailabilityCell";

interface ICalendarProps {
  endDate: string;
  startDate: string;
  type: string;
}

const Calendar: FunctionComponent<ICalendarProps> = ({ endDate, startDate, type =  'quarter' }) => {
  const weeksOfMonth = getCalendarFromCurrentWeek(startDate, endDate, type);
  const groupped = groupByMonth(weeksOfMonth)

  const keySelector = [startDate, endDate].join('/')
  const capacities = useAppSelector(capacitiesSelector(keySelector));

  return (
    <>
      <StyledCalendarContainer>
        <StyledCalendarHead colSpan={weeksOfMonth.length}>
          <StyledCalendarRow>
            <StyledCalendarHeadCell border={{borderRightWidth: 0}} />
            {groupped.map((el: any) => (
              <StyledCalendarHeadBodyCell
                key={el.date}
                border={{borderRightWidth: 0}}
                colSpan={el.list.length}
                sx={{justifyContent: 'start'}}
                className={type}
              >
                <Typography ml={.5}>{dayjs(el.date).format('MMM\'YY')}</Typography>
              </StyledCalendarHeadBodyCell>
            ))}
          </StyledCalendarRow>
          <StyledCalendarRow>
            <StyledDayHeadCell />
            {groupped.map((el: any) => el.list.map((cell: any, index: number) => (
              <StyledDayHeadBodyCell
                key={cell.date}
                className={cn(type, {
                  'currentDay': dayjs().isSame(dayjs(cell.date), type === 'quarter' && 'week' || 'day'),
                  'lastPeriod': el.list.length === index + 1,
                })}
                colSpan={1}
              >
                <Typography>{dayjs(cell.date).format('D')}</Typography>
              </StyledDayHeadBodyCell>
            )))}
          </StyledCalendarRow>
          <StyledCalendarRow>
            <StyledWeekHeadCell justifyContent="start">Availability</StyledWeekHeadCell>
            {capacities.map((capacity: IConsultantCapacity) => (
              <AvailabilityCell
                {...capacity}
                keySelector={keySelector}
                key={capacity.startWeekDate}
                type={type}
              />
            ))}
          </StyledCalendarRow>
          <StyledCalendarRow>
            <StyledCalendarHeadCell justifyContent="start"><Typography variant="subtitle2">Total Time</Typography></StyledCalendarHeadCell>
            {capacities.map((capacity: any) => (
              <StyledCalendarHeadBodyCell
                key={capacity.startWeekDate}
                border={{borderRightStyle: 'solid'}}
                sx={getColor(capacity.capacity)}
                colSpan={type === 'quarter' && 1 || 5}
                className={cn(type, {
                  'lastPeriod': dayjs(capacity.startWeekDate)
                                  .endOf('month')
                                  .endOf('isoWeek')
                                  .isSame(dayjs(capacity.startWeekDate).add(1, 'day').endOf('isoWeek')),
                })}
              >
                {capacity.capacity > 0 && `${capacity.capacity}h free`
                || capacity.availability === 0 && '-' || 'Full'}
              </StyledCalendarHeadBodyCell>
            ))}
          </StyledCalendarRow>
        </StyledCalendarHead>
        <CalendarBody endDate={endDate} startDate={startDate} type={type} />
      </StyledCalendarContainer>
    </>
  )
}

export default Calendar
