import React, { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Chip from '@mui/material/Chip';

interface ISortableItem {
  id: number;
  index: number;
  getTagProps: any;
  name: string;
  dataTest?: string;
}
const SortableItem: FC<ISortableItem> = props => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Chip
      ref={setNodeRef}
      {...props.getTagProps({ index: props.index })}
      {...attributes}
      key={props.id}
      color="secondary"
      label={
        <span ref={setActivatorNodeRef} {...listeners}>
          {props.name}
        </span>
      }
      style={style}
      variant="filled"
      data-test={props.dataTest}
    />
  );
};

export default SortableItem;
