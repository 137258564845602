/**
 *  Refactor by 12.23
 */
import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

import getOr from 'lodash/fp/getOr'

export const consultantCertificatesSelector = (key: string | number) => createSelector((state: DefaultRootState) => state.consultant,
  getOr([])(['consultantById', key, 'certificatesList'])
);

