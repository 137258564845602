import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";

const StyledDrawerWrapperBox = styled(Box)(({ theme }) => theme.unstable_sx({
  py: 4,
  px: 2,
}));

const CloseButton = styled(Button)(({
  padding: 0,
  height: '24px',
  width: '24px',
  minWidth: '24px',
  marginRight: '14px',
}));

const DrawerHeaderContainer = styled(Grid)(({ theme }) => theme.unstable_sx({
  paddingBottom: '24px',
  borderBottom: '1px solid #EBEBEB',
  marginBottom: '22px',
}));

export {
  StyledDrawerWrapperBox,
  CloseButton,
  DrawerHeaderContainer,
};
