import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";

const IconStyled = styled(Box)({
  height: 20,
  width: 20,
  overflow: 'hidden',
  borderRadius: '50%',
  boxShadow: 'rgba(0,0,0,0.5) 0 0 0 1px, rgba(0,0,0,0.5) 0 0 0 1px inset',
  marginRight: 8
})

const ImgStyled = styled('img')({
  height: '100%',
  width: '100%',
  display: 'block',
  objectFit: 'cover'
})

const StyledCountryCode = styled(Box)({
  width: 40
})

export { IconStyled, ImgStyled, StyledCountryCode }
