import dayjs from 'utils/dayjs'

export const standartDateToMonth = (date: string) => {
  return dayjs(date).format('MMM YYYY');
}

export const calcWeekCount = (startDate: string, endDate?: string) => {
  const startMoment = dayjs(startDate);
  const endMoment = endDate ? dayjs(endDate) : dayjs();
  return dayjs.duration(endMoment.diff(startMoment)).asWeeks();
}

export const daysDiff = (startDate: string | Date, createdAt: string | Date) => {
  return dayjs(startDate).diff(createdAt, 'days');
}

export const getEndDate = (startDate: string, hoursCount: number, availability: number | undefined) => {
  if (availability && startDate) {
    const weeksCount = Math.ceil(hoursCount / (+availability));
    return dayjs(startDate).add(weeksCount - 1, 'weeks').add(4, 'days').format('YYYY-MM-DD');
  }
  return undefined;
}

export const getNextMonday = (date?: string | Date) => {
  const dateWeekMonday = dayjs(date).isoWeekday(1);

  if (dayjs(date).isAfter(dateWeekMonday)) {
    return dayjs(date).add(7, 'day').isoWeekday(1).format('YYYY-MM-DD');
  }
  return dateWeekMonday.format('YYYY-MM-DD');
}

export const isMonday = (date: Date) => {
  const day = dayjs(date).day();
  return day === 1;
};

export const isFriday = (date: Date) => {
  const day = dayjs(date).day();
  return day === 5;
};

export const experienceHumanized = (month: number) => {
  return dayjs.duration(month, 'months').humanize();
}

export const calcWeeks = (endDate?: string | Date, startSate?: string | Date) => {
  if (endDate) {
    // getting number of endDate
    const endDay = dayjs(endDate).day();

    if (startSate) {
      if (endDay !== 0) {
        const newEndDate = dayjs(endDate).add((8 - endDay), 'days').utc();
        return dayjs(newEndDate).diff(dayjs(startSate), 'weeks');
      }
      return dayjs(endDate).diff(dayjs(startSate), 'weeks');
    }

    // if number of endDate isn't Monday we need get next week
    // we need Tuesday to calculate whole week difference between weeks
    const endTuesday = endDay === 1
      ? dayjs(endDate).add(1, 'day')
      : dayjs(endDate).add(8 - endDay, 'day');

    // week started, so we are skipping this week
    // finding next Monday
    const today = dayjs().day();
    const nextMonday = dayjs().add(7 - today, 'day');
    // calculate difference in weeks
    return dayjs(endTuesday).diff(dayjs(nextMonday), 'weeks');
  }
  return 0;
};
