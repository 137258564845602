import React, {FunctionComponent, useState} from 'react'
import cn from 'classnames'

import dayjs from "utils/dayjs";

import {StyledAvailabilityCell, StyledWeekHeadBodyCell} from "./styled";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import EditIcon from "styles/icons/EditIcon";

import ChangeAvailability from "../Modal/ChangeAvailability";

interface IAvailabilityCellProps {
  availability: number;
  capacity: number;
  keySelector: string;
  startWeekDate: string;
  type: string;
}

const AvailabilityCell: FunctionComponent<IAvailabilityCellProps> = ({ availability, startWeekDate, keySelector, type }) => {
  const [isOpen, setIsOpen ] = useState<boolean>(false)
  const startDate = dayjs(startWeekDate)

  const isFuture = dayjs().isBefore(dayjs(startDate), 'week')

  return (
    <StyledWeekHeadBodyCell
      key={startWeekDate}
      className={cn(type, {
        ['disabled']: availability === 0,
        'lastPeriod': startDate.endOf('month').endOf('isoWeek').isSame(startDate.add(1, 'day').endOf('isoWeek')),
      })}
      colSpan={type === 'quarter' && 1 || 5}
    >
      <StyledAvailabilityCell>
        <Typography>{availability} h{type !== 'quarter' && '/week'}</Typography>
        {!!isFuture && (
          <>
            <IconButton size="small" sx={{fontSize: 'inherit'}} color="inherit" onClick={() => setIsOpen(true)}>
              <EditIcon fontSize="inherit" sx={{ fill: 'none' }}/>
            </IconButton>
            <ChangeAvailability
              onClose={() => setIsOpen(false)}
              open={isOpen}
              startWeekDate={startWeekDate}
              availability={availability}
              keySelector={keySelector}
            />
          </>
        )}
      </StyledAvailabilityCell>
    </StyledWeekHeadBodyCell>
  )
}

export default AvailabilityCell
