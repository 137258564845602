import { styled } from "@mui/material/styles";
import MuiTableCell from "@mui/material/TableCell";

const TableCell = styled(MuiTableCell)(({ theme }) => theme.unstable_sx({
  border: 0,
  py: 1,
  [theme.breakpoints.up("sm")]: {
    px: 1,
  },
  [theme.breakpoints.down("sm")]: {
    px: 0.5,
  },
}))

export default TableCell
