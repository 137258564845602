import { Components } from '@mui/material/styles';

const MuiTab: Components['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }: any) => theme.unstable_sx({
      color: 'action.active',
      textTransform: 'none',
      fontSize: '1.125rem'
    }),
  }
}

export default MuiTab
