import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { styled } from '@mui/material/styles';

interface IListItem extends ListItemProps {
  isShow?: boolean;
}

const StyledNotificationWrap = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const StyledNoNotification = styled(Box)({
  display: 'flex',
  flexFlow: 'row nowrap',
  placeContent: 'stretch center',
  alignItems: 'center',
  flex: 1,
  color: 'text.secondary',
});

const StyledListItem = styled(({ isShow, ...props }: IListItem) => <ListItem {...props} />)(
  ({ isShow, theme }) =>
    theme.unstable_sx({
      borderRadius: 1,
      p: theme.spacing(2),
      mb: theme.spacing(1),
      borderTop: `1px solid #EBEBEB`,
      ...(!isShow && {
        bgcolor: 'primary.main',
        '& .MuiListItemAvatar-root .MuiBox-root': {
          bgcolor: 'common.black',
        },
      }),
    }),
);

const StyledListItemText = styled(ListItemText)(({ theme }) =>
  theme.unstable_sx({
    m: 0,
  }),
);

const StyledLink = styled(Link)(({ theme }) =>
  theme.unstable_sx({
    color: 'inherit',
    textDecoration: 'none',
    '&:focus, &:hover': {
      color: 'inherit',
    },
  }),
);

const StyledAction = styled('span')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    mt: 2,
  }),
);

const StyledListItemStatus = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    height: 38,
    width: 38,
    borderRadius: '50%',
    mr: 2,
    bgcolor: 'rgba(0, 0, 0, 0.2)',
    color: 'background.paper',
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

export {
  StyledAction,
  StyledLink,
  StyledListItem,
  StyledListItemText,
  StyledListItemStatus,
  StyledNotificationWrap,
  StyledNoNotification,
};
