import { Components } from '@mui/material/styles';

const MuiSelect: Components['MuiSelect'] = {
  styleOverrides: {
    nativeInput: {
      minHeight: 46
    },
    select: {
      minHeight: 46,
      display: 'flex',
      alignItems: 'center'
    },
    filled: {
      background: '#f5f5f5 !important'
    },
  }
}

export default MuiSelect
