import { createAsyncThunk } from '@reduxjs/toolkit';
import { EUser } from 'store/constants';

import instance from 'store/middlewares/api';

interface IResponseAgency {
  count: number;
  page: number;
  users: any[]
}

const getAgencyTeamRoasterList = createAsyncThunk<IResponseAgency, any>(
  EUser.getAgencyTeamRoasterList,
  async ({ id, filter, ...params }) => {
    const { data } = await instance.get(`/admin/agency/${id}/users-team`, { params: { ...filter, ...params } });

    return data
  }
);

export default getAgencyTeamRoasterList;