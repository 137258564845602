import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { ChatSendMobileIcon } from 'styles/icons/ChatSendMobileIcon';

const SendMsgBtn: FunctionComponent<any> = () => {
  return (
    <Box sx={{ backgroundColor: 'black' }}>
      <IconButton type="submit" size={'medium'}>
        <ChatSendMobileIcon />
      </IconButton>
    </Box>
  );
};

export default SendMsgBtn;
