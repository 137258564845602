import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EPreOrder } from '../../constants';
import { IBasicPreOrder } from "../../types/preOrder";

const sendCustomerInvitation = createAsyncThunk<IBasicPreOrder, {id: number}>(
  EPreOrder.sendCustomerInvitation,
  async ({id}) => {
    const { data } = await instance.post(`/lead-managers/pre-orders/${id}/invite-customers`);

    const preOrderObj = JSON.parse(data.metaData);

    return preOrderObj.preOrder;
  }
)

export default sendCustomerInvitation;
