import { CandidateAgency } from 'store/types/preOrder';


export const candidateAgenciesNormalize = (candidateAgencies: CandidateAgency[]) => {
  if (!candidateAgencies.length) {
    return undefined;
  }
  const agencies: any = {};
  candidateAgencies.forEach((item) => {
    agencies[item.rank] = {
      ...item,
      priceCents: item.priceCents / 100,
    };
  });
  return agencies;
};
