import {styled} from "@mui/material/styles";
import CustomScroll from "react-custom-scroll";

const StyledCustomScroll: any = styled(CustomScroll)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  flexGrow: 1,
  width: '100%',
  flexDirection: 'column',
  overflow: 'hidden'
}));

export { StyledCustomScroll }
