import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { OGIcon } from 'styles/icons/OGIcon';
import { VerifiedIcon } from 'styles/icons/VerifiedIcon';
import { LinkedinIcon } from 'styles/icons/LinkedinIcon';
import { FavoriteIcon } from 'styles/icons/FavoriteIcon';
const StyledPaper = styled(Paper)(({ theme }) =>
  theme.unstable_sx({
    borderRadius: 2,
    boxShadow: '0 4px 8px rgb(0 0 0 / 40%)',
    border: '1px solid #EBEBEB',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    mx: {
      xs: -1,
      md: 0,
    },
  }),
);
const StyledCardActionArea = styled(CardActionArea)(({ theme }) =>
  theme.unstable_sx({
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    flexShrink: 2,
  }),
);
const StyledClamp = styled(Typography)({
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
});
const StyledMoreTags = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    display: 'inline-flex',
    mb: 0.25,
    ml: 0.5,
    verticalAlign: 'middle',
    alignItems: 'center',
  }),
);
const StyledMatchingValue = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: {
      xs: '1.875rem',
      md: '3rem',
    },
    lineHeight: 'normal',
    fontWeight: 300,
    height: {
      md: 50,
    },
    mb: {
      md: 1,
    },
    width: {
      xs: 106,
      md: 'auto',
    },
  }),
);
const StyledMatch = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: {
      xs: '.875rem',
      md: '1rem',
    },
    ml: {
      xs: 2,
      md: 0,
    },
  }),
);
const StyledCostPerWeek = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: {
      xs: '1.125rem',
      md: '1rem',
    },
    textAlign: 'center',
  }),
);
interface IExtendAvatarWrapProps extends BoxProps {
  backgroundColor?: any;
  textColor?: string;
}
const StyledAvatarWrap = styled(Box)<IExtendAvatarWrapProps>(({ backgroundColor, theme }) =>
  theme.unstable_sx({
    p: 2.5,
    pr: 2,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    backgroundColor: backgroundColor || '#fff',
    position: 'relative',
    maxWidth: {
      md: 150,
      lg: 184,
    },
    flex: {
      md: '1 0 150px',
      lg: '1 0 184px',
    },
  }),
);
const StyledSocialWrap = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    mb: 3,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    minWidth: 82,
  }),
);
const StyledCompanyLogo = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    overflow: 'hidden',
    mt: 2.5,
    height: 48,
    flexWrap: 'wrap',
  }),
);
const StyledTagsWrap = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    overflow: 'hidden',
    height: 33,
    flexWrap: 'wrap',
    mt: 1.5,
  }),
);
const StyledStarChip = styled(Chip)(({ theme }) =>
  theme.unstable_sx({
    fontSize: '.750rem',
    fontWeight: 700,
    display: 'Flex',
    backgroundColor: '#F3F3F3',
    paddingLeft: 1,
  }),
);
const StyledOGIcon = styled(OGIcon)(({ theme }) =>
  theme.unstable_sx({
    ml: 0.5,
    mr: 0.5,
    color: '#FFB923',
  }),
);
const StyledVerifiedIcon = styled(VerifiedIcon)(({ theme }) =>
  theme.unstable_sx({
    mr: 0.5,
    color: '#FFB923',
  }),
);
const StyledLinkedinIcon = styled(LinkedinIcon)(({ theme }) =>
  theme.unstable_sx({
    ml: 0.5,
    color: '#FFB923',
  }),
);
const StyledCompanyIconsTags = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    p: 2.5,
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexGrow: 1,
  }),
);
const StyledRavenRated = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#FDB829',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    width: '180px',
    height: '22px',
    top: '20px',
    left: '-45px',
    paddingLeft: '45px',
    transform: 'rotate(-28deg)',
    [theme.breakpoints.between('md', 'lg')]: {
      top: '10px',
      left: '-51px',
    },
  }),
);
const StyledRavenRatedText = styled(Typography)({
  color: '#fff',
  fontSize: '0.6rem',
  fontWeight: 500,
});
const StyledRecommended = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#61ADE2',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    width: '230px',
    height: '22px',
    top: '23px',
    left: '-30px',
    paddingLeft: '32px',
    transform: 'rotate(-28deg)',
    [theme.breakpoints.between('md', 'lg')]: {
      top: '13px',
      left: '-35px',
    },
  }),
);
const StyledFavoriteIcon = styled(FavoriteIcon)(({ theme }) =>
  theme.unstable_sx({
    color: '#fff',
  }),
);
const StyledBadgeImage = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  }),
);
const StyledMobileBadgeImage = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    top: 10,
    left: 0,
    zIndex: 1,
  }),
);
export {
  StyledAvatarWrap,
  StyledCardActionArea,
  StyledClamp,
  StyledCostPerWeek,
  StyledCompanyIconsTags,
  StyledCompanyLogo,
  StyledLinkedinIcon,
  StyledMatch,
  StyledMatchingValue,
  StyledMoreTags,
  StyledOGIcon,
  StyledPaper,
  StyledSocialWrap,
  StyledStarChip,
  StyledTagsWrap,
  StyledVerifiedIcon,
  StyledRavenRated,
  StyledRavenRatedText,
  StyledFavoriteIcon,
  StyledRecommended,
  StyledBadgeImage,
  StyledMobileBadgeImage,
};
