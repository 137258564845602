import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledTitleContainer = styled(Box)(({theme}) => theme.unstable_sx({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mt: 4,
  mb: 3
}));

export { StyledTitleContainer }
