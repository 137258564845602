import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EUser } from 'store/constants';
import { ICompanyDto } from "../../types/company";

interface IUpdateCustomerCompanyProps {
  company: ICompanyDto;
  userId: number;
}

const updateCustomerCompany = createAsyncThunk<any, IUpdateCustomerCompanyProps>(
  EUser.updateCustomerCompany,
  async ({ company }) => {
    const { data } = await instance.patch(`companies`, [company]);

    return data;
  }
)

export default updateCustomerCompany;
