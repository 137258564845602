import { Components } from '@mui/material/styles';

import MuiAppBar from './MuiAppBar';
import MuiAutocomplete from './MuiAutocomplete';
import MuiBadge from './MuiBadge';
import MuiBottomNavigation from './MuiBottomNavigation';
import MuiBottomNavigationAction from './MuiBottomNavigationAction';
import MuiButton from './MuiButton';
import MuiCheckbox from './MuiCheckbox';
import MuiChip from './MuiChip';
import MuiContainer from './MuiContainer';
import MuiCssBaseline from './MuiCssBaseline';
import MuiDialog from './MuiDialog';
import MuiDialogActions from './MuiDialogActions';
import MuiDialogContent from './MuiDialogContent';
import MuiDialogTitle from './MuiDialogTitle';
import MuiDivider from './MuiDivider';
import MuiDrawer from './MuiDrawer';
import MuiFab from './MuiFab';
import MuiFormGroup from './MuiFormGroup';
import MuiFormHelperText from './MuiFormHelperText';
import MuiFormLabel from './MuiFormLabel';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiLinearProgress from './MuiLinearProgress';
import MuiLink from './MuiLink';
import MuiMenuItem from './MuiMenuItem';
import MuiSelect from './MuiSelect';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTab from './MuiTab';
import MuiTableFooter from './MuiTableFooter';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiToggleButton from './MuiToggleButton';
import MuiToggleButtonGroup from './MuiToggleButtonGroup';
import MuiTooltip from './MuiTooltip';
import MuiTypography from './MuiTypography';

const components: Components = {
  MuiAppBar,
  MuiAutocomplete,
  MuiBadge,
  MuiBottomNavigation,
  MuiBottomNavigationAction,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiContainer,
  MuiCssBaseline,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
  MuiDivider,
  MuiDrawer,
  MuiFab,
  MuiFormGroup,
  MuiFormHelperText,
  MuiFormLabel,
  MuiIconButton,
  MuiInputBase,
  MuiLinearProgress,
  MuiLink,
  MuiMenuItem,
  MuiSelect,
  MuiSvgIcon,
  MuiTab,
  MuiTableSortLabel,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiTooltip,
  MuiTypography,
  MuiTableFooter,
};

export default components;
