import { createSlice } from '@reduxjs/toolkit';

import { IStripeCoupon } from "../types/stripe";

import orderPayByHashCode from '../query/order/orderPayByHashCode';
import checkStripeCoupons from '../query/order/checkStripeCoupons';

import addAgencyStripePayment from "../query/stripe/addAgencyStripePayment";
import getAgencyStripeDashboardLink from "../query/stripe/getAgencyStripeDashboardLink";
import getConsultantStripeDashboardLink from "../query/stripe/getConsultantStripeDashboardLink";
import getConsultantStripeAccountLink from "../query/stripe/getConsultantStripeAccountLink";
import getCustomerStripeDashboardLink from "../query/stripe/getCustomerStripeDashboardLink";

export interface StripeState {
  accountLink: string | undefined;
  clientSecret: string | undefined;
  paymentLinkUrl: string | undefined;
  isLoading: boolean;
  stripeCoupon: IStripeCoupon | undefined;
  isCouponLoading: boolean;
}
const initialState: StripeState = {
  accountLink: undefined,
  clientSecret: undefined,
  paymentLinkUrl: undefined,
  isLoading: false,
  stripeCoupon: undefined,
  isCouponLoading: false,
}

const {actions, reducer} = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    setAccountLink: (state, {payload}) => {
      state.accountLink = payload.url;
    },
    setClientSecret: (state, {payload}) => {
      state.clientSecret = payload.payment.client_secret;
    },
    setPaymentLinkUrl: (state, {payload}) => {
      state.paymentLinkUrl = payload.paymentLinkUrl;
    },
  },
  extraReducers: (builder) => builder
    .addCase(getConsultantStripeAccountLink.fulfilled, (state, { payload }) => {
      state.accountLink = payload.url;
    })
    .addCase(getConsultantStripeDashboardLink.fulfilled, (state, { payload }) => {
      state.accountLink = payload.url;
    })
    .addCase(getCustomerStripeDashboardLink.fulfilled, (state, { payload }) => {
      state.accountLink = payload.url;
    })


    .addCase(orderPayByHashCode.pending, (state) => {
      state.isLoading = true;
      state.paymentLinkUrl = undefined;
    })
    .addCase(orderPayByHashCode.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(orderPayByHashCode.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.paymentLinkUrl = payload.paymentLinkUrl;
    })
    .addCase(checkStripeCoupons.pending, (state) => {
      state.isCouponLoading = true;
      state.stripeCoupon = undefined;
    })
    .addCase(checkStripeCoupons.rejected, (state) => {
      state.isCouponLoading = false;
    })
    .addCase(checkStripeCoupons.fulfilled, (state, { payload }) => {
      state.isCouponLoading = false;
      state.stripeCoupon = payload;
    })

    .addCase(getAgencyStripeDashboardLink.pending, (state) => {
      state.isLoading = true;
      state.accountLink = undefined;
    })
    .addCase(getAgencyStripeDashboardLink.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(getAgencyStripeDashboardLink.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.accountLink = payload.url;
    })

    .addCase(addAgencyStripePayment.pending, (state) => {
      state.isLoading = true;
      state.accountLink = undefined;
    })
    .addCase(addAgencyStripePayment.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(addAgencyStripePayment.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.accountLink = payload.url;
    })
});
export const { setPaymentLinkUrl } = actions;

export default reducer;
