import { Components } from "@mui/material/styles";

const MuiTableFooter: Components['MuiTableFooter'] = {
  styleOverrides: {
    root: ({theme}: any) => theme.unstable_sx({
      position: 'sticky',
      bottom: 0,
      backgroundColor: 'common.white'
    })
  }
}

export default MuiTableFooter;