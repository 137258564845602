import React, { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

interface IToastMessage {
  body: string;
  title: string;
}

const ToastMessage: FunctionComponent<IToastMessage> = ({ title, body }) => (
  <Box data-test="toast-container">
    <Typography variant="subtitle1" fontWeight={500}>
      {title}
    </Typography>
    <Typography fontSize="1rem">{body}</Typography>
  </Box>
);

export default ToastMessage;
