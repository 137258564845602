import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';
import { IWeeklyBillings } from '../../types/order';

interface IRequestParams {
  orderId: number;
  id: number;
  index: number;
  hours: number;
  notes: string;
}

const submitWeeklyBillings = createAsyncThunk<IWeeklyBillings, IRequestParams>(
  EOrder.submitWeeklyBillings,
  async ({ orderId, id, hours, notes }) => {
    const { data } = await instance.post(`orders/${orderId}/weekly-billings/${id}/submit`, {
      hours,
      notes,
    });
    return data;
  },
);

export default submitWeeklyBillings;
