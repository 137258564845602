import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { ECommon } from "../../constants";
import { ISearchSeniority, ISeniorityData } from "../../types/common";

const searchSeniority = createAsyncThunk<ISeniorityData[], ISearchSeniority>(
  ECommon.searchSeniority,
  async (payload) => {
    const { data } = await instance.post('seniorityV6', payload)
    return data
  }
)

export default searchSeniority
