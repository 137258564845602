import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { ECommon } from 'store/constants';

type FetchCustomersParams = {
  property?: 'firstName' | 'lastName' | 'email' | 'confirmAt',
  type?: 'asc' | 'desc',
  limit?: number,
  offset?: number,
  search: string,
}

const fetchCustomers = createAsyncThunk(
  ECommon.getCustomers,
  async ({
    property = 'firstName',
    type = 'asc',
    limit = 25,
    offset = 0,
    search,
  }: FetchCustomersParams) => {
    const { data } = await instance.get(`customer`, {
      params: { property, type, limit, offset, search },
    })
    return data;
  }
)

export default fetchCustomers;
