import { ITags } from '../models';

interface ICustomFilter {
  inputValue: string;
  name: string;
  create: string;
}

export const customFilter = (options: ITags[], inputValue: string): ITags[] | ICustomFilter[] => {
  // Suggest the creation of a new value
  const isExisting = options.some(option => inputValue === option.name);
  if (inputValue !== ' ' && !isExisting) {
    return [
      {
        inputValue: inputValue.trim(),
        name: inputValue.trim(),
        create: `Add new company: "${inputValue.trim()}"`,
      },
    ];
  }
  return options;
};
