import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

const LazyComponent: FunctionComponent<any> = ({ children }) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(
      entries => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            setIsVisible(entry.isIntersecting);
          }
        }
      },
      {
        threshold: 0.1,
        rootMargin: '300px 0px',
      },
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return (
    <div ref={ref} style={{ minHeight: 630 }}>
      {isVisible ? children : null}
    </div>
  );
};

export default LazyComponent;
