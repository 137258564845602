import { createAsyncThunk} from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EOrder } from '../../constants';
import { IOrdersFeedback } from "../../types/order";

const fetchOrderFeedback = createAsyncThunk<IOrdersFeedback, number | string>(
  EOrder.getFeedbackByOrder,
  async (id) => {
    const { data } = await instance.get(`orders/${id}/feedback`);

    return data
  }
)

export default fetchOrderFeedback;
