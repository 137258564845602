import {styled} from "@mui/material/styles";
import FormControlLabel  from "@mui/material/FormControlLabel";
import Radio  from "@mui/material/Radio";

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => theme.unstable_sx({
  ml: 0,
  mr: {
    xs: 0,
    sm: 2
  },
  mb: 2,
  backgroundColor: 'common.white',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'common.black',
  width: {
    xs: '100%',
    sm: 252
  },
  minHeight: 56,
  maxWidth: '100%',
  transition: theme.transitions.create(['background-color', 'color'], {
    duration: theme.transitions.duration.standard,
  }),
  alignItems: 'flex-start',
  paddingTop: 1.5,
  paddingBottom: 1.5,
  '&:has(.Mui-disabled)': {
    borderColor: '#5d5d5e',
  },
  '&:has(.Mui-disabled) .MuiRadio-root, &:has(.Mui-disabled) .MuiFormControlLabel-label': {
    color: '#5d5d5e',
  },
  '&:not(.Mui-disabled):hover': {
    color: 'common.white',
    backgroundColor: 'primary.contrastText',

    '& .MuiTypography-root': {
      color: 'common.white',
    },
  },
  '&:last-child': {
    mb: {
      xs: 0,
      md: 2
    }
  },
  '&:has(.Mui-checked), &:has(.Mui-checked.Mui-disabled) .MuiFormControlLabel-label, &:has(.Mui-checked.Mui-disabled) .MuiRadio-root': {
    color: 'common.white',
    backgroundColor: 'primary.contrastText'
  },
}))

const StyledRadio = styled(Radio)(({ theme }) => theme.unstable_sx({
  color: 'inherit',
  '&.Mui-checked': {
    color: 'inherit',
  },
  '& input': {
    zIndex: 'auto',
  },
}))

export { StyledFormControlLabel, StyledRadio }
