import React, { FunctionComponent } from 'react';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { IModalProps } from 'store/types/common';
import { StyledCloseBtn } from '../Modal/styled';
import CloseIcon from 'styles/icons/CloseIcon';
import { StyledDialog } from './styled';

interface IConfirmationModal extends IModalProps {
  title: string;
  subtitle: string;
  primaryButtonText: string;
  onPrimaryButtonClick: () => void;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
}

const ConfirmationModal: FunctionComponent<IConfirmationModal> = props => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    visible,
    close,
    title,
    subtitle,
    primaryButtonText,
    onPrimaryButtonClick,
    secondaryButtonText,
    onSecondaryButtonClick,
  } = props;

  return (
    <StyledDialog open={visible} fullScreen={fullScreen}>
      <DialogTitle>
        <Typography
          data-test="modal-title"
          component="p"
          fontSize="30px"
          lineHeight="normal"
          fontWeight="500"
        >
          {title}
        </Typography>

        <StyledCloseBtn size="large" onClick={e => close()}>
          <CloseIcon />
        </StyledCloseBtn>

        <Typography component="p" fontSize="18px" lineHeight="normal" fontWeight="400" pt={2}>
          {subtitle}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button variant="contained" color="secondary" fullWidth onClick={onPrimaryButtonClick}>
              {primaryButtonText}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={onSecondaryButtonClick ?? close}
            >
              {props.secondaryButtonText ?? 'Close'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export default ConfirmationModal;
