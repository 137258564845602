import { Components } from '@mui/material/styles';

const MuiTableSortLabel: Components['MuiTableSortLabel'] = {
  styleOverrides: {
    icon: ({ theme }: any) => theme.unstable_sx({
      opacity: '.3'
    }),
  }
}

export default MuiTableSortLabel
