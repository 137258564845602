import { Components } from '@mui/material/styles';

const MuiDialog: Components['MuiDialog'] = {
  defaultProps: {
    maxWidth: 'md',
  },
  styleOverrides: {
    root: ({ theme }: any) => theme.unstable_sx({}),
    paper: ({ theme }: any) => ({
      [theme.breakpoints.down('md')]: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: 'auto',
        maxHeight: '100%',
        width: '100%'
      }
    })
  }
}

export default MuiDialog
