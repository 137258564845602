import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledDateTextInput = styled(Box)(({ theme }) => theme.unstable_sx({
  '&.text-custom-input': {
    display: 'flex',
    alignItems: 'center',
    minHeight: 48,
    borderRadius: 0,
    border: '1px solid #f5f5f5',
    boxSizing: 'border-box',
    boxShadow: 'none',
    backgroundColor: 'tertiary.main',
    fontSize: 14,
    padding: '0.375rem 0.75rem',
    cursor: 'text',
    color: 'text.secondary',
    'svg': {
      mr: 1,
    }
  },
  '&.selected': {
    color: 'secondary.main',
    fontSize: 18,
  },
  '&.disabled': {
    color: '#a1a0a0',
  },
}))

export default StyledDateTextInput
