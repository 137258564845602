import { createAsyncThunk} from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

const declineDraftOrder = createAsyncThunk<any, { id: number }>(
  EOrder.declineDraftOrder,
  async ({ id}) => {
    const { data } = await instance.patch(`draft-orders/decline`, {ids: [id]});

    return { ...data, id };
  }
)

export default declineDraftOrder;
