import {createAsyncThunk} from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { ICompany } from 'store/types/company';
import { request } from 'http';
import { EAuth } from 'store/constants/agency';

interface RequestAgencyProps {
    email: string,
    firstName: string,
    lastName: string,
    company: string,
    notes: string,
    teamSize: string,
    newsletterSubscription: string,
};

const requestAgency = createAsyncThunk(
    EAuth.requestAgency,
    async (props: RequestAgencyProps, { rejectWithValue }) => {
      try {
        const payload = {
          email: props.email,
          firstName: props.firstName,
          lastName: props.lastName,
          companyName: props.company,
          notes: props.notes,
          teamSize: props.teamSize,
          newsletterSubscription: props.newsletterSubscription,
        };

        const { data } = await instance.post('auth/request-agency', payload);
        return data;
      } catch (err: any) {
        return rejectWithValue(err.response ? err.response.data : err.message);
      }
    }
);

export default requestAgency;
