import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {EConsultant} from "../../constants";

const deleteProductExperiences = createAsyncThunk(
  EConsultant.removeCompanyExperience,
  async (payload: any) => {
      await instance.delete('experiences', { data: { ids: payload.ids } })

    return payload
  }
)

export default deleteProductExperiences
