import React from 'react';

import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

export default (props: SvgIconProps) => (
  <SvgIcon {...props}>
      <path d="M19.5 23V4.2C19.5 4.08954 19.4105 4 19.3 4H8.2C8.08954 4 8 4.08954 8 4.2V23" stroke="currentColor"
            strokeWidth="1.5"/>
      <path d="M15 23V18.3C15 18.1343 14.8657 18 14.7 18H12.3C12.1343 18 12 18.1343 12 18.3V23" stroke="currentColor"
            strokeWidth="1.5"/>
      <path d="M8 7L3.57601 10.4955C3.528 10.5334 3.5 10.5912 3.5 10.6524V23" stroke="currentColor" strokeWidth="1.5"/>
      <path d="M1 23L23 23" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M13.5 4V1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
      <rect x="10" y="7" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="13" y="7" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="16" y="7" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="10" y="9" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="10" y="11" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="5" y="11" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="5" y="13" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="10" y="13" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="13" y="9" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="13" y="11" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="13" y="13" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="16" y="9" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="16" y="11" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
      <rect x="16" y="13" width="1.5" height="1.5" rx="0.3" fill="currentColor"/>
  </SvgIcon>
);
