import { createAsyncThunk } from '@reduxjs/toolkit';
import { EUser } from 'store/constants';

import instance from 'store/middlewares/api';

interface IResponseAgency {
  count: number;
  page: number;
  companies: any[]
}

const getAgencyList = createAsyncThunk<IResponseAgency, any>(
  EUser.getAgencyList,
  async ({ filter, key, ...params }) => {
    const { data } = await instance.get(`/admin/agency`, { params: { ...filter, ...params } });
    
    return data
  }
);

export default getAgencyList;