import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledStepContainer = styled(Box)(({theme}) => ({
  marginRight: theme.spacing(2),
  maxWidth: 1076,
  height: '100%'
}))

const StyledHeaderWrap = styled(StyledStepContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(6),
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: 'none'
  },
}))

export { StyledStepContainer, StyledHeaderWrap }
