import { Components } from '@mui/material/styles';

declare module '@mui/material/Link' {
  interface LinkPropsColorOverrides {
    tertiary: true;
  }
}

const MuiLink: Components['MuiLink'] = {
  variants: [
    {
      props: {
        color: 'primary',
      },
      style: ({ theme }: any) =>
        theme.unstable_sx({
          color: '#FFCA28',
          transition: theme.transitions.create(['color'], {
            duration: theme.transitions.duration.standard,
          }),
          '&:hover': {
            color: '#1F1F1F',
          },
        }),
    },
    {
      props: {
        color: 'secondary',
      },
      style: ({ theme }: any) =>
        theme.unstable_sx({
          color: '#1F1F1F',
          cursor: 'pointer',
          transition: theme.transitions.create(['color'], {
            duration: theme.transitions.duration.standard,
          }),
          '&:hover': {
            color: '#FFCA28',
          },
        }),
    },
    {
      props: {
        color: 'tertiary',
      },
      style: ({ theme }: any) =>
        theme.unstable_sx({
          color: '#FFF',
          transition: theme.transitions.create(['color'], {
            duration: theme.transitions.duration.standard,
          }),
          '&:hover': {
            color: '#FFCA28',
          },
        }),
    },
  ],
};

export default MuiLink;
