import React, { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import dayjs from 'utils/dayjs';

import { useAppDispatch } from "store/hooks";
import getCapacities from "store/query/consultant/getAvailabilities";
import getCalendar from "store/query/consultant/getCalendar";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

import ArrowLeftIcon from "styles/icons/ArrowLeft";
import {StyledPaginationButton, StyledPaginationText} from "../../components/CalendarComponents/styled";

import { calculatedDate } from "components/CalendarComponents/utils";
import Calendar from "components/CalendarComponents";
import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";

const CalendarView = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));

  const dispatch = useAppDispatch();
  const [type, setType] = React.useState<string>('month');

  const dates = calculatedDate(dayjs().format('YYYY-MM-DD'), type);
  const [dateRange, setDateRange] = useState(dates);

  useEffect(() => {
    dispatch(getCapacities(dateRange))
  }, [ dateRange, dispatch ])

  useEffect(() => {
    dispatch(getCalendar(dateRange))
  }, [ dateRange, dispatch ])

  const handleType = (
    event: React.MouseEvent<HTMLElement>,
    newType: string,
  ) => {
    setType(newType);
    const dates = calculatedDate(dayjs().format('YYYY-MM-DD'), newType);
    setDateRange(dates);
  };

  const handleChangeRange = (param?: 'subtract' | 'add') => {
    let newStartDate = dayjs();
    if(param && dayjs(dateRange.startDate)[param]) {
      newStartDate = dayjs(dateRange.startDate)[param]!(1, 'week').startOf('week') as Dayjs;
    }
    const dates = calculatedDate(newStartDate.format('YYYY-MM-DD'), type);
    setDateRange(dates);
  };

  return (
    <>
      {/*<Container maxWidth="xxl" sx={{px: {xs: 2, sm: 3, md: 5, lg: 8}, pt: 4}}>*/}
      <Container
        maxWidth="xxl"
        fixed={matches}
        sx={{px: {xs: 2, sm: 3, md: 5, lg: 8}, mt: 4}}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h1">My Calendar</Typography>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <ToggleButtonGroup
              value={type}
              exclusive
              onChange={handleType}
              aria-label="text alignment"
              sx={{mx: 2}}
            >
              <ToggleButton value="month" aria-label="left aligned" size="small">
                Month
              </ToggleButton>
              <ToggleButton value="quarter" aria-label="centered" size="small">
                Quarter
              </ToggleButton>
            </ToggleButtonGroup>
            <StyledPaginationButton color="secondary" size="small" onClick={() => handleChangeRange('subtract')}>
              <ArrowLeftIcon />
            </StyledPaginationButton>
            <StyledPaginationText
              color="secondary"
              onClick={() => handleChangeRange()}
              size="small"
            >
              Today
            </StyledPaginationText>
            <StyledPaginationButton color="secondary" size="small" onClick={() => handleChangeRange('add')}>
              <Box sx={{ transform: 'rotate(180deg)' }}><ArrowLeftIcon /></Box>
            </StyledPaginationButton>
          </Box>
        </Box>
      </Container>
      <Calendar {...dateRange} type={type} />
    </>
  )
}

export default CalendarView
