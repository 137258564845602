import { Action, createSlice } from '@reduxjs/toolkit';

import union from 'lodash/fp/union';

import {
  DEFAULT_ENTITY,
  DEFAULT_SENIORITIES,
  DEFAULT_ENTITY_IDS,
  EPreOrderStatus,
  LIMITS,
  keyStatuses,
  orderTabStatuses,
} from 'models/consts';

import fetchOrderList from '../query/order/fetchOrderList';

import { EPreOrder } from 'store/constants';

import {
  DRAFT_CREATION_DEFAULT,
  IBasicOrders,
  IDraftOrderCreation,
  IFullOrder,
} from '../types/order';
import {
  CommonEntities,
  CommonEntitiesAsObject,
  CommonEntitiesCount,
  IDynamicKey,
  IEntitiesIds,
  IGetSeniorityCounts,
  ISeniorityData,
} from '../types/common';
import { IExtendUserCustomer } from '../types/customer';
import { IBasicPreOrder, IFullPreOrder } from '../types/preOrder';

import fetchOrderById from '../query/order/fetchOrderById';
import orderChangeName from '../query/order/orderChangeName';
import fetchOrderHistory from '../query/order/fetchOrderHistory';
import fetchWeeklyBillings from '../query/order/fetchWeeklyBillings';
import approveWeeklyBillings from '../query/order/approveWeeklyBillings';
import declineWeeklyBillings from '../query/order/declineWeeklyBillings';
import fetchOrderBalance from '../query/order/fetchOrderBalance';
import adjustWeeklyBillings from '../query/order/adjustWeeklyBillings';
import submitWeeklyBillings from '../query/order/submitWeeklyBillings';
import createOrder from '../query/order/createOrder';
import fetchOrderByHashCode from '../query/order/fetchOrderByHashCode';
import fetchOrderFeedback from '../query/order/fetchOrderFeedback';
import createFeedback from '../query/order/createFeedback';
import fetchSharedLinks from '../query/order/fetchSharedLinks';
import updateOrderDraft from '../query/order/updateOrderDraft';
import approveDraftOrder from '../query/order/approveDraftOrder';
import declineDraftOrder from '../query/order/declineDraftOrder';
import updateOrder from '../query/agency/updateOrder';
import searchSeniority from '../query/common/searchSeniority';
import fetchCustomers from '../query/common/fetchCustomersByName';
import getSeniorityCounts from 'store/query/common/getSeniorityCounts';
import fetchPreOrderList from '../query/preorder/fetchPreOrderList';
import fetchPreOrderById from '../query/preorder/fetchPreOrderById';
import editPreorder from '../query/order/editPreorder';
import finalizePreorder from '../query/order/finalizePreorder';
import createOrderDraft from 'store/query/order/createOrderDraft';
import fetchPreOrderHistory from '../query/order/fetchPreOrderHistory';
import declineOpportunity from '../query/preorder/declineOpportunity';
import getCompaniesLogos from '../query/consultant/getCompaniesLogos';

const preorderStatucChangeAction = [
  EPreOrder.cancelOpportunity,
  EPreOrder.acceptOpportunity,
  EPreOrder.confirmOpportunity,
  EPreOrder.declineOpportunity,
  EPreOrder.sendToAgency,
  EPreOrder.sendCustomerInvitation,
  EPreOrder.paidOpportunity,
];

const isChangePreorderStatus = (
  action: Action,
  actionState: '/fulfilled' | '/pending' | '/rejected',
) => {
  return preorderStatucChangeAction.some(
    act => action.type.includes(act) && action.type.endsWith(actionState),
  );
};

export interface OrderState {
  customersSearch: CommonEntitiesAsObject<IExtendUserCustomer[]>;
  orderList: IDynamicKey<IEntitiesIds>;
  ordersById: IDynamicKey<IFullOrder>;
  preOrderList: IDynamicKey<IEntitiesIds>;
  preOrdersById: IDynamicKey<IFullPreOrder>;
  searchSeniority: CommonEntities<ISeniorityData[]>;
  getSeniorityCounts: CommonEntitiesCount<IGetSeniorityCounts[]>;
  draftOrderCreation: IDraftOrderCreation;
}

const initialState: OrderState = {
  customersSearch: {},
  orderList: {},
  ordersById: {},
  preOrderList: {},
  preOrdersById: {},
  searchSeniority: DEFAULT_ENTITY,
  getSeniorityCounts: DEFAULT_SENIORITIES,
  draftOrderCreation: DRAFT_CREATION_DEFAULT,
};

const addOrders = (orders: any[]) => (ordersMap: IDynamicKey<IFullOrder | IFullPreOrder>) =>
  orders.reduce((orderMap, order) => {
    if (!orderMap[order.id.toString()]) {
      orderMap[order.id.toString()] = {
        ...orderMap[order.id.toString()],
        order: {
          ...order,
          loading: false,
          loaded: true,
        },
      };
    }
    return orderMap;
  }, ordersMap);

const { reducer, actions } = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateOrderById: (state, { payload }) => {
      if (state.ordersById[payload.order.id]) {
        state.ordersById[payload.order.id] = {
          ...state.ordersById[payload.order.id],
          order: {
            ...state.ordersById[payload.order.id].order,
            ...payload.order,
          },
        };
        const nextTab = Object.entries(orderTabStatuses).find(([_, values]) =>
          values.includes(payload.order.status),
        );

        const listsOrderEntries = Object.entries(state.orderList);
        const listsWithOrder = listsOrderEntries.filter(([_, values]) =>
          values.ids.includes(payload.order.id),
        );

        if (nextTab && !listsWithOrder.find(([key]) => key.includes(nextTab[0]))) {
          listsWithOrder.forEach(([key]) => {
            state.orderList[key] = {
              ...state.orderList[key],
              ids: [...state.orderList[key].ids.filter(id => id !== payload.order.id)],
            };
          });
        }
        if (
          nextTab &&
          listsOrderEntries.some(([key]) => key.includes(nextTab[0])) &&
          !listsWithOrder.find(([key]) => key.includes(nextTab[0]))
        ) {
          listsOrderEntries.forEach(([key]) => {
            if (key.includes(nextTab[0])) {
              state.orderList[key] = {
                ...state.orderList[key],
                ids: [...state.orderList[key].ids, payload.order.id],
              };
            }
          });
        }
      }
    },
  },
  extraReducers: builder =>
    builder
      .addCase(updateOrderDraft.fulfilled, (state, action) => {
        const { payload } = action;

        if (payload.length) {
          state.ordersById = {
            ...state.ordersById,
            [payload[0].id]: payload[0],
          };
        }
      })

      /**
       *  Update order by Agency
       */
      .addCase(updateOrder.fulfilled, (state, action) => {
        const {
          meta: { arg },
          payload,
        } = action;
        const unassignedKey = ['unassignedOrders', arg.key, 'ASC'].join(':');
        const currentKey = ['currentKey', arg.key, 'ASC'].join(':');

        state.orderList[unassignedKey].ids = state.orderList[unassignedKey].ids.filter(
          id => id !== arg.orderId,
        );
        if (state.orderList[currentKey]) {
          state.orderList[currentKey].ids.unshift(arg.orderId);
        }
        state.ordersById[arg.orderId].order = {
          ...state.ordersById[arg.orderId].order,
          ...payload[0],
        };
      })

      // TODO: test on env
      .addCase(approveDraftOrder.fulfilled, (state, action) => {
        const { payload } = action;
        state.orderList[['draftOrders', 'nonEnterprise', 'ASC'].join(':')].ids = state.orderList[
          ['draftOrders', 'nonEnterprise', 'ASC'].join(':')
        ].ids.filter(item => item !== payload.id);
        const newOrdersById: any = {};
        for (const key in state.ordersById) {
          if (key !== payload.id) {
            newOrdersById[key] = state.ordersById[key];
          }
        }
        state.ordersById = newOrdersById;
      })

      // TODO: test on env
      .addCase(declineDraftOrder.fulfilled, (state, action) => {
        const { payload } = action;
        state.orderList[['draftOrders', 'nonEnterprise', 'ASC'].join(':')].ids = state.orderList[
          ['draftOrders', 'nonEnterprise', 'ASC'].join(':')
        ].ids.filter(item => item !== payload.id);

        const newOrdersById: any = {};
        for (const key in state.ordersById) {
          if (key !== payload.id) {
            newOrdersById[key] = state.ordersById[key];
          }
        }
        state.ordersById = newOrdersById;
      })

      /**
       *  Create draft order
       */
      .addCase(createOrderDraft.pending, state => {
        state.draftOrderCreation.loading = true;
      })
      .addCase(createOrderDraft.fulfilled, (state, { payload }) => {
        addOrders([payload])(state.ordersById);
        const key = ['draftOrders', 'nonEnterprise', 'ASC'].join(':');
        if (state.orderList[key]) {
          state.orderList[key] = {
            ...state.orderList[key],
            ids: [...state.orderList[key].ids, payload.id],
          };
        }
        state.draftOrderCreation.loaded = true;
        state.draftOrderCreation.loading = false;
      })
      .addCase(createOrderDraft.rejected, (state, { error, type }) => {
        state.draftOrderCreation.actionError = {
          message: error?.message || '',
          action: type,
        };
        state.draftOrderCreation.loaded = false;
        state.draftOrderCreation.loading = false;
      })

      /**
       *  Refactor 18-12
       */
      /**
       *  Fetch order list
       */
      .addCase(fetchOrderList.pending, (state, action) => {
        const { meta } = action;

        if (!meta.arg.offset && meta.arg.filter) {
          state.orderList[meta.arg.key] = DEFAULT_ENTITY_IDS;
        }
      })
      .addCase(fetchOrderList.fulfilled, (state, action) => {
        const { meta, payload } = action;
        if (payload.length) {
          state.orderList[meta.arg.key].ids = union(
            state.orderList[meta.arg.key]?.ids,
            payload.map((order: IBasicOrders) => order.id),
          );
          addOrders(payload as IBasicOrders[])(state.ordersById);
        }
        // state.orderList[meta.arg.key].sort = meta.arg.sort;
        state.orderList[meta.arg.key].hasMore =
          !!payload.length && payload.length >= (meta.arg.limit || LIMITS.order);
        state.orderList[meta.arg.key].loaded = true;
        state.orderList[meta.arg.key].loading = false;
      })
      .addCase(fetchOrderList.rejected, (state, action) => {
        const { meta } = action;

        if (!meta.arg.offset && meta.arg.filter) {
          state.orderList[meta.arg.key.toString()] = {
            ...state.orderList[meta.arg.key.toString()],
            loaded: true,
            loading: false,
            hasMore: false,
          };
        }
      })
      /**
       *  Fetch Pre Order list
       */
      .addCase(fetchPreOrderList.pending, (state, action) => {
        const { meta } = action;

        if (!meta.arg.offset && meta.arg.filter) {
          state.preOrderList[meta.arg.key] = DEFAULT_ENTITY_IDS;
        }
      })
      .addCase(fetchPreOrderList.fulfilled, (state, action) => {
        const { meta, payload } = action;
        if (payload.length) {
          state.preOrderList[meta.arg.key].ids = union(
            state.preOrderList[meta.arg.key].ids,
            payload.map((order: IBasicPreOrder) => order.id),
          );
          addOrders(payload as IBasicPreOrder[])(state.preOrdersById);
        }
        // state.preOrderList[meta.arg.key].sort =  meta.arg.sort;
        state.preOrderList[meta.arg.key].hasMore =
          !!payload.length && payload.length >= (meta.arg.params.limit || LIMITS.order);
        state.preOrderList[meta.arg.key].loaded = true;
        state.preOrderList[meta.arg.key].loading = false;
      })
      .addCase(fetchPreOrderList.rejected, (state, action) => {
        const { meta } = action;

        if (!meta.arg.offset && meta.arg.filter) {
          state.preOrderList[meta.arg.key.toString()] = {
            ...state.preOrderList[meta.arg.key.toString()],
            loaded: true,
            loading: false,
            hasMore: false,
          };
        }
      })
      /**
       *  Fetch order By Id
       */
      .addCase(fetchOrderById.pending, (state, action) => {
        const { meta } = action;

        state.ordersById[meta.arg.id] = {
          ...state.ordersById[meta.arg.id],
          order: {
            ...state.ordersById[meta.arg.id]?.order,
            loading: true,
            loaded: false,
          },
        };
      })
      .addCase(fetchOrderById.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.ordersById[meta.arg.id] = {
          ...state.ordersById[meta.arg.id],
          order: {
            ...state.ordersById[meta.arg.id]?.order,
            ...payload,
            loading: false,
            loaded: true,
          },
        };
      })

      /**
       *  Fetch PreOrder By Id
       */
      .addCase(fetchPreOrderById.pending, (state, action) => {
        const { meta } = action;

        state.preOrdersById[meta.arg.id] = {
          ...state.preOrdersById[meta.arg.id],
          order: {
            ...state.preOrdersById[meta.arg.id]?.order,
            loading: true,
            loaded: false,
          },
        };
      })
      .addCase(fetchPreOrderById.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.preOrdersById[meta.arg.id] = {
          ...state.preOrdersById[meta.arg.id],
          order: {
            ...state.preOrdersById[meta.arg.id]?.order,
            ...payload,
            loading: false,
            loaded: true,
          },
        };
      })

      /**
       *  Fetch order by hash code
       */
      .addCase(fetchOrderByHashCode.fulfilled, (state, action) => {
        const {
          payload: { order, extendHour, paymentLink, ...sharedLink },
        } = action;

        state.ordersById[order.id] = {
          ...state.ordersById[order.id],
          order: {
            ...order,
            paymentLink,
            loading: false,
            loaded: true,
          },
          extendHours: {
            items: [extendHour],
            loading: false,
            loaded: true,
          },
          sharedLinks: {
            items: [sharedLink],
            loading: false,
            loaded: true,
          },
        };
      })

      /**
       *  Create order
       */

      /**
       * SAS-1382
       *  Don't need it for case with empty order list to prevent adding new order without downloading from BE
       */

      // .addCase(createOrder.pending, (state, action) => {
      // const { meta } = action;
      // const purchaseId = meta?.arg?.expertise?.purchaseOrder?.id || 'nonEnterprise'
      // const key = ['ordersCurrent', purchaseId, 'ASC'].join(':')

      // if(!state.orderList[key]) {
      //   state.orderList[key] = DEFAULT_ENTITY_IDS
      // }
      // })
      .addCase(createOrder.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const purchaseId = meta?.arg?.expertise?.purchaseOrder?.id || 'nonEnterprise';
        const key = ['ordersCurrent', purchaseId, 'ASC'].join(':');

        if (payload.length && state.orderList[key]) {
          state.orderList[key].ids.push(...payload.map((order: IBasicOrders) => order.id));
          addOrders(payload as IBasicOrders[])(state.ordersById);
        }
        if (state.orderList[key]) {
          state.orderList[key].loaded = true;
          state.orderList[key].loading = false;
        }
      })

      /**
       *  Change Order Name
       */
      .addCase(orderChangeName.fulfilled, (state, { payload }) => {
        state.ordersById[payload.id].order.name = payload.name;
      })

      /**
       *  Get Order Shared Links
       */
      .addCase(fetchSharedLinks.pending, (state, action) => {
        const { meta } = action;
        state.ordersById[meta.arg].sharedLinks = {
          items: [],
          loading: true,
          loaded: false,
        };
      })
      .addCase(fetchSharedLinks.fulfilled, (state, action) => {
        const { meta, payload } = action;
        state.ordersById[meta.arg].sharedLinks = {
          items: payload,
          loading: false,
          loaded: true,
        };
      })

      /**
       *  Get Worker`s Company logos
       */
      .addCase(getCompaniesLogos.fulfilled, (state, action) => {
        const { meta, payload } = action;
        if (state.ordersById[meta.arg.orderId].order.worker.consultant?.companiesLogos) {
          state.ordersById[meta.arg.orderId].order.worker.consultant.companiesLogos = payload;
        }
      })

      /**
       *  Get Order Feedback
       */
      .addCase(fetchOrderFeedback.pending, (state, action) => {
        const { meta } = action;
        state.ordersById[meta.arg].feedback = {
          ...state.ordersById[meta.arg].feedback,
          loading: true,
          loaded: false,
        };
      })
      .addCase(fetchOrderFeedback.fulfilled, (state, action) => {
        const { meta, payload } = action;
        state.ordersById[meta.arg].feedback = {
          ...payload,
          loading: false,
          loaded: true,
        };
      })
      /**
       *  Create Order Feedback
       */
      .addCase(createFeedback.fulfilled, (state, action) => {
        const { meta, payload } = action;
        state.ordersById[meta.arg.orderId].feedback = {
          ...payload,
          loading: false,
          loaded: true,
        };
      })

      /**
       *  Fetch Order WeeklyBillings
       */
      .addCase(fetchWeeklyBillings.pending, (state, action) => {
        const { meta } = action;
        state.ordersById[meta.arg.orderId].weeklyBillings = {
          items: [],
          loading: true,
          loaded: false,
        };
      })
      .addCase(fetchWeeklyBillings.fulfilled, (state, action) => {
        const { meta, payload } = action;
        state.ordersById[meta.arg.orderId].weeklyBillings = {
          items: payload,
          loading: false,
          loaded: true,
        };
      })
      /**
       *  Weekly Billings Actions
       */
      // Approve Weekly Billings
      .addCase(approveWeeklyBillings.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.ordersById[meta.arg.orderId].weeklyBillings.items[meta.arg.index] = payload;
      })
      // Decline Weekly Billings
      .addCase(declineWeeklyBillings.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.ordersById[meta.arg.orderId].weeklyBillings.items[meta.arg.index] = payload;
      })
      // Adjust Weekly Billings
      .addCase(adjustWeeklyBillings.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.ordersById[meta.arg.orderId].weeklyBillings.items[meta.arg.index] = payload;
      })
      // Submit Weekly Billings
      .addCase(submitWeeklyBillings.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.ordersById[meta.arg.orderId].weeklyBillings.items[meta.arg.index] = payload;
      })

      /**
       *  Order Balance
       */
      .addCase(fetchOrderBalance.fulfilled, (state, action) => {
        const { meta, payload } = action;
        state.ordersById[meta.arg.id].orderBalance = {
          ...payload,
          loading: false,
          loaded: true,
        };
      })

      /**
       *  Fetch order History
       */
      .addCase(fetchOrderHistory.pending, (state, action) => {
        const { meta } = action;
        state.ordersById[meta.arg.id].history = {
          items: [],
          loading: true,
          loaded: false,
        };
      })
      .addCase(fetchOrderHistory.fulfilled, (state, action) => {
        const { meta, payload } = action;
        state.ordersById[meta.arg.id].history = {
          items: payload,
          loading: false,
          loaded: true,
        };
      })

      /**
       *  Fetch preorder History
       */
      .addCase(fetchPreOrderHistory.pending, (state, action) => {
        const { meta } = action;
        state.preOrdersById[meta.arg.id].history = {
          items: [],
          loading: true,
          loaded: false,
        };
      })
      .addCase(fetchPreOrderHistory.fulfilled, (state, action) => {
        const { meta, payload } = action;
        state.preOrdersById[meta.arg.id].history = {
          items: payload,
          loading: false,
          loaded: true,
        };
      })

      /**
       *  Fetch Search Seniority
       */
      .addCase(searchSeniority.pending, state => {
        state.searchSeniority.loading = true;
      })
      .addCase(searchSeniority.fulfilled, (state, { payload }) => {
        state.searchSeniority = {
          items: payload,
          loaded: true,
          loading: false,
        };
      })

      /**
       *  Fetch get Seniority counts
       */
      .addCase(getSeniorityCounts.pending, state => {
        state.getSeniorityCounts.loading = true;
      })
      .addCase(getSeniorityCounts.fulfilled, (state, { payload }) => {
        state.getSeniorityCounts = {
          items: payload,
          loaded: true,
          loading: false,
        };
      })

      /**
       *  Fetch Customers for order
       */
      .addCase(fetchCustomers.pending, (state, action) => {
        const { meta } = action;
        state.customersSearch[meta.arg.search] = {
          items: [],
          loaded: true,
          loading: false,
        };
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        const { meta } = action;
        state.customersSearch[meta.arg.search] = {
          items: [],
          loaded: false,
          loading: false,
        };
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        const { meta, payload } = action;
        state.customersSearch[meta.arg.search] = {
          items: payload.customers,
          countAll: payload.count,
          loaded: true,
          loading: false,
        };
      })

      /**
       *  Edit Preorder
       */
      .addCase(editPreorder.pending, (state, action) => {
        const { meta } = action;

        state.preOrdersById[meta.arg.preorder.id] = {
          ...state.preOrdersById[meta.arg.preorder.id],
          order: {
            ...state.preOrdersById[meta.arg.preorder.id]?.order,
            loading: true,
            loaded: false,
          },
        };
      })
      .addCase(editPreorder.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.preOrdersById[meta.arg.preorder.id] = {
          ...state.preOrdersById[meta.arg.preorder.id],
          order: {
            ...state.preOrdersById[meta.arg.preorder.id]?.order,
            ...payload[0],
            loading: false,
            loaded: true,
          },
        };
      })
      .addCase(editPreorder.rejected, (state, action) => {
        const { meta } = action;

        state.preOrdersById[meta.arg.preorder.id] = {
          ...state.preOrdersById[meta.arg.preorder.id],
          order: {
            ...state.preOrdersById[meta.arg.preorder.id]?.order,
            loading: false,
            loaded: false,
          },
        };
      })

      /**
       *  Finalize Preorder
       */
      .addCase(finalizePreorder.pending, (state, action) => {
        const { meta } = action;

        state.preOrdersById[meta.arg.preOrderId] = {
          ...state.preOrdersById[meta.arg.preOrderId],
          order: {
            ...state.preOrdersById[meta.arg.preOrderId]?.order,
            loading: true,
          },
        };
      })
      .addCase(finalizePreorder.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.preOrdersById[meta.arg.preOrderId] = {
          ...state.preOrdersById[meta.arg.preOrderId],
          order: {
            ...state.preOrdersById[meta.arg.preOrderId]?.order,
            ...payload[0],
            loading: false,
          },
        };
      })
      .addCase(finalizePreorder.rejected, (state, action) => {
        const { meta } = action;

        state.preOrdersById[meta.arg.preOrderId] = {
          ...state.preOrdersById[meta.arg.preOrderId],
          order: {
            ...state.preOrdersById[meta.arg.preOrderId]?.order,
            loading: false,
          },
        };
      })

      /**
       *  Decline Preorder
       */
      .addCase(declineOpportunity.pending, (state, action) => {
        const { meta } = action;

        state.preOrdersById[meta.arg.id] = {
          ...state.preOrdersById[meta.arg.id],
          order: {
            ...state.preOrdersById[meta.arg.id]?.order,
            loading: true,
          },
        };
      })
      .addCase(declineOpportunity.fulfilled, () => {
        //logic in addMatcher for '/filfilled' below to avoid race condition
      })
      .addCase(declineOpportunity.rejected, (state, action) => {
        const { meta } = action;

        state.preOrdersById[meta.arg.id] = {
          ...state.preOrdersById[meta.arg.id],
          order: {
            ...state.preOrdersById[meta.arg.id]?.order,
            loading: false,
          },
        };
      })

      // TODO: need check for decline (at least for agency), it should remove item from store.
      /**
       *  Update Preorder Statuses
       */
      .addMatcher(
        action => isChangePreorderStatus(action, '/pending'),
        (state, action) => {
          const { meta }: any = action;
          state.preOrdersById[meta.arg.id] = {
            ...state.preOrdersById[meta.arg.id],
            order: {
              ...state.preOrdersById[meta.arg.id]?.order,
              loading: true,
              loaded: false,
            },
          };
        },
      )
      .addMatcher(
        action => isChangePreorderStatus(action, '/fulfilled'),
        (state, action) => {
          const { meta, payload, type }: any = action;
          if (!type.includes(EPreOrder.declineOpportunity)) {
            //only update the pre orders by ID if we're not deleting
            state.preOrdersById[meta.arg.id] = {
              ...state.preOrdersById[meta.arg.id],
              order: {
                ...state.preOrdersById[meta.arg.id]?.order,
                ...payload,
                loading: false,
                loaded: true,
              },
            };
          } else {
            const newPreOrdersById = { ...state.preOrdersById };
            delete newPreOrdersById[meta.arg.id];
            state.preOrdersById = {
              ...newPreOrdersById,
            };
          }

          const listsPreorderEntries = Object.entries(state.preOrderList);
          const listsWithPreorder = listsPreorderEntries.filter(([_, values]) =>
            values.ids.includes(meta.arg.id),
          );
          if (listsWithPreorder.length) {
            listsWithPreorder.forEach(([key]) => {
              state.preOrderList[key] = {
                ...state.preOrderList[key],
                ids: [...state.preOrderList[key].ids.filter(id => id !== meta.arg.id)],
              };
            });
          }
          if (!type.includes(EPreOrder.declineOpportunity)) {
            const status = (payload.status ||
              state.preOrdersById[meta.arg.id].order.status) as EPreOrderStatus;
            const categories = keyStatuses[status];
            listsPreorderEntries.forEach(([key]) => {
              if (categories.some(item => key.includes(item))) {
                state.preOrderList[key] = {
                  ...state.preOrderList[key],
                  ids: [...state.preOrderList[key].ids, meta.arg.id],
                };
              }
            });
          }
        },
      ),
});

export const { updateOrderById } = actions;

export default reducer;
