import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import ArrowDown from 'styles/icons/ArrowDown';
import LogoutIcon from 'styles/icons/LogoutIcon';
import BuildingIcon from 'styles/icons/BuildingIcon';

import ROUTES from 'router/constants';

import Avatar from 'components/Avatar';

const NotAuthorizedMenu: FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" alignItems="center" mr={1.5}>
      <Avatar icon={<BuildingIcon />} firstName="" />
      <IconButton disableRipple onClick={handleClick}>
        <ArrowDown />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        data-test="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            width: 240,
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem component={Link} onClick={handleClose} to={ROUTES.AUTH}>
          <ListItemText primary="Log In" />
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default NotAuthorizedMenu;
