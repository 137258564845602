import { createAsyncThunk} from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EOrder } from '../../constants';
import { IWeeklyBillings} from "../../../models";
import { ERole } from "../../../models/consts";
import { IRole } from "../../types/common";

interface IWeeklyBillingsProps {
  orderId: number;
  role: IRole;
}
const fetchWeeklyBillings = createAsyncThunk<IWeeklyBillings[], IWeeklyBillingsProps>(
  EOrder.fetchWeeklyBillings,
  async ({ orderId, role }) => {
    let url = `/orders/${orderId}/weekly-billings`

    if(role.name === ERole.LEAD_MANAGER) {
      url = `/lead-managers/orders/${orderId}/weekly-billings`
    }
    const { data } = await instance.get(url);

    return data
  }
)

export default fetchWeeklyBillings;
