import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { MobileState } from '../reducers/mobile';

const mobileState = (state: DefaultRootState) => state.mobile;

export const isOnlineSelector = createSelector(
  mobileState,
  ({ isOnline }: MobileState) => isOnline,
);
