import { useMemo } from 'react';
import { IExpertiseModulesWorksInfo, IModule, IWork } from "store/types/categoryProducts";

const useExpertiseModulesInfo = (modulesWorks?: IExpertiseModulesWorksInfo[]) => {
  const modulesInfo = useMemo(() => {
    let worksArray, modulesArray;

    if (modulesWorks && modulesWorks.length) {
      const newWorks = new Map<number, IWork>();
      const newModules = new Map<number, IModule>();
      modulesWorks?.forEach((modulesWorkItem) => {
        newWorks.set(modulesWorkItem.workId, modulesWorkItem.work);
        newModules.set(modulesWorkItem.moduleId, modulesWorkItem.module);
      });
      worksArray = Array.from(newWorks, ([, value]) => ({ ...value }));
      modulesArray = Array.from(newModules, ([, value]) => ({ ...value }));
    }

    return { works: worksArray, modules: modulesArray }
  }, [modulesWorks]);

  return modulesInfo;
}

export default useExpertiseModulesInfo;
