import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';

import { EChangeTimelineModal } from 'models/consts';
import { IModalProps } from "store/types/common";
import fetchAvailability from "store/query/common/fetchAvailability";
import { useAppDispatch } from "store/hooks";

import MatchingModal from './MatchingModal/MatchingModal';
import ModalWrapper from "../Modal";

const ChangeTimeLineModal: FunctionComponent<IModalProps> = ({visible, close}) => {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState<EChangeTimelineModal>(EChangeTimelineModal.FORM);

  useEffect(() => {
    dispatch(fetchAvailability())
  }, [ dispatch ])

  const handleClose = () => {
    close && close();
    setStep(EChangeTimelineModal.FORM);
  }

  const renderChangeTimelineStage = () => {
    switch (EChangeTimelineModal.FORM) {
      case step:
        return (
          <>
            {/*<Timeline onFormStatusChange={setStep} isModal={true} />*/}
          </>
        );
      default:
        return (
          <MatchingModal close={handleClose} />
        )
    }
  }

  const modalTitle = useMemo(() => {
    switch (EChangeTimelineModal.FORM) {
      case step:
        return {
          title: 'Timeline'
        };
      default:
        return {
          title: 'Update timeline'
        }
    }
  }, [ step ])

  return (
    <ModalWrapper
      dialogProps={{
        open: visible,
        onClose: handleClose
      }}
      title={modalTitle}
    >
      {renderChangeTimelineStage()}
    </ModalWrapper>
  );
};

export default ChangeTimeLineModal;
