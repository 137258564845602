import {createAsyncThunk} from "@reduxjs/toolkit";

import instance from "../../middlewares/api";
import { EUser } from "../../constants";

import { removeAuthTokens, removeImpersonateTokens } from "../../../utils/apiCallHelpers";
import { socketInstance } from "../../middlewares/socket";

const logout = createAsyncThunk(
  EUser.logout,
  async () => {
    try {
      const { data } = await instance.get('auth/logout');
      return data;
    } finally {
      try {
        await socketInstance.disconnect();
      } catch (err) {
        console.error('Error during socket disconnect:', err);
      }
      await removeAuthTokens();
      await removeImpersonateTokens();
    }
  }
)

export default logout;
