import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {EConsultant} from "../../constants";

const deleteSubExperience = createAsyncThunk(
  EConsultant.removeSubExperience,
  async ({ ids, ...payload }: any) => {
    await instance.delete('experiences', {data: { ids }})

    return payload;
  }
)

export default deleteSubExperience
