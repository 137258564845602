import React, { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ToastService } from 'services';

import acceptOpportunity from 'store/query/preorder/acceptOpportunity';
import cancelOpportunity from 'store/query/preorder/cancelOpportunity';
import confirmOpportunity from 'store/query/preorder/confirmOpportunity';
import declineOpportunity from 'store/query/preorder/declineOpportunity';
import sendCustomerInvitation from 'store/query/preorder/sendCustomerInvitation';
import sendToAgency from 'store/query/preorder/sendToAgency';
import fetchInviteCustomersLinkByPreOrderId from 'store/query/preorder/fetchInviteCustomersLinkByPreOrderId';
import paidOpportunity from 'store/query/preorder/paidOpportunity';
import finalizePreorder from 'store/query/order/finalizePreorder';

import { getMeRoleSelector } from 'store/selectors/getUserSelector';

import ToastMessage from 'components/Toast/ToastMessage';

import ROUTES from 'router/constants';
import { EPreOrderStatus, ERole } from 'models/consts';
import { IBasicPreOrder } from 'store/types/preOrder';

interface IPreorderActions {
  preOrder: IBasicPreOrder;
}

const PreorderActions: FunctionComponent<IPreorderActions> = ({ preOrder }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const role = useAppSelector(getMeRoleSelector);

  const handleAcceptOpportunity = () => {
    dispatch(acceptOpportunity({ id: preOrder.id }));
  };

  const handleCancelOpportunity = () => {
    dispatch(cancelOpportunity({ id: preOrder.id, role }));
  };

  const handleConfirmOrder = () => {
    dispatch(confirmOpportunity({ id: preOrder.id }));
  };

  const handleCopyLink = () => {
    dispatch(fetchInviteCustomersLinkByPreOrderId(preOrder.id))
      .unwrap()
      .then(response => {
        const link = `${window.location.origin}/auth/signup-customer/${response.uniqCode}`;
        navigator?.clipboard?.writeText(link).then(() => {
          ToastService.success(ToastMessage({ title: 'Success', body: 'Link was copied!' }));
        });
      })
      .catch(() => {
        ToastService.error(ToastMessage({ title: 'Error', body: 'Something went wrong!' }));
      });
  };

  const handleDeclineOpportunity = () => {
    dispatch(declineOpportunity({ id: preOrder.id }))
      .unwrap()
      .then(response => {
        navigate(ROUTES.ORDER_LIST);
      });
  };

  const handleSendToAgency = () => {
    dispatch(sendToAgency({ id: preOrder.id }));
  };

  const handleSendToCustomer = () => {
    dispatch(sendCustomerInvitation({ id: preOrder.id }));
  };

  const handlePayPreorder = () => {
    dispatch(paidOpportunity({ id: preOrder.id }));
  };

  const handleFinalizePaperwork = () => {
    dispatch(finalizePreorder({ preOrderId: preOrder.id }));
  };

  return (
    <Box mb={4}>
      <Typography component="div" variant="h4">
        Manage PreOrder
      </Typography>
      <Divider />
      <Stack spacing={2}>
        <Button variant="contained" color="secondary" fullWidth onClick={handleDeclineOpportunity}>
          Decline Opportunity
        </Button>
        {role.name === ERole.LEAD_MANAGER && (
          <>
            {preOrder.status === EPreOrderStatus.IN_REVISION && (
              <Button variant="contained" color="primary" fullWidth onClick={handleSendToAgency}>
                Send For Agency Approvals
              </Button>
            )}
            {[EPreOrderStatus.PENDING_INTRODUCTION, EPreOrderStatus.PENDING_CUSTOMER].includes(
              preOrder.status,
            ) && (
              <Button variant="contained" color="primary" fullWidth onClick={handleSendToCustomer}>
                Send Customer Invitation
              </Button>
            )}
            {preOrder.status === EPreOrderStatus.PENDING_INTRODUCTION && (
              <Button variant="outlined" color="secondary" fullWidth onClick={handleCopyLink}>
                Copy Sign Up Link
              </Button>
            )}
            {preOrder.status !== EPreOrderStatus.CANCEL_PREORDER && (
              <>
                <Button variant="outlined" color="secondary" fullWidth component={Link} to="edit">
                  Edit Lead
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleCancelOpportunity}
                >
                  Cancel Opportunity
                </Button>
              </>
            )}
          </>
        )}

        {[ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(role.name) && (
          <>
            {preOrder.status === EPreOrderStatus.PENDING_AGENCY &&
              !!preOrder.agencyReadinessDate && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleAcceptOpportunity}
                >
                  Accept Opportunity
                </Button>
              )}
            {preOrder.status === EPreOrderStatus.PENDING_BILLING && (
              <Button variant="contained" color="primary" fullWidth onClick={handlePayPreorder}>
                Accept Preorder
              </Button>
            )}
            {[EPreOrderStatus.PENDING_CUSTOMER, EPreOrderStatus.PENDING_APPROVAL].includes(
              preOrder.status,
            ) && (
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                component={Link}
                to={`${ROUTES.CHAT}/${preOrder?.chat?.id}`}
                disabled={preOrder.status === EPreOrderStatus.PENDING_CUSTOMER}
              >
                Send Message
              </Button>
            )}
            <Button variant="outlined" color="secondary" fullWidth component={Link} to="edit">
              Edit Opportunity
            </Button>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleDeclineOpportunity}
            >
              Decline Opportunity
            </Button>
          </>
        )}
        {role.name === ERole.CUSTOMER_ADMIN &&
          preOrder.status === EPreOrderStatus.PENDING_APPROVAL && (
            <>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleConfirmOrder}
                disabled={!preOrder.timeLine?.startDate || !preOrder.timeLine?.endDate}
              >
                Confirm Preorder
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                component={Link}
                to={`${ROUTES.CHAT}/${preOrder?.chat?.id}`}
              >
                Send Message
              </Button>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleCancelOpportunity}
              >
                Cancel Preorder
              </Button>
            </>
          )}
        {role.name === ERole.CUSTOMER_ADMIN &&
          preOrder.status === EPreOrderStatus.FINALIZE_PAPERWORK &&
          preOrder.billingType === 'customer' && (
            <>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleFinalizePaperwork}
                disabled={!preOrder.purchaseOrderId}
              >
                Finalize Paperwork
              </Button>
              <Button variant="contained" color="primary" fullWidth component={Link} to="edit">
                Set Payment Authorization
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                component={Link}
                to={`${ROUTES.CHAT}/${preOrder?.chat?.id}`}
              >
                Send Message
              </Button>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleCancelOpportunity}
              >
                Cancel Preorder
              </Button>
            </>
          )}
      </Stack>
    </Box>
  );
};

export default PreorderActions;
