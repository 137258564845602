import { createAsyncThunk } from "@reduxjs/toolkit";

import instance from "../../middlewares/api";

import { EConsultant } from "../../constants";

const fetchConsultant = createAsyncThunk(
  EConsultant.fetchConsultant,
  async (payload: number | string) => {
    const { data } = await instance.get(`consultant/${payload}`);
    return data
  }
)

export default fetchConsultant
