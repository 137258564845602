import React, { FunctionComponent } from 'react';
import { ISvgProps } from 'models/inner-models';
export const ChatSendMobileIcon: FunctionComponent<ISvgProps> = ({ className, style }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 1.99829L11 12.9983"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 1.99829L15 21.9983L11 12.9983L2 8.99829L22 1.99829Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
