import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import MoreVerticalIcon from 'styles/icons/MoreVerticalIcon';

import { useAppDispatch } from 'store/hooks';
import { ToastService } from 'services';

import acceptOpportunity from 'store/query/preorder/acceptOpportunity';
import cancelOpportunity from 'store/query/preorder/cancelOpportunity';
import sendToAgency from 'store/query/preorder/sendToAgency';
import declineOpportunity from 'store/query/preorder/declineOpportunity';
import sendCustomerInvitation from 'store/query/preorder/sendCustomerInvitation';
import paidOpportunity from 'store/query/preorder/paidOpportunity';
import confirmOpportunity from 'store/query/preorder/confirmOpportunity';
import fetchInviteCustomersLinkByPreOrderId from 'store/query/preorder/fetchInviteCustomersLinkByPreOrderId';

import ToastMessage from 'components/Toast/ToastMessage';

import { IBasicPreOrder } from 'store/types/preOrder';
import { IRole } from 'store/types/common';
import ROUTES from 'router/constants';
import { EPreOrderStatus, ERole } from 'models/consts';

type IMenuWrapper = {
  children: ReactNode;
  anchorEl?: HTMLElement;
  onToggleMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
  open: boolean;
};

const MenuWrapper: React.FunctionComponent<IMenuWrapper> = ({
  children,
  anchorEl,
  onToggleMenu,
  onClose,
  open,
}) => {
  return (
    <>
      <MuiMenu
        anchorEl={anchorEl}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
      >
        {children}
      </MuiMenu>
      <IconButton onClick={onToggleMenu} size="small" sx={{ alignSelf: 'start' }}>
        <MoreVerticalIcon />
      </IconButton>
    </>
  );
};

export interface IPreOrderMenu {
  preOrder: IBasicPreOrder;
  role: IRole;
}

const PreOrderMenu: FunctionComponent<IPreOrderMenu> = ({ preOrder, role }) => {
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>(undefined);
  const open = Boolean(anchorEl);
  const handleToggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleAcceptPreorder = () => {
    dispatch(acceptOpportunity({ id: preOrder.id }));
    handleClose();
  };

  const handleCancelPreorder = () => {
    dispatch(cancelOpportunity({ id: preOrder.id, role }));
    handleClose();
  };

  const handleAgencyDeclineOpportunity = () => {
    dispatch(declineOpportunity({ id: preOrder.id }));
    handleClose();
  };

  const handleSendToAgency = () => {
    dispatch(sendToAgency({ id: preOrder.id }));
    handleClose();
  };

  const handleSendToCustomer = () => {
    dispatch(sendCustomerInvitation({ id: preOrder.id }));
    handleClose();
  };

  const handleCustomerAcceptPreorder = () => {
    dispatch(confirmOpportunity({ id: preOrder.id }));
    handleClose();
  };

  const handleCopyLink = () => {
    dispatch(fetchInviteCustomersLinkByPreOrderId(preOrder.id))
      .unwrap()
      .then(response => {
        const link = `${window.location.origin}/auth/signup-customer/${response.uniqCode}`;
        navigator?.clipboard?.writeText(link).then(() => {
          ToastService.success(ToastMessage({ title: 'Success', body: 'Link was copied!' }));
        });
      })
      .catch(() => {
        ToastService.error(ToastMessage({ title: 'Error', body: 'Something went wrong!' }));
      })
      .finally(() => {
        handleClose();
      });
  };

  const handlePayPreorder = () => {
    dispatch(paidOpportunity({ id: preOrder.id }));
    handleClose();
  };

  // LEAD MANAGER
  if (
    role.name === ERole.LEAD_MANAGER &&
    (preOrder.status === EPreOrderStatus.NEW || preOrder.status === EPreOrderStatus.IN_REVISION)
  ) {
    return (
      <MenuWrapper
        anchorEl={anchorEl}
        onToggleMenu={handleToggleMenu}
        onClose={handleClose}
        open={open}
      >
        <MenuItem component={Link} onClick={handleClose} to={`${ROUTES.PRE_ORDER}/${preOrder.id}`}>
          Review Lead
        </MenuItem>
        <MenuItem onClick={handleSendToAgency} disabled={preOrder.status === EPreOrderStatus.NEW}>
          Send to Agency - Staffing
        </MenuItem>
        <MenuItem onClick={handleCancelPreorder}>Cancel Lead</MenuItem>
      </MenuWrapper>
    );
  }

  if (
    role.name === ERole.LEAD_MANAGER &&
    preOrder.status === EPreOrderStatus.PENDING_INTRODUCTION
  ) {
    return (
      <MenuWrapper
        anchorEl={anchorEl}
        onToggleMenu={handleToggleMenu}
        onClose={handleClose}
        open={open}
      >
        <MenuItem onClick={handleCopyLink}>Copy Sign Up Link</MenuItem>
        <MenuItem component={Link} onClick={handleClose} to={`${ROUTES.PRE_ORDER}/${preOrder.id}`}>
          Review Lead
        </MenuItem>
        <MenuItem onClick={handleSendToCustomer}>Send Invitation</MenuItem>
        <MenuItem onClick={handleCancelPreorder}>Cancel Preorder</MenuItem>
      </MenuWrapper>
    );
  }

  if (role.name === ERole.LEAD_MANAGER && preOrder.status === EPreOrderStatus.PENDING_BILLING) {
    return (
      <MenuWrapper
        anchorEl={anchorEl}
        onToggleMenu={handleToggleMenu}
        onClose={handleClose}
        open={open}
      >
        <MenuItem component={Link} onClick={handleClose} to={`${ROUTES.PRE_ORDER}/${preOrder.id}`}>
          Review Lead
        </MenuItem>
        <MenuItem onClick={handlePayPreorder}>Accept Preorder</MenuItem>
        <MenuItem onClick={handleCancelPreorder}>Cancel Lead</MenuItem>
      </MenuWrapper>
    );
  }

  if (
    role.name === ERole.LEAD_MANAGER &&
    [
      EPreOrderStatus.PENDING_CUSTOMER,
      EPreOrderStatus.PENDING_APPROVAL,
      EPreOrderStatus.PENDING_AGENCY,
    ].includes(preOrder.status)
  ) {
    return (
      <MenuWrapper
        anchorEl={anchorEl}
        onToggleMenu={handleToggleMenu}
        onClose={handleClose}
        open={open}
      >
        {preOrder.status === EPreOrderStatus.PENDING_CUSTOMER && (
          <MenuItem onClick={handleCopyLink}>Copy Sign Up Link</MenuItem>
        )}
        <MenuItem component={Link} onClick={handleClose} to={`${ROUTES.PRE_ORDER}/${preOrder.id}`}>
          Review Lead
        </MenuItem>
        <MenuItem onClick={handleCancelPreorder}>Cancel Lead</MenuItem>
      </MenuWrapper>
    );
  }

  // AGENCY
  if (
    [ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(role.name) &&
    preOrder.status === EPreOrderStatus.PENDING_AGENCY
  ) {
    return (
      <MenuWrapper
        anchorEl={anchorEl}
        onToggleMenu={handleToggleMenu}
        onClose={handleClose}
        open={open}
      >
        <MenuItem component={Link} onClick={handleClose} to={`${ROUTES.PRE_ORDER}/${preOrder.id}`}>
          Review
        </MenuItem>
        {!!preOrder.agencyReadinessDate && (
          <MenuItem onClick={handleAcceptPreorder}>Accept Preorder</MenuItem>
        )}
        <MenuItem onClick={handleAgencyDeclineOpportunity}>Decline Preorder</MenuItem>
      </MenuWrapper>
    );
  }

  if (
    [ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(role.name) &&
    preOrder.status === EPreOrderStatus.PENDING_CUSTOMER
  ) {
    return (
      <MenuWrapper
        anchorEl={anchorEl}
        onToggleMenu={handleToggleMenu}
        onClose={handleClose}
        open={open}
      >
        <MenuItem component={Link} onClick={handleClose} to={`${ROUTES.PRE_ORDER}/${preOrder.id}`}>
          Review
        </MenuItem>
      </MenuWrapper>
    );
  }

  if (
    [ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(role.name) &&
    preOrder.status === EPreOrderStatus.PENDING_APPROVAL
  ) {
    return (
      <MenuWrapper
        anchorEl={anchorEl}
        onToggleMenu={handleToggleMenu}
        onClose={handleClose}
        open={open}
      >
        <MenuItem
          component={Link}
          onClick={handleClose}
          to={`${ROUTES.PRE_ORDER}/${preOrder.id}/edit`}
        >
          Edit
        </MenuItem>
      </MenuWrapper>
    );
  }
  // CUSTOMER
  if (
    role.name === ERole.CUSTOMER_ADMIN &&
    [EPreOrderStatus.PENDING_CUSTOMER, EPreOrderStatus.PENDING_APPROVAL].includes(preOrder.status)
  ) {
    return (
      <MenuWrapper
        anchorEl={anchorEl}
        onToggleMenu={handleToggleMenu}
        onClose={handleClose}
        open={open}
      >
        <MenuItem component={Link} onClick={handleClose} to={`${ROUTES.PRE_ORDER}/${preOrder.id}`}>
          Review
        </MenuItem>
        <MenuItem component={Link} onClick={handleClose} to={`${ROUTES.CHAT}/${preOrder.id}`}>
          Send a Message
        </MenuItem>
        <MenuItem
          onClick={handleCustomerAcceptPreorder}
          disabled={!preOrder.timeLine?.startDate || !preOrder.timeLine?.endDate}
        >
          Accept Preorder
        </MenuItem>
        <MenuItem onClick={handleCancelPreorder}>Decline Preorder</MenuItem>
      </MenuWrapper>
    );
  }

  return <></>;
};
export default PreOrderMenu;
