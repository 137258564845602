import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {ECommon} from "../../constants";

const fetchAvailability = createAsyncThunk(
  ECommon.availability,
  async () => {
    const { data } = await instance.get('enums/availability-week')
    return await data
  }
)

export default fetchAvailability
