import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EAgency } from "../../constants";

interface IActivateAgenciesWithLeadSourceProps {
  leadSourceId: number;
  agencyIds: number[];
}

const activateAgenciesWithLeadSource = createAsyncThunk<any[], IActivateAgenciesWithLeadSourceProps>(
  EAgency.activateAgenciesWithLeadSource,
  async (payload) => {
    const { data } = await instance.post(`/lead-source/${payload.leadSourceId}/agencies`, payload);
    return await data
  }
)

export default activateAgenciesWithLeadSource
