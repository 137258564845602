import {createAsyncThunk} from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EConsultant } from 'store/constants';
import { CreateOrderDraftProps } from './createOrderDraft';

export type UpdateOrderDraftProps = CreateOrderDraftProps & {
  id: number;
  customPrise?: {
    priceCents: number,
    priceCurrency?: string,
  }
}

const updateOrderDraft = createAsyncThunk(
  EConsultant.updateOrderDraft,
  async (payload: UpdateOrderDraftProps) => {
    const { data } = await instance.patch('draft-orders', [payload]);
    return data;
  }
)

export default updateOrderDraft;
