import { createAsyncThunk} from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

const checkStripeCoupons = createAsyncThunk(
  EOrder.checkStripeCoupons,
  async ({ code }: { code: string }) => {
    const { data } = await instance.get(`stripe-coupons?code=${code}`);
    return data;
  }
)

export default checkStripeCoupons;
