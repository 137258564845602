import { createAsyncThunk} from '@reduxjs/toolkit';
import { Cookies } from "react-cookie";

import instance from 'store/middlewares/api';

import { EAgency } from "../../constants";
import { ELocalStoreKeys } from "../../../models/consts";

import fetchMe from "../user/fetchMe";

const cookies = new Cookies(null, { path: '/', sameSite: 'lax' })

const createVirtualSpecialist = createAsyncThunk(
  EAgency.createVirtualSpecialist,
  async (arg, thunkAPI) => {
    const { data } = await instance.post('/agency/users-team/registration-virtual-consultant');

    cookies.set(ELocalStoreKeys.IMPERSONATE_TOKEN, data.accessToken);
    cookies.set(ELocalStoreKeys.IMPERSONATE_REFRESH_TOKEN, data.refreshToken);

    await thunkAPI.dispatch(fetchMe());
    return data
  }
);

export default createVirtualSpecialist;
