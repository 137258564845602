import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {EConsultant} from "../../constants";

const updateConsultantExpertise = createAsyncThunk(
  EConsultant.updateConsultantExpertise,
  async ({ expertises }: any) => {
    const { data } = await instance.patch('consultant/expertises', expertises)
    return data
  }
)

export default updateConsultantExpertise
