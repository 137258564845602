import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { ENotification } from 'store/constants';

interface IRequest {
  ids: number[];
}

const shownNotification = createAsyncThunk<{ ids: [] }, IRequest>(
  ENotification.shownNotification,
  async payload => {
    const { data } = await instance.post('notification/show', payload);

    return data;
  },
);

export default shownNotification;
