import React, { FunctionComponent, useMemo, useState } from 'react'

import MuiLink from "@mui/material/Link";
import { Typography } from '@mui/material';
import PowerIcon from '@mui/icons-material/PowerSettingsNew'
import PauseCircleIcon from '@mui/icons-material/PauseCircleOutline';
import RefreshIcon from '@mui/icons-material/Autorenew';
import TrashIcon from '@mui/icons-material/DeleteOutline';

import { IAgencyUser } from 'store/types/agency';
import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { IBasicUser } from 'store/types/user';
import { ERole } from 'models/consts';
import StackRow from 'components/StackList/StackRow';
import { StyledLabel } from './styled';
import usePermissions from "../ManageTable/usePermissions";

const invited = (user: IAgencyUser) => {
  return !user.isActive && !user.lastChangeActiveAt
}

export interface IActionsRowProps {
  user: IAgencyUser;
  onChangeStatus: (user: IAgencyUser, selectedAction: string) => void;
}

const ActionsRow: FunctionComponent<IActionsRowProps> = ({ user, onChangeStatus }) => {
  const me = useAppSelector(getMeSelector) as IBasicUser;

  const isViewDeleteBtn = usePermissions(user)

  const showActivationButtons = useMemo(() => {
    const condition = !invited(user) &&
      user.id !== me.id &&
      user.agency?.owner.id !== user.id;
    if (me.role.name === ERole.RESOURCE_MANAGER && me.id !== user.agency?.owner.id) {
      return condition && user.role.name !== ERole.AGENCY_ADMIN
    }
    return condition;
  }, [me, user]);

  return (
    <StackRow direction="row">
      <StyledLabel color="textSecondary">Action</StyledLabel>
        {user.isActive
          && showActivationButtons
          && (
            <MuiLink
              color="secondary"
              onClick={() => onChangeStatus(user, 'active')}
              sx={{display: 'inline-flex', alignItems: 'center', stroke: 'none !important'}}
              underline="none"
            >
              <PauseCircleIcon/>
              <Typography component="span" lineHeight="20px" ml={1}>Pause</Typography>
            </MuiLink>
        )}
        {!user.isActive
          && showActivationButtons
          && (
          <MuiLink
            color="secondary"
            onClick={() => onChangeStatus(user, 'deactive')}
            sx={{display: 'inline-flex', alignItems: 'center', stroke: 'none !important'}}
            underline="none"
          >
            <PowerIcon/>
            <Typography component="span" lineHeight="20px" ml={1}>Activate</Typography>
          </MuiLink>
        )}
        {invited(user) && (
          <MuiLink
            color="secondary"
            onClick={() => onChangeStatus(user, 'refresh')}
            sx={{display: 'inline-flex', alignItems: 'center', stroke: 'none !important'}}
            underline="none"
          >
            <RefreshIcon/>
            <Typography component="span" lineHeight="normal" ml={1}>Resend</Typography>
          </MuiLink>
        )}
      {isViewDeleteBtn && (
        <MuiLink
          color="secondary"
          onClick={() => onChangeStatus(user, user.isActive === null ? 'cancel' : 'delete')}
          sx={{display: 'inline-flex', alignItems: 'center', stroke: 'none !important', marginLeft: 1}}
          underline="none"
        >
          <TrashIcon />
          <Typography component="span" lineHeight="normal" ml={1}>{user.isActive === null ? 'Cancel' : 'Delete'}</Typography>
        </MuiLink>
      )}
    </StackRow>
  )
}
export default ActionsRow;
