import React, { FunctionComponent, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ROUTES from '../../router/constants';

import { IFullOrders } from 'store/types/order';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import MarkAsCompletedModal from '../Modal/MarkAsCompletedModal';

interface IOrderMenuProps {
  order: IFullOrders;
}

const OrderMenu: FunctionComponent<IOrderMenuProps> = ({ order }) => {
  const navigate = useNavigate();

  const [showCompleteModal, setCompleteModalDisplay] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAsCompleted = () => {
    setCompleteModalDisplay(true);
  };

  return (
    <>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: 'inherit' }}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => navigate(`${ROUTES.ORDER}/${order.id}`)}>Order details</MenuItem>
        <MenuItem onClick={() => navigate(`${ROUTES.CHAT}/${order.chat?.id}`)}>
          Send Message
        </MenuItem>
        {order.status === 'active' && (
          <MenuItem onClick={handleMarkAsCompleted}>Mark as Completed</MenuItem>
        )}
      </Menu>
      <MarkAsCompletedModal
        order={order}
        onClose={() => {
          setAnchorEl(null);
          setCompleteModalDisplay(false);
        }}
        open={showCompleteModal}
      />
    </>
  );
};

export default OrderMenu;
