import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { ECommon } from 'store/constants';

const fetchWorks = createAsyncThunk(
  ECommon.workTypes,
  async (productId: number) => {
    const { data } = await instance.get(`products-works/${productId}/works`)
    return data
  }
)

export default fetchWorks;
