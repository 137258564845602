import { AxiosResponse } from 'axios';
import { createAsyncThunk} from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

const sendDraftNotification = createAsyncThunk<AxiosResponse, { id: number }>(
  EOrder.sendDraftNotification,
  async ({ id}) => {
    const { data } = await instance.post(`draft-orders/${id}`);

    return data;
  }
)

export default sendDraftNotification;
