export enum ECommon {
  products = 'common/products',
  workTypes = 'common/workTypes',
  searchSeniority = 'common/searchSeniority',
  getSeniorityCount = 'common/getSeniorityCount',
  getCustomers = 'common/getCustomers',

  // Refactor
  availability = 'common/availability',
  categoryProducts = 'common/categoryProducts',
  expertiseLevels = 'common/expertiseLevels',
  languages = 'common/languages',
  orderProductWithModulesWorks = 'common/orderProductWithModulesWorks',
  productModules = 'common/productModules',
  productModulesWithWorks = 'common/productModulesWithWorks',
  productsPrices = 'common/productsPrices',
  productsPricesWithoutFee = 'common/productsPricesWithoutFee',
  roles = 'common/roles',
  timePreferences = 'common/timePreferences',
}
