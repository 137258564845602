import React, { FunctionComponent, useEffect, useMemo } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { useAppDispatch } from 'store/hooks';
import getConsultantPipeStatus from 'store/query/consultant/getConsultantPipeStatus';

import SnackBar from '../SnackBar';
import { IBasicConsultant, IProductExperienceFull } from 'store/types/consultant';
import { IBasicUser } from 'store/types/user';
import { EConsultantEditingModals } from 'models/consts';
import { ICertificateItem } from 'store/types/certificates';
import isDev, { isLocal } from 'helpers/environmentDetector';

interface StyledProgressbarItemProps extends BoxProps {
  filled: boolean;
}

interface IConsultantProgressSnackBar {
  consultant: IBasicConsultant;
  expertiseSummary: IProductExperienceFull[];
  certificatesList: ICertificateItem[];
  user: IBasicUser;
  onOpen: (open: { type: number; open: boolean; showIntro?: boolean }) => void;
}

const StyledProgressbarItem = styled(({ filled, ...props }: StyledProgressbarItemProps) => (
  <Box {...props} />
))(({ filled, theme }) => ({
  height: 9,
  borderRadius: 16,
  backgroundColor: (filled && theme.palette.primary.main) || theme.palette.common.white,
}));

const ConsultantProgressSnackBar: FunctionComponent<IConsultantProgressSnackBar> = ({
  consultant,
  expertiseSummary,
  certificatesList,
  user,
  onOpen,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLocal() && !isDev()) {
      dispatch(getConsultantPipeStatus());
    }
  }, [dispatch]);

  const progress = useMemo(() => {
    const defaultValue = {
      btn: undefined,
      description:
        'Make sure you keep your profile up to date as you sharpen your skills. Keep it up!',
      percent: 100,
      title: 'Your Profile is looking good',
    };

    // Basic Info (profile picture, display name, headline, about me, location, linkedin, cv, languages, public profile)
    if (
      !consultant.avatarUrl ||
      !user.displayName ||
      !consultant.shortDescriptions ||
      !consultant.description ||
      !consultant.location ||
      (!consultant.linkedInLink && !consultant.cvFileId) ||
      !(consultant.languages || []).length ||
      (consultant.isConfirm !== true && consultant.isConfirm !== false)
    ) {
      return {
        btn: {
          name: 'Update Your Profile',
          callback: () =>
            onOpen({ open: true, showIntro: false, type: EConsultantEditingModals.PROFILE }),
        },
        description:
          'Your Workerbee profile displays your professional background, highlighting your experience, expertise, and availability to potential clients.',
        percent: 0,
        title: 'Tell us about yourself',
      };
    }

    // Availability (weekly availability, start date, block out dates, daily availability)
    if (
      !(consultant.availabilities || []).length ||
      !consultant.availabilities[0].availableFrom ||
      !consultant.availabilities[0].availabilityWeek ||
      !consultant.availabilities[0].forMeetTimeId ||
      !consultant.availabilities[0].forWorkTimeId
    ) {
      return {
        btn: {
          name: 'Update Your Availability',
          callback: () =>
            onOpen({ open: true, showIntro: false, type: EConsultantEditingModals.AVAILABILITY }),
        },
        description:
          'Providing your availability helps set clear expectations and can lead to better matches.',
        percent: 20,
        title: 'Update your availability',
      };
    }

    // Expertise ( products, work history, proficiency matrix )
    if (
      !expertiseSummary ||
      !(expertiseSummary || []).length ||
      !expertiseSummary[0] ||
      !expertiseSummary[0].product ||
      !(expertiseSummary[0].experiences || []).length ||
      !expertiseSummary[0].expertise ||
      !(expertiseSummary[0].expertise.modulesWorks || []).length
    ) {
      return {
        btn: {
          name: 'Update Your Work History',
          callback: () =>
            onOpen({ open: true, showIntro: false, type: EConsultantEditingModals.PRODUCTS }),
        },
        description:
          'Detailing your expertise helps clients understand your professional capabilities and the specific areas where you excel. By clearly communicating your preferences and expertise, we can also better align you with projects that suit your skills and interests.',
        percent: 40,
        title: 'Update your work history',
      };
    }

    // Certifications (upload new - name, issuing organization, product, modules, expiry date)
    if (!certificatesList || !(certificatesList || []).length || !certificatesList[0]) {
      return {
        btn: {
          name: 'Upload Your Certifications',
          callback: () =>
            onOpen({
              open: true,
              showIntro: false,
              type: EConsultantEditingModals.CERTIFICATE_CREATION_FORM,
            }),
        },
        description:
          'Certifications validate your expertise and reassure clients about your qualifications.',
        percent: 60,
        title: 'Upload your certifications',
      };
    }

    // Additional Skills (tag list)
    if (!consultant.tags || !(consultant.tags || []).length || !consultant.tags[0]) {
      return {
        btn: {
          name: 'Add Your Additional Skills',
          callback: () =>
            onOpen({
              open: true,
              showIntro: false,
              type: EConsultantEditingModals.ADDITIONAL_SKILLS,
            }),
        },
        description:
          'List any other relevant industry-specific skills that set you apart. This can easily help increase your appeal to a wider pool of potential clients.',
        percent: 80,
        title: 'Any additional skills?',
      };
    }

    return defaultValue;
  }, [
    certificatesList,
    consultant.availabilities,
    consultant.avatarUrl,
    consultant.cvFileId,
    consultant.description,
    consultant.isConfirm,
    consultant.languages,
    consultant.linkedInLink,
    consultant.location,
    consultant.shortDescriptions,
    consultant.tags,
    expertiseSummary,
    onOpen,
    user.displayName,
  ]);

  return (
    <Grid
      container
      xs={12}
      sx={{
        display: { xs: 'block', md: 'block' },
        mx: { xs: 'auto', md: 0 },
        mt: { xs: 2, md: -4 },
        p: { xs: 2, md: 0 },
        zIndex: 2,
      }}
    >
      <SnackBar title={progress.title} description={progress.description} button={progress.btn}>
        <Stack my={1} spacing={1} direction="row">
          <Stack width="20%">
            <StyledProgressbarItem filled={progress.percent >= 20} />
          </Stack>
          <Stack width="20%">
            <StyledProgressbarItem filled={progress.percent >= 40} />
          </Stack>
          <Stack width="20%">
            <StyledProgressbarItem filled={progress.percent >= 60} />
          </Stack>
          <Stack width="20%">
            <StyledProgressbarItem filled={progress.percent >= 80} />
          </Stack>
          <Stack width="20%">
            <StyledProgressbarItem filled={progress.percent >= 100} />
          </Stack>
        </Stack>
      </SnackBar>
    </Grid>
  );
};

export default ConsultantProgressSnackBar;
