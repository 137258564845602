import React, { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { StyledContainer } from './styled'

const FixWidthLayout: FunctionComponent = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <StyledContainer maxWidth="xxl" fixed={matches}>
      <Outlet />
    </StyledContainer>
  )
};

export default FixWidthLayout;


