import React, {FC, useCallback} from "react";
import { RefCallBack } from "react-hook-form";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import en from 'react-phone-number-input/locale/en'
import {Country, getCountries, getCountryCallingCode} from "react-phone-number-input/input";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { IconStyled, ImgStyled, StyledCountryCode } from "./styled";

import BootstrapInput from "../BootstrapInput";

interface ICountrySelect {
  onChange: (data: string) => void,
  inputRef?: RefCallBack,
  disabled?: boolean,
}

const CountrySelect: FC<ICountrySelect> = ({ onChange, disabled, ...rest }) => {
  const onChange_ = useCallback((event: any) => {
    const value = event.target.value
    onChange(value === 'ZZ' ? undefined : value)
  }, [onChange])

  return (
    <Select
      {...rest}
      name="mobilePhone.country"
      onChange={onChange_}
      onBlur={onChange_}
      renderValue={(selected: Country) => (
        <>
          <IconStyled>
            <ImgStyled src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selected}.svg`} />
          </IconStyled>
          <StyledCountryCode>
            +{getCountryCallingCode(selected)}
          </StyledCountryCode>
        </>
      )}
      input={<BootstrapInput />}
      sx={{ pr: 0 }}
      disabled={disabled}
    >
      {getCountries().map((country) => (
        <MenuItem key={country} value={country}>
          {en[country]}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CountrySelect
