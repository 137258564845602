import { Components } from '@mui/material/styles';

const MuiTypography: Components['MuiTypography'] = {
  styleOverrides: {
    root: ({ theme }: any) => theme.unstable_sx({
      '& strong': {
        fontWeight: 500
      }
    }),
  },
  // variants: [
  //   {
  //     props: { variant: 'h1' },
  //     // style: ({ theme }) => theme.unstable_sx({
  //     //   [theme.breakpoints.down('md')]: {
  //     //     fontSize: '1.375rem',
  //     //   },
  //     // }),
  //   },
  // ],
}

export default MuiTypography
