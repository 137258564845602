import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EUser } from "../../constants";

interface IRemoveLanguagesProps {
  ids: any[];
  userId: string | number
}

const removeUserLanguages = createAsyncThunk<any, IRemoveLanguagesProps>(
  EUser.removeUserLanguages,
  async ({ ids, userId }) => {
    await instance.delete('consultant/languages', { data: { ids } })

    return { ids, userId }
  }
)

export default removeUserLanguages
