import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const VerifiedIcon: FunctionComponent<ISvgProps> = ({className}) => {
  return (
    <svg className={className} width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.6017 9.44554L17.4705 0.554462C17.2736 0.209974 16.9062 0 16.5092 0H6.24672C5.84974 0 5.48228 0.213255 5.28543 0.554462L0.147638 9.44554C-0.0492126 9.79003 -0.0492126 10.2133 0.147638 10.5577L5.27887 19.4455C5.47572 19.79 5.84318 20 6.24016 20H16.5026C16.8996 20 17.2671 19.7867 17.4639 19.4455L22.5951 10.5577C22.792 10.2133 22.792 9.79003 22.5951 9.44554H22.6017ZM9.34383 16.4173L4.37008 11.4436L5.9416 9.87205L9.34383 13.2743L16.8143 5.80381L18.3858 7.37533L9.34383 16.4173Z" fill="#FFB923"/>
    </svg>
  );
}
