import { createAsyncThunk} from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';

import { EEnterprise } from "store/constants/enterprise";
import {IEnterpriseUser, IInviteUser} from "store/types/enterprise";

const resendInviteUser = createAsyncThunk<IEnterpriseUser[], IInviteUser>(
  EEnterprise.resendInvitationUser,
  async (values)=> {
    const { data } = await instance.post('enterprise/companies/users-team/resend-invite-user', { data: [values]});

    return data
  }
);

export default resendInviteUser;
