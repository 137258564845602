import React, {FunctionComponent} from 'react'
import cn from "classnames";

import {Dayjs, OpUnitType} from "dayjs";
import dayjs from "utils/dayjs";

import Tooltip from "@mui/material/Tooltip";

import { IFullOrders } from "store/types/order";
import { StyledBoxStatus } from "./styled";

import OrderTooltip from "./OrderTooltip";


interface IOrderCellContentProps {
  date: Dayjs;
  isOpen?: boolean;
  order: IFullOrders | any;
  unit: OpUnitType;
}

const OrderCellPreview: FunctionComponent<IOrderCellContentProps> = ({ date, isOpen, order, unit }) => {
  return (
    <Tooltip
      arrow
      title={
        date.isSameOrAfter(dayjs(order.timeLine.startDate), unit) &&
        date.isSameOrBefore(dayjs(order.timeLine.endDate), unit) && (
          <OrderTooltip order={order as any}/>
        )
      }
    >
      <StyledBoxStatus
        className={
          cn({
            startOrder: date.isSame(dayjs(order.timeLine.startDate), unit),
            endOrder: date.isSame(dayjs(order.timeLine.endDate), unit),
            [order.status || 'drafts']: date.isSameOrAfter(dayjs(order.timeLine.startDate), unit) && date.isSameOrBefore(dayjs(order.timeLine.endDate), unit),
            'isOpen': isOpen
          })
        }
        key={order.id}
      />
    </Tooltip>
  )
}

export default OrderCellPreview
