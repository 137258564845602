import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";


const StyledTalentFilterWrap = styled('form')(({theme}) => theme.unstable_sx({
  my: { xs: 0, md: 3 }
}));
const StyledDrawerBtn = styled(Grid)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  justifyContent: 'space-between'
}));

const StyledCloseIcon = styled(IconButton)(({ theme }) => theme.unstable_sx({
  position:{
    sm:  'absolute'
  },
  top: {
    sm: 1
  },
  left: {
    sm: 1
  },
  zIndex: 10
}));


export { StyledTalentFilterWrap, StyledDrawerBtn, StyledCloseIcon }
