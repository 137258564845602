import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EConsultant } from "../../constants";

const getCapacities = createAsyncThunk(
  EConsultant.fetchCapacities,
  async (params: any) => {
    const { data } = await instance.get('consultant/calendar/capacities', { params })
    return await data
  }
)

export default getCapacities
