import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EAgency } from "../../constants";

const fetchAllAgencies = createAsyncThunk(
  EAgency.fetchAllAgencies,
  async () => {
    const { data } = await instance.get('/agency/all')
    return data
  }
)

export default fetchAllAgencies
