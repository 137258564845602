import React, { FC, useState } from 'react';
import { RefCallBack, useFormContext } from 'react-hook-form';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';

import { MAX_AVATAR_IMAGE_SIZE } from 'models/consts';

import { UploadIcon } from 'styles/icons/UploadIcon';

import Avatar from '../Avatar';
import ImageCropperModal from '../Modal/ImageCropperModal/ImageCropperModal';
import { ToastService } from 'services';
import ToastMessage from 'components/Toast/ToastMessage';

interface IUploadAvatar {
  btnName?: string;
  disabled?: boolean;
  name: string;
  onChange: (data: any) => void;
  value?: Blob | string;
  firstName?: string;
  lastName?: string;
  icon: any;
  image?: string | null;
  inputRef?: RefCallBack;
  size: 'small' | 'medium' | 'large' | undefined;
}

const UploadAvatar: FC<IUploadAvatar> = ({
  btnName = 'Upload',
  firstName,
  disabled,
  lastName,
  icon,
  image,
  inputRef,
  size,
  value,
  ...field
}) => {
  const [uploadImage, setUploadImage] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(image || null);

  const [isOpenCropper, setCropperState] = useState<boolean>(false);

  const onImageUpload = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      if (file.size < MAX_AVATAR_IMAGE_SIZE) {
        const reader = new FileReader();
        reader.onload = () => {
          setUploadImage(reader.result as any);
          setCropperState(true);
          e.target.value = null;
        };
        reader.readAsDataURL(file);
      } else {
        ToastService.error(
          ToastMessage({
            title: 'Error',
            body: 'Please upload a photo smaller than 5Mb',
          }),
        );
      }
    }
  };

  const handleCropperClose = (uploadImage?: string, blob?: Blob) => {
    if (uploadImage && blob) {
      setCroppedImage(uploadImage);
      field.onChange(blob);
    }
    setCropperState(false);
    setUploadImage('');
  };

  return (
    <>
      <Grid
        xs={12}
        md="auto"
        sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'start' } }}
      >
        <Avatar
          size={size}
          icon={icon}
          image={croppedImage || image || ''}
          firstName={firstName || ''}
          lastName={lastName || ''}
        />
      </Grid>
      <Grid
        xs={12}
        md="auto"
        sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'start' } }}
      >
        <Button
          disabled={disabled}
          variant="contained"
          size="small"
          component="label"
          color="secondary"
        >
          <UploadIcon className="upload-icon me-2" />
          {btnName}
          <input
            {...field}
            ref={inputRef}
            hidden
            accept=".png, .jpeg, .jpg, .PNG, .JPEG, .JPG"
            multiple
            type="file"
            onChange={onImageUpload}
          />
        </Button>
      </Grid>
      <ImageCropperModal
        visible={isOpenCropper}
        image={uploadImage || ''}
        close={handleCropperClose}
      />
    </>
  );
};
export default UploadAvatar;
