import { styled } from '@mui/material/styles';
import MuiIconButton from '@mui/material/IconButton';

const StyledCloseButton = styled(MuiIconButton)({
  position: 'absolute',
  top: -8,
  right: -8,
});

export { StyledCloseButton };
