import React, {FunctionComponent} from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { EOrderFilter, ERole } from "models/consts";
import { IRole } from "store/types/common";

import generalTabOrder, { purchaseTabs } from "./tabOrder";

interface IToggleOrders {
  isAgency?: boolean;
  handleChange: (event: React.SyntheticEvent<Element>, newTab: EOrderFilter) => void;
  value: string;
  userRole: IRole;
  isPurchaseDetail?: boolean;
}

const ToggleOrders: FunctionComponent<IToggleOrders> = ({ isAgency, value, handleChange, userRole, isPurchaseDetail }) => {
  const tabOrder = isPurchaseDetail ? purchaseTabs : generalTabOrder;
  return (
    <Tabs
      value={value}
      variant="scrollable"
      onChange={handleChange}
      aria-label="Orders"
      textColor="secondary"
      indicatorColor="primary"
    >
      {tabOrder.map((tab) => {
        const isRender = tab.roles.includes(userRole?.name) && !(isAgency && userRole?.name === ERole.CONSULTANT && tab.value === EOrderFilter.DRAFT_ORDERS)
        return isRender && <Tab disableRipple key={tab.value} value={tab.value} label={tab.label} />
      })}
    </Tabs>
  )
}

export default ToggleOrders
