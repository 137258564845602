import { Components } from '@mui/material/styles';

const MuiFormLabel: Components['MuiFormLabel'] = {
  styleOverrides: {
    root: ({ theme }: any) =>
      theme.unstable_sx({
        color: '#1F1F1F',
        fontSize: '1rem',
        fontWeight: 500,
        mb: 1,
      }),
  },
};

export default MuiFormLabel;
