import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EPreOrder } from '../../constants';
import { IBasicPreOrder } from "../../types/preOrder";

const acceptOpportunity = createAsyncThunk<IBasicPreOrder, {id: number}>(
  EPreOrder.acceptOpportunity,
  async ({id}) => {
    const { data } = await instance.post(`/agency/pre-orders/${id}/accept`);

    return data;
  }
)

export default acceptOpportunity;
