import React, { FunctionComponent } from 'react';
import { useDropzone } from 'react-dropzone';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import PaperClipIcon from 'styles/icons/PaperClip';

const UploadImageBtn: FunctionComponent<any> = ({
  currentConversationRef,
  isiPadOrMobile,
  sender,
}) => {
  const onDropAccepted = (files: any[]) => {
    if (files.length > 0) {
      currentConversationRef.current.sendMessage(
        {
          contentType: files[0].type,
          media: files[0],
          filename: files[0].name,
        },
        sender,
      );
    }
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
  });

  return (
    <Box component="div" {...getRootProps()}>
      <input {...getInputProps()} />
      <IconButton onClick={open} size={isiPadOrMobile ? 'medium' : 'large'}>
        <PaperClipIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

export default UploadImageBtn;
