import React, { lazy, Suspense } from 'react';

import Box from '@mui/material/Box';

import { useAppSelector } from 'store/hooks';

import { getMeAgencySelector } from 'store/selectors/getAgencySelector';

import Loader from 'components/Loader/Loader';

const Profile = lazy(() => import('../../AgencyCreationFlow/Profile'))

const AgencyProfile = () => {
  const agency = useAppSelector(getMeAgencySelector);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <Suspense fallback={<Loader />}>
        <Profile key={agency?.id} agency={agency} isNotSignUpFlow={true} />
      </Suspense>
    </Box>
  );
}

export default AgencyProfile;
