/**
 * Refactor by 12.23
 */
import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

import getOr from "lodash/fp/getOr";
import flowRight from "lodash/fp/flowRight";
import compact from "lodash/fp/compact";
import map from "lodash/fp/map";

import { UserState } from "../reducers/user";

import { CustomerByIdState, getCustomers } from "./getCustomerSelector";
import {LIMITS} from "../../models/consts";
import {IAgencyUser} from "../types/agency";

const agencyState = (state: DefaultRootState) => state.agency
const userState = (state: DefaultRootState) => state.user
const usersById = (state: DefaultRootState) => state.agency.usersById
const AgencyCustomerByIdState = (state: DefaultRootState) => state.agency.customerOrders

const getUsers = (usersById: Record<string, any>) =>
  flowRight(
    compact,
    map((id: number) => id && usersById[id]),
  );

export const getMeAgencySelector = createSelector(
  userState,
  ({ agency }: UserState) => agency
);

export const getAgencyByIdSelector = (key: number | string = -1) => createSelector(
  agencyState,
  getOr(undefined)(['agencyById', key])
)
export const matchedAgencySelector = (key: string | number) => createSelector(
  agencyState,
  getOr(undefined)(['matchedAgency', key])
);

/**
 *  Get Product And Experience
 */
export const getProductAndExperienceSelector = createSelector(
  agencyState,
  getOr(undefined)(['agency', 'allProductAndExperience'])
);
/**
 *  Get Customer List
 */
const selectCustomerIdsByAgency = (key: string) => createSelector(
  AgencyCustomerByIdState,
  getOr([])([key, 'ids'])
);
export const getBasicCustomerListByAgencySelector = (key: string, type?: string) => createSelector(
  selectCustomerIdsByAgency(key),
  CustomerByIdState,
  (ids, orderById) => getCustomers(orderById, type)(ids)
);
export const loadingAgencyCustomerSelector = (key: string) => createSelector(
  agencyState,
  getOr(false)(['customerOrders', key, 'loading'])
);

export const loadedAgencyCustomerSelector = (key: string) => createSelector(
  agencyState,
  getOr(false)(['customerOrders', key, 'loaded'])
);

/**
 *  Get Users List
 */
export const selectUsersIds = createSelector(
  agencyState,
  (state) => state.users[state.usersFilter]?.ids
);
export const getAgencyUsersByPageSelector = (page: number) => createSelector(
  selectUsersIds,
  usersById,
  (ids, userById) => getUsers(userById)(ids?.slice((page - 1) * LIMITS.team, page * LIMITS.team)) as IAgencyUser[]
);
export const getAgencyUsersSelector = createSelector(
  selectUsersIds,
  usersById,
  (ids, userById) => getUsers(userById)(ids) as IAgencyUser[]
);
export const loadingAgencyUsersSelector = createSelector(
  agencyState,
  (state) => getOr(false)(['users', state.usersFilter, 'loading'])(state as any) //state.users[state.usersFilter]?.loading
);
export const hasMoreAgencyUsersSelector = createSelector(
  agencyState,
  (state) => getOr(true)(['users', state.usersFilter, 'hasMore'])(state as any)
);
export const totalAgencyUsersSelector = createSelector(
  agencyState,
  (state) => state.users[state.usersFilter]?.countAll || 0
);

/** 
 *  Agency List for lead managers
*/
export const getAllAgenciesSelector = createSelector(agencyState, state => state.allAgencies);

export const getActiveAgenciesSelector = (leadSourceId: number) => createSelector(
  getAllAgenciesSelector,
  (agencies) => agencies.filter(agency => agency.leadSources?.some(leadSource => leadSource.id === leadSourceId)));