import React, { FC, useMemo } from 'react';

import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flowRight from 'lodash/fp/flowRight';
import keyBy from 'lodash/fp/keyBy';
import mapValues from 'lodash/fp/mapValues';
import take from 'lodash/fp/take';
import values from 'lodash/fp/values';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import ArrowDown from 'styles/icons/ArrowDown';
import CameraIcon from 'styles/icons/CameraIcon';
import { CertifiedIcon } from 'styles/icons/CertifiedIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  StyledBoxCompany,
  StyledDuration,
  StyledDurationMobile,
  StyledBoxSeniority,
} from './styled';

import { calculateExperiencesDuration } from 'utils/transformers';

import { IConsultantExperience } from 'store/types/experiences';
import { ISeniorityDurationLevel } from 'store/types/common';
import { ICompany } from 'store/types/company';
import { IProduct } from 'store/types/categoryProducts';

import { SeniorityDurationLevel } from 'models/consts';

import Avatar from '../../Avatar';
import ModulesSettings from '../ModulesSettings';
import ExperiencesView from '../ExperiencesView';

interface IExperiencesPanel {
  expanded: boolean;
  experiences: IConsultantExperience[];
  expertise: any;
  handleChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  productName: string;
  isCertified?: boolean;
  product: IProduct;
}

const transformCompaniesData = flowRight(
  take(2),
  values,
  mapValues('company'),
  keyBy('companyId'),
  filter(({ company }: { company: any }) => company.logo),
);

const ExperiencesPanel: FC<IExperiencesPanel> = ({
  expanded,
  experiences,
  expertise,
  handleChange,
  productName,
  isCertified,
  product,
}) => {
  const { duration, durationFormatted } = useMemo(
    () => calculateExperiencesDuration(experiences),
    [experiences],
  );

  const seniority = find((level: ISeniorityDurationLevel) => level.value <= duration)(
    SeniorityDurationLevel,
  );

  const companies = useMemo(() => {
    return transformCompaniesData(experiences);
  }, [experiences]);

  return (
    <Accordion elevation={0} expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ArrowDown />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{ flexGrow: 1 }}
      >
        <Box sx={{ position: 'relative' }}>
          <Avatar
            firstName={productName}
            image={product?.stripeProducts && product.stripeProducts[0]?.stripeProductImageUrl}
            size="small"
            icon={<CameraIcon />}
            round
          />
          {isCertified && (
            <Box
              sx={{
                position: 'absolute',
                bottom: '-13px',
                right: 0,
              }}
            >
              <CertifiedIcon />
            </Box>
          )}
        </Box>
        <Typography
          sx={{
            width: { xs: 'auto', lg: '33%' },
            flexShrink: 0,
            ml: 2,
            height: { xs: '50px', lg: 'auto' },
          }}
          variant="h6"
        >
          {productName}
          {!expanded && (
            <StyledDurationMobile gutterBottom variant="body2">
              {durationFormatted}
            </StyledDurationMobile>
          )}
        </Typography>
        <Box sx={{ mr: 1, width: 144, display: { xs: 'none', lg: 'block' } }}>
          {seniority?.label && (
            <Chip
              className={seniority?.label}
              label={<Typography variant="subtitle1">{seniority?.label}</Typography>}
            />
          )}
        </Box>
        {!expanded && (
          <StyledBoxCompany>
            <StyledDuration variant="body2">{durationFormatted}</StyledDuration>
            <Box display="flex">
              {companies.map((company: ICompany) => (
                <Box key={company.name} sx={{ mx: 1 }}>
                  <Avatar
                    firstName={company.name}
                    lastName=""
                    image={company.logo}
                    size="xs-small"
                    icon={<CameraIcon />}
                    round
                  />
                </Box>
              ))}
            </Box>
            {seniority?.label && (
              <StyledBoxSeniority>
                <Chip
                  className={seniority?.label}
                  label={<Typography variant="subtitle1">{seniority?.label}</Typography>}
                />
              </StyledBoxSeniority>
            )}
          </StyledBoxCompany>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <ModulesSettings value={expertise || null} />
        <Box display="flex">
          <Typography variant="subtitle1" sx={{ width: 240 }}>
            Experience
          </Typography>
          <Typography>{durationFormatted}</Typography>
        </Box>
        <ExperiencesView experiences={experiences} />
      </AccordionDetails>
    </Accordion>
  );
};

export default ExperiencesPanel;
