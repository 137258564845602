import { Components } from '@mui/material/styles';

const MuiBottomNavigation: Components['MuiBottomNavigation'] = {
  styleOverrides: {
    root: ({ theme }: any) => theme.unstable_sx({
      border: 'none',
      boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
      height: 64
    })
  },
}

export default MuiBottomNavigation
