import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';

import { EConsultant } from 'store/constants';
import { ICertificateDto, ICertificateItem } from "../../types/certificates";

interface ICertificateUpdating {
  key: string;
  certificate: ICertificateDto
}

const updateConsultantCertificate = createAsyncThunk<ICertificateItem, ICertificateUpdating>(
  EConsultant.updateCertificate,
  async ({ certificate }) => {
    const { data } = await instance.patch('consultant/certificates', certificate);
    return data;
  }
);

export default updateConsultantCertificate;
