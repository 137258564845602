import { createAsyncThunk } from '@reduxjs/toolkit';
import { EUser } from 'store/constants';

import { Cookies } from "react-cookie";
import { AxiosResponse } from "axios";

import instance from 'store/middlewares/api';

import {ELocalStoreKeys} from "../../../models/consts";

import fetchMe from "../user/fetchMe";

const cookies = new Cookies(null, { path: '/', sameSite: 'lax' })

const impersonateUser = createAsyncThunk<AxiosResponse, number>(
  EUser.impersonateUser,
  async (id, thunkAPI) => {
    const { data } = await instance.get(`/admin/auth/${id}/access-token`);

    cookies.set(ELocalStoreKeys.IMPERSONATE_TOKEN, data.accessToken);
    cookies.set(ELocalStoreKeys.IMPERSONATE_REFRESH_TOKEN, data.refreshToken);
    cookies.set(ELocalStoreKeys.GLOBAL_IMPERSONATION, 'true');

    await thunkAPI.dispatch(fetchMe());
    return data
  }
);

export default impersonateUser;