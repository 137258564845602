import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { StyledRoot } from './styled'
import { IStepperItem } from "store/types/common";

import Step from "./Step";

interface IStepper<T> {
  steps: Array<IStepperItem<T>>;
}

const Stepper: FunctionComponent<IStepper<any>> = ({ steps }) => {
  const location = useLocation();

  return (
    <StyledRoot>
      {(steps.map((props: any) =>
          <Step key={props.number} {...props} />
        ))
      }
    </StyledRoot>
  )
};

export default Stepper;
