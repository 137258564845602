import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EConsultant } from 'store/constants';

interface ICertificateDelete {
  key: number | string;
  ids: number[]
}

const deleteConsultantCertificate = createAsyncThunk<number[], ICertificateDelete>(
  EConsultant.deleteCertificate,
  async ({ ids }) => {
    await instance.delete('consultant/certificates', { data: { ids } });
    return ids;
  }
);

export default deleteConsultantCertificate;
