import React from 'react';

import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

export default (props: SvgIconProps) => (
  <SvgIcon {...props} sx={{fontSize: '1.25rem'}} fill="none">
    <mask id="mask0_90_16775" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="currentColor"/>
    </mask>
    <g mask="url(#mask0_90_16775)">
      <path
        d="M16.1 13.3L14.65 11.85C14.8 11.0667 14.575 10.3334 13.975 9.65005C13.375 8.96671 12.6 8.70005 11.65 8.85005L10.2 7.40005C10.4833 7.26672 10.7707 7.16672 11.062 7.10005C11.354 7.03338 11.6667 7.00005 12 7.00005C13.25 7.00005 14.3127 7.43738 15.188 8.31205C16.0627 9.18738 16.5 10.25 16.5 11.5C16.5 11.8334 16.4667 12.146 16.4 12.438C16.3333 12.7294 16.2333 13.0167 16.1 13.3ZM19.3 16.45L17.85 15.05C18.4833 14.5667 19.046 14.0374 19.538 13.462C20.0293 12.8874 20.45 12.2334 20.8 11.5C19.9667 9.81672 18.7707 8.47905 17.212 7.48705C15.654 6.49572 13.9167 6.00005 12 6.00005C11.5167 6.00005 11.0417 6.03338 10.575 6.10005C10.1083 6.16672 9.65 6.26672 9.2 6.40005L7.65 4.85005C8.33333 4.56672 9.03333 4.35405 9.75 4.21205C10.4667 4.07072 11.2167 4.00005 12 4.00005C14.5167 4.00005 16.7583 4.69572 18.725 6.08705C20.6917 7.47905 22.1167 9.28338 23 11.5C22.6167 12.4834 22.1127 13.396 21.488 14.238C20.8627 15.0794 20.1333 15.8167 19.3 16.45ZM19.8 22.6L15.6 18.45C15.0167 18.6334 14.4293 18.7711 13.838 18.8631C13.246 18.9544 12.6333 19 12 19C9.48333 19 7.24167 18.3044 5.275 16.913C3.30833 15.521 1.88333 13.7167 1 11.5C1.35 10.6167 1.79167 9.79571 2.325 9.03705C2.85833 8.27905 3.46667 7.60005 4.15 7.00005L1.4 4.20005L2.8 2.80005L21.2 21.2001L19.8 22.6ZM5.55 8.40005C5.06667 8.83338 4.625 9.30838 4.225 9.82505C3.825 10.3417 3.48333 10.9 3.2 11.5C4.03333 13.1834 5.229 14.5207 6.787 15.512C8.34567 16.504 10.0833 17 12 17C12.3333 17 12.6583 16.9794 12.975 16.938C13.2917 16.896 13.6167 16.85 13.95 16.8L13.05 15.85C12.8667 15.9 12.6917 15.9374 12.525 15.962C12.3583 15.9874 12.1833 16 12 16C10.75 16 9.68733 15.5627 8.812 14.688C7.93733 13.8127 7.5 12.75 7.5 11.5C7.5 11.3167 7.51233 11.1417 7.537 10.975C7.56233 10.8084 7.6 10.6334 7.65 10.45L5.55 8.40005Z"
        fill="currentColor"/>
    </g>
  </SvgIcon>
);
