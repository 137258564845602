// eslint-disable-next-line
// @ts-nocheck
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const isDevelopment = process.env.NODE_ENV === 'development';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
  preloadedState: (typeof window !== 'undefined' && window.__PRELOADED_STATE__) || {},
  devTools: isDevelopment ? composeWithDevTools() : undefined,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
