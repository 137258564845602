import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EConsultant } from 'store/constants';

const downloadAnyConsultantResume = createAsyncThunk(
  EConsultant.downloadAnyConsultantResume,
  async (id: number) => {
    const { data } = await instance.get(`admin/consultants/cv-file/${id}`);
    return data;
  }
)

export default downloadAnyConsultantResume;
