import { Components } from '@mui/material/styles';

const MuiAutocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    input: ({ theme }: any) => theme.unstable_sx({
      border: 'none',
      minHeight: 38,
      minWidth: '10px !important'
    }),
    inputRoot: {
      backgroundColor: '#F5F5F5',
      padding: '4px 2px',
    },
    option:  ({ theme }: any) => theme.unstable_sx({
      fontSize: '1rem',
      justifyContent: 'space-between !important',
      [`${theme.breakpoints.down('md')}`]: {
        '&:hover': {
          backgroundColor: 'white',
        }
      },
      [`${theme.breakpoints.up('md')}`]: {
        '&:hover': {
          backgroundColor: '#171717 !important',
          color: 'common.white'
        }
      },
      '&[aria-selected="true"]': {
        backgroundColor: '#171717 !important',
        color: 'common.white'
      }
    }),
  }
}

export default MuiAutocomplete
