import { createAsyncThunk} from '@reduxjs/toolkit';
import { AxiosResponse } from "axios";

import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

interface IDeclineOrderComplete {
  order: {
    ids: number[]
  };
  notification: {
    ids: number[]
  };
}

const declineOrderComplete = createAsyncThunk<AxiosResponse, IDeclineOrderComplete>(
  EOrder.declineOrderComplete,
  async (payload) => {
    const { data } = await instance.patch('orders/decline-complete', payload.order);

    return data;
  }
)

export default declineOrderComplete;
