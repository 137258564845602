import { createAsyncThunk } from '@reduxjs/toolkit';
import { EUser } from 'store/constants';
import {IBasicUser} from "../../types/user";

export const updateUserRole = createAsyncThunk<IBasicUser, any>(
  EUser.updateRole,
  async (payload: any) => {
    return new Promise<IBasicUser>((resolve) => {
      resolve(payload);
    });
  }
)