import React, { FC } from 'react';
import { Box, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { getLevelByLevelId } from 'utils/getLevelByLevelId';
import CloseIcon from 'styles/icons/CloseIcon';

import { useAppSelector } from 'store/hooks';
import {
  modulesByProductSelector,
  productByIdSelector,
  workTypesByProductSelector,
} from 'store/selectors/getCommonSelector';

interface AvailableFiltersProps {
  filter: {
    productId?: number;
    moduleIds?: number[];
    workIds?: number[];
    levelId?: number;
    availabilityWeek?: number;
    startDateOrder?: string;
    endDateOrder?: string;
    [key: string]: any;
  };
  onFilterRemove?: (key: string, value?: any) => void;
}

const AvailableFilters: FC<AvailableFiltersProps> = ({ filter, onFilterRemove }) => {
  const theme = useTheme();
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'xxl'));

  const product = useAppSelector(productByIdSelector(filter?.productId || 1));
  const modulesListByProduct = useAppSelector(modulesByProductSelector(filter?.productId || 1));
  const worksListByProduct = useAppSelector(workTypesByProductSelector(filter?.productId || 1));

  const handleDelete = (key: string, value?: any) => {
    if (onFilterRemove) {
      onFilterRemove(key, value);
    }
  };

  const getLabelSource = (label: string) => {
    switch (label) {
      case 'moduleIds':
        return modulesListByProduct;
      case 'workIds':
        return worksListByProduct;
      case 'langIds':
        return filter?.langIds;
      case 'tagIds':
        return filter?.tagIds;
      case 'works':
        return filter?.works;
      case 'modules':
        return filter?.modules;
      default:
        return null;
    }
  };

  const renderChipsForArray = (label: string, array: any[] | undefined) =>
    array?.map((id, index) => {
      const labelSource = getLabelSource(label);
      const labelName = labelSource?.find((item: any) => item?.id === (id?.id || id))?.name;
      return labelName ? (
        <Chip
          key={`${label}-${index}`}
          label={labelName}
          color="default"
          sx={{
            fontSize: isiPadOrMobile ? '10px' : '12px',
            fontWeight: 500,
            mr: 0.5,
            mb: 1,
            width: 'auto',
            flexDirection: 'row-reverse',
            paddingRight: 1,
          }}
          variant="outlined"
          size="small"
          icon={
            <CloseIcon height={10} width={10} onClick={() => handleDelete(label, array[index])} />
          }
        />
      ) : null;
    }) || null;

  const renderSingleValueChip = (key: string, value: any) => {
    let label = '';

    switch (key) {
      case 'productId':
        label = product?.name || '';
        break;
      case 'levelId':
        label = getLevelByLevelId(value)?.name || '';
        break;
      case 'availabilityWeek':
        label = `${value}h /Week`;
        break;
      case 'startDateOrder':
        label = `Start: ${value}`;
        break;
      case 'endDateOrder':
        label = `End: ${value}`;
        break;
      case 'weeklyHours':
        label = `${value} \\ Week`;
        break;
      case 'searchStartDate':
        label = `Start: ${value}`;
        break;
      case 'searchEndDate':
        label = `End: ${value}`;
        break;
      default:
        label = typeof value === 'string' || typeof value === 'number' ? `${value}` : '';
    }

    return label && key !== 'heroSearch' ? (
      <Chip
        key={`chip-${key}`}
        label={label}
        color="default"
        sx={{
          fontSize: isiPadOrMobile ? '10px' : '12px',
          fontWeight: 500,
          mr: 0.5,
          mb: 1,
          width: 'auto',
          flexDirection: 'row-reverse',
          paddingRight: 1,
        }}
        variant="outlined"
        size="small"
        icon={<CloseIcon height={10} width={10} onClick={() => handleDelete(key)} />}
      />
    ) : null;
  };

  return (
    <Box>
      <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {Object.entries(filter).map(([key, value]) => {
          if (
            Array.isArray(value) &&
            ['moduleIds', 'workIds', 'langIds', 'tagIds', 'works', 'modules'].includes(key)
          ) {
            return renderChipsForArray(key, value);
          }
          return renderSingleValueChip(key, value);
        })}
      </Box>
    </Box>
  );
};

export default AvailableFilters;
