import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';

import { EOrder } from '../../constants';

const fetchPurchaseOrderArchive = createAsyncThunk(
  EOrder.getPurchaseOrderArchive,
  async (id: number) => {
    return await instance.get(`enterprise/purchase-orders/${id}/private-files/download`, {
      responseType: 'arraybuffer',
    });
  },
);

export default fetchPurchaseOrderArchive;
