import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EEnterprise } from 'store/constants/enterprise';

import { IInvoice } from '../../types/enterprise';

interface IResponsePOEnterpriseInvoices {
  count: number;
  page: number;
  purchaseOrderInvoices: IInvoice[];
  perPage: number;
}

const getInvoices = createAsyncThunk<IResponsePOEnterpriseInvoices, any>(
  EEnterprise.getInvoices,
  async ({ id, key, ...params }) => {
    const { data } = await instance.post(
      `/enterprise/purchase-orders/${id}/purchase-order-invoice/filter`,
      params,
    );
    return data;
  },
);

export default getInvoices;
