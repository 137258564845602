import { Components } from '@mui/material/styles';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiary: true;
  }
}
const MuiIconButton: Components['MuiIconButton'] = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      height: 48,
      width: 48,
      padding: 6,
      color: '#1F1F1F',
      transition: theme.transitions.create('color', {
        duration: theme.transitions.duration.standard,
      }),
      ':hover': {
        backgroundColor: 'transparent',
        color: '#FFCA28',
      },
    }),
    colorSecondary: {
      color: 'white',
    },
    sizeLarge: {
      width: 56,
      height: 56,
    },
    sizeSmall: {
      width: 30,
      height: 30,
    },
  },
  variants: [
    {
      props: {
        color: 'tertiary',
      },
      style: {
        backgroundColor: '#F5F5F5',
        '&:hover': {
          backgroundColor: '#F5F5F5',
        },
      },
    },
  ],
};

export default MuiIconButton;
