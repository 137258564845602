import { styled } from "@mui/material/styles";
import MuiStack from "@mui/material/Stack";

const StackItem = styled(MuiStack)(({ theme }) => theme.unstable_sx({
  p: {
    xs: 2,
    sm: 3
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#FBFBFB',
  },
}))
export default StackItem
