import { PaletteOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
    status: CustomPaletteOptions;
  }

  interface CustomPaletteOptions {
    progress: CustomPaletteColorOptions;
    canceled: CustomPaletteColorOptions;
    completed: CustomPaletteColorOptions;
    created: CustomPaletteColorOptions;
    drafts: CustomPaletteColorOptions;
    onHold: CustomPaletteColorOptions;
  }
  interface CustomPaletteColorOptions {
    light: string;
    dark: string;
    main: string;
  }
  interface PaletteOptions {
    status: CustomPaletteOptions;
    tertiary: PaletteOptions['primary'];
  }
}

const palette: PaletteOptions = {
  mode: 'light',
  error: {
    main: '#DB204D',
  },
  primary: {
    dark: '#FFA000',
    light: '#FFC107',
    main: '#FFCA28',
    contrastText: '#1F1F1F',
  },
  secondary: {
    dark: '#171717',
    light: '#5D5D5D',
    main: '#171717',
    contrastText: '#fff',
  },
  tertiary: {
    light: '#F9F9F9',
    main: '#F5F5F5',
    dark: '#F5F5F5',
    contrastText: '#1F1F1F',
  },
  text: {
    primary: '#1F1F1F',
    secondary: '#5D5D5E',
  },
  status: {
    progress: {
      light: '#C0FFC7',
      main: '#76E384',
      dark: '#669F6D',
    },
    completed: {
      light: '#D8E3FD',
      main: '#6A87CB',
      dark: '#6788FF',
    },
    canceled: {
      light: '#FFCED6',
      main: '#EC6D83',
      dark: '#EC6D83',
    },
    drafts: {
      light: '#E7E7E7',
      main: '#C1C1C1',
      dark: '#5D5D5E',
    },
    created: {
      light: '#FFF1C0',
      main: '#F5D462',
      dark: '#D9B32D',
    },
    onHold: {
      light: '#D4D4D4',
      main: '#1F1F1F',
      dark: '#1F1F1F',
    },
  },
};

export default palette;
