import React, { useMemo } from 'react';
import { calcWeeks } from 'utils/dateFormatter';

type TotalWeeks = {
  startDate?: string,
  endDate?: string,
};
const useTotalWeeks = ({ startDate, endDate }: TotalWeeks) => {
  return useMemo(() => {
    if (endDate && startDate) {
      return calcWeeks(endDate, startDate);
    }
    return undefined
  }, [endDate, startDate]);
}

export default useTotalWeeks;
