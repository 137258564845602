import { createSlice } from "@reduxjs/toolkit";

import find from 'lodash/fp/find'

import { LIMITS } from "models/consts";
import { IFeedback } from "../../models";

import {IConsultantCalendar, IConsultantCapacity} from "../types/consultant";
import fetchConsultantReview from "../query/feedbacks/fetchConsultantReview";
import getCapacities from "../query/consultant/getAvailabilities";
import getCalendar from "../query/consultant/getCalendar";
import updateCapacity from "../query/consultant/updateCapacity";
import {IDynamicKey} from "../types/common";

interface IDynamicNumberKey<T> {
  [key: number]: T
}

export interface ConsultantOldState {
  calendar: IDynamicKey<IConsultantCalendar[]>,
  capacities: IDynamicKey<IConsultantCapacity[]>,

  loadedExperience: boolean;

  feedbacks: IDynamicNumberKey<IFeedback[]>;
  loadedFeedbacks: boolean;
  hasMoreFeedbacks?: boolean;
}

const initialState: ConsultantOldState = {
  calendar: {},
  capacities: {},
  loadedExperience: false,
  loadedFeedbacks: false,
  feedbacks: {},
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { reducer} = createSlice({
  name: 'consultantOld',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder

    // Get Calendar
    .addCase(getCalendar.pending, (state, action ) => {
      const { meta } = action

      state.calendar = {
        [[meta.arg.startDate, meta.arg.endDate].join('/')]: []
      }
    })
    .addCase(getCalendar.fulfilled, (state, action ) => {
      const { meta, payload } = action
      state.calendar[[meta.arg.startDate, meta.arg.endDate].join('/')] = payload
    })

    // Get Capacities
    .addCase(getCapacities.pending, (state, action ) => {
      const { meta } = action

      state.capacities = {
        [[meta.arg.startDate, meta.arg.endDate].join('/')]: []
      }
    })
    .addCase(getCapacities.fulfilled, (state, action) => {
      const { meta, payload } = action
      state.capacities[[meta.arg.startDate, meta.arg.endDate].join('/')] = payload
    })

    .addCase(fetchConsultantReview.fulfilled, (state, { payload }) => {
      const { consultantId, data, offset } = payload
      if (offset) {
        state.feedbacks[consultantId] = state.feedbacks && state.feedbacks[consultantId]
          ? [...state.feedbacks[consultantId], ...data]
          : [...data];
      } else {
        state.feedbacks = data.length ? {[consultantId]: [...data]} : [];
      }
      state.loadedFeedbacks = true;
      state.hasMoreFeedbacks = data.length && data.length >= LIMITS.feedback;
    })

    /**
     *  Capacity Consultant
     */
    .addCase(updateCapacity.fulfilled, (state, action) => {
      const { meta, payload } = action

      state.capacities[meta.arg.keySelector] = state.capacities[meta.arg.keySelector].map(item => {
        return find({ startWeekDate: item.startWeekDate })(payload) || item;
      }) as IConsultantCapacity[]
    })

});

export default reducer
