import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

// @ts-ignore
interface IStyledH1 extends TypographyProps {
  isModal?: boolean;
  component?: string;
}
const StyledH1 = styled(Typography)<IStyledH1>(({ isModal, theme }) =>
  theme.unstable_sx({
    fontSize: {
      xs: 22,
      sm: '1.875rem',
      lg: (isModal && 30) || 46,
    },
    mb: {
      xs: 2.5,
      lg: 4,
    },
  }),
);

const StyledH1Mobile = styled(Typography)<IStyledH1>(({ isModal, theme }) =>
  theme.unstable_sx({
    display: {
      md: (isModal && 'block') || 'none',
    },
    mt: 3,
    textAlign: (isModal && 'center') || undefined,
  }),
);

const StyledActionGroup = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'center',
      marginBottom: 4,
    },
  }),
);

const StyledRoot = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  }),
);

const StyledGrid = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
  }),
);

const StyledForm = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    padding: '15px',
    marginRight: '20px',
    marginBottom: '20px',
    marginLeft: '-15px',
    borderRadius: '10px',
    [theme.breakpoints.down('lg')]: {
      margin: '0px',
    },
  }),
);

export { StyledActionGroup, StyledGrid, StyledRoot, StyledH1, StyledH1Mobile, StyledForm };
