import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';
import { createStore } from 'little-state-machine';
import { StateMachineProvider } from 'little-state-machine';
import Hotjar from '@hotjar/browser';

import TagManager from 'react-gtm-module';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';

import VisueltProRegular from 'styles/font/VisueltPro-Regular.ttf';
import VisueltProMedium from 'styles/font/VisueltPro-Medium.ttf';
import VisueltProBold from 'styles/font/VisueltPro-Bold.ttf';

import { ELocalStoreKeys } from './models/consts';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';

import { useAppSelector, useAppDispatch } from './store/hooks';
import { getMeSelector } from './store/selectors/getUserSelector';

import CustomToastContainer from './components/Toast/CustomToastContainer';

import { isProd } from './helpers/environmentDetector';
import ROUTES from 'router/constants';
import useMatchMultiple from './hooks/useMatchUrls';

import SplashScreenAnimation from 'views/Splash';
import useSafeAreaView from 'components/SafeAreaView';
import { MOBILE_SPLASH_DURATION, MOBILE_SPLASH_EXPIRATION } from 'constants/mobile-constants';

import { setDeviceInfo, setIsOnline } from 'store/reducers/mobile';

import updateUserNotificationKey from 'store/query/mobile/updateUserNotificationKey';
import config from './app.config';

createStore({
  businessType: undefined,
  experiences: null,
  expertise: {
    primaryModule: 0,
    modules: [],
    works: [],
    modulesWorks: [],
    primaryWorkType: 0,
    purchaseOrder: null,
  },
  timeLine: {
    allHours: 0,
    availabilityWeek: undefined,
    endDate: '',
    flexibleHours: false,
    startDate: '',
  },
  levelId: 0,
  orderPrice: 0,
  searchParams: {},
  worker: {},
});

const globalStyles = (
  <GlobalStyles
    styles={`
      @font-face {
        font-family: 'Visuelt Pro';
        src: url('${VisueltProRegular}')format('truetype');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'Visuelt Pro';
        src: url('${VisueltProMedium}')format('truetype');
        font-weight: 500;
        font-style: normal;
      }
      @font-face {
        font-family: 'Visuelt Pro';
        src: url('${VisueltProBold}')format('truetype');
        font-weight: 700;
        font-style: normal;
      }
    `}
  />
);

const siteId = parseInt(config.HOTJAR_ID);
const hotjarVersion = 6;

const App = () => {
  const [cookies, setCookies] = useCookies([
    ELocalStoreKeys.ACCESS_TOKEN,
    ELocalStoreKeys.MOBILE_SPLASH,
  ]);

  const navigate = useNavigate();
  const match = useMatchMultiple([
    `${ROUTES.AUTH}/*`,
    `${ROUTES.ORDER_CREATION}/*`,
    `${ROUTES.CONSULTANT}/*`,
    `${ROUTES.SHARABLE_CONSULTANT}/*`,
    `${ROUTES.PAYMENT}/*`,
    ROUTES.TERMS_OF_USE,
    ROUTES.PRIVACY_POLICY,
    ROUTES.EMAIL_UNSUBSCRIBE,
    `${ROUTES.CONSULTANT_VALIDATE_AVAILABILITY}`,
  ]);
  const isScrollDisabled = useMatchMultiple([
    `${ROUTES.TERMS_OF_USE}`,
    `${ROUTES.PRIVACY_POLICY}`,
    `${ROUTES.CUSTOMER_USER_AGREEMENT}`,
  ]);

  const isReloadEnabled = useMatchMultiple([`${ROUTES.CHAT}`]);

  const me = useAppSelector(getMeSelector);

  const dispatch = useAppDispatch();

  const location = useLocation();

  const isMobileNative = Capacitor.isNativePlatform();

  const [currentView, setCurrentView] = useState<'splash' | 'content' | 'unset'>(
    isMobileNative ? 'unset' : 'content',
  );

  const { safeAreaPadding } = useSafeAreaView();

  //Capacitor mobile entry point
  useEffect(() => {
    if (siteId) {
      Hotjar.init(siteId, hotjarVersion);
    }

    if (isMobileNative && currentView === 'content') {
      const handleKeyboardDidShow = () => {
        const activeElement = document.activeElement;
        if (activeElement) {
          activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      };

      Keyboard.setAccessoryBarVisible({ isVisible: false });
      Keyboard.setScroll({ isDisabled: false });

      Keyboard.addListener('keyboardDidShow', handleKeyboardDidShow);

      Device.getInfo()
        .then(info => {
          const deviceInfoStr = info.model + ' ' + info.operatingSystem + ' ' + info.osVersion;

          console.warn('Got Device Info: ', info);

          dispatch(setDeviceInfo(deviceInfoStr));
        })
        .catch(error => {
          console.error('Device info error', error);
          dispatch(setDeviceInfo(error.message));
        });

      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          PushNotifications.register();
        }
      });

      Network.addListener('networkStatusChange', status => {
        dispatch(setIsOnline(status.connected));
      });

      PushNotifications.addListener('registration', token => {
        console.warn('Firebase push notifications registration success, token: ' + token.value);

        dispatch(updateUserNotificationKey(token.value));
      });

      PushNotifications.addListener('registrationError', error => {
        console.error('Error on registration: ' + JSON.stringify(error));
        //TODO: Send this to crashlytics
      });

      PushNotifications.addListener('pushNotificationReceived', notification => {
        console.warn('Push received: ', notification);
        //TODO: Handle push notification
      });
    }

    return () => {
      if (isMobileNative) {
        Keyboard.removeAllListeners();
        PushNotifications.removeAllListeners();
        Network.removeAllListeners();
      }
    };
  }, [isMobileNative, dispatch, isReloadEnabled, currentView]);

  useEffect(() => {
    if (location.pathname === ROUTES.LOGIN_JWT) {
      return;
    }

    if (isProd() && me) {
      TagManager.dataLayer({
        dataLayer: {
          user: me,
        },
      });
    }

    if (!me && !cookies.accessToken && !match) {
      navigate(ROUTES.AUTH);
    }
  }, [location.pathname, me, cookies.accessToken, match, navigate]);

  useEffect(() => {
    const initialize = async () => {
      if (!cookies[ELocalStoreKeys.MOBILE_SPLASH]) {
        setTimeout(() => {
          setCurrentView('content');

          setCookies(ELocalStoreKeys.MOBILE_SPLASH, 'true', {
            expires: new Date(Date.now() + MOBILE_SPLASH_EXPIRATION),
          });
        }, MOBILE_SPLASH_DURATION);

        setCurrentView('splash');
      } else {
        setCurrentView('content');
      }
    };

    isMobileNative && initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StateMachineProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          paddingTop: safeAreaPadding.top,
          backgroundColor:
            location.pathname.includes('/payment/') && isMobileNative
              ? 'primary.main'
              : isMobileNative && (currentView === 'splash' || currentView === 'unset')
                ? 'black'
                : 'white',
          overflowY: isScrollDisabled ? 'hidden' : undefined,
        }}
      >
        {currentView === 'content' && (
          <>
            <Helmet defaultTitle="Workerbee" />
            <CssBaseline />
            {globalStyles}
            <Outlet />
            <CustomToastContainer />
          </>
        )}
        {currentView === 'splash' && <SplashScreenAnimation />}
      </Box>
    </StateMachineProvider>
  );
};

App.whyDidYouRender = true;

export default App;
