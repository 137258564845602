import React from 'react';

import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

export default (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M18 6L6 18" stroke="CURRENTcOLOR" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 6L18 18" stroke="CURRENTcOLOR" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>
);
