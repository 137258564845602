import React, { FunctionComponent } from 'react';
import {Controller, FormProvider, SubmitHandler, useForm} from "react-hook-form";

import filter from 'lodash/fp/filter'
import flatMap from 'lodash/fp/flatMap'
import flowRight from 'lodash/fp/flowRight'
import map from 'lodash/fp/map'
import dayjs from "../../../utils/dayjs";

import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Unstable_Grid2";

import { StyledInputLabel } from "../../CustomFields/styled";

import {useAppDispatch, useAppSelector} from "store/hooks";
import addOutDates from "store/query/consultant/addOutDates";
import deleteOutDates from "store/query/consultant/deleteOutDates";
import updateCapacity from "store/query/consultant/updateCapacity";

import {capacitiesSelector} from "store/selectors/getConsultantSelector";

import RangeDate from "../../CustomFields/RangeDate";
import ModalWrapper from "../Modal";
import {Select} from "../../CustomFields";
import {splitDateRangeByWeeks} from "../../CalendarComponents/utils";

interface IChangeAvailabilityProps {
  availability: number;
  keySelector: string;
  onClose: () => void;
  open: boolean;
  startWeekDate: string;
}

interface IForm {
  newAvailability: number;
  startDate: string;
  endDate: string;
}

const getOutDates = flowRight(
  map('outDateId'),
  filter({typeBlock: 'out_date_block'}),
  flatMap((el: any) => el?.details)
)

const ChangeAvailability: FunctionComponent<IChangeAvailabilityProps> = ({ availability, keySelector, open, onClose, startWeekDate}) => {
  const dispatch = useAppDispatch();
  const capacities = useAppSelector(capacitiesSelector(keySelector));

  const methods = useForm<IForm>({
    mode: 'onChange',
    defaultValues: {
      newAvailability: availability,
      startDate: dayjs(startWeekDate).add(1, 'day').format('YYYY-MM-DD'),
      endDate: dayjs(startWeekDate).add(5, 'day').format('YYYY-MM-DD'),
    }
  });

  const onSubmit: SubmitHandler<IForm> = async ({ endDate, newAvailability, startDate }) => {
    const detailsArray = capacities.filter((capacity) => dayjs(capacity.startWeekDate).isBetween(dayjs(startDate), dayjs(endDate), 'week', '[]') && true)

    if(newAvailability) {
      if(getOutDates(detailsArray).length) {
        await dispatch(deleteOutDates(getOutDates(detailsArray)))
      }
      dispatch(updateCapacity({
        endDate,
        keySelector,
        newAvailability,
        startDate
      }))
        .unwrap()
        .then(onClose)
    } else {
      dispatch(addOutDates({
        outDates: splitDateRangeByWeeks(startDate, endDate),
        keySelector,
        userId: 0 // TEMP
      }))
        .unwrap()
        .then(onClose)

    }
  }

  return (
    <ModalWrapper
      dialogProps={{
        open,
        onClose
      }}
      title={{title: 'Edit Availability' }}
    >
      <DialogContent>
        <FormProvider {...methods} >
          <form id={`availability-form-${startWeekDate}`} onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <StyledInputLabel>Date</StyledInputLabel>
              </Grid>
              <RangeDate nameEndDate="endDate" nameStartDate="startDate" />
              <Grid xs={12}>
                <StyledInputLabel>Availability</StyledInputLabel>
                <Controller
                  name="newAvailability"
                  control={methods.control}
                  rules={{ required: 'Select your weekly availability' }}
                  render={({ field: { ref, ...field} }) => (
                    <Select
                      {...field}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      inputRef={ref}
                      label="Select available time for meeting"
                      options={[ 0, 10, 20, 30, 40]}
                    />
                  )}
                />
              </Grid>

            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" form={`availability-form-${startWeekDate}`} fullWidth type="submit" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};

export default ChangeAvailability;
