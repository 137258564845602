import React from 'react';

import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

export default (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 17 16">
    <path
      d="M8.04925 0.588625C8.23078 0.210464 8.76922 0.210465 8.95075 0.588625L11.0119 4.88235C11.0836 5.03167 11.2246 5.1358 11.3884 5.16042L16.021 5.85669C16.4266 5.91766 16.5903 6.41417 16.3005 6.70442L12.9317 10.0784C12.8176 10.1926 12.7658 10.3548 12.7923 10.514L13.5848 15.2654C13.6533 15.6758 13.2198 15.9854 12.8538 15.7875L8.73783 13.5617C8.58943 13.4815 8.41057 13.4815 8.26217 13.5617L4.14621 15.7875C3.78023 15.9854 3.34673 15.6758 3.41518 15.2654L4.2077 10.514C4.23425 10.3548 4.18235 10.1926 4.06834 10.0784L0.699509 6.70442C0.409697 6.41417 0.573405 5.91766 0.979019 5.85669L5.61165 5.16042C5.77544 5.1358 5.91641 5.03167 5.98809 4.88235L8.04925 0.588625Z"
      fill="#FFC107"/>
  </SvgIcon>
);
