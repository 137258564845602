import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EAgencyProducts } from "store/constants/productAndExperience";
import { IAgencyProductAndExperience } from "store/types/agency";

export const submitProductAndExperienceRate = createAsyncThunk(
  EAgencyProducts.SubmitProductAndExperienceRate,
  async (payload: any) => {
    const { data } = await instance.post(`/agency/expertises`, payload)
    return data
  }
)

interface ISubmitProductAndExperienceRateForLeadSourceProps {
  agencyId: number
  leadSourceId: number,
  payload: any
}

export const submitProductAndExperienceRateForLeadSource = createAsyncThunk<any[], ISubmitProductAndExperienceRateForLeadSourceProps>(
  EAgencyProducts.SubmitProductAndExperienceRate,
  async ({agencyId, leadSourceId, payload}) => {
    const { data } = await instance.post(`/agency/expertises/${agencyId}/source/${leadSourceId}`, payload)
    return data
  }
)

export const updateProductAndExperienceRate = createAsyncThunk(
  EAgencyProducts.UpdateProductAndExperienceRate,
  async (payload: any) => {
    const { data } = await instance.patch<IAgencyProductAndExperience[]>(`/agency/expertises`, payload)
    return data
  }
)

export const updateProductAndExperienceRateForLeadSource = createAsyncThunk<any[], ISubmitProductAndExperienceRateForLeadSourceProps>(
  EAgencyProducts.SubmitProductAndExperienceRate,
  async ({agencyId, leadSourceId, payload}) => {
    const { data } = await instance.patch(`/agency/expertises/${agencyId}/source/${leadSourceId}`, payload)
    return data
  }
)
