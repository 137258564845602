import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

import { ESize } from 'models/consts';

import StarRating from 'components/StarRating';

export interface IFeedbackProps {
  onDataSave: () => void;
}

const GeneralFeedback: FunctionComponent<IFeedbackProps> = ({ onDataSave }) => {
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useFormContext();

  return (
    <form id="general-feedback" onSubmit={handleSubmit(onDataSave)}>
      <DialogContent>
        <Typography fontSize="1rem" mb={2}>
          Rate your workerbee
        </Typography>
        <Controller
          control={control}
          name="totalRate"
          render={({ field }) => {
            const { ref, ...restField } = field;
            return (
              <StarRating
                {...restField}
                size={ESize.LARGE}
                onRatingChange={stars => field.onChange(stars)}
              />
            );
          }}
          rules={{ required: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid} fullWidth variant="contained" type="submit">
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

export default GeneralFeedback;
