import { Components } from '@mui/material/styles';

const MuiDialogTitle: Components['MuiDialogTitle'] = {
  styleOverrides: {
    root: ({ theme }: any) => theme.unstable_sx({
      position: 'relative',
      textAlign: 'center',
      fontSize: {
        xs: '1.375rem',
        md: '1.875rem'
      },
      lineHeight: 'normal',
      px: {
        xs: 5,
        md: 11
      },
      pt: 3.5,
      pb: 4
    }),
  },
}

export default MuiDialogTitle
