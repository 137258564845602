import React, {FunctionComponent} from 'react'

import Box from "@mui/material/Box";

import { IFullOrders } from "store/types/order";

import OrderDetails from "../Order/Details";

interface IOrderTooltipProps {
  order: IFullOrders | any;
}
const OrderTooltip: FunctionComponent<IOrderTooltipProps> = ({ order }) => (
  <Box px={1.5} py={2} width={300}>
    <OrderDetails order={order} />
  </Box>
)

export default OrderTooltip
