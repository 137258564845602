import React, { FunctionComponent, useCallback, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { IImageCropperProps } from 'models/inner-models';
import CloseIcon from 'styles/icons/CloseIcon';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  '& .MuiDialogContent-root': {
    padding: 0,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(0),
  },
}));

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ImageCropperModal: FunctionComponent<IImageCropperProps> = ({ image, visible, close }) => {
  const handleClose = () => {
    close && close();
  };

  const cropperRef = useRef<HTMLImageElement>(null);

  const saveImage = useCallback(() => {
    const cropper = (cropperRef?.current as any)?.cropper;
    const croppedImage = cropper.getCroppedCanvas().toDataURL();
    cropper.getCroppedCanvas().toBlob((blob: Blob) => {
      close && close(croppedImage, blob);
    }, 'image/jpeg');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close, image]);

  return (
    <BootstrapDialog onClose={handleClose} open={visible} maxWidth="sm" scroll="body" fullWidth>
      <BootstrapDialogTitle onClose={handleClose} />
      <DialogContent>
        <Cropper
          src={image}
          style={{ height: 400, width: '100%' }}
          initialAspectRatio={1}
          aspectRatio={1}
          guides={false}
          ref={cropperRef}
        />
      </DialogContent>

      <DialogActions sx={{ mt: 2 }}>
        <Button onClick={handleClose} variant="contained">
          Close
        </Button>
        <Button onClick={saveImage} variant="contained">
          Save changes
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ImageCropperModal;
