import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" sx={{ fill: 'transparent' }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.4403 11.0489L12.2503 20.2389C11.1244 21.3648 9.59747 21.9973 8.00529 21.9973C6.41311 21.9973 4.88613 21.3648 3.76029 20.2389C2.63445 19.1131 2.00195 17.5861 2.00195 15.9939C2.00195 14.4018 2.63445 12.8748 3.76029 11.7489L12.9503 2.55894C13.7009 1.80838 14.7188 1.38672 15.7803 1.38672C16.8417 1.38672 17.8597 1.80838 18.6103 2.55894C19.3609 3.30951 19.7825 4.32749 19.7825 5.38894C19.7825 6.4504 19.3609 7.46838 18.6103 8.21894L9.41029 17.4089C9.03501 17.7842 8.52602 17.9951 7.99529 17.9951C7.46456 17.9951 6.95557 17.7842 6.58029 17.4089C6.20501 17.0337 5.99418 16.5247 5.99418 15.9939C5.99418 15.4632 6.20501 14.9542 6.58029 14.5789L15.0703 6.09894"
        stroke="#9A9A9B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
