import { ENotificationsType, ERole } from 'models/consts';
import dayjs from 'utils/dayjs';

export const parseNotification = (notificationInfo: string) => JSON.parse(notificationInfo);

export const notificationDateTime = (createdAt: string) => dayjs(createdAt).fromNow();

export const notificationTitle = ({
  type,
  roleName,
  isConsultant,
}: {
  type: ENotificationsType;
  roleName: ERole;
  isConsultant?: boolean;
}) => {
  switch (type) {
    case ENotificationsType.CREATE_ORDER: {
      if ([ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(roleName)) {
        return 'New Order!';
      }
      return 'Congratulations!'; // 3, 4
    }
    case ENotificationsType.WILL_START_ORDER:
      return 'Coming Order'; // 5
    case ENotificationsType.START_ORDER:
      return 'Order Starts'; // 6
    case ENotificationsType.WILL_END_ORDER:
      return "Order's Completion"; // 7, 8
    case ENotificationsType.COMPLETE_ORDER: {
      if (isConsultant) {
        return "Order's Completion"; // 10
      }
      return 'Leave a Review'; // 9
    }
    case ENotificationsType.REQUEST_EARLY_COMPLETE_ORDER:
      return "Order's Completion"; // 11
    case ENotificationsType.APPROVED_EARLY_COMPLETE_ORDER:
      return "Order's Completion"; // 12
    case ENotificationsType.DECLINE_EARLY_COMPLETE_ORDER:
      return "Order's Completion"; // 13
    case ENotificationsType.APPROVED_FEEDBACK_ORDER: {
      if (isConsultant) {
        return 'Received Review'; // 10
      }
      return 'Approve Review'; // 9
    } // 14
    case ENotificationsType.DECLINE_FEEDBACK_ORDER:
      return 'Declined Review'; // 15
    case ENotificationsType.CHANGE_ORDER_EXTEND_HOURS:
      return 'Extend Order'; // 16
    case ENotificationsType.CHANGE_ORDER_ACCEPT:
      return "Order's Changes"; // 17
    case ENotificationsType.CHANGE_ORDER_REJECT:
      return "Order's Changes"; // 18
    case ENotificationsType.CANCEL_ORDER:
      return 'Order Cancelled'; // 19
    case ENotificationsType.CHANGE_ORDER:
      return "Order's Changes";
    case ENotificationsType.RECEIVE_MESSAGE_CHAT:
      return 'New Message';
    case ENotificationsType.CREATE_WEEKLY_BILLING:
      return 'Submit';
    case ENotificationsType.SUBMITTED_WEEKLY_BILLING:
      return 'Confirm';
    case ENotificationsType.APPROVED_WEEKLY_BILLING:
      return 'Weekly Payout';
    case ENotificationsType.CREATE_WEEKLY_BILLING_INVOICE:
      return 'View Invoice';
    case ENotificationsType.CHANGE_ORDER_UPDATE_TIME_LINE:
      return 'Timeline changes';
    case ENotificationsType.PAID_INVOICE: {
      if (isConsultant) {
        return 'Order Paid For';
      }
      return 'Payment success';
    }
    case ENotificationsType.PAID_ORDER_EXTEND_HOURS:
      return 'Extend Order';
    case ENotificationsType.ON_HOLD_ORDER:
      return 'Order On Hold';
    case ENotificationsType.EXPIRED_ORDER:
      return 'Order Expired';
    case ENotificationsType.TERMINATED_ORDER:
      return 'Order Terminated';
    case ENotificationsType.ADJUSTED_WEEKLY_BILLING:
      return 'Order Adjusted';
    case ENotificationsType.DISPUTED_WEEKLY_BILLING:
      return 'Order Disputed';
    case ENotificationsType.DRAFT_ORDER_CREATE:
      return 'Draft Order!';
    case ENotificationsType.DRAFT_ORDER_APPROVE:
      return 'Draft Approved!';
    case ENotificationsType.DRAFT_ORDER_DECLINE:
      return 'Draft Declined!';
    // Preorder
    case ENotificationsType.PRE_ORDER_CREATE: {
      if (roleName === ERole.LEAD_MANAGER) {
        return 'Preorder Created!';
      }
      return '';
    }
    case ENotificationsType.PRE_ORDER_PENDING_AGENCY: {
      if (roleName === ERole.LEAD_MANAGER) {
        return 'Preorder Pending Agency!';
      }
      if ([ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(roleName)) {
        return 'Preorder Waiting Review!';
      }
      return '';
    }
    case ENotificationsType.PRE_ORDER_AGENCY_ACCEPTED: {
      if (roleName === ERole.LEAD_MANAGER) {
        return 'Preorder Accepted Agency!';
      }
      return '';
    }
    case ENotificationsType.PRE_ORDER_UPDATED: {
      if ([ERole.LEAD_MANAGER, ERole.CUSTOMER_ADMIN].includes(roleName)) {
        return 'Preorder Updated!';
      }
      return '';
    }
    case ENotificationsType.PRE_ORDER_ACCEPTED: {
      if ([ERole.LEAD_MANAGER, ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(roleName)) {
        return 'Preorder Accepted!';
      }
      return '';
    }
    case ENotificationsType.PRE_ORDER_CONFIRMED:
      return 'Preorder Confirmed!';
    case ENotificationsType.PRE_ORDER_PENDING_APPROVAL: {
      if ([ERole.LEAD_MANAGER, ERole.CUSTOMER_ADMIN].includes(roleName)) {
        return 'Preorder Customer Approval!';
      }
      return '';
    }

    // Preorder
    default:
      return "Order's Changes";
  }
};
