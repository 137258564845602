import React, { FunctionComponent, useState } from 'react';

import { Typography, Box } from '@mui/material';

import {StyledDescription, Accordion, AccordionDetails, AccordionSummary } from './styled';

import { AgencyLocationIcon } from 'styles/icons/AgencyLocationIcon';
import ArrowDown from 'styles/icons/ArrowDown';

import Avatar from 'components/Avatar';

interface IAgencyBlock {
  agency: any;
  isAccordion?: boolean;
}

interface IAgencyContainer {
  children: any;
  isAccordion?: boolean;
  expanded: boolean;
  onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

interface IAgencyDetailsInfo {
  children: any;
  isAccordion?: boolean;
}

interface IAgencySummary {
  children: any;
  isAccordion?: boolean;
}

const AgencyContainer: FunctionComponent<IAgencyContainer> = ({
  children,
  isAccordion,
  expanded,
  onChange,
}) => {
  if (isAccordion) {
    return (
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={onChange}
      >
        {children}
      </Accordion>
    );
  }
  return children;
};

const AgencyDetailsInfo: FunctionComponent<IAgencyDetailsInfo> = ({ children, isAccordion }) => {
  if (isAccordion) {
    return (
      <AccordionDetails>
        {children}
      </AccordionDetails>
    );
  }
  return children;
};

const AgencySummary: FunctionComponent<IAgencySummary> = ({ children, isAccordion }) => {
  if (isAccordion) {
    return (
      <AccordionSummary
        expandIcon={<ArrowDown />}
        aria-controls="agency-content"
        id="agency-header"
      >
        {children}
      </AccordionSummary>
    );
  }
  return children;
};

const AgencyBlock: FunctionComponent<IAgencyBlock> = ({
  agency,
  isAccordion = false,
}) => {
  const [expanded, setExpanded] = useState<string | number | undefined>(undefined);

  const handleChange =
    (agencyId: string | number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? agencyId : undefined);
    };

  return (
    <AgencyContainer
      isAccordion={isAccordion}
      expanded={expanded === agency.id}
      onChange={handleChange(agency.id)}
    >
      <AgencySummary isAccordion={isAccordion}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          flexWrap="nowrap"
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Avatar
              size="medium"
              image={agency.logo}
              firstName={agency.name || ''}
            />
            <Typography
              gutterBottom
              variant="h6"
              sx={{
                my: 1,
                wordBreak: 'break-all',
                ml: 2,
              }}
            >
              {agency.name}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            mt={2}
          >
            <Box sx={{ ml: .375, width: 22, mr: 1}}>
              <AgencyLocationIcon />
            </Box>
            <Typography
              component="div"
              sx={{
                alignItems: 'center',
                lineHeight: 'normal',
                display: 'flex',
                flex: '1 0 90%',
              }}
            >
              <strong>{agency.location}</strong>
            </Typography>
          </Box>
        </Box>
      </AgencySummary>
      {agency.description && (
        <AgencyDetailsInfo isAccordion={isAccordion}>
          <StyledDescription>
            {agency.description}
          </StyledDescription>
        </AgencyDetailsInfo>
      )}
    </AgencyContainer>
  );
};

export default AgencyBlock;
