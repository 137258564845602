import React, { FunctionComponent, useState } from 'react';

import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { EOrderFilter, ERole } from 'models/consts';
import { IBasicOrders } from 'store/types/order';

import MoreVerticalIcon from 'styles/icons/MoreVerticalIcon';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import declineDraftOrder from 'store/query/order/declineDraftOrder';
import { getMeRoleSelector, isConsultantSelector } from 'store/selectors/getUserSelector';
import { isOrderDraftCreationLoadingSelector } from 'store/selectors/getOrderDraftCreationSelector';

import ChangeNameModal from '../../Modal/ChangeNameModal/ChangeNameModal';

export interface IOrderMenuProps {
  order: IBasicOrders;
  filter: EOrderFilter;
  handleApproveDraft: () => void;
  onOrderEdit?: (order: IBasicOrders) => void;
}

const Menu: FunctionComponent<IOrderMenuProps> = ({
  filter,
  handleApproveDraft,
  onOrderEdit,
  order,
}) => {
  const dispatch = useAppDispatch();

  const isConsultant = useAppSelector(isConsultantSelector);
  const isFetching = useAppSelector(isOrderDraftCreationLoadingSelector);
  const role = useAppSelector(getMeRoleSelector);

  const [showRenameModal, setVisiblityRename] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeclineDraft = () => {
    dispatch(declineDraftOrder({ id: order.id }));
    handleClose();
  };

  const handleEdit = () => {
    onOrderEdit && onOrderEdit(order);
    handleClose();
  };

  const openRenameModal = () => {
    setVisiblityRename(true);
    handleClose();
  };

  return (
    <>
      <MuiMenu
        anchorEl={anchorEl}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
      >
        {isConsultant && filter === EOrderFilter.DRAFT_ORDERS && (
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
        )}
        {[ERole.CUSTOMER_ADMIN, ERole.SELF_CUSTOMER, ERole.CUSTOMER].includes(role?.name) &&
          filter === EOrderFilter.DRAFT_ORDERS && (
            <>
              <MenuItem onClick={handleApproveDraft} disabled={isFetching}>
                Approve
              </MenuItem>
              <MenuItem onClick={handleDeclineDraft} disabled={isFetching}>
                Decline
              </MenuItem>
            </>
          )}
        {[ERole.CUSTOMER_ADMIN, ERole.SELF_CUSTOMER, ERole.CUSTOMER].includes(role?.name) &&
          order.status !== 'completed' &&
          filter !== EOrderFilter.DRAFT_ORDERS && (
            <MenuItem onClick={openRenameModal}>Rename</MenuItem>
          )}
      </MuiMenu>
      {(filter === EOrderFilter.DRAFT_ORDERS || order.status !== 'completed') && (
        <IconButton onClick={handleClick} size="small" sx={{ alignSelf: 'start' }}>
          <MoreVerticalIcon />
        </IconButton>
      )}
      <ChangeNameModal
        filter={filter}
        order={order}
        visible={showRenameModal}
        close={() => setVisiblityRename(false)}
      />
    </>
  );
};
export default Menu;
