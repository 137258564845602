import React from 'react';

import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

export default (props: SvgIconProps) => (
  <SvgIcon {...props} sx={{fontSize: '1.25rem'}} viewBox="0 0 27 15" fill="none">
    <path d="M0 7.5C4.54431 1.84032 16.2887 -6.08322 26.9118 7.5H0Z" fill="currentColor"/>
    <path d="M26.9121 7.5C22.3678 13.1597 10.6234 21.0832 0.000345756 7.5L26.9121 7.5Z" fill="currentColor"/>
    <circle cx="13.4559" cy="7.27937" r="5.95588" fill="white"/>
    <circle cx="13.4563" cy="7.27953" r="3.30882" fill="currentColor"/>
  </SvgIcon>
);

