import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MuiLink from '@mui/material/Link';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import PowerIcon from '@mui/icons-material/PowerSettingsNew';
import PauseCircleIcon from '@mui/icons-material/PauseCircleOutline';
import RefreshIcon from '@mui/icons-material/Autorenew';

import { byRole } from 'models/consts';
import { IEnterpriseUser } from 'store/types/enterprise';

import BootstrapInput from '../../CustomFields/BootstrapInput';
import TableCell from '../../TableCustomized/TableCell';
import TableRow from '../../TableCustomized/TableRow';
import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { IBasicUser } from 'store/types/user';

interface IEnterpriseTeamRowProps {
  row: IEnterpriseUser;
  onChangeStatus: (row: IEnterpriseUser, status: string) => void;
  onChangeRole: (event: SelectChangeEvent, id: number) => void;
}

const invited = (user: IEnterpriseUser) => {
  return !user.isActive && !user.lastChangeActiveAt;
};

const EnterpriseTeamRow: FunctionComponent<IEnterpriseTeamRowProps> = ({
  row,
  onChangeRole,
  onChangeStatus,
}) => {
  const me = useAppSelector(getMeSelector) as IBasicUser;

  return (
    <TableRow key={row.email}>
      <TableCell>
        <Typography fontWeight={500}>{row.email}</Typography>
      </TableCell>
      <TableCell>
        <Chip
          className={invited(row) ? 'created' : (row.isActive && 'active') || 'terminated'}
          label={invited(row) ? 'Pending Invitation' : (row.isActive && 'Active') || 'Inactive'}
          variant="filled"
        />
      </TableCell>
      <TableCell>
        <Box mr={10}>
          <MuiSelect
            displayEmpty
            label="Select role"
            labelId="select-role"
            input={<BootstrapInput />}
            value={String(row.role.id) || ''}
            sx={{ textTransform: 'capitalize', height: 42, fontSize: '.875rem' }}
            onChange={event => {
              onChangeRole(event, row.id);
            }}
            disabled={me.id === row.id || row.isActive === null}
          >
            {byRole?.map(({ id, name }: { id: number; name: string }) => (
              <MenuItem key={name} sx={{ textTransform: 'capitalize' }} value={id}>
                {name}
              </MenuItem>
            ))}
          </MuiSelect>
        </Box>
      </TableCell>
      <TableCell>
        {!invited(row) && row.isActive && me.id !== row.id && (
          <MuiLink
            color="secondary"
            onClick={() => onChangeStatus(row, 'active')}
            sx={{ display: 'inline-flex' }}
            underline="none"
          >
            <PauseCircleIcon />
            <Typography component="span" lineHeight="20px" ml={1}>
              Pause
            </Typography>
          </MuiLink>
        )}
        {!invited(row) && !row.isActive && me.id !== row.id && (
          <MuiLink
            color="secondary"
            onClick={() => onChangeStatus(row, 'deactive')}
            sx={{ display: 'inline-flex', alignItems: 'center', stroke: 'none !important' }}
            underline="none"
          >
            <PowerIcon />
            <Typography component="span" lineHeight="20px" ml={1}>
              Activate
            </Typography>
          </MuiLink>
        )}
        {invited(row) && me.id !== row.id && (
          <MuiLink
            color="secondary"
            onClick={() => onChangeStatus(row, 'refresh')}
            sx={{ display: 'inline-flex', alignItems: 'center', stroke: 'none !important' }}
            underline="none"
          >
            <RefreshIcon />
            <Typography component="span" lineHeight="normal" ml={1}>
              Resend
            </Typography>
          </MuiLink>
        )}
      </TableCell>
    </TableRow>
  );
};

export default EnterpriseTeamRow;
