import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import {ECommon} from "../../constants";
import {ICategoryWithProducts, IModuleOfProduct, IWorkTypesOfProduct} from "../../types/categoryProducts";

interface IResponse {
  modules: IModuleOfProduct[];
  products: ICategoryWithProducts[];
  works: IWorkTypesOfProduct[];
}

const fetchProductsWithModulesWorks = createAsyncThunk<IResponse>(
  ECommon.orderProductWithModulesWorks,
  async () => {
    const { data: products } = await instance.get('categories/products')
    const { data: modules } = await instance.get('products-modules')
    const { data: works } = await instance.get('products-works')
    return { modules, products, works }
  }
)

export default fetchProductsWithModulesWorks
