import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../middlewares/api";
import { EAgencyProducts } from "store/constants/productAndExperience";

export const fetchAllProductsAndExperience = createAsyncThunk<any[], number>(
  EAgencyProducts.AllProductsAndExperience,
  async (agencyId) => {
    const { data } = await instance.get(`/agency/expertises/${agencyId}/expertises`)
    return await data
  }
)

interface FetchExpertisesForSourceProps {
  agencyId: number
  leadSourceId: number
}

export const fetchAllProductsAndExperienceForSource = createAsyncThunk<any[], FetchExpertisesForSourceProps>(
  EAgencyProducts.AllProductsAndExperience,
  async ({agencyId, leadSourceId}) => {
    const { data } = await instance.get(`/agency/expertises/${agencyId}/expertises/${leadSourceId}`)
    return await data
  }
)

export default fetchAllProductsAndExperience
