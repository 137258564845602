import {createAsyncThunk} from "@reduxjs/toolkit";

import instance from "../../middlewares/api";
import { EUser } from "../../constants";
import { IUserLanguages } from "../../types/language";

interface IAddUserLanguagesProps {
  languages: any;
  userId: string | number
}

const addUserLanguages = createAsyncThunk<IUserLanguages[], IAddUserLanguagesProps>(
  EUser.addUserLanguages,
  async ({ languages }) => {
    const { data } = await instance.post('consultant/languages', languages)
    return data
  }
)

export default addUserLanguages
