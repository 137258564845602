import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const StyledTitleContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    my: 1,
  }),
);

export { StyledTitleContainer };
