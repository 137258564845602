import React from 'react';

import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

export default (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 17 17">
    <path d="M2.26109 8.95671L7.64014 14.2113C7.76359 14.3309 7.86156 14.4731 7.92843 14.6298C7.99529 14.7865 8.02971 14.9546 8.02971 15.1243C8.02971 15.2941 7.99529 15.4622 7.92843 15.6189C7.86156 15.7756 7.76359 15.9178 7.64014 16.0374C7.51764 16.1579 7.37193 16.2535 7.21139 16.3188C7.05084 16.384 6.87866 16.4176 6.70475 16.4176C6.53084 16.4176 6.35865 16.384 6.19811 16.3188C6.03757 16.2535 5.89185 16.1579 5.76935 16.0374L0.385986 10.7828C0.138772 10.5402 0 10.2119 0 9.86973C0 9.52756 0.138772 9.19931 0.385986 8.95671C0.635262 8.71515 0.972277 8.57959 1.32354 8.57959C1.6748 8.57959 2.01182 8.71515 2.26109 8.95671Z"/>
    <path d="M2.40367 3.25477L13.6111 14.1941C13.7345 14.3137 13.8325 14.4559 13.8994 14.6126C13.9662 14.7693 14.0007 14.9374 14.0007 15.1072C14.0007 15.2769 13.9662 15.445 13.8994 15.6017C13.8325 15.7584 13.7345 15.9006 13.6111 16.0202C13.4886 16.1407 13.3429 16.2363 13.1823 16.3016C13.0218 16.3668 12.8496 16.4004 12.6757 16.4004C12.5018 16.4004 12.3296 16.3668 12.1691 16.3016C12.0085 16.2363 11.8628 16.1407 11.7403 16.0202L0.532883 5.08081C0.409437 4.96124 0.311458 4.81901 0.244596 4.66231C0.177733 4.50561 0.143311 4.33754 0.143311 4.16779C0.143311 3.99804 0.177733 3.82997 0.244596 3.67327C0.311458 3.51657 0.409437 3.37433 0.532883 3.25477C0.655378 3.13427 0.801097 3.03864 0.961638 2.97338C1.12218 2.90811 1.29437 2.87451 1.46828 2.87451C1.64218 2.87451 1.81437 2.90811 1.97491 2.97338C2.13545 3.03864 2.28117 3.13427 2.40367 3.25477Z"/>
    <path d="M5.86871 0.796272L15.9442 10.6265C16.1927 10.8693 16.3323 11.1985 16.3323 11.5417C16.3323 11.8849 16.1927 12.214 15.9442 12.4568C15.6956 12.6981 15.3593 12.8335 15.0088 12.8335C14.6582 12.8335 14.3219 12.6981 14.0734 12.4568L3.99792 2.62231C3.75071 2.37972 3.61194 2.05146 3.61194 1.70929C3.61194 1.36712 3.75071 1.03887 3.99792 0.796272C4.12042 0.675778 4.26614 0.580142 4.42668 0.514879C4.58722 0.449616 4.75941 0.416016 4.93332 0.416016C5.10723 0.416016 5.27941 0.449616 5.43996 0.514879C5.6005 0.580142 5.74621 0.675778 5.86871 0.796272Z"/>
    <path d="M11.9244 0.912704C11.4081 0.408748 10.571 0.408749 10.0547 0.912704C9.53842 1.41666 9.53842 2.23373 10.0547 2.73769L14.0966 6.68287C14.6129 7.18683 15.45 7.18683 15.9663 6.68287C16.4826 6.17892 16.4826 5.36185 15.9663 4.85789L11.9244 0.912704Z"/>
  </SvgIcon>
);
