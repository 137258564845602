import React, { FunctionComponent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import useExtendHours from 'hooks/useExtendHours';
import useCalculatePrice from 'hooks/useCalculatePrice';

import { getEndDate } from 'utils/dateFormatter';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ExtendHeaderCellBox } from './styled';
import dayjs from 'utils/dayjs';

import extendHours from 'store/query/order/extendHours';

import { currentOrderSelector } from 'store/selectors/getOrderSelector';
import { getMeRoleSelector } from 'store/selectors/getUserSelector';
import { purchaseOrderSelector } from 'store/selectors/getEnterpriseSelector';

import WeeklyCommitmentForm from './WeeklyCommitmentForm';
import WeeklyCommitmentTable from './WeeklyCommitmentTable';
import NumberOfHoursForm from './NumberOfHoursForm';
import NumberOfHoursTable from './NumberOfHoursTable';

interface IExtendHoursProps {
  close: (isSaved?: boolean) => void;
}

const ExtendHours: FunctionComponent<IExtendHoursProps> = ({ close }) => {
  const { id = '' } = useParams();

  const dispatch = useAppDispatch();

  const order = useAppSelector(currentOrderSelector(id));
  const role = useAppSelector(getMeRoleSelector);

  const purchaseOrder = useAppSelector(purchaseOrderSelector(order?.purchaseOrderId));

  const { calculatedPrice } = useCalculatePrice(order);

  const totalHours = useMemo(() => {
    let total = 0;
    if (order) {
      total += order.timeLine.actualHours || order.timeLine.allHours;
    }
    return total;
  }, [order]);

  const methodsWeeklyCommitment = useForm({
    mode: 'onChange',
    defaultValues: {
      weeks: 0,
      availability: 0,
    },
  });

  const methodsNumberOfHours = useForm({
    mode: 'onChange',
    defaultValues: {
      hours: null,
      endDate: '',
    },
  });

  const { totalWeeks, setNewAvailability, setNewWeeks, setNewHours, outstanding, updated } =
    useExtendHours({
      order,
    });

  const { errors, isValid } = useMemo(() => {
    const isValid1 = methodsWeeklyCommitment.formState.isValid;
    const isValid2 = methodsNumberOfHours.formState.isValid;

    return {
      errors: {
        ...methodsNumberOfHours.formState.errors,
        ...methodsWeeklyCommitment.formState.errors,
      },
      isValid: isValid1 && isValid2,
    };
  }, [methodsNumberOfHours.formState, methodsWeeklyCommitment.formState]);

  const actualizedAvailability = useMemo(() => {
    return updated.availability && updated.hours && updated.availability % 1
      ? Math.ceil(updated.availability)
      : null;
  }, [updated.availability, updated.hours]);

  const actualizedHours = useMemo(() => {
    if (actualizedAvailability) {
      if (updated?.weeks) {
        return updated.weeks * actualizedAvailability - (outstanding.hours || 0);
      } else if (outstanding.weeks) {
        return outstanding.weeks * actualizedAvailability - (outstanding.hours || 0);
      }
    }
    return null;
  }, [actualizedAvailability, updated.weeks, outstanding]);

  const onSubmit: SubmitHandler<any> = ({ availability, endDate, hours, weeks }: any) => {
    if (order?.id) {
      let endDateNew = endDate;
      if ((!!availability || !!weeks) && !!order.timeLine.endDate) {
        const dayOfWeek = dayjs(order.timeLine.endDate).day(); // 0 (Sunday) to 6 (Saturday)
        const daysUntilNextMonday = (8 - dayOfWeek) % 7; // 8 - dayOfWeek - guarantees next Monday
        endDateNew = getEndDate(
          dayjs(order.timeLine.endDate).add(daysUntilNextMonday, 'day').format('YYYY-MM-DD'),
          availability * weeks,
          availability,
        );
      }

      dispatch(
        extendHours({
          orderId: order.id,
          extendHours: {
            hours: actualizedHours || +hours || updated.hours - outstanding.hours,
            endDate: endDateNew || order?.timeLine.endDate,
            availability: updated.availability || 0,
          },
          role,
          isPurchase: !!order.purchaseOrder,
        }),
      )
        .unwrap()
        .then(() => close(true));
    }
  };

  const additionalSum = useMemo(() => {
    return (updated.hours - outstanding.hours) * calculatedPrice;
  }, [updated.hours, outstanding.hours, calculatedPrice]);

  const balanceRemaining = useMemo(() => {
    return (purchaseOrder?.balanceCents || 0) / 100;
  }, [purchaseOrder?.balanceCents]);

  const remainingNotEnough = useMemo(() => {
    return !!(purchaseOrder && additionalSum && balanceRemaining < additionalSum);
  }, [purchaseOrder, additionalSum, balanceRemaining]);

  return (
    <>
      <DialogContent>
        <Grid container id="extend-hours" width="100%" justifyContent="center">
          <Grid xs={12}>
            <Box mb={5} width="100%" display="table">
              <Box display="table-header-group">
                <Box display="table-row">
                  <ExtendHeaderCellBox pl={0}>Current Order</ExtendHeaderCellBox>
                  <ExtendHeaderCellBox>Outstanding</ExtendHeaderCellBox>
                  <ExtendHeaderCellBox pr={0}>Order Updates</ExtendHeaderCellBox>
                </Box>
              </Box>
              <Box display="table-row-group">
                {order?.timeLine.flexibleHours && (
                  <NumberOfHoursTable
                    outstanding={outstanding}
                    order={order}
                    watch={methodsNumberOfHours.watch}
                    totalHours={totalHours}
                    totalWeeks={totalWeeks}
                    updated={updated}
                  />
                )}
                {!order?.timeLine.flexibleHours && (
                  <WeeklyCommitmentTable
                    outstanding={outstanding}
                    order={order}
                    watch={methodsWeeklyCommitment.watch}
                    totalHours={totalHours}
                    totalWeeks={totalWeeks}
                    updated={updated}
                  />
                )}
              </Box>
            </Box>

            {purchaseOrder && (
              <Box style={{ backgroundColor: '#F5F5F5' }} px={3} py={2} mb={4}>
                <Typography
                  component="h6"
                  style={{
                    fontSize: '1.125rem',
                    fontWeight: 500,
                    textAlign: 'center',
                  }}
                  mb={2}
                >
                  Payment Authorization details
                </Typography>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={4} mb={1}>
                    <Typography variant="subtitle1">Balance remaining</Typography>
                    <Typography>$ {balanceRemaining.toFixed(2)}</Typography>
                  </Grid>
                  <Grid xs={12} sm={4} mb={1}>
                    <Typography variant="subtitle1">End Date</Typography>
                    <Typography>{dayjs(purchaseOrder.endDate).format('MM/DD/YYYY')}</Typography>
                  </Grid>
                  <Grid xs={12} sm={4} mb={1}>
                    <Typography variant="subtitle1">Payment Authorization #</Typography>
                    <Typography>{purchaseOrder.name}</Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Typography component="h6" style={{ fontSize: '1.125rem' }}>
              How do you want to extend this order?
            </Typography>
            <Box
              component="form"
              id="extend-hours-form"
              onSubmit={
                (order?.timeLine.flexibleHours && methodsNumberOfHours.handleSubmit(onSubmit)) ||
                methodsWeeklyCommitment.handleSubmit(onSubmit)
              }
              noValidate
              display="flex"
              flexDirection="column"
            >
              {order?.timeLine.flexibleHours && (
                <FormProvider {...methodsNumberOfHours}>
                  <NumberOfHoursForm
                    setNewAvailability={setNewAvailability}
                    setNewHours={setNewHours}
                    setNewWeeks={setNewWeeks}
                    timeLine={order.timeLine}
                  />
                </FormProvider>
              )}
              {!order?.timeLine.flexibleHours && (
                <FormProvider {...methodsWeeklyCommitment}>
                  <WeeklyCommitmentForm
                    setNewAvailability={setNewAvailability}
                    setNewWeeks={setNewWeeks}
                  />
                </FormProvider>
              )}
              {((errors.weeks?.type === 'requiredAtLeastOne' &&
                errors.availability?.type === 'requiredAtLeastOne') ||
                (errors.hours?.type === 'requiredAtLeastOne' &&
                  errors.endDate?.type === 'requiredAtLeastOne')) && (
                <FormControl error sx={{ margin: '-20px 0px 8px' }}>
                  <FormHelperText>Required filled at least one field</FormHelperText>
                </FormControl>
              )}
              {actualizedHours && (
                <Box mb={4}>
                  Actualized number added hours = <b>{actualizedHours}</b>
                </Box>
              )}
              <Box mb={4}>
                This order will not be updated until the specialist approves the order updates and
                the remaining balance is paid.
                <br />
                <b>Do you want to proceed?</b>
              </Box>

              {remainingNotEnough && (
                <FormControl error sx={{ margin: '-20px 0px 8px' }}>
                  <FormHelperText>
                    It seems that the payment authorization doesn’t have enough budget for this
                    extension. Please contact our Sales team directly and they will be able to help
                    you
                  </FormHelperText>
                </FormControl>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          disabled={!isValid || remainingNotEnough}
          fullWidth
          type="submit"
          variant="contained"
          form="extend-hours-form"
        >
          Send Request
        </Button>
      </DialogActions>
    </>
  );
};

export default ExtendHours;
