import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EAgency } from 'store/constants';

const getAgencyStripeDashboardLink = createAsyncThunk(
  EAgency.getAgencyStripeDashboardLink,
  async (payload: {redirectUri?: string}) => {
    const response = await instance.post('agency/payment/stripe-agency-dashboard', {...payload});
    return await response.data;
  }
);

export default getAgencyStripeDashboardLink;
