import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';

import { pluralizeWord } from 'utils/transformers';

import { CalculatedValue, ExtendCellBox } from './styled';

const WeeklyCommitmentTable: FunctionComponent<any> = ({
  order,
  outstanding,
  totalHours,
  totalWeeks,
  updated,
  watch,
}) => {
  const { availability, weeks } = watch();

  return (
    <>
      <Box display="table-row">
        <ExtendCellBox pl={0} pt={0.75}>
          {totalWeeks} {pluralizeWord('week', totalWeeks)}
        </ExtendCellBox>
        <ExtendCellBox pt={0.75}>
          <b>
            {outstanding.weeks} {pluralizeWord('week', outstanding.weeks)}
          </b>
        </ExtendCellBox>
        <ExtendCellBox pr={0} pt={0.75}>
          {availability || weeks ? (
            updated.weeks ? (
              <CalculatedValue sx={{ backgroundColor: '#FED45B', borderRadius: '4px' }}>
                {updated.weeks || outstanding.weeks}{' '}
                {pluralizeWord('week', updated.weeks || outstanding.weeks)}
              </CalculatedValue>
            ) : (
              <CalculatedValue>
                {outstanding.weeks} {pluralizeWord('week', outstanding.weeks)}
              </CalculatedValue>
            )
          ) : (
            <CalculatedValue>-</CalculatedValue>
          )}
        </ExtendCellBox>
      </Box>
      <Box display="table-row">
        <ExtendCellBox pl={0}>{order?.timeLine.availabilityWeek} h/week</ExtendCellBox>
        <ExtendCellBox>
          <b>{outstanding.availability} h/week</b>
        </ExtendCellBox>
        <ExtendCellBox pr={0}>
          {availability || weeks ? (
            updated.availability ? (
              <CalculatedValue sx={{ backgroundColor: '#FED45B', borderRadius: '4px' }}>
                {updated.availability || outstanding.availability} h/week
              </CalculatedValue>
            ) : (
              <CalculatedValue>{outstanding.availability} h/week</CalculatedValue>
            )
          ) : (
            <CalculatedValue>-</CalculatedValue>
          )}
        </ExtendCellBox>
      </Box>
      <Box display="table-row">
        <ExtendCellBox pl={0}>Total Hours: {totalHours}h</ExtendCellBox>
        <ExtendCellBox>
          <b>{outstanding.hours} h</b>
        </ExtendCellBox>
        <ExtendCellBox pr={0}>
          {availability || weeks ? (
            updated.hours ? (
              <CalculatedValue sx={{ backgroundColor: '#FED45B', borderRadius: '4px' }}>
                {updated.hours} h
              </CalculatedValue>
            ) : (
              <CalculatedValue>{outstanding.hours} h</CalculatedValue>
            )
          ) : (
            <CalculatedValue>-</CalculatedValue>
          )}
        </ExtendCellBox>
      </Box>
    </>
  );
};

export default WeeklyCommitmentTable;
