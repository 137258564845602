import { createSelector } from "@reduxjs/toolkit";
import { DefaultRootState } from "react-redux";

import flowRight from 'lodash/fp/flowRight'
import compact from 'lodash/fp/compact'
import map from 'lodash/fp/map'
import getOr from 'lodash/fp/getOr'

import { IFullOrders } from "../types/order";

const getOrders = (orderById: Record<string, any>) =>
  flowRight(
    compact,
    map((id: number) => id && orderById[id].order),
  );

const orderState = (state: DefaultRootState) => state.order;
const preOrdersById = (state: DefaultRootState) => state.order.preOrdersById;

export const selectOrderIds = (key: string) => createSelector(
  orderState,
  getOr([])(['preOrderList', key, 'ids'])
);

/**
 *  Get Pre Orders List
 */
export const preOrderListSelector = (key: string) => createSelector(
  selectOrderIds(key),
  preOrdersById,
  (ids, orderById) => getOrders(orderById)(ids) as IFullOrders[]
);
export const isPreOrdersLoadedSelector = (key: string) => createSelector(
  orderState,
  getOr(false)(['preOrderList', key, 'loaded'])
);
export const isPreOrdersLoadingSelector = (key: string) => createSelector(
  orderState,
  getOr(false)(['preOrderList', key, 'loading'])
);
export const hasMorePreOrderSelectorSelector = (key: string) => createSelector(
  orderState,
  getOr(true)(['preOrderList', key, 'hasMore'])
);

/**
 *  Get PreOrder By Id
 */
export const currentPreOrderSelector = (id: string | number) => createSelector(
  preOrdersById,
  getOr(undefined)([id, 'order'])
);
export const currentPreOrderTimelineSelector = (id: string) => createSelector(
  preOrdersById,
  getOr(null)([id, 'order', 'timeLine'])
);
export const currentPreOrderLoadedSelector = (id: string) => createSelector(
  preOrdersById,
  getOr(false)([id, 'order', 'loaded'])
);
export const currentPreOrderLoadingSelector = (id: string) => createSelector(
  preOrdersById,
  getOr(false)([id, 'order', 'loading'])
);

/**
 *  Fetch Order History
 */
export const preorderHistorySelector = (id: string) => createSelector(
  preOrdersById,
  getOr([])([id, 'history', 'items'])
);
