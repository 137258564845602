import { Components } from '@mui/material/styles';

const MuiFormHelperText: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: ({ theme }: any) =>
      theme.unstable_sx({
        // position: 'absolute',
        // bottom: -20,
        ml: 0,
      }),
  },
};

export default MuiFormHelperText;
